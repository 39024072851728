/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Avatar,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ShowMoreText from 'react-show-more-text';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const InsightsBy = (props) => {
  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  return (
    <Paper className="WhitePaper" component="div">
      <Box className="CommonDetailBoxGridSecond">
        <Grid container>
          <Grid item xs={12}>
            <Box className="HeadingBoxCD FlexCenterStart">
              <img
                className="BadgeIcon"
                src={require('../../../assets/images/Badge-Icon.svg').default}
                alt="Badge Icon"
              />{' '}
              <Typography variant="h4" component="span" className="BadgeHeadingCD">
                <span>{communityDetails?.communityNameText}</span> is a Represented Community
              </Typography>
            </Box>
            <Box className="HeadingBoxRC">
              <Accordion className="AccordionTransparent">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="ExpandIcon" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h4" color="primary" component="div" className="HeadingRC">
                    What is a represented community?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="AccordionContentRC">
                    <Typography variant="body2" component="div">
                      As hard as we work to get you the information you need to know about a
                      Community, nothing beats a first-hand account of what the Community looks and
                      feels like.
                    </Typography>
                    <Box mt={2.5}>
                      <Typography variant="body2" component="div">
                        Read through the Community Insights to see how{' '}
                        <span>{communityDetails?.communityNameText}</span> could be the perfect spot
                        for you.
                      </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <If condition={communityDetails?.isRepresented}>
              {' '}
              <Box className="HeadingBoxRC">
                <If
                  condition={
                    props?.isAccessible &&
                    !props?.publicView &&
                    communityDetails?.userrole?.[0]?.userDescription !== ''
                  }
                >
                  <Typography variant="h5" component="div" className="Lightgray">
                    Insights provided by
                  </Typography>

                  <Box className="UserInfoName UserInfoIP">
                    <Button
                      type="submit"
                      className="EditCommonButton LinkTypeButton"
                      startIcon={<EditPenIconGreen />}
                      size="small"
                      color="primary"
                      href={'/#/app/community-details-edit-community-reps/' + communityDetails?.id}
                    >
                      Edit
                    </Button>
                    <Avatar
                      className="UserAvatar small"
                      alt={`${communityDetails?.userrole[0]?.firstName}}`}
                      src={
                        window.location.hostname === 'localhost'
                          ? `//localhost:9001/${communityDetails?.userrole[0]?.profilePic}`
                          : `/api/${communityDetails?.userrole[0]?.profilePic}`
                      }
                    >
                      <PersonIcon />
                    </Avatar>
                    <Box className="UserInfoText">
                      <Typography variant="h6">
                        <Box component="span" className="FirstName">
                          {communityDetails?.userrole[0]?.firstName}
                        </Box>
                        <Box component="span" className="LastName">
                          {communityDetails?.userrole[0]?.lastName}
                        </Box>
                      </Typography>
                      <Typography variant="caption">
                        {communityDetails?.userrole[0]?.userDesignationText}
                      </Typography>
                    </Box>
                  </Box>
                </If>
                <ShowMoreText
                  /* Default options */
                  lines={3}
                  more="Read more"
                  less="Show less"
                  className="ReadMoreBox"
                  anchorClass="show-more-less-clickable"
                  // onClick={this.executeOnClick}
                  expanded={false}
                  // width={280}
                  truncatedEndingComponent={'... '}
                >
                  <Typography variant="body2" component="div">
                    {communityDetails?.userrole[0]?.userDescription}
                  </Typography>
                </ShowMoreText>
              </Box>
            </If>
          </Grid>
        </Grid>
      </Box>
      {/* <Grid item xs={12}>
        <Divider className="DividerCD"></Divider>
      </Grid> */}

      <If condition={props?.isAccessible && !props?.publicView}>
        <If condition={communityDetails?.userrole[0]?.userDescription == ''}>
          <Grid item xs={12}>
            <Box>
              <Typography variant="body2" className="InsightLabel">
                Add a quick bio about about yourself and your involvement in your Community
              </Typography>
            </Box>
          </Grid>
        </If>
        <If
          condition={
            // props?.loggedInUser?.id &&
            // props?.loggedInUser?.id === communityDetails?.userrole[0]?.userId &&
            communityDetails?.userrole?.[0] &&
            (communityDetails?.userrole?.[0]?.userDescription == '' ||
              communityDetails?.userrole?.[0]?.userDesignationText == '')
          }
        >
          <Grid item xs={12}>
            <div className="Wrapper ButtonWrapper InsightButtonContainer">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                href={'/#/app/community-details-edit-community-reps/' + communityDetails?.id}
              >
                <Box className="AddButton">Add Bio</Box>
              </Button>
            </div>
          </Grid>
        </If>
      </If>
    </Paper>
  );
};
InsightsBy.defaultProps = {};
export default InsightsBy;
