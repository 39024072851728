/* eslint-disable */
import axios from 'axios';
import ajax from '../utils/ajaxHelper';
import { useCallback } from 'react';
import { jBoardImporter } from '../constants/globalConstants';
import moment from 'moment';

const useJobList = () => {
  const headers = {
    Authorization: 'Bearer sAKaN0snYeQLYh8PVkAFJ5xpSxh5FlE9DnQJUaGOyN1R5Vez22Ne9Dpw5F7n',
    'Content-Type': 'application/json',
  };

  let jboard;
  const getJboardImportIds = useCallback(async (val) => {
    const values = {
      communityId: val.communityId,
    };
    jboard = await axios.post(`community/get-jboards`, values);

    return jboard;
  });

  // const getJobListByCommunity = useCallback(async (val) => {
  //   const communityName = val?.communityName;
  //   if (val?.searchObject) {
  //     const { jobTitle, jobCat, jobType, jobIsRemote, jobPostedAt } = val?.searchObject || null;
  //   }

  //   // const apiUrl = `https://app.jboard.io/api/jobs?jobs?q=Job%20list&location=Lagrange&location_id=1428947&filter%5Bfinal_status%5D=active`;

  //   try {
  //     const allImporters = jBoardImporter[communityName] || [];
  //     const fetchImportersPromises = await allImporters.map(async (imp) => {
  //       const apiUrl = `https://app.jboard.io/api/jobs?&filter%5Bjob_importer_id%5D=${imp}&filter%5Bfinal_status%5D=active&page=1&perPage=100`;
  //       //const apiUrl = `https://app.jboard.io/api/jobs?filter%5Bposted_at_from%5D=${oneWeekAgoUTCDateString}&filter%5Bposted_at_to%5D=${currentUTCDateString}&filter%5Bfinal_status%5D=active&page=1&perPage=100`;
  //       // Making a GET request
  //       const getPage1Response = await axios.get(apiUrl, { headers });
  //       console.log(allImporters, 'JIM');

  //       if (getPage1Response.data?.total > 100) {
  //         const allPages = getPage1Response.data?.last_page;

  //         let fetchPromises = [];

  //         for (let pageNo = 2; pageNo <= allPages; pageNo++) {
  //           let apiUrlPage = `https://app.jboard.io/api/jobs?&filter%5Bjob_importer_id%5D=${imp}&filter%5Bfinal_status%5D=active&page=${pageNo}&perPage=100`;
  //           const result = await axios.get(apiUrlPage, { headers });
  //           fetchPromises.push(result?.data?.items);
  //         }

  //         const fieldsDataArray = await Promise.all(fetchPromises);
  //         const flattenedSecondArray = [].concat(...fieldsDataArray);

  //         const allPageJobs = [...getPage1Response.data?.items, ...flattenedSecondArray];
  //         return allPageJobs;
  //       } else {
  //         return getPage1Response?.data?.items;
  //       }
  //     });

  //     const result = await Promise.all(fetchImportersPromises);
  //     const finalListOfJobs = [].concat(...result);
  //     return finalListOfJobs;
  //     // // Making a POST request
  //     // const postResponse = await axios.post(apiUrl, requestBody, { headers });
  //     // console.log('POST Response:', postResponse.data);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // });

  const getJobListByCommunity = useCallback(async (val) => {
    try {
      const values = {
        communityId: val.communityId,
      };

      return await ajax.post(`community/get-job-list`, values);
    } catch (error) {
      return error;
    }
  });

  const getRecentJobListByCommunity = useCallback(async (val) => {
    try {
      const values = {
        communityId: val.communityId,
      };

      return await ajax.post(`community/get-recent-job-list`, values);
    } catch (error) {
      return error;
    }
  });

  // const getRecentJobListByCommunity = useCallback(async (val) => {
  //   const communityName = val.communityName;
  //   // const apiUrl = `https://app.jboard.io/api/jobs?jobs?q=Job%20list&location=Lagrange&location_id=1428947&filter%5Bfinal_status%5D=active`;

  //   try {
  //     const currentUTCDateString = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  //     const oneWeekAgoDate = moment().subtract(1, 'month');
  //     const oneWeekAgoUTCDateString = oneWeekAgoDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  //     console.log(currentUTCDateString);
  //     const allImporters = jBoardImporter[communityName] || [];

  //     const fetchImportersPromises = allImporters.map(async (imp) => {
  //       const apiUrl = `https://app.jboard.io/api/jobs?filter%5Bposted_at_from%5D=${oneWeekAgoUTCDateString}&filter%5Bposted_at_to%5D=${currentUTCDateString}&filter%5Bjob_importer_id%5D=${imp}&filter%5Bfinal_status%5D=active&page=1&perPage=100`;
  //       //const apiUrl = `https://app.jboard.io/api/jobs?filter%5Bposted_at_from%5D=${oneWeekAgoUTCDateString}&filter%5Bposted_at_to%5D=${currentUTCDateString}&filter%5Bfinal_status%5D=active&page=1&perPage=100`;
  //       // Making a GET request
  //       const getPage1Response = await axios.get(apiUrl, { headers });
  //       if (getPage1Response.data?.total > 100) {
  //         const allPages = getPage1Response.data?.last_page;
  //         let fetchPromises = [];

  //         for (let pageNo = 2; pageNo <= allPages; pageNo++) {
  //           let apiUrlPage = `https://app.jboard.io/api/jobs?filter%5Bposted_at_from%5D=${oneWeekAgoUTCDateString}&filter%5Bposted_at_to%5D=${currentUTCDateString}&filter%5Bfinal_status%5D=active&page=${pageNo}&perPage=100`;
  //           const result = await axios.get(apiUrlPage, { headers });
  //           fetchPromises.push(result?.data?.items);
  //         }

  //         const fieldsDataArray = await Promise.all(fetchPromises);
  //         const flattenedSecondArray = [].concat(...fieldsDataArray);

  //         const allPageJobs = [...getPage1Response.data?.items, ...flattenedSecondArray];
  //         return allPageJobs;
  //       } else {
  //         return getPage1Response?.data?.items;
  //       }
  //     });

  //     const result = await Promise.all(fetchImportersPromises);
  //     const finalListOfJobs = [].concat(...result);
  //     return finalListOfJobs;
  //     // // Making a POST request
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // });

  const getJobDetails = useCallback(async (id) => {
    try {
      console.log(headers, 8988);
      const apiUrl = `https://app.jboard.io/api/jobs/${id}`;
      const result = await axios.get(apiUrl, { headers });
      console.log(result, 8989);
      return result;
      // // Making a POST request
    } catch (error) {
      console.error('Error:', error);
    }
  });
  const getEmployerDetails = useCallback(async (id) => {
    try {
      const apiUrl = `https://app.jboard.io/api/employers/${id}`;
      const result = await axios.get(apiUrl, { headers });

      return result;
      // // Making a POST request
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const getDifferenceInHrs = (timestamp) => {
    const givenTimestamp = moment.utc(timestamp);
    const currentTimestamp = moment.utc();
    const timeDifferenceInHours = currentTimestamp.diff(givenTimestamp, 'hours');

    if (timeDifferenceInHours >= 30 * 24) {
      const months = Math.floor(timeDifferenceInHours / (30 * 24));
      return `${months} m`;
    } else if (timeDifferenceInHours >= 7 * 24) {
      const weeks = Math.floor(timeDifferenceInHours / (7 * 24));
      return `${weeks} w`;
    } else if (timeDifferenceInHours >= 24) {
      const days = Math.floor(timeDifferenceInHours / 24);
      return `${days} d`;
    } else {
      return `${timeDifferenceInHours} h`;
    }
  };

  return {
    getJobListByCommunity,
    getRecentJobListByCommunity,
    getJobDetails,
    getEmployerDetails,
    getDifferenceInHrs,
    getJboardImportIds,
  };
};

export default useJobList;
