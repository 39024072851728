/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Avatar,
  Link,
  Divider,
  List,
  ListItem,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useParams, useNavigate } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as GreatSchoolsLogo } from '../../../assets/images/GreatSchools-Logo.svg';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import { ReactComponent as HelpIcon } from '../../../assets/images/HelpIcon.svg';
import Carousel from 'react-simply-carousel';
import ArrowLeft from '../../../assets/images/Arrow-Left.png';
import ArrowRight from '../../../assets/images/Arrow-Right.png';
import useCommunityList from '../../../hooks/useCommunityList';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useLogin from '../../../hooks/useAuth';

const Education = (props) => {
  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const params = useParams();
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  const [educationData, setEducationData] = useState([]);
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [isAccessible, setIsAccessible] = useState(false);
  const [repDetails, setRepDetails] = useState();
  const { getUserDataToAdmin } = useLogin();

  const { getEducationSchools, getCommunityDetailById } = useCommunityList();

  //console.log(communityDetails?.lat, 'CMD');

  const fillCommunityDetails = async () => {
    // let communityDetailsResults = [];
    // if (params?.id) {
    //   communityDetailsResults = await getCommunityDetailById({
    //     communityId: params?.id,
    //     // userId,
    //   });
    //   setCommunityDetails(communityDetailsResults.data);
    // }
    // return communityDetailsResults?.data;
    setCommunityDetails(props.communityInfo);
  };

  // const fetchData = async (levelCode) => {
  //   try {
  //     const response = await getEducationSchools({
  //       'level-codes': levelCode,
  //       communityId: params?.id,
  //       // lat: communityDetails?.lat,
  //       // long: communityDetails?.long,
  //     });
  //     //console.log(response, 'EDU');

  //     // Log the levelCode for debugging
  //     //console.log('Level Code:', levelCode);

  //     // Assuming the response contains an array of school data
  //     const filteredData = response.schoolData.filter((school) => {
  //       // Log individual school data for debugging
  //       //console.log('School Data:', school);

  //       // Log the level-codes property for debugging
  //       //console.log('School Level-Codes:', school['level-codes']);

  //       // Check if the level-codes property contains the levelCode as a string
  //       return school['level-codes'].toString().includes(levelCode);
  //     });

  //     //console.log('Filtered Data:', filteredData); // Log the filtered data to check if it's correct

  //     setEducationData(filteredData);
  //     setActiveSlide(0);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const getRepDetails = async () => {
    try {
      if (communityDetails?.education?.[0]?.isAdminEntry) {
        const userDataResult = await getUserDataToAdmin({
          userIdd: communityDetails?.education?.[0]?.repId,
        });
        setRepDetails(userDataResult.usersResult);
      }
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  useEffect(() => {
    setIsAccessible(
      (props?.loggedInUserData?.id &&
        communityDetails?.userrole?.[0]?.id === props?.loggedInUserData?.id) ||
        props?.loggedInUserData?.roleId === 2,
    );
    getRepDetails();
  }, [communityDetails]);

  useEffect(() => {
    fillCommunityDetails();
  }, []);

  //console.log(educationData);
  // useEffect(() => {
  //   if (!initialDataFetched) {
  //     fetchData('m'); // Fetch data initially with 'e' for Elementary
  //     setInitialDataFetched(true);
  //   }
  // }, [initialDataFetched]);

  // const handleElementaryClick = () => {
  //   setSelectedButton('elementary');
  //   fetchData('e'); // Pass 'e' as the level code for Elementary
  // };

  // const handleMiddleSchoolClick = () => {
  //   setSelectedButton('middleSchool');
  //   fetchData('m'); // Pass 'm' as the level code for Middle School
  // };

  // const handleHighSchoolClick = () => {
  //   setSelectedButton('highSchool');
  //   fetchData('h'); // Pass 'h' as the level code for High School
  // };

  function formatLevels(level) {
    const levels = level.split(',').map((str) => str.trim()); // Split and trim the levels
    const formattedLevels = [];

    let startGrade = null;
    let endGrade = null;

    levels.forEach((level) => {
      if (/^\d+$/.test(level)) {
        // If it's a single grade level (e.g., 1, 2, 3)
        const grade = parseInt(level, 10);
        if (startGrade === null) {
          startGrade = grade;
          endGrade = grade;
        } else if (grade === endGrade + 1) {
          // If the grade is consecutive, extend the range
          endGrade = grade;
        } else {
          // If it's not consecutive, add the range to formattedLevels
          if (startGrade === endGrade) {
            formattedLevels.push(startGrade.toString());
          } else {
            formattedLevels.push(`${startGrade}-${endGrade}`);
          }
          startGrade = grade;
          endGrade = grade;
        }
      } else {
        // Handle other cases as needed
        if (startGrade !== null) {
          // If there was a range, add it to formattedLevels
          if (startGrade === endGrade) {
            formattedLevels.push(startGrade.toString());
          } else {
            formattedLevels.push(`${startGrade}-${endGrade}`);
          }
          startGrade = null;
          endGrade = null;
        }
        formattedLevels.push(level);
      }
    });

    // Add any remaining range to formattedLevels
    if (startGrade !== null) {
      if (startGrade === endGrade) {
        formattedLevels.push(startGrade.toString());
      } else {
        formattedLevels.push(`${startGrade}-${endGrade}`);
      }
    }

    // Join the formatted levels with a comma and space
    return formattedLevels.join(', ');
  }

  function splitCamelCase(input) {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  return (
    <Paper className="WhitePaper" component="div">
      <Grid container>
        <Grid item xs={12} className="BoxHeadingContainer">
          <Typography color="primary" variant="h2" className="BoxHeading">
            Education
          </Typography>

          <If
            condition={
              props?.isAccessible &&
              !props?.publicView &&
              (communityDetails?.education?.[0]?.publicType ||
                communityDetails?.education?.[0]?.privateType ||
                communityDetails?.education?.[0]?.faithBasedPrivate ||
                communityDetails?.education?.[0]?.specialNeedsPrivate ||
                communityDetails?.education?.[0]?.publicTechnicalJuniorCollege ||
                communityDetails?.education?.[0]?.privateTechnicalJuniorCollege ||
                communityDetails?.education?.[0]?.publicFourYearCollegeUniversity ||
                communityDetails?.education?.[0]?.privatecFourYearCollegeUniversity ||
                communityDetails?.education?.[0]?.homeschoolResourceGroups ||
                communityDetails?.education?.[0]?.charter ||
                communityDetails?.education?.[0]?.preDesc !== '' ||
                communityDetails?.education?.[0]?.postDesc !== '')
            }
          >
            <Button
              type="submit"
              className="EditCommonButton LinkTypeButton"
              startIcon={<EditPenIconGreen />}
              size="small"
              color="primary"
              href={'/#/app/community-details-edit-education/' + communityDetails?.id}
            >
              Edit
            </Button>
          </If>
        </Grid>
      </Grid>
      <Grid item xs={12} className="CommonDetailBoxGrid">
        <Box className="FilterTagContent">
          <Button
            className={`FilterTag ${selectedButton === 'elementary' ? 'selected' : ''}`}
            onClick={() => handleElementaryClick()}
          >
            Elementary
          </Button>
          <Button
            className={`FilterTag ${selectedButton === 'middleSchool' ? 'selected' : ''}`}
            onClick={() => handleMiddleSchoolClick()}
          >
            Middle School
          </Button>
          <Button
            className={`FilterTag ${selectedButton === 'highSchool' ? 'selected' : ''}`}
            onClick={() => handleHighSchoolClick()}
          >
            High School
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box className="CarouselContainer SchoolCardCarouselContainer">
          <Box className="CarouselInner">
            {educationData.length > 0 && (
              <Carousel
                containerProps={{
                  style: {
                    width: '100%',
                    justifyContent: 'space-between',
                    userSelect: 'none',
                  },
                }}
                disableSwipeByTouch
                disableSwipeByMouse
                preventScrollOnSwipe
                swipeTreshold={60}
                infinite={false}
                activeSlideIndex={activeSlide}
                activeSlideProps={
                  {
                    // style: {
                    //   background: 'blue',
                    // },
                  }
                }
                onRequestChange={setActiveSlide}
                forwardBtnProps={{
                  children: '',
                  style: {
                    width: '24px',
                    height: '24px',
                    minWidth: '24px',
                    alignSelf: 'center',
                    position: 'absolute',
                    zIndex: '999',
                    backgroundColor: 'rgb(0, 0, 0, 0.5)',
                    color: '#fff',
                    borderRadius: '100%',
                    cursor: 'pointer',
                    right: '-10px',
                    backgroundImage: `url(${ArrowRight})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    border: 'none',
                    top: '45%',
                  },
                }}
                backwardBtnProps={{
                  children: '',
                  style: {
                    width: '24px',
                    height: '24px',
                    minWidth: '24px',
                    alignSelf: 'center',
                    position: 'absolute',
                    zIndex: '999',
                    backgroundColor: 'rgb(0, 0, 0, 0.5)',
                    color: '#fff',
                    borderRadius: '100%',
                    cursor: 'pointer',
                    backgroundImage: `url(${ArrowLeft})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    border: 'none',
                    top: '45%',
                    left: '-10px',
                  },
                }}
                dotsNav={{
                  show: false,
                  itemBtnProps: {
                    style: {
                      height: 16,
                      width: 16,
                      borderRadius: '50%',
                      border: 0,
                    },
                  },
                  activeItemBtnProps: {
                    style: {
                      height: 16,
                      width: 16,
                      borderRadius: '50%',
                      border: 0,
                      background: 'black',
                    },
                  },
                }}
                itemsToShow={0}
                speed={400}
              >
                {educationData.map((school, index) => (
                  <Box
                    className="SlideSection"
                    style={{
                      minWidth: 300,
                    }}
                    // key={item.id}
                  >
                    <Paper className="SchoolCard">
                      <Typography variant="body2" component="div" className="SchoolCardHeading">
                        <Link color="primary" underline="none">
                          {school?.name}
                          {/* {console.log(educationData[0]?.name,"DDD1")} */}
                        </Link>
                      </Typography>
                      <Typography variant="caption" component="div" className="SchoolCardAddress">
                        {school?.street +
                          ', ' +
                          school?.city +
                          ', ' +
                          school?.state +
                          ', ' +
                          school?.zip}
                      </Typography>
                      <Box className="GradeTypeBox">
                        <Box className="GradeBox">
                          <Typography variant="body2" component="div" className="GradeTypeNumber">
                            {formatLevels(school?.level)}
                          </Typography>
                          <Typography variant="caption" component="div" className="GradeTypeLabel">
                            Grades
                          </Typography>
                        </Box>
                        <Box className="TypeBox">
                          <Typography variant="body2" component="div" className="GradeTypeNumber">
                            {school?.type}
                          </Typography>
                          <Typography variant="caption" component="div" className="GradeTypeLabel">
                            Type
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="SchoolRating">
                        <List>
                          <ListItem disableGutters className="Rating1 CommonRatingBox">
                            <Box className="CommonRatingCircle">
                              {school.rating !== null ? (
                                <img
                                  src={
                                    require(`../../../assets/images/${school.rating}-10_icon.svg`)
                                      .default
                                  }
                                  alt={`${school.rating}-10 icon`}
                                />
                              ) : (
                                <img
                                  src={require('../../../assets/images/EquityRating.svg').default}
                                  alt="Null rating icon"
                                />
                              )}
                            </Box>
                            <Typography
                              variant="body1"
                              component="span"
                              className="CommonRatingText"
                            >
                              GreatSchools Rating
                              {/* {educationData[1]?.themedRatingsData?.['equity-rating']?.value} */}
                            </Typography>
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <Typography className="TooltipHeading">
                                    GreatSchools Summary Rating
                                  </Typography>
                                  <Typography className="TooltipText">
                                    GreatSchools is a national nonprofit organization. Our Summary
                                    Rating provides a snapshot of school quality based on the
                                    following measures. Learn more about GreatSchools Ratings and
                                    why we changed them
                                  </Typography>
                                </React.Fragment>
                              }
                              arrow
                              placement="top"
                            >
                              <Box component="span" className="HelpIcon">
                                <HelpIcon />
                              </Box>
                            </Tooltip>
                          </ListItem>
                          <ListItem disableGutters className="CommonRatingBox">
                            <Box className="CommonRatingCircle">
                              {school?.themedRatingsData &&
                              school.themedRatingsData['academic-progress-rating'] &&
                              school.themedRatingsData['academic-progress-rating'].value !==
                                undefined &&
                              school.themedRatingsData['academic-progress-rating'].value !==
                                null ? (
                                <img
                                  src={
                                    require(`../../../assets/images/${school.themedRatingsData['academic-progress-rating'].value}-10_icon.svg`)
                                      .default
                                  }
                                  alt={`${school.themedRatingsData['academic-progress-rating'].value}-10 icon`}
                                />
                              ) : (
                                <img
                                  src={require('../../../assets/images/EquityRating.svg').default}
                                  alt="Null rating icon"
                                />
                              )}
                            </Box>
                            <Typography
                              variant="caption"
                              component="span"
                              className="CommonRatingText"
                            >
                              Academic Progress Rating
                            </Typography>
                          </ListItem>
                          <ListItem disableGutters className="EquityRating">
                            <Box className="CommonRatingCircle">
                              {school?.themedRatingsData &&
                              school.themedRatingsData['equity-rating'] &&
                              school.themedRatingsData['equity-rating'].value !== undefined &&
                              school.themedRatingsData['equity-rating'].value !== null ? (
                                <img
                                  src={
                                    require(`../../../assets/images/${school?.themedRatingsData['equity-rating']?.value}-10_icon.svg`)
                                      ?.default
                                  }
                                  alt={`${school?.themedRatingsData['equity-rating']?.value}-10 icon`}
                                />
                              ) : (
                                <img
                                  src={require('../../../assets/images/EquityRating.svg')?.default}
                                  alt="Null rating icon"
                                />
                              )}
                            </Box>
                            <Typography
                              variant="caption"
                              component="span"
                              className=" CommonRatingText"
                            >
                              Equity Rating
                            </Typography>
                          </ListItem>
                          <ListItem disableGutters className="CommonRatingBox">
                            <Box className="CommonRatingCircle">
                              {school?.themedRatingsData &&
                              school.themedRatingsData['college-readiness-rating'] &&
                              school.themedRatingsData['college-readiness-rating'].value !==
                                undefined &&
                              school.themedRatingsData['college-readiness-rating'].value !==
                                null ? (
                                <img
                                  src={
                                    require(`../../../assets/images/${school.themedRatingsData['college-readiness-rating'].value}-10_icon.svg`)
                                      .default
                                  }
                                  alt={`${school.themedRatingsData['college-readiness-rating'].value}-10 icon`}
                                />
                              ) : (
                                <img
                                  src={require('../../../assets/images/EquityRating.svg').default}
                                  alt="Null rating icon"
                                />
                              )}
                            </Box>
                            <Typography
                              variant="caption"
                              component="span"
                              className="CommonRatingText"
                            >
                              College Readiness Rating
                            </Typography>
                          </ListItem>
                          <ListItem disableGutters className="CommonRatingBox">
                            <Box className="CommonRatingCircle">
                              {school?.themedRatingsData &&
                              school.themedRatingsData['test-scores-rating'] &&
                              school.themedRatingsData['test-scores-rating'].value !== undefined &&
                              school.themedRatingsData['test-scores-rating'].value !== null ? (
                                <img
                                  src={
                                    require(`../../../assets/images/${school.themedRatingsData['test-scores-rating'].value}-10_icon.svg`)
                                      .default
                                  }
                                  alt={`${school.themedRatingsData['test-scores-rating'].value}-10 icon`}
                                />
                              ) : (
                                <img
                                  src={require('../../../assets/images/EquityRating.svg').default}
                                  alt="Null rating icon"
                                />
                              )}
                            </Box>
                            <Typography
                              variant="caption"
                              component="span"
                              className="CommonRatingText"
                            >
                              Test Score Rating
                            </Typography>
                          </ListItem>
                        </List>
                      </Box>
                    </Paper>
                  </Box>
                ))}
              </Carousel>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} className="GreatSchoolsGrid">
        <Link
          className="GreatSchoolsLogo"
          href="https://www.greatschools.org/"
          alt="GreatSchools"
          target="_blank"
        >
          <GreatSchoolsLogo />
        </Link>
      </Grid>
      <Grid item xs={12} className="AddAmenityDividerRow">
        <Divider className="DividerCD"></Divider>
      </Grid>
      <Grid item xs={12} className="SchoolTypes EducationSchoolTypes">
        {(communityDetails?.education?.[0]?.publicType ||
          communityDetails?.education?.[0]?.privateType ||
          communityDetails?.education?.[0]?.faithBasedPrivate ||
          communityDetails?.education?.[0]?.specialNeedsPrivate ||
          communityDetails?.education?.[0]?.publicTechnicalJuniorCollege ||
          communityDetails?.education?.[0]?.privateTechnicalJuniorCollege ||
          communityDetails?.education?.[0]?.publicFourYearCollegeUniversity ||
          communityDetails?.education?.[0]?.privatecFourYearCollegeUniversity ||
          communityDetails?.education?.[0]?.homeschoolResourceGroups ||
          communityDetails?.education?.[0]?.charter) && (
          <Typography variant="body1" className="InsightLabel">
            School Types
          </Typography>
        )}
        <List>
          {Object.entries({
            Public: communityDetails?.education?.[0]?.publicType,
            Private: communityDetails?.education?.[0]?.privateType,
            Charter: communityDetails?.education?.[0]?.charter,
            FaithBasedPrivate: communityDetails?.education?.[0]?.faithBasedPrivate,
            SpecialNeedsPrivate: communityDetails?.education?.[0]?.specialNeedsPrivate,
            PublicTechnicalJuniorCollege:
              communityDetails?.education?.[0]?.publicTechnicalJuniorCollege,
            PrivateTechnicalJuniorCollege:
              communityDetails?.education?.[0]?.privateTechnicalJuniorCollege,
            PublicFourYearCollegeUniversity:
              communityDetails?.education?.[0]?.publicFourYearCollegeUniversity,
            PrivateFourYearCollegeUniversity:
              communityDetails?.education?.[0]?.privatecFourYearCollegeUniversity,
            HomeschoolResourceGroups: communityDetails?.education?.[0]?.homeschoolResourceGroups,
            // Add more school types here
          }).map(
            ([schoolType, hasValue]) =>
              hasValue && (
                <ListItem key={schoolType}>
                  <Typography component="div" variant="body2">
                    {splitCamelCase(schoolType)}
                  </Typography>
                </ListItem>
              ),
          )}
        </List>
      </Grid>
      {/* {props?.isAccessible && !props?.publicView && ( */}
      <Grid item xs={12}>
        {communityDetails?.education.length > 0 && communityDetails?.education?.[0]?.preDesc && (
          <Typography variant="body2" className="ChatTextLabel">
            Pre-K through 12th Grades
          </Typography>
        )}
        <Box className="ChatBox CommonDetailBoxGrid EducationChatBoxFirst EducationChat">
          {communityDetails?.education.length > 0 && communityDetails?.education?.[0]?.preDesc && (
            // communityDetails?.education?.[0].postDesc
            <Box className="ChatContent">
              <Typography variant="body2" className="ChatText">
                {communityDetails?.education.length > 0 &&
                  communityDetails?.education?.[0]?.preDesc}
              </Typography>
            </Box>
          )}

          <Box className="UserInfoName UserInfoIP">
            {communityDetails?.education.length > 0 && communityDetails?.education?.[0]?.preDesc && (
              // communityDetails?.education.find((x) => x.repId === communityDetails?.userrole[0]?.id).postDesc
              <Avatar
                className="UserAvatar small"
                alt={`${
                  communityDetails?.education?.[0]?.isAdminEntry
                    ? repDetails?.firstName
                    : communityDetails?.userrole[0]?.firstName
                }`}
                src={
                  window.location.hostname === 'localhost'
                    ? `//localhost:9001/${
                        communityDetails?.education?.[0]?.isAdminEntry
                          ? repDetails?.profilePic
                          : communityDetails?.userrole[0]?.profilePic
                      }`
                    : `/api/${
                        communityDetails?.education?.[0]?.isAdminEntry
                          ? repDetails?.profilePic
                          : communityDetails?.userrole[0]?.profilePic
                      }`
                }
              >
                <PersonIcon />
              </Avatar>
            )}
            <Box className="UserInfoText">
              {communityDetails?.education.length > 0 && communityDetails?.education?.[0]?.preDesc && (
                // communityDetails?.education.find((x) => x.repId === communityDetails?.userrole[0]?.id).postDesc
                <Typography variant="h6">
                  <Box component="span" className="FirstName">
                    {communityDetails?.education?.[0]?.isAdminEntry
                      ? repDetails?.firstName
                      : communityDetails?.userrole[0]?.firstName}
                  </Box>
                  <Box component="span" className="LastName">
                    {communityDetails?.education?.[0]?.isAdminEntry
                      ? repDetails?.lastName
                      : communityDetails?.userrole[0]?.lastName}
                  </Box>
                </Typography>
              )}
              {communityDetails?.education.length > 0 && communityDetails?.education?.[0]?.preDesc && (
                // communityDetails?.education.find((x) => x.repId === communityDetails?.userrole[0]?.id).postDesc
                <Typography variant="caption">
                  {communityDetails?.education?.[0]?.isAdminEntry
                    ? repDetails?.userDesignationText
                    : communityDetails?.userrole[0]?.userDesignationText}
                </Typography>
              )}
              {/* {console.log(communityDetails?.userrole[0]?.userDesignationText)} */}
            </Box>
          </Box>
        </Box>
        {communityDetails?.education.length > 0 && communityDetails?.education?.[0]?.postDesc && (
          <Typography variant="body2" className="ChatTextLabel">
            Post-Secondary Education
          </Typography>
        )}
        <Box className="ChatBox CommonDetailBoxGrid EducationChat">
          {communityDetails?.education.length > 0 &&
            // communityDetails?.education.find((x) => x.repId === communityDetails?.userrole[0]?.id).preDesc ||
            communityDetails?.education?.[0]?.postDesc && (
              <Box className="ChatContent">
                <Typography variant="body2" className="ChatText">
                  {communityDetails?.education.length > 0 &&
                    communityDetails?.education?.[0]?.postDesc}
                </Typography>
              </Box>
            )}

          <Box className="UserInfoName UserInfoIP">
            {communityDetails?.education.length > 0 &&
              // communityDetails?.education.find((x) => x.repId === communityDetails?.userrole[0]?.id).preDesc ||
              communityDetails?.education?.[0]?.postDesc && (
                <Avatar
                  className="UserAvatar small"
                  alt={`${
                    communityDetails?.education?.[0]?.isAdminEntry
                      ? repDetails?.firstName
                      : communityDetails?.userrole[0]?.firstName
                  }`}
                  src={
                    window.location.hostname === 'localhost'
                      ? `//localhost:9001/${
                          communityDetails?.education?.[0]?.isAdminEntry
                            ? repDetails?.profilePic
                            : communityDetails?.userrole[0]?.profilePic
                        }`
                      : `/api/${
                          communityDetails?.education?.[0]?.isAdminEntry
                            ? repDetails?.profilePic
                            : communityDetails?.userrole[0]?.profilePic
                        }`
                  }
                >
                  <PersonIcon />
                </Avatar>
              )}
            <Box className="UserInfoText">
              {communityDetails?.education.length > 0 &&
                // communityDetails?.education.find((x) => x.repId === communityDetails?.userrole[0]?.id).preDesc ||
                communityDetails?.education?.[0]?.postDesc && (
                  <Typography variant="h6">
                    <Box component="span" className="FirstName">
                      {communityDetails?.education?.[0]?.isAdminEntry
                        ? repDetails?.firstName
                        : communityDetails?.userrole[0]?.firstName}
                    </Box>
                    <Box component="span" className="LastName">
                      {communityDetails?.education?.[0]?.isAdminEntry
                        ? repDetails?.lastName
                        : communityDetails?.userrole[0]?.lastName}
                    </Box>
                  </Typography>
                )}
              {communityDetails?.education.length > 0 &&
                // communityDetails?.education.find((x) => x.repId === communityDetails?.userrole[0]?.id).preDesc ||
                communityDetails?.education?.[0]?.postDesc && (
                  <Typography variant="caption">
                    {communityDetails?.education?.[0]?.isAdminEntry
                      ? repDetails?.userDesignationText
                      : communityDetails?.userrole[0]?.userDesignationText}
                  </Typography>
                )}
            </Box>
          </Box>
        </Box>
      </Grid>
      {/* )} */}
      {/* {console.log(
        communityDetails?.education.find((x) => x.repId === communityDetails?.userrole[0]?.id),
        4565,
      )} */}
      {props?.isAccessible && !props?.publicView && (
        <If
          condition={
            communityDetails?.education?.length == 0 ||
            (communityDetails?.education.find(
              (x) => x.repId === communityDetails?.userrole[0]?.id,
            ) &&
              !communityDetails?.education?.[0]?.publicType &&
              !communityDetails?.education?.[0]?.privateType &&
              !communityDetails?.education?.[0]?.faithBasedPrivate &&
              !communityDetails?.education?.[0]?.specialNeedsPrivate &&
              !communityDetails?.education?.[0]?.publicTechnicalJuniorCollege &&
              !communityDetails?.education?.[0]?.privateTechnicalJuniorCollege &&
              !communityDetails?.education?.[0]?.publicFourYearCollegeUniversity &&
              !communityDetails?.education?.[0]?.privatecFourYearCollegeUniversity &&
              !communityDetails?.education?.[0]?.homeschoolResourceGroups &&
              !communityDetails?.education?.[0]?.charter &&
              communityDetails?.education?.[0]?.preDesc == '' &&
              communityDetails?.education?.[0]?.postDesc == '')
          }
        >
          <Box>
            <Typography variant="body2" className="InsightLabel">
              Let people know what the education system is like in your Community. You can add
              details on K-12 and Post-Grad Secondary Education options.
            </Typography>
            {/* </If> */}
          </Box>
        </If>
      )}

      <If condition={props?.isAccessible && !props?.publicView}>
        <Grid item xs={12}>
          <If
            condition={
              !(
                communityDetails?.education?.[0]?.publicType ||
                communityDetails?.education?.[0]?.privateType ||
                communityDetails?.education?.[0]?.faithBasedPrivate ||
                communityDetails?.education?.[0]?.specialNeedsPrivate ||
                communityDetails?.education?.[0]?.publicTechnicalJuniorCollege ||
                communityDetails?.education?.[0]?.privateTechnicalJuniorCollege ||
                communityDetails?.education?.[0]?.publicFourYearCollegeUniversity ||
                communityDetails?.education?.[0]?.privatecFourYearCollegeUniversity ||
                communityDetails?.education?.[0]?.homeschoolResourceGroups ||
                communityDetails?.education?.[0]?.charter ||
                communityDetails?.education?.[0]?.preDesc ||
                communityDetails?.education?.[0]?.postDesc
              )
            }
          >
            <Box className="Wrapper ButtonWrapper InsightButtonContainer">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                href={'/#/app/community-details-edit-education/' + communityDetails?.id}
              >
                {/* <img
                  src={require('../../../assets/images/Insight.svg').default}
                  alt="Insight Icon"
                /> */}

                <Box className="AddButton">Add Insight</Box>
              </Button>
            </Box>
          </If>
        </Grid>
      </If>
    </Paper>
  );
};

export default Education;
