/* eslint-disable global-require */
/* eslint-disable  */

import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  TextField,
  IconButton,
  Container,
  InputLabel,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useParams, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';
import { KEY } from '../../../constants/globalConstants';

const DeclineInvitation = ({ invitationId, handleDeclineClose }) => {
  const { id } = useParams();
  const maxCharacterCount = 700;
  const { declineInvitation, getDeclineInvitationById } = useAuth();

  const navigate = useNavigate();
  const [state, setState] = React.useState({
    invitationId: id || invitationId,
    Other: false,
    unqualified: false,
    noTime: false,
    notInterested: false,
    suggestionOne: '',
  });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(false);

  const [action, setAction] = React.useState({});
  const handleChange = (event) => {
    if (isDisabled) return;
    if (event.target.name !== 'suggestionOne') {
      setIsChecked({ ...isChecked, [event.target.name]: event.target.checked });
    }

    if (event.target.value) {
      setState({ ...state, [event.target.name]: event.target.value });
    } else {
      setState({ ...state, [event.target.name]: event.target.checked });
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onDeleteHandler = async () => {
    const data = await declineInvitation(state, action);
    if (data?.status) {
      // handleDeclineClose();
      setOpen(false);
      setTimeout(() => {
        navigate('/app/home-page', { replace: true });
      }, 1000);
    }
  };
  const _getDeclineInvitationById = async (params) => {
    const data = await getDeclineInvitationById(params);
    if (data?.status) {
      if (data?.data?.id) {
        setState({
          ...state,
          Other: data?.data?.Other,
          unqualified: data?.data?.unqualified,
          noTime: data?.data?.noTime,
          notInterested: data?.data?.notInterested,
          suggestionOne: data?.data?.suggestionOne,
        });
        setDisabled(true);
      }
      // setAction(data?.data);
    }
  };
  useEffect(() => {
    _getDeclineInvitationById({ id });
  }, [id]);
  const recaptchaRef = React.useRef();

  return (
    <Formik>
      {({
        errors,
        isSubmitting,
        resetForm,
        setSubmitting,
        setStatus,
        setErrors,
        isValid,
        dirty,
        touched,
      }) => {
        return (
          <Box className="FullWidthPattern">
            <Container>
              <Box container className="PageSpacing FixedMD">
                <Box className="InvitationWrapper DeclineInvitationWrapper">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Form autoComplete="off">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          className="MainContainer"
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} className="DeclineInvitationTopsection">
                                <Link underline="none" to={`/app/invitation/${id}`}>
                                  <Typography
                                    className="BackButton DeclineBackButton"
                                    variant="body1"
                                  >
                                    <ArrowBackIcon className="BackButtonArrow" />
                                    {isDisabled ? 'Declined Invitation Reason' : ''}
                                  </Typography>
                                </Link>
                              </Grid>
                              {!isDisabled ? (
                                <>
                                  <Grid item xs={12} className="DeclineInvitationTopsection">
                                    <Box className="OnboardingBg">
                                      <Box className="OnboardingImgContainer">
                                        {/* <img
                      className=""
                      src={require('../../../assets/images/bg-blue1.png').default}
                      alt="background image"
                    /> */}
                                        <img
                                          className="HeartIcon"
                                          src={
                                            require('../../../assets/images/DeclineInvitationIcon.svg')
                                              .default
                                          }
                                          alt="background image"
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      component="div"
                                      variant="h2"
                                      className="LightgreyColor DeclineInvitationSubTitle"
                                    >
                                      We're sorry to hear you're not interested.
                                    </Typography>
                                    <Divider />
                                  </Grid>
                                </>
                              ) : (
                                ''
                              )}

                              <Box className="InvitationContent">
                                <Grid item xs={12}>
                                  <InputLabel
                                    className="InputLabelFld NoWrap"
                                    htmlFor="CommunityName"
                                  >
                                    We value your feedback. Why aren’t you interested in
                                    representing a community on Move to Happy?
                                    <Box
                                      mb={1}
                                      component="span"
                                      display="inline-block"
                                      className="Asterisk"
                                    >
                                      *
                                    </Box>
                                  </InputLabel>
                                  <Box className="CheckboxOption ">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={state.unqualified}
                                          onChange={handleChange}
                                          name="unqualified"
                                          color="primary"
                                        />
                                      }
                                      label="I'm unqualified"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={state.noTime}
                                          onChange={handleChange}
                                          name="noTime"
                                          color="primary"
                                        />
                                      }
                                      label="I don't have time"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={state.notInterested}
                                          onChange={handleChange}
                                          name="notInterested"
                                          color="primary"
                                        />
                                      }
                                      label="I'm not interested"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={state.Other}
                                          onChange={handleChange}
                                          name="Other"
                                          color="primary"
                                        />
                                      }
                                      label="Other"
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  {isDisabled ? (
                                    <Box className="DeclineLabel">{state.suggestionOne}</Box>
                                  ) : (
                                    <TextField
                                      className="TextFieldTextarea DeclineInvitationTextarea"
                                      fullWidth
                                      multiline
                                      name="suggestionOne"
                                      minRows={8}
                                      maxLength={700}
                                      label=""
                                      variant="outlined"
                                      value={state.suggestionOne}
                                      onChange={handleChange}
                                      inputProps={{
                                        maxLength: maxCharacterCount,
                                      }}
                                      helperText={`${
                                        maxCharacterCount - (state?.suggestionOne?.length || 0)
                                      }`}
                                    />
                                  )}
                                </Grid>
                              </Box>
                              {!isDisabled ? (
                                <Grid item xs={12}>
                                  <Box className="Wrapper ButtonWrapper">
                                    <Button
                                      disabled={
                                        isChecked?.unqualified == true ||
                                        isChecked?.noTime == true ||
                                        isChecked?.notInterested == true ||
                                        isChecked?.Other == true
                                          ? false
                                          : true
                                      }
                                      type="button"
                                      fullWidth
                                      className="Button EditViewButton"
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        handleClickOpen();
                                        setAction({
                                          resetForm,
                                          setSubmitting,
                                          setStatus,
                                          setErrors,
                                        });
                                      }}
                                    >
                                      Decline Invitation
                                    </Button>
                                    {isSubmitting && (
                                      <CircularProgress size={24} className="ButtonProgress" />
                                    )}
                                  </Box>
                                </Grid>
                              ) : (
                                ''
                              )}
                            </Grid>
                            <Grid container>
                              <Grid>
                                <ReCAPTCHA
                                  ref={recaptchaRef}
                                  sitekey={KEY.SITE}
                                  size={'invisible'}
                                />
                              </Grid>
                              <Dialog
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={open}
                              >
                                <MuiDialogTitle
                                  className="DialogTitleLeft"
                                  id="customized-dialog-title"
                                  onClose={handleClose}
                                >
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    className="DialogTitleHeading TextColor"
                                  >
                                    Are you sure you want to decline the invitation?
                                  </Typography>
                                  {/* <CloseIcon onClick={handleClose} /> */}
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                  <Typography gutterBottom variant="body2" component="div">
                                    This action can not be undone.
                                  </Typography>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                  <Button
                                    onClick={handleClose}
                                    variant="outlined"
                                    color="primary"
                                    className="NoBorderButton"
                                  >
                                    No, Go Back
                                  </Button>
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={onDeleteHandler}
                                  >
                                    Yes, Decline
                                  </Button>
                                </MuiDialogActions>
                              </Dialog>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </Box>
        );
      }}
    </Formik>
  );
};

export default DeclineInvitation;
