/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik'; // Import useFormik
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import {
  Grid,
  Link,
  Typography,
  Box,
  TextField,
  InputLabel,
  Button,
  Container,
  Divider,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useCommunityList from '../../../../hooks/useCommunityList';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';
const CommunityEditViewJobs = (props) => {
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    window.location.hash = '#/app/home-page';
  }

  const [userId] = useState(loggedInUserData?.id);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [jobsDesc, setJobsDesc] = React.useState('');
  const [isjobDesc, setIsJobDesc] = React.useState(false);
  const [communityDetails, setCommunityDetails] = React.useState(null);
  const { updateCommunityCatJobs, getCommunityDetailById } = useCommunityList();
  const [isAccessible, setIsAccessible] = useState(false);

  const maxCharacterCount = 700;
  const params = useParams();

  const navigate = useNavigate();

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobsDesc(value);
  };

  const formik = useFormik({
    initialValues: {
      repId: loggedInUserData?.id,
      communityId: params?.id,
      jobsDesc: jobsDesc,
      isAdminEntry: loggedInUserData?.roleId === 2,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const jobsDesc = values.jobsDesc?.trim();
        values['jobsDesc'] = jobsDesc;
        const response = await updateCommunityCatJobs(values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response); // The response from the API
        formik.resetForm(); // Reset the form after successful submission
        navigate(`../../community-details/${params?.id}#Section5`);
        // Do something with the response, e.g., show a success message
      } catch (error) {
        console.error(error.response.data); // Log any error response from the API
        // Handle error, e.g., show an error message
      }
    },
  });

  const fillCommunityDetails = async () => {
    let communityDetailsResults = [];
    if (params?.id) {
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults.data);

      setJobsDesc(communityDetailsResults?.data?.jobs?.[0]?.jobsDesc || '');
      if (communityDetailsResults?.data?.jobs?.[0]?.jobsDesc !== '') {
        setIsJobDesc(true);
      }
    }
    return communityDetailsResults?.data;
  };

  useEffect(() => {
    fillCommunityDetails();
  }, []);

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (formik.dirty) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${params?.id}#Section5`);
    }
  };

  useEffect(() => {
    loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2
      ? setIsAccessible(true)
      : setIsAccessible(false);
  }, [loggedInUserData]);

  return (
    <Container>
      <Box className="PageSpacing FixedMD CommunityEditViewJobs">
        {isAccessible ? (
          <>
            {showDeleteBox && (
              <>
                <ModalEditViewDelete
                  status={true}
                  navigateUrl={`../../community-details/${params?.id}#Section5`}
                  onClose={onTakeMeback}
                ></ModalEditViewDelete>
              </>
            )}
            <Grid container direction="row" className="ResidentBoxCEV">
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    Jobs Insights
                  </Typography>
                </Link>
              </Grid>
              <Grid container className="FixedID">
                <Grid item xs={12} className="AutoWidth">
                  <Box className="CommonDetailBoxGrid DataCDNumber">
                    <Typography variant="h4" component="div" className="CDNumber">
                      ${communityDetails && communityDetails.medianIncome.toLocaleString()}
                    </Typography>
                    <Typography variant="body2" component="div">
                      Median Household Income
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <InputLabel className="InputLabelFld">
                      Highlight the economic activity and job market in your Community.{' '}
                    </InputLabel>
                  </Box>
                  <TextField
                    className="TextFieldTextarea"
                    fullWidth
                    multiline
                    minRows={8}
                    maxLength={700}
                    label=""
                    variant="outlined"
                    value={jobsDesc}
                    name="jobsDesc"
                    onChange={handleChange}
                    {...formik.getFieldProps('jobsDesc')}
                    inputProps={{
                      maxLength: maxCharacterCount,
                    }}
                    helperText={`${maxCharacterCount - formik.values.jobsDesc.length}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className="Wrapper ButtonWrapper">
                    <Button
                      disabled={
                        !formik.dirty || (formik.values.jobsDesc.length === 0 && !isjobDesc)
                      }
                      onClick={formik.handleSubmit}
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="EditViewButton"
                      fullWidth
                    >
                      Publish
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          'You do not have permissions to access this url.'
        )}
      </Box>
    </Container>
  );
};
CommunityEditViewJobs.defaultProps = {};
export default CommunityEditViewJobs;
