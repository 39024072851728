/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Menu,
  MenuItem,
  Button,
  Typography,
  Box,
  Tab,
  Tabs,
  InputBase,
  InputAdornment,
  TextField,
  Popover,
  Paper,
  Container,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../assets/images/Search-Icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { entityEnum } from '../../../constants/globalConstants';
import useCommunityList from '../../../hooks/useCommunityList';
import EmptyState from './EmptyState/EmptyState';
import '../Main.style.css';
// import useStyles from '../Main.style';
import CommunityCard from './CommunityCard/CommunityCard';
import CardLoader from '../../../components/CardLoader/CardLoader';

const InvitationLinkExpired = (props) => {
  const { reason } = useParams();
  return (
    <Container>
      {
        <Box className="PageSpacing">
          <Grid container direction="row" className="InvitationExpiredcontainer">
            <Grid item xs={12}>
              <Typography
                color="primary"
                variant="h2"
                className="InvitationExpiredTitle FlexCenter"
              >
                Uh oh. This link has expired.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="InvitationExpiredContent FlexCenter">
                {(reason === 'accepted' &&
                  'The link you are trying to access expires after account creation.') ||
                  ''}
                {(reason === 'expired' &&
                  'The link you are trying to access expires after 14 days for security reasons.') ||
                  ''}
                {(reason === 'cancelled' &&
                  'The link you are trying to access has been cancelled by the admin.') ||
                  ''}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      }
    </Container>
  );
};
InvitationLinkExpired.defaultProps = {
  showSearch: true,
  tabValue: 0,
};
export default InvitationLinkExpired;
