/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Carousel from 'react-simply-carousel';
import {
  Grid,
  Button,
  Popover,
  Divider,
  Typography,
  TextField,
  Box,
  Tab,
  Tabs,
  InputBase,
  Paper,
  InputAdornment,
  InputLabel,
  Container,
} from '@material-ui/core';
import { Search } from '@material-ui/icons/';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ReactComponent as DollarIcon } from '../../../assets/images/Dollar-Sign.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ReactComponent as SearchIcon } from '../../../assets/images/Search-Icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import PropTypes from 'prop-types';
import ArrowLeft from '../../../assets/images/Arrow-Left.png';
import ArrowRight from '../../../assets/images/Arrow-Right.png';
import { entityEnum } from '../../../constants/globalConstants';
/* CSS */
// import useStyles from '../Main.style';
import '../Main.style.css';
import useHouseList from '../../../hooks/useHouseList';
import HouseCard from './HouseCard/HouseCard';

const options = ['Filter 1', 'Filter 2', 'Filter 3', 'Filter 4'];
const ITEM_HEIGHT = 48;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const HouseList = (props) => {
  const [houseList, setHouseList] = useState([]);
  const [userHouses, setUserHouses] = useState([]);
  const [favHouses, setFavHouses] = React.useState([]);
  const [tabValue, setTabValue] = React.useState(props?.tabValue);
  const [searchedText, setSearchedText] = useState('');
  const { getAllHouses, getHousesByUser } = useHouseList();
  let housesArr = [];
  let userHousesArr = [];
  const favHousesList = {};
  let sliderHtml = [];

  // const classes = useStyles();
  const key = 'iFrasCwdJh';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);
  const params = useParams();

  // const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);

  const fillHouseList = async () => {
    if (housesArr.length === 0) {
      housesArr = await getAllHouses();
      if (params?.communityId) {
        setHouseList(
          housesArr?.usersResult?.filter((hl) => {
            return hl.communityId === params?.communityId;
          }),
        );

        // setHouseList(housesArr?.usersResult?.filter(h));
      } else if (props?.communityId) {
        setHouseList(
          housesArr?.usersResult?.filter((hl) => {
            return hl.communityId === props?.communityId;
          }),
        );
      } else {
        setHouseList(housesArr?.usersResult);
      }
    }
    return housesArr?.usersResult;
  };

  const fillUserHouses = async () => {
    if (userId !== null && userId !== undefined) {
      if (userHousesArr.length === 0) {
        userHousesArr = await getHousesByUser({
          userId,
          typeText: entityEnum[1],
        });
        setUserHouses(userHousesArr?.usersResult);
      }
    }
    return userHousesArr;
  };

  // const getFavHousesList = async () => {
  //   userHousesArr = await fillUserHouses();
  // };

  const houseFilterFunction = (houseArray, _tabValue, searchedTextParam) => {
    let filteredList = [];
    switch (_tabValue) {
      case 0: {
        filteredList = houseArray;
        break;
      }
      case 1: {
        if (userHouses !== null && userHouses.length > 0) {
          const userHouseObj = userHouses.reduce(
            (acc, cur) => ({ ...acc, [cur?.houseMapId?.id]: cur?.readAt }),
            {},
          );
          houseArray
            .sort((a, b) => {
              return new Date(userHouseObj[b.id]) - new Date(userHouseObj[a.id]);
            })
            .forEach((clbs) => {
              if (userHouseObj[clbs?.id] !== undefined) {
                /* eslint-disable no-param-reassign */
                clbs.readAt = userHouseObj[clbs?.id];
                /* eslint-enable no-param-reassign */
              }
            });
          filteredList = houseArray;
        }
        break;
      }
      case 2: {
        if (userHouses !== null && userHouses.length > 0) {
          filteredList = houseArray.filter((clbs) => favHouses[clbs.id] === true);
        }
        break;
      }

      default: {
        break;
      }
    }
    return filteredList.filter((clbs) => {
      return (
        clbs?.houseStyle?.toLowerCase().indexOf(searchedTextParam.toLowerCase()) !== -1 ||
        clbs?.address?.toLowerCase().indexOf(searchedTextParam.toLowerCase()) !== -1
      );
    });
  };

  const createFavListObject = async () => {
    // housesArr = await fillHouseList();
    // userHousesArr = await fillUserHouses();
    if (userId !== null && userId !== undefined) {
      const userHousesObject = userHousesArr.usersResult;
      // userCommunities?.length > 0 ? userCommunities : userCommunitiesArr.usersResult;
      const housesListObject = housesArr;
      // communityListByState?.length > 0 ? communityListByState : stateCommunitiesArr;
      if (userHousesObject) {
        const mergeArr = [];
        for (let i = 0; i < housesListObject.length; i + 1) {
          mergeArr.push({
            ...housesListObject[i],
            ...userHousesObject.find(
              (itmInner) => itmInner.houseMapId.id === housesListObject[i].id,
            ),
          });
        }
        if (mergeArr.length > 0) {
          mergeArr.forEach((elem) => {
            favHousesList[
              `${elem?.houseMapId?.id === undefined ? elem?.id : elem?.houseMapId?.id}`
            ] = elem.isFavourite !== undefined ? Boolean(elem.isFavourite) : false;
          });
        }
        setFavHouses(favHousesList);
      }
    }
    return favHousesList;
  };
  // const handleFavToggle = async (houseId) => {
  //   setSelectedHouse(houseId);
  //   const favResult = await addHouseAsFavourite({
  //     houseId: houseId,
  //     userId: userId,
  //     typeText: 'house',
  //   });
  //   console.log(favResult, 224);
  //   if (favResult) {
  //     const udpatedFavStatus = await createFavListObject();
  //     setFavHouses(udpatedFavStatus);
  //   }
  // };
  const handleChange = (event, newValue) => {
    if (userId !== null && userId !== undefined) {
      setTabValue(newValue);
      houseFilterFunction(houseList, tabValue, searchedText);
    }
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchChange = (event) => {
    setSearchedText(event.currentTarget.value);
    houseFilterFunction(houseList, tabValue, searchedText);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    fillHouseList();
  }, []);

  useEffect(() => {
    fillUserHouses();
  }, []);

  useEffect(() => {
    createFavListObject();
  }, []);

  return (
    <Container>
      <Box className="PageSpacing">
        {props?.showSearch && (
          <>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography variant="h1" className="PageTitle">
                  Homes
                </Typography>
              </Grid>
            </Grid>
            <Box mb={2} mt={2}>
              <Tabs
                className="CommonTabNav"
                value={tabValue}
                onChange={handleChange}
                aria-label="Communities Tabs"
              >
                <Tab label="All" tabValue={0} {...tabProps(0)} />
                <Tab label="Recent" tabValue={1} {...tabProps(1)} />
                <Tab label="Favorites" tabValue={2} {...tabProps(2)} />
              </Tabs>
            </Box>
            <form>
              <Box className="SearchBoxContainer" mb={0}>
                <TextField
                  className="SearchBox"
                  value={searchedText}
                  as={InputBase}
                  onChange={handleSearchChange}
                  variant="outlined"
                  type="text"
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        // onClick={clearSearch}
                      >
                        {searchedText !== '' && <CloseIcon className="GreenColor" />}
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  className="SearchButton"
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleFilterClick}
                  startIcon={<FilterListIcon color="primary" />}
                  variant="outlined"
                >
                  Filters{' '}
                  <Box className="FilterCount" component="span">
                    (12)
                  </Box>
                </Button>
              </Box>
              <Popover
                id="long-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                className="MobileFilter"
              >
                <Paper className="CommonFilterBox AmenityFilters">
                  <Box className="FilterHeader">
                    <Typography variant="h4">Home Filters</Typography>
                    <CloseIcon
                      color="primary"
                      className="CloseButton"
                      onClick={handleFilterClose}
                    />
                  </Box>
                  <Box className="FilterBody">
                    <Box className="FilterDropdownBox">
                      <Box className="FilterTagContent FilterDropdownContent">
                        <InputLabel className="InputLabelFld" htmlFor="email">
                          Price
                        </InputLabel>
                        <Box className="AutocompleteBox">
                          <Box className="AutocompleteStartIcon">
                            <DollarIcon className="DollarIcon" />
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                          <Box className="DashIcon">
                            <Typography variant="body2">-</Typography>
                          </Box>
                          <Box className="AutocompleteStartIcon">
                            <DollarIcon className="DollarIcon" />
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className="FilterTagContent FilterDropdownContent">
                        <InputLabel className="InputLabelFld" htmlFor="email">
                          Bedrooms
                        </InputLabel>
                        <Box className="AutocompleteBox">
                          <Box className="AutocompleteNoIcon">
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                          <Box className="DashIcon">
                            <Typography variant="body2">-</Typography>
                          </Box>
                          <Box className="AutocompleteNoIcon">
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="FilterTagContent FilterDropdownContent">
                        <InputLabel className="InputLabelFld" htmlFor="email">
                          Bathrooms
                        </InputLabel>
                        <Box className="AutocompleteBox">
                          <Autocomplete
                            fullWidth={true}
                            className="CommonAutocomplete"
                            id="combo-box-demo"
                            options={optionsBox}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="No Min"
                                label=""
                                variant="outlined"
                              />
                            )}
                            popupIcon={<KeyboardArrowDownIcon />}
                          />
                        </Box>
                      </Box>
                      <Divider />
                      <Box className="FilterTagContent FilterDropdownContent">
                        <InputLabel className="InputLabelFld" htmlFor="email">
                          Square Feet
                        </InputLabel>
                        <Box className="AutocompleteBox">
                          <Box className="AutocompleteNoIcon">
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                          <Box className="DashIcon">
                            <Typography variant="body2">-</Typography>
                          </Box>
                          <Box className="AutocompleteNoIcon">
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="FilterTagContent FilterDropdownContent">
                        <InputLabel className="InputLabelFld" htmlFor="email">
                          Lot Size
                        </InputLabel>
                        <Box className="AutocompleteBox">
                          <Box className="AutocompleteNoIcon">
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                          <Box className="DashIcon">
                            <Typography variant="body2">-</Typography>
                          </Box>
                          <Box className="AutocompleteNoIcon">
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className="FilterTagContent FilterDropdownContent">
                        <InputLabel className="InputLabelFld" htmlFor="email">
                          Year Built
                        </InputLabel>
                        <Box className="AutocompleteBox">
                          <Box className="AutocompleteNoIcon">
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                          <Box className="DashIcon">
                            <Typography variant="body2">-</Typography>
                          </Box>
                          <Box className="AutocompleteNoIcon">
                            <Autocomplete
                              className="CommonAutocomplete"
                              id="combo-box-demo"
                              options={optionsBox}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="No Min"
                                  label=""
                                  variant="outlined"
                                />
                              )}
                              popupIcon={<KeyboardArrowDownIcon />}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="FilterFooter">
                    <Divider />
                    <Box className="FilterAction">
                      <Button variant="outlined" size="small">
                        Reset All
                      </Button>
                      <Button variant="contained" color="primary" size="small">
                        See “Results” results
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Popover>
            </form>
          </>
        )}

        {houseList?.length > 0 ? (
          <Choose>
            <When condition={props?.communityId === null}>
              {console.log(props?.communityId, 4000)}
              <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
                {houseFilterFunction(houseList, tabValue, searchedText).map((hs) => (
                  <HouseCard hs={hs} updateFavObject={createFavListObject} />
                ))}
              </Grid>
            </When>
            <When condition={props?.communityId}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box className="CarouselContainer">
                    {houseList
                      .filter((hs) => hs.communityId === props?.communityId)
                      .forEach(() => {
                        sliderHtml += '<div style={{ width: 300, height: 300 }}>slide 0</div>';
                      })}
                    {console.log(sliderHtml)}
                    <Box className="CarouselInner">
                      <Carousel
                        containerProps={{
                          style: {
                            width: '100%',
                            justifyContent: 'space-between',
                            userSelect: 'none',
                          },
                        }}
                        disableSwipeByTouch
                        disableSwipeByMouse
                        preventScrollOnSwipe
                        swipeTreshold={60}
                        infinite={false}
                        activeSlideIndex={activeSlide}
                        activeSlideProps={
                          {
                            // style: {
                            //   background: 'blue',
                            // },
                          }
                        }
                        onRequestChange={setActiveSlide}
                        forwardBtnProps={{
                          children: '',
                          style: {
                            width: '30px',
                            height: '30px',
                            minWidth: '30px',
                            alignSelf: 'center',
                            position: 'absolute',
                            zIndex: '999',
                            backgroundColor: '#000',
                            color: '#fff',
                            borderRadius: '100%',
                            cursor: 'pointer',
                            right: '10px',
                            backgroundImage: `url(${ArrowRight})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            border: 'none',
                            top: '30%',
                          },
                        }}
                        backwardBtnProps={{
                          children: '',
                          style: {
                            width: '30px',
                            height: '30px',
                            minWidth: '30px',
                            alignSelf: 'center',
                            position: 'absolute',
                            zIndex: '999',
                            backgroundColor: '#000',
                            color: '#fff',
                            borderRadius: '100%',
                            cursor: 'pointer',
                            backgroundImage: `url(${ArrowLeft})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            border: 'none',
                            top: '30%',
                            left: '10px',
                          },
                        }}
                        dotsNav={{
                          show: false,
                          itemBtnProps: {
                            style: {
                              height: 16,
                              width: 16,
                              borderRadius: '50%',
                              border: 0,
                            },
                          },
                          activeItemBtnProps: {
                            style: {
                              height: 16,
                              width: 16,
                              borderRadius: '50%',
                              border: 0,
                              background: 'black',
                            },
                          },
                        }}
                        itemsToShow={0}
                        speed={400}
                      >
                        {houseList
                          .filter((hs) => hs.communityId === props?.communityId)
                          .map((item) => (
                            <div
                              style={{
                                minWidth: 300,
                              }}
                              key={item.id}
                            >
                              <HouseCard
                                homeInView
                                hs={item}
                                updateFavObject={createFavListObject}
                              />
                            </div>
                          ))}
                      </Carousel>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </When>
          </Choose>
        ) : (
          'No houses in this community yet...'
        )}
      </Box>
    </Container>
  );
};
// const CarouselWrapper = styled.div`
//   &.carousel-container {
//     margin: 12px auto;
//     max-width: 272px;
//     filter: drop-shadow(0px 12px 30px rgba(50, 50, 50, 0.2));

//     /* Total-width (including margin) + 1 additional margin */
//     @media (min-width: 832px) {
//       max-width: 704px;
//     }

//     @media (min-width: 1088px) {
//       max-width: 960px;
//     }

//     @media (min-width: 1272px) {
//       max-width: 1152px;
//     }

//     @media (min-width: 1504px) {
//       max-width: 1344px;
//     }
//   }

//   /* This class is found in Slide from pure-react-carousel */
//   /* We need to override it to add space between slides */
//   .carousel__inner-slide {
//     /* width: 100% - margin */
//     width: calc(100% - 16px);
//     /* margin-left: margin/2 */
//     /* margin is required to adjust positioning as the width is diminished*/
//     margin-left: 8px;

//     @media (min-width: 1272px) {
//       width: calc(100% - 24px);
//       margin-left: 12px;
//     }

//     @media (min-width: 1272px) {
//       width: calc(100% - 32px);
//       margin-left: 16px;
//     }
//   }
// `;
HouseList.defaultProps = {
  showSearch: true,
  communityId: null,
  tabValue: 0,
};
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const optionsBox = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
];
export default HouseList;
