/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Alert } from '@material-ui/lab';
import {
  Grid,
  Link,
  Typography,
  Box,
  TextField,
  InputLabel,
  Button,
  Container,
  Select,
  MenuItem,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import * as yup from 'yup';
import { InputAdornment } from '@material-ui/core';
// import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { ReactComponent as LocationIcon } from '../../../../assets/images/LocationIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/images/TrashWhite.svg';
import { Formik, Form, Field, useFormik, FastField } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useCommunityList from '../../../../hooks/useCommunityList';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { showErrIfTouched } from '../../../../utils';
import * as Yup from 'yup';
import { set, values } from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import { ReactComponent as SnackBarTrashIcon } from '../../../../assets/images/TrashIcon.svg';
const CommunityEditViewAddNewAmenity = (props) => {
  const theme = useTheme();
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [showAmenityDeleteBox, setShowAmenityDeleteBox] = useState(false);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [amenitiesDesc, setAmenitiesDesc] = React.useState('');

  const [amenityCategories, setAmenityCategories] = useState([]);
  const [amenityToEdit, setAmenityToEdit] = useState(null);
  const [isEditView, setIsEditView] = useState(false);
  const [selectedAmenityCategory, setSelectedAmenityCategory] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const maxCharacterCount = 700;
  const {
    addAmenitiesDescForCommunity,
    getAmenityCategories,
    addAmenityMaster,
    getAmenityById,
    deleteAmenity,
  } = useCommunityList();
  const params = useParams();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const maxImages = 6;
  const maxFileSize = 5 * 1024 * 1024;
  const imgDimensions = {
    width: 1200,
    height: 720,
    minWidth: 400,
    minHeight: 236,
    ratio: 1.3,
    errorMessage:
      'For optimal display, please ensure images have a balanced 4:3 width-to-height ratio with a minimum width of 400 pixels.',
  };
  const [uploadError, setUploadError] = useState(null);
  const [isImagesDirty, setIsImagesDirty] = useState(false);
  const formData = new FormData();
  const formikRef = React.createRef();
  let imageDim = {};
  const [isCommunityRepresentative] = useState(params?.id === loggedInUserData?.communityId);
  const [previmages, setPrevimages] = useState(images);
  const [isAccessible, setIsAccessible] = React.useState('');

  const handleClose = () => {
    setSnackBarOpen(false);
    navigate(`../../../community-details/${params?.id}#Section1`);
  };

  const validationSchema = Yup.object().shape({
    imageList: Yup.mixed()
      .test('fileSize', 'File size too large, max file size is 5mb', (fileVal) =>
        fileVal ? fileVal.size <= maxFileSize : true,
      )
      .test('fileType', 'Incorrect file type', (fileVal) =>
        fileVal ? ['image/png', 'image/jpg', 'image/jpeg'].includes(fileVal.type) : true,
      ),

    amenityType: yup.string().required('Amenity type is required'),
    amenityName: yup.string().required('Amenity name is required'),
    amenityAddress: yup.string().required('Amenity address is required'),
    amenityDescription: yup.string().required('Amenity description is required'),
  });
  const handleTextAreaChange = (event, value) => {
    setAmenitiesDesc(value);
  };

  const reset = (values, images) => {
    values = {};
    setImages([]);
  };

  const handleBackToParent = (e, formikObj) => {
    e.preventDefault();
    // if (!(!formikObj.dirty || !formikObj.isValid || formikObj.isSubmitting) || isImagesDirty) {
    if (
      !(!formikObj.dirty || formikObj.isSubmitting) ||
      isImagesDirty ||
      images.length !== previmages.length
    ) {
      setShowDeleteBox(true);
    } else {
      if (params?.amenityId && params?.amenityId !== 'default') {
        navigate(`../../../view-all-amenities/${params?.id}`);
      } else {
        navigate(`../../../community-details/${params?.id}#Section1`);
      }
    }
  };

  const onTakeMeback = (event) => {
    //console.log('here');
    setShowDeleteBox(false);
    setShowAmenityDeleteBox(false);
  };

  const onDeleteBack = (event) => {
    //console.log('here');
    if (params?.amenityId && params?.amenityId !== 'default') {
      navigate(`../../../view-all-amenities/${params?.id}`);
    } else {
      navigate(`../../../community-details/${params?.id}#Section1`);
    }
  };

  const handleDeleteAmenity = async (response) => {
    if (response) {
      setShowDeleteBox(false);
      const result = await deleteAmenity({ id: params?.amenityId });
      if (result) {
        reset(values, images);
        setSnackBarStatus({ status: result?.status, message: result?.message, type: 'delete' });
        setSnackBarOpen(true);
        setTimeout(() => {
          setSnackBarOpen(false);
          navigate(`../../../community-details/${params?.id}#Section1`);
        }, 2500);
      }
    }
  };

  const handlePublish = async (values) => {
    //loggedIn user sholuld be community rep and param should have community Id

    try {
      const amenityAddress = values?.amenityAddress?.trim();
      const amenityDescription = values?.amenityDescription?.trim();
      const amenityName = values?.amenityName?.trim();
      formData.append('communityId', params?.id);
      formData.append('repId', loggedInUserData?.id);
      formData.append('isAdminEntry', Boolean(loggedInUserData?.roleId === 2));
      formData.append('amenityAddress', amenityAddress);
      formData.append('amenityCategoryId', values?.amenityCategory);
      formData.append('amenityDesc', amenityDescription);
      formData.append('amenityName', amenityName);
      formData.append('amenityTypeId', values?.amenityType);
      params?.amenityId &&
        params?.amenityId !== 'default' &&
        formData.append('amenityId', params?.amenityId);
      images.forEach((file, index) => {
        formData.append(`imageList`, file);
      });
      // formData.append('imageList', images);
      const result = await addAmenityMaster(formData);
      // console.log(result, 3131);
      if (result) {
        setSnackBarStatus({ status: result?.status, message: result?.message, type: 'add' });
        setSnackBarOpen(true);
        reset(values, images);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isSupportedFileType = (file) => {
    const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    return acceptedTypes.includes(file.type);
  };

  const getUniqueIdentifier = (file) => {
    //console.log('getUniqueIdentifier');
    return `${file.size}`;
  };

  const loadImageAndCheckDimensions = (src, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        //console.log(parseFloat((width / height)?.toFixed(2)), '1919');
        if (
          !(width >= imgDimensions?.minWidth) &&
          parseFloat((width / height)?.toFixed(1)) !== imgDimensions?.ratio
        ) {
          resolve('Incorrect Photo dimensions');
        } else {
          resolve('Photo dimensions are within the allowed limits.');
        }
        // if (Number(width) !== Number(maxWidth) || Number(height) !== Number(maxHeight)) {
        //   resolve('Incorrect Photo dimensions');
        // } else {

        // }
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = src;
    });
  };

  const handleImageUpload = async (event, values, errors, form, field) => {
    //console.log(event, 1111);
    if (event) {
      const selectedFiles = event.target.files;
      let checkImageDims = '';
      let newImages = [];

      for (const file of selectedFiles) {
        if (!isSupportedFileType(file)) {
          setUploadError('Incorrect file type');
          return;
        }

        if (file.size > maxFileSize) {
          setUploadError('File size too large, max file size is 5mb');
          return;
        }
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          try {
            const imageSrc = await new Promise((resolve, reject) => {
              reader.onload = () => {
                resolve(reader.result);
              };
              reader.onerror = (error) => {
                reject(error);
              };
            });

            checkImageDims = await loadImageAndCheckDimensions(
              imageSrc,
              imgDimensions.width,
              imgDimensions.height,
            );
          } catch (error) {
            console.error('Error loading image:', error);
          }
        }
        // imgDimensions
        newImages = Array.from(selectedFiles).filter(
          (file) =>
            isSupportedFileType(file) &&
            file.size <= maxFileSize &&
            !images.some((image) => {
              //console.log(getUniqueIdentifier(image), '===', getUniqueIdentifier(file));
              return getUniqueIdentifier(image) === getUniqueIdentifier(file);
            }),
        );
        //console.log(newImages, 6565);
        if (newImages.length == 0) {
          setUploadError('Duplicate image is not allowed');
          return;
        }
        newImages = Array.from(selectedFiles);
        if (newImages.length + images.length > maxImages) {
          alert(`You can only select up to ${maxImages} images.`);
          return;
        }
        if (checkImageDims == 'Incorrect Photo dimensions') {
          setUploadError(imgDimensions?.errorMessage);
          return;
        }
      }

      setUploadError(null);

      const imageList = [...images, ...newImages];
      setImages(imageList);
      //console.log(imageList, 1111);
      // setPrevimages(imageList);
    }
  };

  const handleImageDelete = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    //console.log(newImages, 8686);
    setImages(newImages);
    // setPrevimages(newImages);
  };

  const loadAmenityCategories = async () => {
    const amenityCatArr = await getAmenityCategories();
    setAmenityCategories(amenityCatArr.usersResult);
  };
  //console.log(amenityCategories, 'AMCT');
  const loadAmenity = async () => {
    try {
      if (params?.amenityId && params?.amenityId !== 'default') {
        //load amenity details from master
        const amenityArr = await getAmenityById({ id: params?.amenityId });
        setAmenityToEdit(amenityArr.usersResult);
        if (amenityArr.usersResult) {
          setIsEditView(true);
          //load amenity types
          const selectedAmenityCat = amenityCategories.find(
            (category) => category?.id == amenityArr.usersResult[0]?.amenityCategory?.id,
          );
          setSelectedAmenityCategory(selectedAmenityCat);

          //load images
          await fetchServerImages(amenityArr.usersResult[0]?.amenityGallery);

          //set initial values
          setInitialValues({
            amenityDescription:
              (amenityArr.usersResult && amenityArr.usersResult[0]?.amenityDesc) || '',
            amenityAddress:
              (amenityArr.usersResult && amenityArr.usersResult[0]?.amenityAddress) || '',
            amenityType:
              (amenityArr.usersResult && amenityArr.usersResult[0]?.amenityType?.id) || '',
            amenityTypeName:
              (amenityArr.usersResult && amenityArr.usersResult[0]?.amenityType?.amenityTypeName) ||
              '',
            amenityCategory:
              (amenityArr.usersResult && amenityArr.usersResult[0]?.amenityCategory?.id) || '',
            amenityCategoryName:
              (amenityArr.usersResult &&
                amenityArr.usersResult[0]?.amenityCategory?.amenityCategoryName) ||
              '',
            amenityName: (amenityArr.usersResult && amenityArr.usersResult[0]?.amenityName) || '',
          });
        } else {
          setIsEditView(false);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchServerImages = async (storedImagesArray) => {
    try {
      const fetchedImages = await Promise.all(
        storedImagesArray.map(async (storedImage) => {
          const imagePath =
            window.location.hostname === 'localhost'
              ? `//localhost:9001/${storedImage.image}`
              : `api/${storedImage.image}`;

          const response = await fetch(imagePath);
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);

          const imageName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
          const imageType = imageBlob.type;
          const lastModified = Date.now();

          const imageFile = new File([imageBlob], imageName, {
            type: imageType,
            lastModified: lastModified,
          });

          return imageFile;
        }),
      );

      setImages(fetchedImages);
      setPrevimages(fetchedImages);
    } catch (error) {
      console.error('Error fetching server images:', error);
    }
  };

  useEffect(() => {
    loadAmenityCategories();
  }, []);
  useEffect(() => {
    handleImageUpload();
  }, [uploadError]);

  useEffect(() => {
    console.log(snackBarOpen);
  }, [snackBarOpen]);

  useEffect(() => {
    //console.log(images.length, 'images');
    //console.log(previmages.length, 'previmages');
    if (
      previmages.length > 0 &&
      images.length !== previmages.length
      // ||
      // (images.length > 0 && images.length !== previmages.length)
    ) {
      setIsImagesDirty(true);
      return;
    }

    // if (images.length > 0 && images.length !== previmages.length) {
    //   setIsImagesDirty(true);
    //   return;
    // }

    for (let i = 0; i < images.length; i++) {
      if (
        previmages.length > 0 &&
        images[i]?.name !== previmages[i].name &&
        images[i]?.size !== previmages[i].size
      ) {
        //console.log(images[i], '!==', previmages[i]);
        setIsImagesDirty(true);
        return;
      }
    }
    setIsImagesDirty(false);
  }, [images]);

  useEffect(() => {
    loadAmenity();
  }, [params?.amenityId, amenityCategories]);

  useEffect(() => {
    loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2
      ? setIsAccessible(true)
      : setIsAccessible(false);
  }, [loggedInUserData]);

  return (
    <Container>
      <Grid container className="PageSpacing FixedMD">
        <Grid item xs={12}>
          {/* {loggedInUserData?.roleId == 1 && isCommunityRepresentative ? ( */}
          {isAccessible ? (
            <>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e, formikRef.current);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    {params?.amenityId && params?.amenityId !== 'default' ? 'Edit ' : 'Add New '}
                    Amenity
                  </Typography>
                </Link>
              </Grid>
              <Grid container className="FixedID">
                <Grid item xs={12}>
                  <Typography variant="caption" className="SubtitleCEV">
                    Amenities are listed by the most recent first on community pages. All fields are
                    required.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handlePublish}
                    innerRef={formikRef}
                    enableReinitialize={true}
                  >
                    {({
                      handleSubmit,
                      setFieldValue,
                      isValid,
                      isSubmitting,
                      dirty,
                      touched,
                      handleChange,
                      values,
                      errors,
                    }) => (
                      <Form className="" onSubmit={handleSubmit} encType="multipart/form-data">
                        <Grid container className="AddAmenityForm">
                          <Grid item xs={12} className="commonFieldBox">
                            <InputLabel className="InputLabelFld" htmlFor="amenityName">
                              Amenity Name
                              {/* <Box
                                mb={1}
                                component="span"
                                display="inline-block"
                                className="Asterisk"
                              >
                                *
                              </Box> */}
                            </InputLabel>
                            <FastField
                              as={TextField}
                              name="amenityName"
                              id="outlined-basic"
                              variant="outlined"
                              type="text"
                              error={!!showErrIfTouched('amenityName', errors, touched)}
                              helperText={showErrIfTouched('amenityName', errors, touched)}
                              fullWidth
                            />
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} className="commonFieldBox">
                              <InputLabel className="InputLabelFld" htmlFor="amenityCategory">
                                Category
                              </InputLabel>
                              <Autocomplete
                                className="CommonAutocomplete"
                                id="amenityCategory"
                                options={amenityCategories || []}
                                disableClearable={!selectedAmenityCategory ? true : false}
                                value={
                                  values?.amenityCategory !== undefined &&
                                  amenityCategories.find((v) => v?.id == values?.amenityCategory)
                                }
                                getOptionLabel={(option) => option.amenityCategoryName || ''}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder=""
                                    label=""
                                    variant="outlined"
                                  />
                                )}
                                onChange={(e, value) => {
                                  setFieldValue('amenityCategory', value?.id || '');
                                  setFieldValue(
                                    'amenityCategoryName',
                                    value?.amenityCategoryName || '',
                                  );
                                  setFieldValue('amenityType', '');
                                  setFieldValue('amenityTypeName', '');

                                  setSelectedAmenityCategory(value);
                                }}
                                popupIcon={<KeyboardArrowDownIcon />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} className="commonFieldBox">
                              <Box display="flex" justifyContent="space-between">
                                <InputLabel className="InputLabelFld" htmlFor="amenityType">
                                  Type
                                </InputLabel>
                                <Typography variant="caption" component="div">
                                  Requires a Category to be selected
                                </Typography>
                              </Box>
                              <Autocomplete
                                className="CommonAutocomplete"
                                id="amenityType"
                                disabled={!selectedAmenityCategory}
                                disableClearable={!values.amenityType ? true : false}
                                options={selectedAmenityCategory?.amenityType || []}
                                getOptionLabel={(option) => option.amenityTypeName || ''}
                                value={
                                  values?.amenityType !== undefined &&
                                  selectedAmenityCategory?.amenityType &&
                                  selectedAmenityCategory?.amenityType.find(
                                    (v) => v.id === values.amenityType,
                                  )
                                }
                                onChange={(e, selectedOption) => {
                                  setFieldValue('amenityType', selectedOption?.id || '');
                                  setFieldValue(
                                    'amenityTypeName',
                                    selectedOption?.amenityTypeName || '',
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder=""
                                    label=""
                                    variant="outlined"
                                  />
                                )}
                                popupIcon={<KeyboardArrowDownIcon />}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} className="commonFieldBox">
                            <InputLabel className="InputLabelFld" htmlFor="amenityAddress">
                              Address
                            </InputLabel>
                            <FastField
                              as={TextField}
                              name="amenityAddress"
                              id="outlined-basic"
                              variant="outlined"
                              type="text"
                              error={!!showErrIfTouched('amenityAddress', errors, touched)}
                              helperText={showErrIfTouched('amenityAddress', errors, touched)}
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationIcon className="AmenityIcons" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          {/* <Field
                        as={TextField}
                        className=""
                        variant="outlined"
                        name="amenityAddress"
                        value={values.amenityAddress}
                        
                      />
                      {touched.amenityAddress && errors.amenityAddress && (
                        <Typography color="error">{errors.amenityAddress}</Typography>
                      )} */}
                          <Grid item xs={12} className="commonFieldBox">
                            <InputLabel className="InputLabelFld" htmlFor="amenityDescription">
                              Description
                            </InputLabel>
                            <Field
                              as={TextField}
                              name="amenityDescription"
                              value={values.amenityDescription}
                              className="TextFieldTextarea"
                              fullWidth
                              multiline
                              minRows={8}
                              maxLength={700}
                              label=""
                              variant="outlined"
                              onChange={(e, value) => {
                                //console.log(e.target.value, 500);
                                setFieldValue('amenityDescription', e.target.value);
                                handleTextAreaChange(e, e.target.value);
                              }}
                              inputProps={{
                                maxLength: maxCharacterCount,
                              }}
                              helperText={`${
                                maxCharacterCount - (values?.amenityDescription || '').length
                              }`}
                            />
                            {touched.amenityDescription && errors.amenityDescription && (
                              <Typography color="error">{errors.amenityDescription}</Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} className="commonFieldBox">
                            <Field name="imageList">
                              {({ field, form }) => (
                                <Box className="AmenityImagesContainer">
                                  <Box display="flex" justifyContent="space-between">
                                    <InputLabel className="InputLabelFld" htmlFor="amenityImages">
                                      Amenity Photos
                                    </InputLabel>
                                    <Typography variant="body2" component="div">
                                      {images.length}/{maxImages} Photos
                                    </Typography>
                                  </Box>
                                  <Typography variant="body2">
                                    At least one photo is required.
                                  </Typography>
                                  <Box className="AmenityImagesContent">
                                    {images.map((image, index) => (
                                      <Box key={index} elevation={3} className="AmenityImagesBox">
                                        <img
                                          src={URL.createObjectURL(image)}
                                          alt={`Image-${index + 1}`}
                                          style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                          }}
                                          className="AmenityImg"
                                        />
                                        <IconButton
                                          onClick={() => handleImageDelete(index)}
                                          style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                          }}
                                          className="AmenityImagesDeleteBox"
                                        >
                                          <TrashIcon />
                                        </IconButton>
                                      </Box>
                                    ))}

                                    {images.length < maxImages && (
                                      <label
                                        htmlFor="image-input"
                                        className="AmenityImagesUploadBox"
                                      >
                                        <input
                                          type="file"
                                          id="image-input"
                                          accept=".jpg, .jpeg, .png"
                                          style={{ display: 'none' }}
                                          multiple
                                          // onClick={(e) => {
                                          //   e.target.value = null;
                                          // }}
                                          onChange={(e) => {
                                            handleImageUpload(e, values, errors, form, field);
                                          }}
                                        />
                                        {images.length === 0 ? (
                                          <Box elevation={3} className="AmenityAddImagesBox">
                                            <PhotoCameraOutlinedIcon
                                              color="primary"
                                              fontSize="large"
                                            />
                                            <Typography
                                              variant="body2"
                                              color="primary"
                                              component="div"
                                              className="AmenityAddImagesLabel"
                                            >
                                              Add Photo
                                            </Typography>
                                          </Box>
                                        ) : (
                                          <Box elevation={3} className="AmenityAddImagesBoxSecond">
                                            <PhotoCameraIcon color="primary" fontSize="large" />
                                            <Typography
                                              variant="body2"
                                              color="primary"
                                              component="div"
                                              className="AmenityAddImagesLabel"
                                            >
                                              Add Photo
                                            </Typography>
                                          </Box>
                                        )}
                                      </label>
                                    )}
                                  </Box>
                                  {uploadError && (
                                    <Box mt={2}>
                                      {' '}
                                      <Typography
                                        variant="body2"
                                        component="div"
                                        className="CustomError"
                                      >
                                        {uploadError}
                                      </Typography>{' '}
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Box className="Wrapper ButtonWrapper">
                              <Button
                                disabled={
                                  ((params?.amenityId !== 'default' &&
                                  dirty &&
                                  values['amenityTypeName'] == ''
                                    ? true
                                    : params?.amenityId !== 'default' &&
                                      dirty &&
                                      values['amenityTypeName'] !== ''
                                    ? false
                                    : !dirty || !isValid || isSubmitting || !images.length > 0) &&
                                    !isImagesDirty) ||
                                  images.length == 0

                                  // !(
                                  //   values?.hasOwnProperty('amenityAddress') &&
                                  //   values?.hasOwnProperty('amenityCategoryName') &&
                                  //   values?.hasOwnProperty('amenityDescription') &&
                                  //   values?.hasOwnProperty('amenityName') &&
                                  //   values?.hasOwnProperty('amenityTypeName') &&
                                  //   images.length > 0
                                  // )
                                }
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="EditViewButton"
                                fullWidth
                              >
                                Publish
                              </Button>
                            </Box>
                            <Box component="div" display="flex" justifyContent="center">
                              {isEditView && (
                                <Button
                                  onClick={() => {
                                    setShowAmenityDeleteBox(true);
                                  }}
                                  underline="none"
                                  className="DeleteAmenityButton LinkTypeButton RedLineButton"
                                  color="secondary"
                                  variant="text"
                                >
                                  Delete Amenity
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
              {/* {console.log(showDeleteBox, 5689)} */}
              {showDeleteBox && (
                <>
                  <ModalEditViewDelete
                    status={true}
                    navigateUrl={`../../../community-details/${params?.id}#Section1`}
                    onDelete={onDeleteBack}
                    onClose={onTakeMeback}
                  ></ModalEditViewDelete>
                </>
              )}
              {showAmenityDeleteBox && (
                <>
                  <ModalEditViewDelete
                    status={true}
                    variant="amenity"
                    navigateUrl={`../../community-details/${params?.id}#Section1`}
                    onClose={onTakeMeback}
                    onDelete={handleDeleteAmenity}
                  ></ModalEditViewDelete>
                </>
              )}
              <Choose>
                <When condition={!snackBarStatus?.status && snackBarStatus?.type == 'add'}>
                  <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleClose}>
                    <Alert
                      elevation={6}
                      variant="filled"
                      onClose={handleClose}
                      severity="error"
                      icon={<WarningRoundedIcon color="secondary" fontSize="inherit" />}
                      action={
                        <Link
                          color="primary"
                          underline="none"
                          aria-label="close"
                          onClick={handleClose}
                          className="AlertDismissButton"
                        >
                          Dismiss
                        </Link>
                      }
                    >
                      {snackBarStatus?.message}
                    </Alert>
                  </Snackbar>
                </When>
                <When condition={snackBarStatus?.status && snackBarStatus?.type == 'delete'}>
                  <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleClose}>
                    <Alert
                      elevation={6}
                      variant="filled"
                      onClose={handleClose}
                      severity="success"
                      icon={<SnackBarTrashIcon color="primary" fontSize="inherit" />}
                      action={
                        <Link
                          color="primary"
                          underline="none"
                          aria-label="close"
                          onClick={handleClose}
                          className="AlertDismissButton"
                        >
                          Dismiss
                        </Link>
                      }
                    >
                      {/* <Typography
                        variant="body2"
                        component="div"
                        className="BlackColor SnackbarHeading"
                      >
                        Amenity Deleted
                      </Typography> */}
                      {/* <Typography variant="caption" component="div" className="SnackbarSubheading">
                        Your amenity was successfully removed
                      </Typography> */}
                      {snackBarStatus?.message}
                    </Alert>
                  </Snackbar>
                </When>
                <When condition={snackBarStatus?.status && snackBarStatus?.type == 'add'}>
                  <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleClose}>
                    <Alert
                      elevation={6}
                      variant="filled"
                      onClose={handleClose}
                      severity="success"
                      icon={<CheckCircleIcon color="primary" fontSize="inherit" />}
                      action={
                        <Link
                          color="primary"
                          underline="none"
                          aria-label="close"
                          onClick={handleClose}
                          className="AlertDismissButton"
                        >
                          Dismiss
                        </Link>
                      }
                    >
                      {snackBarStatus?.message}
                    </Alert>
                  </Snackbar>
                </When>
              </Choose>
            </>
          ) : (
            'You do not have permissions to access this url.'
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
CommunityEditViewAddNewAmenity.defaultProps = {};
export default CommunityEditViewAddNewAmenity;
