/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'; // Import useFormik
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import {
  Grid,
  Link,
  Typography,
  Box,
  TextField,
  InputLabel,
  Button,
  Container,
  Divider,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useCommunityList from '../../../../hooks/useCommunityList';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';
const CommunityEditViewCostOfLiving = (props) => {
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [userId] = useState(loggedInUserData?.id);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [communityDetails, setCommunityDetails] = React.useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [costOfLivingValue, setCostOfLivingValue] = React.useState('');
  const [costOfLivingDesc, setCostOfLivingDesc] = React.useState('');

  console.log(costOfLivingValue);

  const maxCharacterCount = 700;
  const { addCommunityCostOfLiving, getCommunityDetailById } = useCommunityList();
  const params = useParams();
  const navigate = useNavigate();
  const [isAccessible, setIsAccessible] = useState(false);

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setCostOfLivingValue(value);
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;

    setClimateDesc(value);
  };

  const formik = useFormik({
    initialValues: {
      communityId: params?.id,
      repId: loggedInUserData?.id,
      costOfLivingType: costOfLivingValue, // Use the captured value
      costOfLivingDesc: costOfLivingDesc,
      isAdminEntry: loggedInUserData?.roleId === 2,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const costOfLivingDesc = values.costOfLivingDesc?.trim();
      values['costOfLivingDesc'] = costOfLivingDesc;
      try {
        const response = await addCommunityCostOfLiving(values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response); // The response from the API
        formik.resetForm(); // Reset the form after successful submission
        navigate(`../../community-details/${params?.id}#Section7`);
        // Do something with the response, e.g., show a success message
      } catch (error) {
        console.error(error.response.data); // Log any error response from the API
        // Handle error, e.g., show an error message
      }
    },
  });

  let communityDetailsResults = [];
  const fillCommunityDetails = async () => {
    if (params?.id) {
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults.data);
      setCostOfLivingDesc(communityDetailsResults?.data?.costOfLiving[0]?.costOfLivingDesc || '');
      setCostOfLivingValue(
        communityDetailsResults?.data?.costOfLiving?.[0]?.costOfLivingType || '',
      );
    }
    return communityDetailsResults?.data;
  };

  useEffect(() => {
    fillCommunityDetails();
  }, []);

  useEffect(() => {
    console.log(communityDetails, 2222);
    if (communityDetails) {
      let checkCostOfLiving = false;
      let checkCostOfLivingType = false;

      if (communityDetails?.costOfLiving?.[0]['costOfLivingDesc'] == costOfLivingDesc) {
        checkCostOfLivingType = true;
      }
      if (communityDetails?.costOfLiving?.[0]['costOfLivingType'] == costOfLivingValue) {
        checkCostOfLiving = true;
      }

      if (checkCostOfLiving && checkCostOfLivingType) {
        setIsEdited(false);
      }
      //console.log(checkSchoolTypes, 1002, checkPostDesc, 1003, checkPreDesc, 1004);
    }
  }, [costOfLivingDesc, costOfLivingValue]);

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (formik.dirty) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${params?.id}#Section7`);
    }
  };

  useEffect(() => {
    loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2
      ? setIsAccessible(true)
      : setIsAccessible(false);
  }, [loggedInUserData]);

  return (
    <Container>
      <Box className="PageSpacing FixedMD CommunityEditViewCostOfLiving">
        {isAccessible ? (
          <>
            {showDeleteBox && (
              <>
                <ModalEditViewDelete
                  status={true}
                  navigateUrl={`../../community-details/${params?.id}#Section7`}
                  onClose={onTakeMeback}
                ></ModalEditViewDelete>
              </>
            )}
            <Grid container>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    Cost of Living Insight
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container className="FixedID">
              <Grid item xs={12} className="AutoWidth">
                <Box className="CommonDetailBoxGrid DataCDNumber">
                  <Typography variant="h4" component="div" className="CDNumber">
                    ${communityDetails?.medianHomePrice}
                  </Typography>
                  <Typography variant="body2" component="div">
                    Median Home Listing Price
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Divider className="DividerTB" />
              </Grid>
              <Grid item xs={12} className="CommonOption">
                <Box className="HeadingBoxCEV FlexCenterStart">
                  <Typography variant="body2" className="HeadingCEV">
                    Cost of Living
                  </Typography>
                  <Typography variant="caption" className="HeadingCEVCaption">
                    Select one
                  </Typography>
                </Box>
                <Typography variant="body2" className="SubtitleCEV">
                  Inputting this content helps users find your community when filtering.
                </Typography>
              </Grid>
              <Grid item xs={12} className="CommonOption">
                <Box className="RadioOption">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="Climate Type"
                      name="costOfLivingValue"
                      value={costOfLivingValue}
                      onChange={handleChange1}
                      {...formik.getFieldProps('costOfLivingType')}
                    >
                      <FormControlLabel
                        value="Below the national average"
                        control={<Radio color="primary" />}
                        label="Below the national average"
                      />
                      <FormControlLabel
                        value="Similar to the national average"
                        control={<Radio color="primary" />}
                        label="Similar to the national average"
                      />
                      <FormControlLabel
                        value="Above the national average"
                        control={<Radio color="primary" />}
                        label="Above the national average"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <InputLabel className="InputLabelFld">
                    How far does the dollar go in your Community? What are some benefits to living
                    here that people may not know?
                  </InputLabel>
                </Box>
                <TextField
                  className="TextFieldTextarea"
                  fullWidth
                  multiline
                  minRows={8}
                  maxLength={700}
                  label=""
                  variant="outlined"
                  value={costOfLivingDesc}
                  onChange={handleChange2}
                  {...formik.getFieldProps('costOfLivingDesc')}
                  inputProps={{
                    maxLength: maxCharacterCount,
                  }}
                  // helperText={`${maxCharacterCount - climateDesc.length}`}
                  helperText={`${maxCharacterCount - formik.values.costOfLivingDesc.length}`}
                />
              </Grid>

              <Grid item xs={12}>
                <div className="Wrapper ButtonWrapper">
                  <Button
                    // disabled={
                    //   !publishBool && climateDesc.length == 0 && climateValue == ''
                    //     ? false
                    //     : climateDesc.length == 0 && climateValue == ''
                    // }
                    disabled={
                      !formik.dirty ||
                      (!costOfLivingDesc.length === 0 && !costOfLivingValue === 0 && !isEdited)
                    }
                    onClick={formik.handleSubmit}
                    variant="contained"
                    color="primary"
                    className="EditViewButton"
                    fullWidth
                  >
                    Publish
                  </Button>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          'You do not have permissions to access this url.'
        )}
      </Box>
    </Container>
  );
};
CommunityEditViewCostOfLiving.defaultProps = {};
export default CommunityEditViewCostOfLiving;
