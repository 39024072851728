/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Menu,
  MenuItem,
  Button,
  Typography,
  Box,
  Tab,
  Tabs,
  InputBase,
  InputAdornment,
  TextField,
  Popover,
  Paper,
  Divider,
  Container,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../assets/images/Search-Icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { entityEnum } from '../../../constants/globalConstants';
import useAuth from '../../../hooks/useAuth';
import EmptyState from './EmptyState/EmptyState';
import '../Main.style.css';
// import useStyles from '../Main.style';
import CommunityCard from './CommunityCard/CommunityCard';
import CardLoader from '../../../components/CardLoader/CardLoader';

const OnboardingConfirmation = (props) => {
  const { invitationId } = useParams();
  const { getInviteDetails } = useAuth();
  const [invitationDetails, setInvitationDetails] = useState(null);
  const _getInviteDetails = async () => {
    const invitationData = await getInviteDetails({ invitationId });
    setInvitationDetails(invitationData?.data?.id ? invitationData?.data : null);
  };
  useEffect(() => {
    if (invitationId) {
      _getInviteDetails();
    }
  }, [invitationId]);
  return (
    <Box className="FullWidthPattern">
      <Container>
        {
          <Box container className="PageSpacing FixedMD">
            <Box className="InvitationWrapper DeclineInvitationWrapper">
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Box className="OnboardingBg">
                    <Box className="OnboardingImgContainer">
                      {/* <img
                    className=""
                    src={require('../../../assets/images/bg-blue.png').default}
                    alt="background image"
                  /> */}
                      <img
                        className="ConeIcon"
                        src={require('../../../assets/images/ConeIcon.svg').default}
                        alt="background image"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    variant="h2"
                    className="LightgreyColor DeclineInvitationSubTitle"
                  >
                    Congratulations!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" component="div" className="FlexCenter">
                    You are now the Community Representative for{' '}
                    {invitationDetails?.crInvComMapId?.communityNameText || ''}.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box className="Wrapper ButtonWrapper">
                    <Button
                      fullWidth
                      className="Button EditViewButton"
                      variant="contained"
                      color="primary"
                      href={`#/app/community-details/${invitationDetails?.crInvComMapId?.id}`}
                    >
                      Set Up Your Community
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }
      </Container>
    </Box>
  );
};
OnboardingConfirmation.defaultProps = {
  showSearch: true,
  tabValue: 0,
};
export default OnboardingConfirmation;
