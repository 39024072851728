/* eslint-disable global-require */
/* eslint-disable  */

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  IconButton,
  InputLabel,
  TextField,
  Container,
  Link,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useLogin from '../../../hooks/useAuth';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Formik, Form } from 'formik';
// import '../../Auth.style.css';
import ReCAPTCHA from 'react-google-recaptcha';
import useCommunityList from '../../../hooks/useCommunityList';
import { KEY } from '../../../constants/globalConstants';
import ModalEditViewDelete from '../../Main/ModalEditViewDelete/ModalEditViewDelete';

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="CloseButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const LeaveCommunity = () => {
  const { id } = useParams();
  const { leaveCommunity } = useLogin();
  const { getAmenityByType, getCommunityDetailById, getAmenityCategories } = useCommunityList();

  const navigate = useNavigate();

  let loggedInUserData = null;
  const key = 'iFrasCwdJh';
  const userCookie = Cookies.get('user');
  if (userCookie) {
    const bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = React.useState(loggedInUserData?.id);
  const [state, setState] = React.useState({ communityId: id, userId });
  const [isChecked, setIsChecked] = React.useState(false);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const maxCharacterCount = 700;

  const [feedback, setFeedback] = React.useState('');
  const handleTextAreaChange = (event) => {
    const { value } = event.target;
    setFeedback(value);
    setIsEdited(true);
  };
  const [action, setAction] = React.useState({});
  const handleChange = (event) => {
    if (event.target.name !== 'suggestionOne' && event.target.name !== 'suggestionTwo') {
      setIsChecked({ ...isChecked, [event.target.name]: event.target.checked });
      setIsEdited(true);
    }

    if (event.target.value) {
      setState({ ...state, [event.target.name]: event.target.value });
    } else {
      setState({ ...state, [event.target.name]: event.target.checked });
    }
  };
  console.log(isChecked, 'ISCH');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onDeleteHandler = () => {
    leaveCommunity(state, action);
  };

  const recaptchaRef = React.useRef();

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (isEdited) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${id}#Section1`);
    }
  };

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
  };

  const params = useParams();

  const [communityDetails, setCommunityDetails] = React.useState(null);

  const fillCommunityDetails = async () => {
    let communityDetailsResults = [];
    if (params?.id) {
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults.data);
    }
    return communityDetailsResults?.data;
  };

  useEffect(() => {
    fillCommunityDetails();
  }, []);

  return (
    <Formik>
      {({
        errors,
        isSubmitting,
        resetForm,
        setSubmitting,
        setStatus,
        setErrors,
        isValid,
        dirty,
        touched,
      }) => {
        return (
          <Container>
            <Box className="PageSpacing FixedMD LeaveCommunityContainer">
              {/*  <Grid container className="PageSpacing"> */}
              {showDeleteBox && (
                <>
                  <ModalEditViewDelete
                    status={true}
                    navigateUrl={`../../community-details/${params?.id}`}
                    onClose={onTakeMeback}
                  ></ModalEditViewDelete>
                </>
              )}
              <Grid item xs={12}>
                <Form autoComplete="off">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="MainContainer"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <Link
                            underline="none"
                            onClick={(e) => {
                              handleBackToParent(e);
                            }}
                            // onClick={(e) => {
                            //   navigate(`../../community-details/${id}#Section1`);
                            // }}
                            className="BackButtonWrapper"
                          >
                            {/* <img
                              className=""
                              src={require('../../../assets/images/arrow-left.svg').default}
                              alt="background image"
                            /> */}
                            <Typography className="BackButton" variant="body1">
                              <ArrowBackIcon color="primary" className="BackButtonArrow" />
                              Back to Community
                            </Typography>
                          </Link>
                        </Grid>
                        <Grid container className="FixedID">
                          <Grid item xs={12}>
                            <Typography variant="h3" component="div" className="DeclineTitle">
                              Are you sure you want to leave your community?
                            </Typography>
                            <Typography variant="body1" component="div" className="DeclineSubTitle">
                              You will be removed as a community representative. This action cannot
                              be undone and you will need to request an invitation from a Move to
                              Happy administrator to rejoin.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className="CommonOption">
                            <Box className="HeadingBoxCEV FlexCenterStart">
                              <InputLabel className="InputLabelFld">
                                Why are you leaving your community?
                              </InputLabel>
                              <Typography variant="body2" className="HeadingLCCaption">
                                Required
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} className=" CommonOption">
                            <Box className="CheckboxOption CheckboxMT">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.roleChanged}
                                    onChange={handleChange}
                                    name="roleChanged"
                                    color="primary"
                                  />
                                }
                                label="My role has changed and I no longer represent this community."
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.handingOff}
                                    onChange={handleChange}
                                    name="handingOff"
                                    color="primary"
                                  />
                                }
                                label="I am handing off the role to someone else."
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.timeConsuming}
                                    onChange={handleChange}
                                    name="timeConsuming"
                                    color="primary"
                                  />
                                }
                                label="The role is too time consuming. "
                              />
                              <Box mb={3}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.ParticipationNotProvedValue}
                                      onChange={handleChange}
                                      name="ParticipationNotProvedValue"
                                      color="primary"
                                    />
                                  }
                                  label="Participation has not proved to be of value."
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel className="InputLabelFld NoWrap" htmlFor="CommunityName">
                              We value your feedback. Why aren’t you interested in representing a
                              community on Move to Happy?
                              <Box
                                mb={1}
                                component="span"
                                display="inline-block"
                                className="Asterisk"
                              >
                                *
                              </Box>
                            </InputLabel>
                            <TextField
                              className="TextFieldTextarea"
                              fullWidth
                              multiline
                              minRows={8}
                              maxLength={700}
                              label=""
                              value={feedback}
                              variant="outlined"
                              onChange={handleTextAreaChange}
                              inputProps={{
                                maxLength: maxCharacterCount,
                              }}
                              helperText={`${maxCharacterCount - (feedback?.length || 0)}`}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Box className="Wrapper ButtonWrapper">
                              <Button
                                disabled={
                                  !(
                                    isChecked.roleChanged ||
                                    isChecked.handingOff ||
                                    isChecked.timeConsuming ||
                                    isChecked.ParticipationNotProvedValue
                                  ) || feedback.trim().length === 0
                                }
                                variant="contained"
                                color="secondary"
                                type="button"
                                className="EditViewButton"
                                fullWidth
                                onClick={handleClickOpen}
                              >
                                Leave Community
                              </Button>

                              {isSubmitting && (
                                <CircularProgress size={24} className="ButtonProgress" />
                              )}
                            </Box>
                            <Typography
                              variant="caption"
                              component="div"
                              className="EditViewButtonText"
                            >
                              Your insights will remain unless a new community representative edits
                              them.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className="FixedID">
                        <Grid>
                          <ReCAPTCHA ref={recaptchaRef} sitekey={KEY.SITE} size={'invisible'} />
                        </Grid>
                        <Dialog
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={open}
                          className="DeleteDialog"
                        >
                          <MuiDialogTitle
                            className="DialogTitleLeft"
                            id="customized-dialog-title"
                            onClose={handleClose}
                          >
                            <Typography
                              variant="h4"
                              component="div"
                              className="DialogTitleHeading TextColor"
                            >
                              Are you sure you want to leave the community?
                            </Typography>
                            {/* <CloseIcon onClick={handleClose} /> */}
                          </MuiDialogTitle>
                          <MuiDialogContent>
                            <Typography gutterBottom variant="body2" component="div">
                              You will not be able to recover it or any of your saved data.
                            </Typography>
                          </MuiDialogContent>
                          <MuiDialogActions>
                            <Button
                              onClick={handleClose}
                              variant="outlined"
                              color="primary"
                              className="NoBorderButton"
                            >
                              No, Go Back
                            </Button>
                            <Button color="secondary" variant="contained" onClick={onDeleteHandler}>
                              Yes, Leave
                            </Button>
                          </MuiDialogActions>
                        </Dialog>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Box>
          </Container>
        );
      }}
    </Formik>
  );
};

export default LeaveCommunity;
