/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import {
  Grid,
  Link,
  Typography,
  Box,
  TextField,
  Button,
  InputLabel,
  Container,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from '../../Main.style';
import useCommunityList from '../../../../hooks/useCommunityList';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';
import { useFormik } from 'formik'; // Import useFormik

const CommunityEditViewGettingAround = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [userId] = useState(loggedInUserData?.id);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [publicTransValue, setPublicTransValue] = React.useState('');
  const [othrTrans, setOthrTrans] = React.useState({
    dedicatedWalkingPaths: false,
    dedicatedBikingPaths: false,
    localAirport: false,
    regionalAirport: false,
  });

  const [gettingAroundDesc, setGettingAroundDesc] = React.useState('');

  const maxCharacterCount = 700;
  const { addCommunityCatGettingAround, getCommunityDetailById } = useCommunityList();
  const [communityDetails, setCommunityDetails] = React.useState(null);
  const [isEdited, setIsEdited] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const [isAccessible, setIsAccessible] = React.useState('');

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
  };

  const handleChange1 = (event) => {
    setPublicTransValue(event.target.value);
    setIsEdited(true);
  };

  const handleChange2 = (event) => {
    const { name, checked } = event.target;
    setIsEdited(true);
    setOthrTrans((prevOthrTrans) => ({
      ...prevOthrTrans,
      [name]: checked,
    }));
  };

  const handleChange3 = (e) => {
    const { value } = e.target;
    setGettingAroundDesc(value);
    setIsEdited(true);
  };

  function areObjectsEqual(obj1, obj2) {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }
    return true;
  }

  useEffect(() => {
    if (communityDetails) {
      let checkPublicTransValue = false;
      let checkGettingAroundDesc = false;
      let checkOthrTrans = false;

      if (communityDetails?.gettingAround?.[0]?.['publicTransType'] == publicTransValue) {
        checkPublicTransValue = true;
      }
      if (communityDetails?.gettingAround?.[0]?.['gettingAroundDesc'] == gettingAroundDesc) {
        checkGettingAroundDesc = true;
      }

      checkOthrTrans = areObjectsEqual(communityDetails?.gettingAround?.[0], othrTrans);

      if (checkPublicTransValue && checkGettingAroundDesc && checkOthrTrans) {
        setIsEdited(false);
      }
      //console.log(checkSchoolTypes, 1002, checkPostDesc, 1003, checkPreDesc, 1004);
    }
  }, [othrTrans, gettingAroundDesc, publicTransValue]);

  const { dedicatedWalkingPaths, dedicatedBikingPaths, localAirport, regionalAirport } = othrTrans;

  const formik = useFormik({
    initialValues: {
      communityId: params?.id,
      repId: loggedInUserData?.id,
      gettingAroundDesc: gettingAroundDesc,
      publicTransType: publicTransValue,
      dedicatedWalkingPaths: dedicatedWalkingPaths,
      dedicatedBikingPaths: dedicatedBikingPaths,
      localAirport: localAirport,
      regionalAirport: regionalAirport,
      isAdminEntry: loggedInUserData?.roleId === 2,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const trimmedGettingAroundDesc = values?.gettingAroundDesc?.trim();
        values['gettingAroundDesc'] = trimmedGettingAroundDesc;
        const response = await addCommunityCatGettingAround(values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response); // The response from the API
        formik.resetForm(); // Reset the form after successful submission
        navigate(`../../community-details/${params?.id}#Section9`);
        // Do something with the response, e.g., show a success message
      } catch (error) {
        console.error(error.response.data); // Log any error response from the API
        // Handle error, e.g., show an error message
      }
    },
  });

  const loadCommunityDetails = async () => {
    const result = await getCommunityDetailById({ communityId: params?.id });
    if (result) {
      setCommunityDetails(result.data);

      setGettingAroundDesc(result?.data?.gettingAround?.[0]?.gettingAroundDesc || '');
      setPublicTransValue(result?.data?.gettingAround?.[0]?.publicTransType || '');
      setOthrTrans({
        dedicatedWalkingPaths: result?.data?.gettingAround?.[0]?.dedicatedWalkingPaths || false,
        dedicatedBikingPaths: result?.data?.gettingAround?.[0]?.dedicatedBikingPaths || false,
        localAirport: result?.data?.gettingAround?.[0]?.localAirport || false,
        regionalAirport: result?.data?.gettingAround?.[0]?.regionalAirport || false,
      });
    }
  };
  useEffect(() => {
    loadCommunityDetails();
  }, []);

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (isEdited) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${params?.id}#Section9`);
    }
  };

  useEffect(() => {
    // console.log('othrTrans', othrTrans);
  }, [othrTrans]);

  useEffect(() => {
    loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2
      ? setIsAccessible(true)
      : setIsAccessible(false);
  }, [loggedInUserData]);

  useEffect(() => {
    console.log(communityDetails?.gettingAround, 5000);
  }, [communityDetails]);

  return (
    <Container>
      <Box className="PageSpacing FixedMD">
        {isAccessible ? (
          <>
            {showDeleteBox && (
              <>
                <ModalEditViewDelete
                  status={true}
                  navigateUrl={`../../community-details/${params?.id}#Section9`}
                  onClose={onTakeMeback}
                ></ModalEditViewDelete>
              </>
            )}
            <Grid container>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" /> Getting Around
                    Insights
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container className="FixedID">
              <Grid item xs={12} className="CommonOption">
                <Box className="HeadingBoxCEV FlexCenterStart">
                  <Typography variant="body2" className="HeadingCEV">
                    Public Transportation
                  </Typography>
                  <Typography variant="caption" className="HeadingCEVCaption">
                    Select one
                  </Typography>
                </Box>
                <Typography variant="body2" className="SubtitleCEV">
                  Inputting this content helps users find your community when filtering.
                </Typography>
              </Grid>
              <Grid item xs={12} className="CommonOption">
                <Box className="RadioOption">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="Public Transportation"
                      name="Public Transportation"
                      value={publicTransValue}
                      onChange={handleChange1}
                      // {...formik.getFieldProps('publicTransType')}
                    >
                      <FormControlLabel
                        value="multiplePublicTransportationOptions"
                        control={<Radio color="primary" />}
                        label="Multiple public transportation options"
                      />
                      <FormControlLabel
                        value="limitedPublicTransportation"
                        control={<Radio color="primary" />}
                        label="Limited public transportation"
                      />
                      <FormControlLabel
                        value="noPublicTransportation"
                        control={<Radio color="primary" />}
                        label="No public transportation"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} className="CommonOption">
                <Box className="HeadingBoxCEV FlexCenterStart" mt={3}>
                  <Typography variant="body2" className="HeadingCEV">
                    Other Transportation
                  </Typography>
                  <Typography variant="caption" className="HeadingCEVCaption">
                    Select all that apply
                  </Typography>
                </Box>
                <Typography variant="body2" className="SubtitleCEV">
                  Inputting this content helps users find your community when filtering.
                </Typography>
              </Grid>
              <Grid item xs={12} className="CommonOption">
                <Box className="CheckboxOption">
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={dedicatedWalkingPaths}
                            onChange={handleChange2}
                            // onClick={handleChange2}
                            name="dedicatedWalkingPaths"
                            // {...formik.getFieldProps('dedicatedWalkingPaths')}
                          />
                        }
                        label="Dedicated walking paths"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={dedicatedBikingPaths}
                            onChange={handleChange2}
                            // onClick={handleChange2}
                            name="dedicatedBikingPaths"
                            // {...formik.getFieldProps('dedicatedBikingPaths')}
                          />
                        }
                        label="Dedicated biking paths"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={localAirport}
                            onChange={handleChange2}
                            // onClick={handleChange2}
                            name="localAirport"
                            // {...formik.getFieldProps('localAirport')}
                          />
                        }
                        label="Local airport"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={regionalAirport}
                            onChange={handleChange2}
                            // onClick={handleChange2}
                            name="regionalAirport"
                            // {...formik.getFieldProps('regionalAirport')}
                          />
                        }
                        label="Regional airport"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <InputLabel className="InputLabelFld">
                    How do people get around town? Be sure to describe the public transit options,
                    bike paths, or other ways people could commute.
                  </InputLabel>
                </Box>
                <TextField
                  className="TextFieldTextarea"
                  fullWidth
                  multiline
                  minRows={8}
                  maxLength={700}
                  label=""
                  variant="outlined"
                  value={gettingAroundDesc}
                  onChange={handleChange3}
                  inputProps={{
                    maxLength: maxCharacterCount,
                  }}
                  // {...formik.getFieldProps('gettingAroundDesc')}
                  helperText={`${maxCharacterCount - gettingAroundDesc.length}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="Wrapper ButtonWrapper">
                  <Button
                    disabled={!formik.dirty && !isEdited}
                    // disabled={
                    //   !formik.dirty ||
                    //   (!gettingAroundDesc.length === 0 &&
                    //     !publicTransValue === 0 &&
                    //     !othrTrans === 0 &&
                    //     !isEdited)
                    // }
                    onClick={formik.handleSubmit}
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="EditViewButton"
                    fullWidth
                  >
                    Publish
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          'You do not have permissions to access this url.'
        )}
      </Box>
    </Container>
  );
};
CommunityEditViewGettingAround.defaultProps = {};
export default CommunityEditViewGettingAround;
