/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Avatar,
  Divider,
  Link,
  Popover,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import useCommunityList from '../../../hooks/useCommunityList';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
// import Carousel from "react-simply-carousel";
import Carousel from 'react-material-ui-carousel';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
const Item = (props) => {
  // const imgPath = require(props.item.Image).default;
  return (
    <Paper>
      <Box className="CardBannerBox">
        <img
          src={
            window.location.hostname === 'localhost'
              ? `//localhost:9001/${props.item.Image}`
              : `api/${props.item.Image}`
          }
        />
      </Box>
    </Paper>
  );
};

const AmenitiesAll = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  const key = 'iFrasCwdJh';

  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);

  const params = useParams();

  const [filters, setFilters] = useState([]);
  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const [amenityFilterCategories, setAmenityFilterCategories] = useState([]);
  const [activeAmenityTypes, setActiveAmenityTypes] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredAmenities, setFilteredAmenities] = useState(communityDetails?.amenities);

  //console.log(amenityFilterCategories, 'AMCe');

  const [items, setItems] = useState([]);
  const [isAccessible] = useState(props?.isAccessible);

  const { getAmenityByType, getCommunityDetailById, getAmenityCategories } = useCommunityList();
  const [filteredAmenitiesCount, setFilteredAmenitiesCount] = useState(0);

  const [myAmenities, setAmenities] = useState([]);

  useEffect(() => {
    let itemsOutArr = [];
    let amenityCatArr = [];
    communityDetails?.amenities?.map((amenity) => {
      let itemsInnerArr = [];
      amenity?.amenityGallery?.map((galleryImage) => {
        itemsInnerArr.push({ Image: galleryImage.image });
      });
      itemsOutArr.push(itemsInnerArr);
      if (!amenityCatArr.some((obj) => obj.id === amenity?.amenityCategory.id)) {
        amenityCatArr.push(amenity?.amenityCategory);
      }
    });
    setItems(itemsOutArr);
  }, [communityDetails]);

  useEffect(() => {
    let activeAmenityTypesArr = [];

    communityDetails?.amenities?.map((amenity) => {
      activeAmenityTypesArr.push(amenity?.amenityTypeId);
    });
    setActiveAmenityTypes(activeAmenityTypesArr);
  }, [communityDetails]);

  const fillCommunityDetails = async () => {
    let communityDetailsResults = [];
    if (params?.id) {
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults.data);
    }
    return communityDetailsResults?.data;
  };

  async function fetchAmenities() {
    try {
      // Make your API call here to fetch the initial data
      const response = await getAmenityByType({ communityId: params?.id }); // Replace with your actual API endpoint
      if (response.status === true) {
        // Update the 'amenities' state with the fetched data
        setAmenities(response.amenitiesResult);
      } else {
        console.error('Failed to fetch amenities data');
      }
    } catch (error) {
      console.error('Error fetching amenities data:', error);
    }
  }

  const fillCommunityCategories = async () => {
    const amenityCategoriesArr = await getAmenityCategories();
    setAmenityFilterCategories(amenityCategoriesArr.usersResult);
  };

  useEffect(() => {
    fillCommunityDetails();
    fillCommunityCategories();
    fetchAmenities();
  }, []);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterSelection = (filter) => {
    // Check if the filter is already selected
    const isFilterSelected = selectedFilters.includes(filter);

    // If the filter is selected, remove it; otherwise, add it
    const updatedFilters = isFilterSelected
      ? selectedFilters.filter((selected) => selected !== filter)
      : [...selectedFilters, filter];

    // Update the selected filters
    setSelectedFilters(updatedFilters);

    document.activeElement.blur();
  };

  //console.log(selectedFilters, 'FF4');

  const resetFilters = () => {
    setSelectedFilters([]);
    setFilteredAmenities(communityDetails?.amenities);

    // Update the count to the total number of amenities
    if (communityDetails?.amenities) {
      setFilteredAmenitiesCount(communityDetails?.amenities.length);
    }
  };

  //console.log(filteredAmenities, 'FMA');

  const applyFiltersAndFetch = () => {
    // Filter amenities based on selected filters
    const filteredAmenities = myAmenities.filter((amenity) =>
      selectedFilters.includes(amenity.amenityType.amenityTypeName),
    );

    if (selectedFilters.length === 0) {
      // If no filters are selected, show all amenities
      setFilteredAmenities(myAmenities);
      setFilteredAmenitiesCount(myAmenities.length);
    } else {
      // Update the filtered amenities and count
      setFilteredAmenities(filteredAmenities);
      setFilteredAmenitiesCount(filteredAmenities.length);
    }

    // Close the filter popover
    setAnchorEl(null);
  };

  useEffect(() => {
    setFilteredAmenities(filteredAmenities);
  }, [filteredAmenities, selectedFilters]);

  useEffect(() => {
    fillCommunityDetails();
    fillCommunityCategories();
    fetchAmenities();
  }, []);

  useEffect(() => {
    if (communityDetails?.amenities) {
      setFilteredAmenitiesCount(communityDetails?.amenities.length);
    }
  }, [communityDetails]);

  return (
    <Paper className="WhitePaper" component="div">
      <Grid item xs={12}>
        <Link
          className="BackButtonWrapper"
          underline="none"
          href={`/#/app/community-details/${communityDetails?.id}#Section1`}
        >
          <Typography className="BackButton" variant="body1">
            <ArrowBackIcon color="primary" className="BackButtonArrow" />
            View Amenities
          </Typography>
        </Link>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography color="primary" variant="h2" className="BoxHeading">
            Amenities
          </Typography>
        </Grid>
        {props?.isAccessible && !props?.publicView && (
          <>
            <Grid item xs={12} className="AddAmenityRow">
              <Box className="AddAmenityContentBox">
                <Typography variant="body2" className="AddLabel">
                  Inputting amenities helps users find your community when filtering.
                </Typography>
                <Button
                  className="AddButtonBox"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  href={
                    '/#/app/community-details-edit-add-new-amenity/' +
                    communityDetails?.id +
                    '/default'
                  }
                  startIcon={<AddCircleOutlineSharpIcon />}
                >
                  Add An Amenity
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} className="AddAmenityDividerRow">
              <Divider className="DividerCD"></Divider>
            </Grid>
          </>
        )}
        <Grid item xs={12} className="CommonDetailBoxGrid">
          <Box className="FilterTagSecond FilterTagContent">
            {amenityFilterCategories?.map((category) => {
              return <Button className="FilterTag">{category?.amenityCategoryName}</Button>;
            })}
          </Box>
        </Grid>
        <Grid item xs={12} className="">
          <Box
            // className="SearchBoxContainer MobileFilterBox"
            className="SearchBoxContainer"
            mb={0}
          >
            <Typography
              variant="body1"
              component="span"
              className="AmenityFiltersResults LightgreyColor"
            >
              {`${filteredAmenitiesCount} Result${filteredAmenitiesCount === 1 ? '' : 's'}`}
            </Typography>

            <Button
              className="SearchButton"
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleFilterClick}
              startIcon={<FilterListIcon color="primary" />}
              variant="outlined"
            >
              {selectedFilters.length > 0
                ? selectedFilters.length > 1
                  ? `Filters(${selectedFilters.length})`
                  : `Filter(${selectedFilters.length})`
                : 'Filter'}
            </Button>
            <Popover
              id="long-menu"
              open={open}
              anchorEl={anchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              className="MobileFilter"
            >
              <Paper className="CommonFilterBox AmenityFilters">
                <Box className="FilterHeader">
                  <Typography variant="h4">Amenity Filters</Typography>
                  <CloseIcon color="primary" className="CloseButton" onClick={handleFilterClose} />
                </Box>
                <Box className="FilterBody">
                  {amenityFilterCategories?.map((category) => (
                    <Box className="FiltersSection" key={category?.amenityCategoryId}>
                      <Typography variant="body2" component="div" className="FiltersSectionHeading">
                        {category?.amenityCategoryName}
                      </Typography>
                      <Box className="FiltersOptionBox">
                        <Box className="FilterTagContent">
                          {category?.amenityType?.map((type) => (
                            <Button
                              disabled={!activeAmenityTypes.includes(type.id)}
                              key={type.id}
                              className={`FilterTag ${
                                selectedFilters.includes(type.amenityTypeName) ? 'selected' : ''
                              }`}
                              onClick={(event) => handleFilterSelection(type.amenityTypeName)}
                            >
                              {type.amenityTypeName}
                            </Button>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>

                <Box className="FilterFooter">
                  <Divider />
                  <Box className="FilterAction">
                    <Button variant="outlined" size="small" onClick={resetFilters}>
                      Reset All
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={applyFiltersAndFetch}
                    >
                      {selectedFilters.length > 0
                        ? `See (${selectedFilters.length}) Results`
                        : 'See Results'}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Popover>
          </Box>
        </Grid>
      </Grid>
      <Box className="CommonDetailBoxGridSlider">
        <Grid container spacing={2}>
          {filteredAmenities &&
            filteredAmenities
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((amenity, index) => {
                return (
                  <Grid item xs={12} sm={4} key={index}>
                    <Box className="AmenitiesSliderBox">
                      <Box className="SliderContainer">
                        {isAccessible &&
                          !props?.publicView &&
                          communityDetails?.amenityDescription?.[0] && (
                            <Button
                              className="EditCommonButtonFloatSecond"
                              href={`/#/app/community-details-edit-add-new-amenity/${communityDetails?.id}/${amenity?.id}`}
                              variant="outlined"
                              color="primary"
                              size="small"
                              startIcon={<EditPenIconGreen />}
                            >
                              Edit
                            </Button>
                          )}
                        <Carousel
                          navButtonsAlwaysVisible={true}
                          autoPlay={false}
                          className="SliderCarousel"
                          navButtonsProps={{
                            style: {
                              backgroundColor: '#000',
                              padding: '0',
                            },
                          }}
                          indicatorIconButtonProps={{
                            style: {
                              padding: '0px',
                              color: 'rgba(255, 255, 255, 0.6)',
                            },
                          }}
                          activeIndicatorIconButtonProps={{
                            style: {
                              padding: '0px',
                              color: 'rgba(255, 255, 255, 1)',
                            },
                          }}
                          indicatorContainerProps={{
                            style: {
                              marginTop: '0px',
                              position: 'absolute',
                              bottom: '5px',
                            },
                          }}
                        >
                          {amenity.amenityGallery.map((image, i) => (
                            <Paper key={i}>
                              <Box className="CardBannerBox">
                                <img
                                  src={
                                    window.location.hostname === 'localhost'
                                      ? `//localhost:9001/${image.image}`
                                      : `api/${image.image}`
                                  }
                                />
                              </Box>
                            </Paper>
                          ))}
                        </Carousel>
                      </Box>
                      <Box className="SliderInfoContent">
                        <Typography variant="h6" className="AmenitiesCategory">
                          {amenity?.amenityCategory?.amenityCategoryName}
                        </Typography>
                        <Typography variant="h3" className="AmenitiesName">
                          {amenity?.amenityName}
                        </Typography>
                        <Box className="AmenitiesText">
                          <Typography variant="body2" className="AmenitiesInsight">
                            {amenity?.amenityDesc}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
        </Grid>
      </Box>

      {/* <Grid container>
        <If
          condition={
            communityDetails?.amenities[0]?.amenityDesc &&
            (!props?.isCommunityRepresentative || props?.publicView)
          }
        >
          <Grid item xs={12}>
            <Box className="ChatBox CommonDetailBoxGrid">
              {communityDetails?.amenities[0] && communityDetails?.amenities[0]?.amenityDesc && (
                <Box className="ChatContent">
                  <Typography variant="body2" className="ChatText" color="primary">
                    {communityDetails?.amenities[0]?.amenityDesc}
                  </Typography>
                </Box>
              )}

              <Box className="UserInfoName UserInfoIP">
                <Avatar
                  className="UserAvatar small"
                  alt={`${communityDetails?.userrole[0]?.firstName}}`}
                  src={
                    window.location.hostname === 'localhost'
                      ? `//localhost:9001/${communityDetails?.userrole[0]?.profilePic}`
                      : `/api/${communityDetails?.userrole[0]?.profilePic}`
                  }
                >
                  <PersonIcon />
                </Avatar>
                <Box className="UserInfoText">
                  <Typography variant="h6">
                    <Box component="span" className="FirstName">
                      {communityDetails?.userrole[0]?.firstName}
                    </Box>
                    <Box component="span" className="LastName">
                      {communityDetails?.userrole[0]?.lastName}
                    </Box>
                  </Typography>
                  <Typography variant="caption">
                    {communityDetails?.userrole[0]?.userDesignationText}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </If>
      </Grid> */}
    </Paper>
  );
};

export default AmenitiesAll;
