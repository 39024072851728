/* eslint-disable  */
import Cookies from 'js-cookie';
import { roles } from '../constants/globalConstants';

export const getHostType = (roleId) => {
  switch (roleId) {
    case 'admin':
      return roles.admin;
    case 'communityrep':
      return roles.communityrep;
    default:
      return roles.homeshopper;
  }
};

export const getCompanyId = () => {
  const user = Cookies.get('user');
  let companyId;
  if (user) {
    const parsedUser = JSON.parse(atob(user));
    companyId = parsedUser.companyId;
  }
  return companyId;
};

export const showErrIfTouched = (key, errors, touched) => {
  return touched[key] && errors[key];
};
export const showErrIfTouchedAutocomplete = (key, errors, touched) => {
  return touched && errors[key];
};

export const isFile = (input) => 'File' in window && input instanceof File;

export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const checkForSpaceAndEntry = (string) => {
  return /^ *$/.test(string);
};

export const postalCodeFormat = (input, props = '', setFieldValue = '', fieldName = '') => {
  input = input.replace('-', '');
  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0, 6);
  // Based upon the length of the string, we add formatting as necessary
  var size = input.length;

  if (size < 4) {
    input = input;
  } else {
    input = input.substring(0, 3) + '-' + input.substring(3, 6);
  }
  //input = input.substring(0, 3) + '-' + input.substring(3, 6) ;
  // formik.current.setFieldValue(field, input);
  // setValues({ ...values, [field]: input });
  if (fieldName) {
    setFieldValue(fieldName, input);
  }
  if (props) {
    props.onChange(input);
  }
};
// export function filterDate(d) {
//   return d === null || d === '' || (d instanceof Date && !isNaN(d)) ? d : null;
// }
export function filterDate(d) {
  if (d === null || d === '') return null;
  return d instanceof Date && !isNaN(d) ? d : 'invalid';
}
