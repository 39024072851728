/* eslint-disable */
import React, { lazy /* , useEffect */ } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import MainLogo from '../components/MainLogo/MainLogo';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const Auth = lazy(() => import('../screens/Auth'));
const Login = lazy(() => import('../screens/Auth/Login'));

const SignUp = lazy(() => import('../screens/Auth/SignUp'));
const SignUpSuccess = lazy(() => import('../screens/Auth/SignUpSuccess/SignUpSuccess'));
const LoginSuccess = lazy(() => import('../screens/Auth/LoginSuccess/LoginSuccess'));
const Verify = lazy(() => import('../screens/Auth/Verify'));
const VerifyPasswordToken = lazy(() =>
  import('../screens/Auth/VerifyPasswordToken/VerifyPasswordToken'),
);
const Splash = lazy(() => import('../screens/Auth/Splash'));
const ForgotPassword = lazy(() => import('../screens/Auth/ForgotPassword'));
const DeleteAccount = lazy(() => import('../screens/Auth/DeleteAccount'));
const DeleteAccountSuccess = lazy(() => import('../screens/Auth/DeleteAccountSuccess'));
const ResetPassword = lazy(() => import('../screens/Auth/ResetPassword'));
const CheckYourEmail = lazy(() => import('../screens/Auth/CheckYourEmail'));
const SetPassword = lazy(() => import('../screens/Auth/SetPassword'));
const TwoFactorAuthentication = lazy(() => import('../screens/Auth/TwoFactorAuthentication'));
const CommunityList = lazy(() => import('../screens/Main/CommunityList'));
const CommunityDetails = lazy(() => import('../screens/Main/CommunityDetails'));
const HouseList = lazy(() => import('../screens/Main/HouseList'));
const Account = lazy(() => import('../screens/Main/Account'));
const EditAccount = lazy(() => import('../screens/Main/EditAccount'));
const EditPassword = lazy(() => import('../screens/Main/EditPassword/EditPassword'));
const EditEmail = lazy(() => import('../screens/Main/EditEmail/EditEmail'));
const ChangeEmailSent = lazy(() => import('../screens/Auth/ChangeEmailSent/ChangeEmailSent'));
const VerifyChangeEmail = lazy(() => import('../screens/Auth/VerifyChangeEmail/VerifyChangeEmail'));
const PublicRoute = ({ as: Component, ...props }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(false);
  const userCookie = Cookies.get('user');
  let userType = '';
  let key = 'iFrasCwdJh';
  React.useEffect(() => {
    if (cookies.token && userCookie) {
      //const userData = JSON.parse(atob(userCookie));
      console.log({ userCookie }, 'userCookie');
      let bytes = CryptoJS.AES.decrypt(userCookie, key);
      const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // console.log('userData11');
      // console.log(typeof userData);

      userType = (userData.userType && userData.userType.type) || null;
      if (userData && window?.location?.hash?.indexOf('/verify/') != -1) {
        navigate('/app/not-found');
      }

      // window.location.reload(false);
      //navigate('../../app/home-page');

      // if (userType != 'uberadmin') {
      // navigate('../app/account');
      //       setAuth(true);
      // }
      //else {
      // navigate('../account');
      // Cookies.set('path', 'dashboard');
      // Cookies.set('path', 'account');
      //Cookies.set('path', 'communitylist');
      // setAuth(true);
      //navigate('../account');
      //}
      //setAuth(false);
    } else {
      // navigate('../communitylist');
      setAuth(true);
      Cookies.remove('path');
    }
  }, [cookies.token, navigate]);

  if (window.location.hash === '') {
    window.location.hash = '#/app/home-page';
  }

  // return <Component {...props} />;
  return auth ? <Component {...props} /> : <Loader />;
};

const authNav = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'login-success',
    element: <LoginSuccess />,
  },
  {
    path: 'signup',
    element: <SignUp />,
  },
  {
    path: 'communitylist',
    element: <CommunityList />,
  },
  {
    path: 'community-details/:id',
    element: <CommunityDetails />,
  },
  {
    path: 'houselist',
    element: <HouseList />,
  },
  {
    path: 'account',
    element: <Account />,
  },
  {
    path: 'signup-success',
    element: <SignUpSuccess />,
  },
  {
    path: 'verify/:token',
    element: <Verify />,
  },
  {
    path: 'verify-password-token/:token',
    element: <VerifyPasswordToken />,
  },
  {
    path: 'change-email-sent',
    element: <ChangeEmailSent />,
  },
  {
    path: 'verify-change-email-token/:token',
    element: <VerifyChangeEmail />,
  },
  {
    path: 'editaccount',
    element: <EditAccount />,
  },
  {
    path: 'edit-password',
    element: <EditPassword />,
  },
  {
    path: 'edit-email',
    element: <EditEmail />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'delete-account/:id',
    element: <DeleteAccount />,
  },
  {
    path: 'delete-account-success',
    element: <DeleteAccountSuccess />,
  },
  {
    path: 'reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: 'check-your-email',
    element: <CheckYourEmail />,
  },
  {
    path: 'set-password',
    element: <SetPassword />,
  },
  {
    path: 'two-factor-authentication',
    element: <TwoFactorAuthentication />,
  },
];

const authRoutes = [
  {
    path: '/',
    element: <PublicRoute as={Auth} />,
    children: authNav,
  },
];

export default authRoutes;
