/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Avatar,
  Divider,
  List,
  ListItem,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useLogin from '../../../hooks/useAuth';

const formatClimateType = (climateType) => {
  // Define the mapping of climate types to their formatted versions
  const climateTypeMappings = {
    tropicalSubtropical: 'Tropical / Subtropical',
    dryArid: 'Dry / Arid',
    temperateCoolWintersMildSummers: 'Temperature / Cool Winter & Mild Summers',
    ContinentalColdWintersHotSummers: 'Continental / Cold Winters & Hot Summers',
    // Add more mappings for other climate types if needed
  };

  // Check if the provided climateType exists in the mappings
  if (climateTypeMappings.hasOwnProperty(climateType)) {
    return climateTypeMappings[climateType];
  }

  // If no mapping is found, return the original climateType
  return climateType;
};
const Climate = (props) => {
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const params = useParams();
  const navigate = useNavigate();
  const climateType = communityDetails?.climate?.[0]?.climateType;
  const [isAccessible, setIsAccessible] = useState(false);
  const [repDetails, setRepDetails] = useState();
  const { getUserDataToAdmin } = useLogin();

  const getRepDetails = async () => {
    try {
      if (communityDetails?.climate?.[0]?.isAdminEntry) {
        const userDataResult = await getUserDataToAdmin({
          userIdd: communityDetails?.climate?.[0]?.repId,
        });
        setRepDetails(userDataResult.usersResult);
      }
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  useEffect(() => {
    setIsAccessible(
      (props?.loggedInUserData &&
        communityDetails?.userrole?.[0]?.id === props?.loggedInUserData?.id) ||
        props?.loggedInUserData?.roleId === 2,
    );
    getRepDetails();
  }, [communityDetails]);

  return (
    <Paper id="Section8" className="WhitePaper" component="div">
      <Grid container>
        <Grid item xs={12} className="BoxHeadingContainer">
          <Typography color="primary" variant="h2" className="BoxHeading">
            Climate
          </Typography>
          <If
            condition={
              props?.isAccessible &&
              !props?.publicView &&
              communityDetails?.climate?.[0] &&
              (communityDetails?.climate?.[0]?.climateDesc != '' ||
                communityDetails?.climate?.[0]?.climateType != '')
            }
          >
            <Button
              type="submit"
              className="EditCommonButton LinkTypeButton"
              startIcon={<EditPenIconGreen />}
              size="small"
              color="primary"
              href={'/#/app/community-details-edit-climate/' + communityDetails?.id}
            >
              Edit
            </Button>
          </If>
        </Grid>
      </Grid>
      <If
        condition={
          communityDetails?.climate?.[0] && communityDetails?.climate?.[0]?.climateType != ''
        }
      >
        {/* <Grid item xs={12} className="">
          <Divider className="DividerCD" />
        </Grid> */}

        <Grid item xs={12} className="CommonTypeSection ClimateTypeSection">
          <Typography variant="body2" className="InsightLabel">
            Climate Type
          </Typography>

          <List>
            <ListItem>
              <Typography component="div" variant="body2">
                {formatClimateType(climateType)}
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </If>
      <If
        condition={
          // communityDetails?.costOfLiving&&communityDetails?.costOfLiving[0]?.costOfLivingType ||
          communityDetails?.climate?.[0]?.climateDesc != ''
        }
      >
        <Grid item xs={12}>
          <Box className="ChatBox CommonDetailBoxGrid">
            {communityDetails?.climate?.[0] && communityDetails?.climate?.[0]?.climateDesc != '' && (
              <Box className="ChatContent">
                <Typography variant="body2" className="ChatText" color="primary">
                  {communityDetails?.climate.length > 0 &&
                    communityDetails?.climate?.[0]?.climateDesc}
                </Typography>
              </Box>
            )}

            {
              // props?.isAccessible &&
              communityDetails?.climate?.[0] && communityDetails?.climate?.[0]?.climateDesc != '' && (
                <Box className="UserInfoName UserInfoIP">
                  <Avatar
                    className="UserAvatar small"
                    alt={`${
                      communityDetails?.climate?.[0]?.isAdminEntry
                        ? repDetails?.firstName
                        : communityDetails?.userrole[0]?.firstName
                    } `}
                    src={
                      window.location.hostname === 'localhost'
                        ? `//localhost:9001/${
                            communityDetails?.climate?.[0]?.isAdminEntry
                              ? repDetails?.profilePic
                              : communityDetails?.userrole[0]?.profilePic
                          }`
                        : `/api/${
                            communityDetails?.climate?.[0]?.isAdminEntry
                              ? repDetails?.profilePic
                              : communityDetails?.userrole[0]?.profilePic
                          }`
                    }
                  >
                    <PersonIcon />
                  </Avatar>
                  <Box className="UserInfoText">
                    <Typography variant="h6">
                      <Box component="span" className="FirstName">
                        {communityDetails?.climate?.[0]?.isAdminEntry
                          ? repDetails?.firstName
                          : communityDetails?.userrole[0]?.firstName}
                      </Box>
                      <Box component="span" className="LastName">
                        {communityDetails?.climate?.[0]?.isAdminEntry
                          ? repDetails?.lastName
                          : communityDetails?.userrole[0]?.lastName}
                      </Box>
                    </Typography>
                    <Typography variant="caption">
                      {communityDetails?.climate?.[0]?.isAdminEntry
                        ? repDetails?.userDesignationText
                        : communityDetails?.userrole[0]?.userDesignationText}
                    </Typography>
                  </Box>
                </Box>
              )
            }
          </Box>
        </Grid>
      </If>
      {/* <Grid item xs={12}>
        <Divider className="DividerCD"></Divider>
      </Grid> */}
      <If condition={props?.isAccessible && !props?.publicView}>
        <If
          condition={
            communityDetails?.climate.length == 0 ||
            (communityDetails?.climate?.[0] &&
              communityDetails?.climate?.[0]?.climateDesc == '' &&
              communityDetails?.climate?.[0]?.climateType == '')
          }
        >
          <Grid item xs={12}>
            <Box>
              <Typography variant="body2" className="InsightLabel EmptyInsightLabel">
                What can people look forward to across the four seasons?
              </Typography>
            </Box>
          </Grid>
        </If>
      </If>
      <If condition={props?.isAccessible && !props?.publicView}>
        <Grid item xs={12}>
          <If
            condition={
              communityDetails?.climate.length == 0 ||
              (communityDetails?.climate?.[0] &&
                communityDetails?.climate?.[0]?.climateDesc == '' &&
                communityDetails?.climate?.[0]?.climateType == '')
            }
          >
            <Box className="Wrapper ButtonWrapper InsightButtonContainer">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                href={'/#/app/community-details-edit-climate/' + communityDetails?.id}
              >
                <Box className="AddButton">Add Insight</Box>
              </Button>
            </Box>
          </If>
        </Grid>
      </If>
    </Paper>
  );
};

export default Climate;
