/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCommunityList from '../../../hooks/useCommunityList';
import useLogin from '../../../../src/hooks/useAuth';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { FastField, Formik, Form, Field } from 'formik';
import {
  Grid,
  List,
  ListItem,
  Button,
  Typography,
  InputLabel,
  Box,
  Tab,
  Container,
  InputBase,
  InputAdornment,
  TextField,
  Popover,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';

const AdminEditRejection = () => {
  const { approveEditVideoRequest } = useCommunityList();
  const { requestId } = useParams();
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [reason, setRejectReason] = useState('');
  const [userDataFetched, setUserDataFetched] = useState(false); // Track if user data has been fetched

  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const { getRoleData } = useLogin();
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (userCookie && !userDataFetched) {
      // Fetch user data only if not fetched
      const fetchUserData = async () => {
        const userDataResult = await getRoleData({
          userId: loggedInUserData.id,
        });
        setUserData(userDataResult.usersResult);
        setUserDataFetched(true); // Mark user data as fetched
      };
      fetchUserData();
    }
  }, [userCookie, getRoleData, loggedInUserData, userDataFetched]);

  const handleReject = () => {
    if (reason && userData?.emailId) {
      approveEditVideoRequest({
        requestId: requestId,
        action: 'reject',
        reason: reason,
        communityRepEmail: userData.emailId,
        userName: userData.firstName,
      })
        .then((response) => {
          if (response.status === true) {
            setApprovalStatus('Rejected');
          } else {
            setApprovalStatus('Rejection Failed');
          }
        })
        .catch((error) => {
          console.error('Rejection failed:', error);
          setApprovalStatus('Rejection Failed');
        });
    }
  };
  console.log(reason, 'RRR');
  return (
    <Formik>
      <Box className="FullWidthPattern">
        <Container>
          <Box className="PageSpacing FixedMD">
            <Box className="InvitationWrapper">
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} className="commonFieldBox">
                  <InputLabel className="InputLabelFld" htmlFor="email">
                    Enter reject reason
                    <Box mb={1} component="span" display="inline-block" className="Asterisk">
                      *
                    </Box>
                  </InputLabel>
                  <TextField
                    name="reason"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    value={reason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    // error={!!showErrIfTouched('emailId', errors, touched)}
                    // helperText={showErrIfTouched('emailId', errors, touched)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className="Wrapper ButtonWrapper RejectViewButton">
                    <Button
                      disabled={!userData}
                      type="submit"
                      className="Button loginButton"
                      variant="contained"
                      color="primary"
                      onClick={handleReject}
                    >
                      Reject
                    </Button>
                  </Box>
                </Grid>
                {/* <input
        name="reason"
        type="text"
        placeholder="Enter reject reason"
        value={reason}
        onChange={(e) => setRejectReason(e.target.value)}
      /> */}
                {/* <button onClick={handleReject}>Reject</button> */}
                {approvalStatus === 'Rejected' ? <div>Rejected</div> : <div></div>}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Formik>
  );
};

export default AdminEditRejection;
