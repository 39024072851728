/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Avatar,
  Divider,
  List,
  ListItem,
} from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useLogin from '../../../hooks/useAuth';

const formatGettingRadioType = (publicTransType) => {
  // Define the mapping of gettingAround types to their formatted versions
  const climateTypeMappings = {
    noPublicTransportation: 'No public transportation',
    limitedPublicTransportation: 'Limited public transportation',
    multiplePublicTransportationOptions: 'Multiple public transportation options',

    // Add more mappings for other climate types if needed
  };

  // Check if the provided climateType exists in the mappings
  if (climateTypeMappings.hasOwnProperty(publicTransType)) {
    return climateTypeMappings[publicTransType];
  }

  // If no mapping is found, return the original climateType
  return publicTransType;
};

const formatGettingCheckType = (schoolType) => {
  const schoolTypeMappings = {
    dedicatedWalkingPaths: 'Dedicated Walking Paths',
    dedicatedBikingPaths: 'Dedicated Biking Paths',
    localAirport: 'Local Airport',
    regionalAirport: 'Regional Airport',
    // Add more school type mappings as needed
  };

  if (schoolTypeMappings.hasOwnProperty(schoolType)) {
    return schoolTypeMappings[schoolType];
  }

  return schoolType;
};

const GettingAround = (props) => {
  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const params = useParams();
  const navigate = useNavigate();
  const [isAccessible, setIsAccessible] = useState(false);
  const [repDetails, setRepDetails] = useState();
  const { getUserDataToAdmin } = useLogin();

  const publicTransType = communityDetails?.gettingAround?.[0]?.publicTransType;
  const gettingAround = communityDetails?.gettingAround?.[0];

  const getRepDetails = async () => {
    try {
      if (communityDetails?.gettingAround?.[0]?.isAdminEntry) {
        const userDataResult = await getUserDataToAdmin({
          userIdd: communityDetails?.gettingAround?.[0]?.repId,
        });
        setRepDetails(userDataResult.usersResult);
      }
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  useEffect(() => {
    setIsAccessible(
      communityDetails?.userrole?.[0]?.id === props?.loggedInUserData?.id ||
        props?.loggedInUserData?.roleId === 2,
    );
    getRepDetails();
  }, [communityDetails]);

  return (
    <Paper className="WhitePaper" component="div">
      <Grid container>
        <Grid item xs={12} className="BoxHeadingContainer">
          <Typography color="primary" variant="h2" className="BoxHeading">
            Getting Around
          </Typography>
          <If
            condition={
              props?.isAccessible &&
              !props?.publicView &&
              ((communityDetails?.gettingAround?.length > 0 &&
                communityDetails?.gettingAround?.[0]?.gettingAroundDesc) ||
                communityDetails?.gettingAround?.[0]?.publicTransType ||
                communityDetails?.gettingAround?.[0]?.dedicatedWalkingPaths ||
                communityDetails?.gettingAround?.[0]?.dedicatedBikingPaths ||
                communityDetails?.gettingAround?.[0]?.localAirport ||
                communityDetails?.gettingAround?.[0]?.regionalAirport)
            }
          >
            <Button
              type="submit"
              className="EditCommonButton LinkTypeButton"
              startIcon={<EditPenIconGreen />}
              size="small"
              color="primary"
              href={'/#/app/community-details-edit-getting-around/' + communityDetails?.id}
            >
              Edit
            </Button>
          </If>
        </Grid>
      </Grid>
      <If
        condition={
          // communityDetails?.costOfLiving&&communityDetails?.costOfLiving[0]?.costOfLivingType ||
          communityDetails?.gettingAround?.[0]?.publicTransType
        }
      >
        {/* <Grid item xs={12} className="">
          <Divider className="DividerCD" />
        </Grid> */}

        <Grid item xs={12} className="CommonTypeSection GettingAroundTypeSection">
          <Typography variant="body2" className="InsightLabel FirstInsightLabel">
            Public Transportation
          </Typography>

          <List>
            <ListItem>
              <Typography component="div" variant="body2">
                {formatGettingRadioType(publicTransType)}
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </If>

      {/* Render "Other Transportation" section */}
      <Grid item xs={12} className="SchoolTypes GettingAroundSchoolTypes GettingAroundTypeSection">
        {(communityDetails?.gettingAround?.[0]?.dedicatedWalkingPaths ||
          communityDetails?.gettingAround?.[0]?.dedicatedBikingPaths ||
          communityDetails?.gettingAround?.[0]?.localAirport ||
          communityDetails?.gettingAround?.[0]?.regionalAirport) && (
          <Typography variant="body2" className="InsightLabel">
            Other Transportation
          </Typography>
        )}
        <List>
          {Object.entries({
            dedicatedWalkingPaths: gettingAround?.dedicatedWalkingPaths,
            dedicatedBikingPaths: gettingAround?.dedicatedBikingPaths,
            localAirport: gettingAround?.localAirport,
            regionalAirport: gettingAround?.regionalAirport,
            // Add more transportation types here
          }).map(
            ([schoolType, hasValue]) =>
              hasValue && (
                <ListItem key={schoolType}>
                  <Typography component="div" variant="body2">
                    {formatGettingCheckType(schoolType)}
                  </Typography>
                </ListItem>
              ),
          )}
        </List>
      </Grid>

      <If
        condition={
          // communityDetails?.costOfLiving&&communityDetails?.costOfLiving[0]?.costOfLivingType ||
          communityDetails?.gettingAround?.[0]?.gettingAroundDesc
        }
      >
        <Grid item xs={12}>
          <Box className="ChatBox CommonDetailBoxGrid">
            {communityDetails?.gettingAround.length > 0 &&
              communityDetails?.gettingAround?.[0].gettingAroundDesc && (
                <Box className="ChatContent">
                  <Typography variant="body2" className="ChatText" color="primary">
                    {communityDetails?.gettingAround.length > 0 &&
                      communityDetails?.gettingAround?.[0].gettingAroundDesc}
                  </Typography>
                </Box>
              )}

            <Box className="UserInfoName UserInfoIP">
              <Avatar
                className="UserAvatar small"
                alt={`${
                  communityDetails?.gettingAround?.[0]?.isAdminEntry
                    ? repDetails?.firstName
                    : communityDetails?.userrole[0]?.firstName
                } `}
                src={
                  window.location.hostname === 'localhost'
                    ? `//localhost:9001/${
                        communityDetails?.gettingAround?.[0]?.isAdminEntry
                          ? repDetails?.profilePic
                          : communityDetails?.userrole[0]?.profilePic
                      }`
                    : `/api/${
                        communityDetails?.gettingAround?.[0]?.isAdminEntry
                          ? repDetails?.profilePic
                          : communityDetails?.userrole[0]?.profilePic
                      }`
                }
              >
                <PersonIcon />
              </Avatar>
              <Box className="UserInfoText">
                <Typography variant="h6">
                  <Box component="span" className="FirstName">
                    {communityDetails?.gettingAround?.[0]?.isAdminEntry
                      ? repDetails?.firstName
                      : communityDetails?.userrole[0]?.firstName}
                  </Box>
                  <Box component="span" className="LastName">
                    {communityDetails?.gettingAround?.[0]?.isAdminEntry
                      ? repDetails?.lastName
                      : communityDetails?.userrole[0]?.lastName}
                  </Box>
                </Typography>
                <Typography variant="caption">
                  {communityDetails?.gettingAround?.[0]?.isAdminEntry
                    ? repDetails?.userDesignationText
                    : communityDetails?.userrole[0]?.userDesignationText}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </If>
      <If condition={props?.isAccessible && !props?.publicView}>
        {/* <If
          condition={
            communityDetails?.gettingAround[0] &&
            communityDetails?.gettingAround[0].gettingAroundDesc == '' &&
            !communityDetails?.gettingAround[0].dedicatedBikingPaths &&
            !communityDetails?.gettingAround[0].dedicatedWalkingPaths &&
            !communityDetails?.gettingAround[0].localAirport &&
            !communityDetails?.gettingAround[0].regionalAirport &&
            communityDetails?.gettingAround[0].publicTransType == ''
          }
        > */}
        <If
          condition={
            !(
              (communityDetails?.gettingAround?.length > 0 &&
                communityDetails?.gettingAround?.[0]?.gettingAroundDesc) ||
              communityDetails?.gettingAround?.[0]?.publicTransType ||
              communityDetails?.gettingAround?.[0]?.dedicatedWalkingPaths ||
              communityDetails?.gettingAround?.[0]?.dedicatedBikingPaths ||
              communityDetails?.gettingAround?.[0]?.localAirport ||
              communityDetails?.gettingAround?.[0]?.regionalAirport
            )
          }
        >
          {' '}
          {/* <Grid item xs={12}>
            <Divider className="DividerCD"></Divider>
          </Grid> */}
          <Grid item xs={12}>
            <Box>
              <Typography variant="body2" className="InsightLabel EmptyInsightLabel">
                How do people get around town? Be sure to include any public transit options, bike
                paths, or other ways people could commute.{' '}
              </Typography>
            </Box>
          </Grid>
        </If>
      </If>
      <If condition={props?.isAccessible && !props?.publicView}>
        <Grid item xs={12}>
          <If
            condition={
              !(
                (communityDetails?.gettingAround?.length > 0 &&
                  communityDetails?.gettingAround?.[0]?.gettingAroundDesc) ||
                communityDetails?.gettingAround?.[0]?.publicTransType ||
                communityDetails?.gettingAround?.[0]?.dedicatedWalkingPaths ||
                communityDetails?.gettingAround?.[0]?.dedicatedBikingPaths ||
                communityDetails?.gettingAround?.[0]?.localAirport ||
                communityDetails?.gettingAround?.[0]?.regionalAirport
              )
            }
          >
            <Box className="Wrapper ButtonWrapper InsightButtonContainer">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                href={'/#/app/community-details-edit-getting-around/' + communityDetails?.id}
              >
                <Box className="AddButton">Add Insight</Box>
              </Button>
            </Box>
          </If>
        </Grid>
      </If>
    </Paper>
  );
};

export default GettingAround;
