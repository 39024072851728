/* eslint-disable  */
import axios from 'axios';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const instance = axios.create({
  baseURL: location.hostname === 'localhost' ? '' : 'api',
  withCredentials: true,
});

export const abortAllRequest = () => {
  source.cancel(); ///Operation canceled by the user.
};

export default instance;
