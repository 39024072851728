/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Avatar,
  Divider,
  Link,
  Popover,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
// import Carousel from "react-simply-carousel";
import Carousel from 'react-material-ui-carousel';
import useLogin from '../../../hooks/useAuth';
const Item = (props) => {
  // const imgPath = require(props.item.Image).default;
  return (
    <Paper>
      <Box className="CardBannerBox">
        <img
          src={
            window.location.hostname === 'localhost'
              ? `//localhost:9001/${props.item.Image}`
              : `api/${props.item.Image}`
          }
        />
      </Box>
    </Paper>
  );
};

const Amenities = (props) => {
  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const [amenityFilterCategories, setAmenityFilterCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [isEditView, setIsEditView] = useState({ isAccessible });
  const [isAccessible, setIsAccessible] = useState(false);
  const [publicView] = useState({ publicView });

  const { getUserDataToAdmin } = useLogin();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [repDetails, setRepDetails] = useState();
  const [filteredAmenities, setFilteredAmenities] = useState([]);

  const [amenitiesByLegitReps, setAmenitiesByLegitReps] = useState(
    props?.communityInfo?.amenities?.filter(
      (x) => x.repId === props?.communityInfo?.userrole[0]?.id || x.isAdminEntry,
    ) || [],
  );
  const [filteredAmenitiesCount, setFilteredAmenitiesCount] = useState(0);
  //console.log(amenitiesByLegitReps, 7979, props?.communityInfo?.amenities)
  const handleFilterSelection = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, so remove it.
      setSelectedFilters(selectedFilters.filter((selected) => selected !== filter));
    } else {
      // Filter is not selected, so add it.
      setSelectedFilters([...selectedFilters, filter]);
    }
    const filterCount = selectedFilters.length;

    // Update the button text
    const buttonText = filterCount > 0 ? `See (${filterCount}) Results` : 'See Results';

    document.activeElement.blur();
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterResultClick = () => {
    //console.log(filteredAmenities, 9797, selectedFilters)
    const filteredAmenitiesArr = amenitiesByLegitReps?.filter((amenity) =>
      selectedFilters.includes(amenity.amenityCategory.amenityCategoryName),
    );
    //console.log(filteredAmenitiesArr, 9798)

    if (selectedFilters.length === 0 && filteredAmenitiesArr.length === 0) {
      // If no filters are selected, show all data.
      setFilteredAmenities(filteredAmenitiesArr);
    } else {
      setFilteredAmenities(filteredAmenitiesArr);
    }

    // Close the filter popover
    setAnchorEl(null);
  };

  const getRepDetails = async () => {
    try {
      if (communityDetails?.amenityDescription[0]?.isAdminEntry) {
        const userDataResult = await getUserDataToAdmin({
          userIdd: communityDetails?.amenityDescription[0]?.repId,
        });
        setRepDetails(userDataResult.usersResult);
      }
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  // useEffect to update the count after filteredAmenities is set
  useEffect(() => {
    setFilteredAmenitiesCount(filteredAmenities.length);
  }, [filteredAmenities]);

  const resetFilters = () => {
    setSelectedFilters([]); // Reset the selected filters to an empty array
    setFilteredAmenities(amenitiesByLegitReps);
    if (filteredAmenities) {
      setFilteredAmenitiesCount(amenitiesByLegitReps.length);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let itemsOutArr = [];
    let amenityCatArr = [];

    filteredAmenities?.map((amenity) => {
      let itemsInnerArr = [];
      amenity?.amenityGallery?.map((galleryImage) => {
        itemsInnerArr.push({ Image: galleryImage.image });
      });
      itemsOutArr.push(itemsInnerArr);

      // if (!amenityCatArr.some((obj) => obj?.id === amenity?.amenityCategory?.id)) {
      //amenityCatArr.push(amenity?.amenityCategory);
      // }
    });

    const amenityCatSet = new Set(); // Set to track unique amenityCategoryNames

    amenitiesByLegitReps?.forEach((item) => {
      const amenityCategory = item?.amenityCategory;
      const category = amenityCategory?.amenityCategoryName;

      if (!amenityCatSet.has(category)) {
        amenityCatArr.push(amenityCategory);
        amenityCatSet.add(category); // Add the category to the Set
      }
    });
    // amenitiesByLegitReps?.map((amenity) => {
    //   amenityCatArr.push(amenity?.amenityCategory);
    // });

    setAmenityFilterCategories(amenityCatArr);
    setItems(itemsOutArr);
  }, [communityDetails, filteredAmenities]);

  useEffect(() => {
    setIsAccessible(
      (props?.loggedInUserData?.id &&
        communityDetails?.userrole?.[0]?.id === props?.loggedInUserData?.id) ||
        props?.loggedInUserData?.roleId === 2,
    );
    getRepDetails();
  }, [communityDetails]);

  useEffect(() => {
    if (filteredAmenities) {
      setFilteredAmenitiesCount(filteredAmenities?.length);
    }
  }, [communityDetails]);

  useEffect(() => {
    //console.log(filteredAmenities?.length, 7878)
    if (filteredAmenities?.length === 0) {
      let amenitiesLegitArr = [];
      amenitiesByLegitReps?.map((x) => {
        if ((x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry) {
          amenitiesLegitArr.push(x);
        }
      });
      setFilteredAmenities(amenitiesLegitArr);
    }
  }, []);

  return (
    <Paper className="WhitePaper" component="div">
      <Grid container>
        {/* <Grid item xs={12}>
        </Grid> */}
        <Grid item xs={12} className="BoxHeadingContainer">
          <Typography color="primary" variant="h2" className="BoxHeading">
            Amenities
          </Typography>
          {/* <If
            condition={
              props?.isCommunityRepresentative &&
              !props?.publicView &&
              communityDetails?.amenityDescription[0]?.amenityDesc
            }
          >
            <Button
              type="submit"
              className="EditCommonButton LinkTypeButton"
              startIcon={<EditPenIconGreen />}
              size="small"
              color="primary"
              href={'/#/app/community-details-edit-amenities/' + communityDetails?.id}
            >
              Edit
            </Button>
          </If> */}
        </Grid>
        <If
          condition={
            // (filteredAmenities.length > 0 &&
            // (communityDetails?.amenityDescription?.[0]?.amenityDesc != '' &&
            (isAccessible && !props?.publicView) || false
          }
        >
          {' '}
          <Grid item xs={12}>
            <Grid item xs={12} className="AddAmenityRow">
              <Box className="AddAmenityContentBox">
                <Typography variant="body2" className="AddLabel">
                  Inputting amenities helps users find your community when filtering.
                </Typography>
                <Button
                  className="AddButtonBox"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  href={
                    '/#/app/community-details-edit-add-new-amenity/' +
                    communityDetails?.id +
                    '/default'
                  }
                  startIcon={<AddCircleOutlineSharpIcon />}
                >
                  Add An Amenity
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} className="AddAmenityDividerRow">
              <Divider className="DividerCD"></Divider>
            </Grid>
          </Grid>
        </If>
        {/* <If condition={communityDetails?.amenities && communityDetails?.amenities.length > 0}> */}
        <If
          condition={
            (props?.isAccessible &&
              amenitiesByLegitReps?.find(
                (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
              ) &&
              Array.isArray(
                amenitiesByLegitReps?.find(
                  (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                ),
              )) ||
            (typeof amenitiesByLegitReps?.find(
              (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
            ) === 'object' &&
              !props?.publicView) ||
            false
          }
        >
          <Grid item xs={12} className="CommonDetailBoxGrid">
            <Box className="AmenitiesFilterBox">
              <Typography variant="body1" component="div" className="LightgreyColor">
                {`${filteredAmenitiesCount} Result${filteredAmenitiesCount === 1 ? '' : 's'}`}
              </Typography>
              <Button
                className="SearchButton"
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleFilterClick}
                startIcon={<FilterListIcon color="primary" />}
                variant="outlined"
              >
                {selectedFilters.length > 0
                  ? selectedFilters.length > 1
                    ? `Filters(${selectedFilters.length})`
                    : `Filter(${selectedFilters.length})`
                  : 'Filter'}
                <Box className="FilterCount" component="span"></Box>
              </Button>
              <Popover
                id="long-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className="MobileFilter"
              >
                <Paper className="CommonFilterBox AmenityFilters">
                  <Box className="FilterHeader">
                    <Typography variant="h4">Amenity Filters</Typography>
                    <CloseIcon
                      color="primary"
                      className="CloseButton"
                      onClick={handleFilterClose}
                    />
                  </Box>
                  <Box className="FilterBody">
                    <Box className="FilterTagBox">
                      <Typography variant="body1">
                        <b>Type</b>
                      </Typography>
                      <Box className="FilterTagContent">
                        {amenityFilterCategories?.map((category) => {
                          return (
                            <Button
                              className={`FilterTag ${
                                selectedFilters.includes(category.amenityCategoryName)
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() => handleFilterSelection(category.amenityCategoryName)}
                            >
                              {category.amenityCategoryName}
                            </Button>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="FilterFooter">
                    <Divider />
                    <Box className="FilterAction">
                      <Button variant="outlined" size="small" onClick={resetFilters}>
                        Reset All
                      </Button>
                      <Button
                        // onClick={seeResults}
                        onClick={handleFilterResultClick}
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        {selectedFilters.length > 0
                          ? `See (${selectedFilters.length}) Results`
                          : 'See Results'}
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Popover>
            </Box>
            {/* <Box className="FilterTagSecond FilterTagContent">
            {amenityFilterCategories?.map((category) => {
              return <Button className="FilterTag">{category?.amenityCategoryName}</Button>;
            })}
            </Box> */}
          </Grid>
        </If>
      </Grid>
      <Box className="CommonDetailBoxGridSlider">
        <Grid container spacing={2}>
          {filteredAmenities.length > 0 &&
            filteredAmenities
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .slice(0, 4)
              .map((amenity, index) => {
                if (index < 4) {
                  return (
                    <Grid item xs={12} sm={6} key={index}>
                      <Box className="AmenitiesSliderBox">
                        <Box className="SliderContainer">
                          {isAccessible && !props?.publicView && (
                            <Button
                              className="EditCommonButtonFloatSecond"
                              href={
                                '/#/app/community-details-edit-add-new-amenity/' +
                                communityDetails?.id +
                                '/' +
                                amenity?.id
                              }
                              variant="outlined"
                              color="primary"
                              size="small"
                              startIcon={<EditPenIconGreen />}
                            >
                              Edit
                            </Button>
                          )}

                          <Carousel
                            navButtonsAlwaysVisible={true}
                            autoPlay={false}
                            className="SliderCarousel"
                            navButtonsProps={{
                              style: {
                                backgroundColor: '#000',
                                padding: '0',
                              },
                            }}
                            indicatorIconButtonProps={{
                              style: {
                                padding: '0px',
                                color: 'rgba(255, 255, 255, 0.6)',
                              },
                            }}
                            activeIndicatorIconButtonProps={{
                              style: {
                                padding: '0px',
                                color: 'rgba(255, 255, 255, 1)',
                              },
                            }}
                            indicatorContainerProps={{
                              style: {
                                marginTop: '0px',
                                position: 'absolute',
                                bottom: '5px',
                              },
                            }}
                          >
                            {amenity.amenityGallery.map((image, i) => (
                              <Paper key={i}>
                                <Box className="CardBannerBox">
                                  <img
                                    src={
                                      window.location.hostname === 'localhost'
                                        ? `//localhost:9001/${image.image}`
                                        : `api/${image.image}`
                                    }
                                  />
                                </Box>
                              </Paper>
                            ))}
                          </Carousel>
                        </Box>
                        <Box className="SliderInfoContent">
                          <Typography variant="h6" className="AmenitiesCategory">
                            {amenity?.amenityCategory?.amenityCategoryName}
                          </Typography>
                          <Typography variant="h3" className="AmenitiesName">
                            {amenity?.amenityName}
                          </Typography>
                          <Box className="AmenitiesText">
                            <Typography variant="body2" className="AmenitiesInsight">
                              {amenity?.amenityDesc}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                }
                return null;
              })}
        </Grid>
      </Box>

      <Grid container>
        {/* {((props?.isAccessible && */}
        {((props?.isAccessible &&
          amenitiesByLegitReps?.find(
            (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
          ) &&
          Array.isArray(
            amenitiesByLegitReps?.find(
              (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
            ),
          )) ||
          typeof amenitiesByLegitReps?.find(
            (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
          ) === 'object' ||
          false) && (
          <Grid item xs={12} className="ViewButton">
            <Link
              href={'/#/app/view-all-amenities/' + communityDetails?.id}
              underline="none"
              color="primary"
              className="RightFloatBtn"
            >
              View all Amenities
              <Box component="span" marginLeft={1}>
                <ArrowForwardIcon className="" />
              </Box>
            </Link>
          </Grid>
        )}
        <If condition={communityDetails?.amenityDescription?.[0]?.amenityDesc}>
          <Grid item xs={12} className="ChatBoxContainer">
            {props?.isAccessible &&
              !props?.publicView &&
              communityDetails?.amenityDescription?.[0]?.amenityDesc && (
                <Button
                  className="EditCommonButtonFloat"
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<EditPenIconGreen />}
                  href={'/#/app/community-details-edit-amenities/' + communityDetails?.id}
                >
                  Edit
                </Button>
              )}
            <Box className="ChatBox CommonDetailBoxGrid">
              {communityDetails?.amenityDescription?.[0] && (
                <Box className="ChatContent">
                  <Typography variant="body2" className="ChatText" color="primary">
                    {communityDetails?.amenityDescription?.[0]?.amenityDesc}
                  </Typography>
                </Box>
              )}

              <Box className="UserInfoName UserInfoIP">
                <Avatar
                  className="UserAvatar small"
                  alt={`${
                    communityDetails?.amenityDescription?.[0]?.isAdminEntry
                      ? repDetails?.firstName
                      : communityDetails?.userrole[0]?.firstName
                  } `}
                  src={
                    window.location.hostname === 'localhost'
                      ? `//localhost:9001/${
                          communityDetails?.amenityDescription?.[0]?.isAdminEntry
                            ? repDetails?.profilePic
                            : communityDetails?.userrole[0]?.profilePic
                        }`
                      : `/api/${
                          communityDetails?.amenityDescription?.[0]?.isAdminEntry
                            ? repDetails?.profilePic
                            : communityDetails?.userrole[0]?.profilePic
                        }`
                  }
                >
                  <PersonIcon />
                </Avatar>
                <Box className="UserInfoText">
                  <Typography variant="h6">
                    <Box component="span" className="FirstName">
                      {communityDetails?.amenityDescription?.[0]?.isAdminEntry
                        ? repDetails?.firstName
                        : communityDetails?.userrole[0]?.firstName}
                    </Box>
                    <Box component="span" className="LastName">
                      {communityDetails?.amenityDescription?.[0]?.isAdminEntry
                        ? repDetails?.lastName
                        : communityDetails?.userrole[0]?.lastName}
                    </Box>
                  </Typography>
                  <Typography variant="caption">
                    {communityDetails?.amenityDescription?.[0]?.isAdminEntry
                      ? repDetails?.userDesignationText
                      : communityDetails?.userrole[0]?.userDesignationText}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </If>
        {props?.isAccessible && !props?.publicView && (
          <>
            {/* {console.log(communityDetails, 12345)} */}
            <Grid item xs={12}>
              {props?.isAccessible &&
                !props?.publicView &&
                (communityDetails?.amenityDescription?.length === 0 ||
                  (communityDetails?.amenityDescription?.[0] &&
                    !communityDetails?.amenityDescription?.[0]?.amenityDesc)) && (
                  <Box>
                    <Typography variant="body2" className="InsightLabel">
                      Here’s where you can describe your community’s amenities. Are there amenities
                      that make your community stand out or contribute to a sense of belonging?
                    </Typography>
                  </Box>
                )}
            </Grid>
          </>
        )}
      </Grid>
      <If
        condition={
          props?.isAccessible &&
          !props?.publicView &&
          !communityDetails?.amenityDescription?.[0]?.amenityDesc
        }
      >
        <Grid item xs={12}>
          <div className="Wrapper ButtonWrapper InsightButtonContainer">
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              href={'/#/app/community-details-edit-amenities/' + communityDetails?.id}
            >
              {communityDetails?.amenityDescription?.[0]?.amenityDesc ? ' Edit' : ' Add'}
              <Box className="AddButton">Insight</Box>
            </Button>
          </div>
        </Grid>
      </If>
    </Paper>
  );
};

export default Amenities;
