/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { entityEnum } from '../../../../constants/globalConstants';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import useStyles from '../../../../screens/Main/Main.style';

const CommunityMap = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId, setUserId] = useState(loggedInUserData?.id);

  useEffect(() => {}, []);
  return (
    <Paper className="WhitePaper EmptyWhitePaper" component="div">
      {/* <Grid container>
        <Grid item xs={12}>
          <Typography color='primary' variant="h2" className="BoxHeading">
            Map
          </Typography>
        </Grid>
      </Grid> */}
      <Grid item xs={12}>
        <Box className="CommonImg MapImg">
          <img src={require('../../../../assets/images/map-Trussville.jpg').default} />
        </Box>
      </Grid>
    </Paper>
  );
};
CommunityMap.defaultProps = {};
export default CommunityMap;
