/* eslint-disable*/
import React, { useState } from 'react';
import path from 'path';
import {
  withStyles,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Typography,
  IconButton,
  Grid,
  Box,
  Link,
  Divider,
  CircularProgress,
  InputLabel,
} from '@material-ui/core/';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Share } from '@material-ui/icons/';
import { Alert } from '@material-ui/lab/';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, FastField, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { object, string } from 'yup';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { Link } from "react-router-dom";

import { ReactComponent as FbIcon } from '../../../assets/social-sharing-icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/social-sharing-icons/twitter.svg';
import { ReactComponent as EmailIcon } from '../../../assets/social-sharing-icons/email.svg';
import { ReactComponent as CopyIcon } from '../../../assets/social-sharing-icons/copy.svg';
// import useStyles from './ShareCommunity.styles';
import './ShareCommunity.styles.css';
import { backendUrl } from '../../../constants/globalConstants';
import { showErrIfTouched } from '../../../utils';
import useCommunityList from '../../../hooks/useCommunityList';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const ShareCommunityThroughEmail = ({ communityId, communityName, closeShareModal }) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  // const classes = useStyles();
  const handleClose = () => {
    // e.stopPropagation();
    setOpen(false);
    closeShareModal(false);
    setSnackBarOpen(false);
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const { shareCommunityThroughEmail } = useCommunityList();

  const url = `${window.location.origin}/#/app/community-details/${communityId}`;
  const shareUrl = `${backendUrl}share-community/${communityId}`;

  const handleSubmit = async (values, actions, e) => {
    e.stopPropagation(); // Prevent event propagation
    const result = await shareCommunityThroughEmail(values);
    if (result?.status) {
      setSnackBarOpen(true);
      setOpen(false);
      setTimeout(() => {
        closeShareModal(false);
      }, 3000);
      setSnackBarStatus({ message: result?.message, status: true });
    } else {
      setSnackBarOpen(true);
      setOpen(false);
      setTimeout(() => {
        closeShareModal(false);
      }, 3000);
      setSnackBarStatus({ message: result?.message, status: false });
    }
  };

  const handleFormSubmit = async (values, e) => {
    e.stopPropagation(); // Prevent event propagation
    const result = await shareCommunityThroughEmail(values);
    if (result?.status) {
      setSnackBarOpen(true);
      setOpen(false);
      setTimeout(() => {
        closeShareModal(false);
      }, 3000);
      setSnackBarStatus({ message: result?.message, status: true });
    } else {
      setSnackBarOpen(true);
      setOpen(false);
      setTimeout(() => {
        closeShareModal(false);
      }, 3000);
      setSnackBarStatus({ message: result?.message, status: false });
    }
  };

  return (
    <>
      <Box
        className="ShareIcon"
        textAlign="center"
        onClick={(e) => {
          e.stopPropagation();
          handleClickOpen(e);
        }}
      >
        <EmailIcon />
      </Box>

      <Dialog
        className=""
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Formik
          initialValues={{ emailId: '', message: '', communityId }}
          validationSchema={object().shape({
            emailId: string().email('Invalid email address').required('Required'),
          })}
          // onSubmit={async (values, actions, e) => {
          //   e.stopPropagation();
          //   onFormSubmit(true);
          //   const result = await shareCommunityThroughEmail(values);
          //   if (result?.status) {
          //     setSnackBarOpen(true);
          //     setOpen(false);
          //     setTimeout(() => {
          //       closeShareModal(false);
          //     }, 3000);
          //     // closeShareModal(false);
          //     setSnackBarStatus({ message: result?.message, status: true });
          //   } else {
          //     setSnackBarOpen(true);
          //     setOpen(false);
          //     setTimeout(() => {
          //       closeShareModal(false);
          //     }, 3000);
          //     setSnackBarStatus({ message: result?.message, status: false });
          //   }
          // }}
        >
          {({ values, errors, isSubmitting, isValid, dirty, touched }) => {
            return (
              <Form autoComplete="off" onClick={(e) => e.stopPropagation()}>
                <MuiDialogTitle
                  className="DialogTitleLeft"
                  id="customized-dialog-title"
                  onClose={handleClose}
                >
                  <Typography variant="h4" component="div" className="DialogTitleHeading TextColor">
                    Send Email
                  </Typography>
                  {/* <Button
                    onClick={handleClose}
                    className="LinkTypeButton"
                    color="primary"
                  >
                    Cancel
                  </Button> */}
                  {/* <CloseIcon onClick={handleClose} /> */}
                </MuiDialogTitle>
                <MuiDialogContent>
                  <Grid container alignItems="center" justifyContent="center">
                    <Box className="ShareFormContainer">
                      <Grid item xs={12} className="commonFieldBox">
                        <InputLabel className="InputLabelFld" htmlFor="email">
                          Send to
                          <Box mb={1} component="span" display="inline-block" className="Asterisk">
                            *
                          </Box>
                        </InputLabel>
                        <FastField
                          fullWidth
                          as={TextField}
                          name="emailId"
                          placeholder="Enter recipient's email"
                          id="outlined-basic"
                          variant="outlined"
                          type="email"
                          error={!!showErrIfTouched('emailId', errors, touched)}
                          helperText={showErrIfTouched('emailId', errors, touched)}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel className="InputLabelFld" htmlFor="password">
                          Message (Optional)
                        </InputLabel>
                        <FastField
                          as="textarea"
                          name="message"
                          placeholder="Check out this community I found on Move to Happy!"
                          id="outlined-basic"
                          variant="outlined"
                          error={!!showErrIfTouched('message', errors, touched)}
                          helperText={showErrIfTouched('message', errors, touched)}
                          fullWidth
                          className="TextArea"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                </MuiDialogContent>
                <MuiDialogActions>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                    }}
                    variant="outlined"
                    color="primary"
                    className="NoBorderButton"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting || !dirty || !isValid}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent default form submission
                      handleFormSubmit(values, e); // Custom form submission handler
                    }}
                    color="secondary"
                    variant="contained"
                  >
                    Send
                  </Button>
                </MuiDialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      {snackBarStatus?.status ? (
        <Snackbar open={snackBarOpen} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity="success"
            icon={<CheckCircleIcon color="primary" fontSize="inherit" />}
            action={
              <Link
                color="primary"
                underline="none"
                aria-label="close"
                onClick={handleClose}
                className="AlertDismissButton"
              >
                Dismiss
              </Link>
            }
          >
            {snackBarStatus?.message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar open={snackBarOpen} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity="error"
            icon={<WarningRoundedIcon color="secondary" fontSize="inherit" />}
            action={
              <Link
                color="primary"
                underline="none"
                aria-label="close"
                onClick={handleClose}
                className="AlertDismissButton"
              >
                Dismiss
              </Link>
            }
          >
            {snackBarStatus?.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ShareCommunityThroughEmail;
