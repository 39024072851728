/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'; // Import useFormik
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import {
  Grid,
  Link,
  Typography,
  Box,
  TextField,
  InputLabel,
  Button,
  Container,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useCommunityList from '../../../../hooks/useCommunityList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';

const CommunityEditViewAmenities = (props) => {
  const theme = useTheme();
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [userId] = useState(loggedInUserData?.id);
  const [communityDetails, setCommunityDetails] = React.useState(null);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [amenitiesDesc, setAmenitiesDesc] = React.useState('');
  const [isAmenitiesDesc, setIsAmenitiesDesc] = useState(false);
  const maxCharacterCount = 700;
  const { addAmenitiesDescForCommunity, getCommunityDetailById } = useCommunityList();
  const params = useParams();
  const navigate = useNavigate();
  const [isAccessible, setIsAccessible] = React.useState('');

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setAmenitiesDesc(value);
  };

  const formik = useFormik({
    initialValues: {
      communityId: params?.id,
      repId: loggedInUserData?.id,
      amenitiesDesc: amenitiesDesc,
      isAdminEntry: loggedInUserData?.roleId === 2,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const trimmedAmenitiesDesc = values?.amenitiesDesc?.trim();
        values['amenitiesDesc'] = trimmedAmenitiesDesc;
        const response = await addAmenitiesDescForCommunity(values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response); // The response from the API
        formik.resetForm(); // Reset the form after successful submission
        navigate(`../../community-details/${params?.id}#Section1`);
        // Do something with the response, e.g., show a success message
      } catch (error) {
        console.error(error.response.data); // Log any error response from the API
        // Handle error, e.g., show an error message
      }
    },
  });

  // const getLatestCommunityDetails = async (communityDetails) => {
  //   if (loggedInUserData?.roleId === 1) {
  //     communityDetails?.amenityDescription?.find((x) => x.repId === result?.data?.userrole[0]?.id)
  //       ?.updatedAt
  //   } else if (loggedInUserData?.roleId === 2) {
  //   }
  // };

  const loadCommunityDetails = async () => {
    try {
      const result = await getCommunityDetailById({ communityId: params?.id });

      if (result) {
        setCommunityDetails(result.data);
        // getLatestCommunityDetails(result.data);
        setAmenitiesDesc(result?.data?.amenityDescription?.[0]?.amenityDesc || '');
        if (result?.data?.amenityDescription?.[0]?.amenityDesc !== '') {
          setIsAmenitiesDesc(true);
        }
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };
  useEffect(() => {
    loadCommunityDetails();
  }, []);

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (formik.dirty) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${params?.id}#Section1`);
    }
  };

  useEffect(() => {
    loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2
      ? setIsAccessible(true)
      : setIsAccessible(false);
  }, [loggedInUserData]);

  return (
    <Container>
      <Grid container className="PageSpacing FixedMD">
        <Grid item xs={12}>
          {isAccessible ? (
            <>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    Amenity Insight
                  </Typography>
                </Link>
              </Grid>
              <Grid container className="FixedID">
                {/* <Grid item xs={12} className="ViewButton">
                <Link onClick={() => {}} underline="none" color="primary" className="RightFloatBtn">
                  View Amenities
                  <Box component="span" marginLeft={1}>
                    <ArrowForwardIcon />
                  </Box>
                </Link>
              </Grid> */}

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <InputLabel className="InputLabelFld">
                      Describe your community’s amenities. Are there amenities that make your
                      community stand out or contribute to a sense of belonging?
                    </InputLabel>
                  </Box>
                  <TextField
                    className="TextFieldTextarea"
                    fullWidth
                    multiline
                    minRows={8}
                    maxLength={700}
                    label=""
                    variant="outlined"
                    value={amenitiesDesc}
                    onChange={handleChange}
                    {...formik.getFieldProps('amenitiesDesc')}
                    inputProps={{
                      maxLength: maxCharacterCount,
                    }}
                    helperText={`${maxCharacterCount - formik.values.amenitiesDesc.length}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="Wrapper ButtonWrapper">
                    <Button
                      disabled={
                        !formik.dirty ||
                        (formik.values.amenitiesDesc.length === 0 && !isAmenitiesDesc)
                      }
                      onClick={formik.handleSubmit}
                      variant="contained"
                      color="primary"
                      className="EditViewButton"
                      fullWidth
                    >
                      Publish
                    </Button>
                  </div>
                </Grid>
              </Grid>
              {showDeleteBox && (
                <>
                  <ModalEditViewDelete
                    status={true}
                    navigateUrl={`../../community-details/${params?.id}#Section1`}
                    onClose={onTakeMeback}
                  ></ModalEditViewDelete>
                </>
              )}
            </>
          ) : (
            'You do not have permissions to access this URL.'
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
CommunityEditViewAmenities.defaultProps = {};
export default CommunityEditViewAmenities;
