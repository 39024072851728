/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Typography, Box, Paper, Container } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import Carousel from "react-simply-carousel";
import Carousel from 'react-material-ui-carousel';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import AmenitiesAll from '../../Amenities/AmenitiesAll';
import useCommunityList from '../../../../hooks/useCommunityList';
import ViewPublic from '../../ViewPublic/ViewPublic';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import useLogin from '../../../../hooks/useAuth';

const ViewAllAmenities = (props) => {
  const fileInput = React.useRef();
  const loc = useLocation();
  const { getUserDataToAdmin } = useLogin();
  const params = useParams();
  const navigate = useNavigate();
  const key = 'iFrasCwdJh';
  const [communityDetails, setCommunityDetails] = useState(null);
  const { getCommunityDetailById, getAmenityByType, getAmenityCategories } = useCommunityList();
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  const [amenityFilterCategories, setAmenityFilterCategories] = useState([]);
  const [activeAmenityTypes, setActiveAmenityTypes] = useState([]);
  const [isAccessible, setIsAccessible] = useState(false);
  const [repDetails, setRepDetails] = useState();

  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);
  const [isCommunityRepresentative, setCommunityRepresentative] = useState(
    params?.id === loggedInUserData?.communityId,
  );
  const [items, setItems] = useState([]);
  const [publicView, setPublicView] = useState(false);

  // const [selectedAmenityTypes, setSelectedAmenityTypes] = useState([]);
  // const [filteredAmenities, setFilteredAmenities] = useState([]);

  //console.log(filteredAmenities, 1777);
  //console.log(communityDetails?.amenities, 8888);

  const fillCommunityDetails = async () => {
    let communityDetailsResults = [];
    if (params?.id) {
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults.data);
    }
    return communityDetailsResults?.data;
  };

  const hashFragment = loc.hash.substring(1);

  const fillCommunityCategories = async () => {
    const amenityCategoriesArr = await getAmenityCategories();
    setAmenityFilterCategories(amenityCategoriesArr.usersResult);
  };

  const getRepDetails = async () => {
    try {
      if (communityDetails?.amenityDescription[0]?.isAdminEntry) {
        const userDataResult = await getUserDataToAdmin({
          userIdd: communityDetails?.amenityDescription[0]?.repId,
        });
        setRepDetails(userDataResult.usersResult);
      }
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  useEffect(() => {
    // Scroll to Section1 with offset of -100 when component mounts
    if (hashFragment) {
      setTimeout(() => {
        scroller.scrollTo(hashFragment, {
          duration: 500,
          spy: true,
          smooth: true,
          offset: -100,
        });
      }, 1500);
    }
  }, [loc]);

  useEffect(() => {
    fillCommunityCategories();
  }, []);

  useEffect(() => {
    fillCommunityDetails();
  }, []);

  useEffect(() => {
    let activeAmenityTypesArr = [];

    communityDetails?.amenities?.map((amenity) => {
      activeAmenityTypesArr.push(amenity?.amenityTypeId);
    });
    setActiveAmenityTypes(activeAmenityTypesArr);
    // setAmenityFilterCategories(amenityCatArr);
    // setItems(itemsOutArr);
  }, [communityDetails]);

  const [filter, setFilter] = useState('');
  const [amenities, setAmenities] = useState([]);

  // useEffect(() => {
  //   // Define an async function to fetch amenities
  //   async function fetchAmenities() {
  //     try {
  //       // Make your API call here to fetch the initial data
  //       const response = await getAmenityByType({communityId:params?.id}); // Replace with your actual API endpoint
  //       if (response.status === true) {
  //         // Update the 'amenities' state with the fetched data
  //         setAmenities(response.amenitiesResult);
  //       } else {
  //         //console.error('Failed to fetch amenities data');
  //       }
  //     } catch (error) {
  //       //console.error('Error fetching amenities data:', error);
  //     }
  //   }

  //   // Call the fetchAmenities function to fetch data
  //   fetchAmenities();
  // }, []);

  // const handleFilterClick = (amenityTypeName) => {
  //   if (filter === amenityTypeName) {
  //     // If the filter is already set to this amenity type, clear the filter
  //     setFilter('');
  //     setFilteredAmenities(amenities);
  //   } else {
  //     // Filter amenities by amenityTypeName
  //     const filtered = amenities.filter((amenity) => amenity.amenityType.amenityTypeName === amenityTypeName);
  //     setFilter(amenityTypeName);
  //     setFilteredAmenities(filtered);
  //   }
  // };

  // const handleFilterClick = (amenityTypeName) => {
  //   if (filter === amenityTypeName) {
  //     setFilter(''); // Clear the filter if it's already set to this amenity type
  //   } else {
  //     setFilter(amenityTypeName); // Set the filter to the clicked amenity type
  //   }
  // };

  // // Filter amenities based on the filter state
  // const filteredAmenities = amenities.filter((amenity) => {
  //   return filter === '' || amenity.amenityType.amenityTypeName === filter;
  // });

  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleFilterClick = (amenityTypeName) => {
    if (filters.includes(amenityTypeName)) {
      // Filter is active, so remove it
      setFilters(filters.filter((filter) => filter !== amenityTypeName));
    } else {
      // Filter is not active, so add it
      setFilters([...filters, amenityTypeName]);
    }
  };

  // Filter amenities based on the active filters
  const filteredAmenities = amenities.filter((amenity) => {
    return filters.length === 0 || filters.includes(amenity.amenityType.amenityTypeName);
  });

  const resetFilters = () => {
    setFilters([]);
  };

  useEffect(() => {
    setIsAccessible(
      (loggedInUserData?.id && communityDetails?.userrole?.[0]?.id === loggedInUserData?.id) ||
        loggedInUserData?.roleId === 2,
    );
    getRepDetails();
  }, [communityDetails]);

  return (
    <Box className="ViewAllAmenitiesContainer">
      {communityDetails ? (
        <Box component="div" className="PageSpacing">
          <Container className="CustomContainer">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="ViewAmenitiesContent"
              spacing={3}
            >
              {/* Tushar Your code is here I have commented */}

              {/* <Grid item xs={12} sm={3} className="AmenityFiltersContainer">
                <Paper className="AmenityFiltersBox TransparentPaper">
                  <Typography variant="h3" component="div" className="AmenityFiltersHeading">
                    Amenity Filters
                  </Typography>
                  <Box
                    className="FlexCenterStart AmenityFiltersResultsBox"
                    justifyContent="Space-between"
                  >
                    <Typography variant="body2" component="span" className="AmenityFiltersResults">
                      {communityDetails?.amenities?.length} Results
                    </Typography>
                    <Button
                      onClick={resetFilters}
                      variant="outlined"
                      size="small"
                      className="AmenityFiltersResetButton"
                    >
                      RESET ALL
                    </Button>
                  </Box>
                  {amenityFilterCategories?.map((category) => {
                    return (
                      <Box className="FiltersSection">
                        <Typography
                          variant="body2"
                          component="div"
                          className="FiltersSectionHeading"
                        >
                          {category?.amenityCategoryName}
                        </Typography>
                        <Box className="FiltersOptionBox">
                          <Box className="FilterTagContent">
                            {category?.amenityType?.map((type) => (
                              <Button
                                disabled={!activeAmenityTypes.includes(type.id)}
                                key={type.id}
                                className={`FilterTag ${
                                  filters.includes(type.amenityTypeName) ? 'selected' : ''
                                }`}
                                onClick={() => handleFilterClick(type.amenityTypeName)}
                              >
                                {type.amenityTypeName}
                              </Button>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Grid> */}
              <Grid item xs={12} sm={12} className="ViewAmenityGrid">
                <Box component="div" className="">
                  <If condition={isAccessible && publicView}>
                    <ViewPublic setPublicView={setPublicView} />
                  </If>
                  {/* <Choose>
                    <When condition={communityDetails?.isRepresented}>
                      <Element name="Section1">
                        <AmenitiesAll
                          communityInfo={communityDetails}
                          isAccessible={isAccessible}
                          publicView={publicView}
                          amenities={filteredAmenities}
                          fillCommunityCategories={fillCommunityCategories}
                        />
                      </Element>
                    </When>
                  </Choose> */}

                  <Element name="Section1">
                    <AmenitiesAll
                      communityInfo={communityDetails}
                      isAccessible={isAccessible}
                      publicView={publicView}
                      amenities={filteredAmenities}
                      fillCommunityCategories={fillCommunityCategories}
                    />
                  </Element>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : (
        <>
          <Container>
            <Grid container className="PageSpacing">
              <Grid item xs={12}>
                No content to display
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </Box>
  );
};

ViewAllAmenities.defaultProps = {};
export default ViewAllAmenities;
