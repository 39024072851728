/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';
import {
  Grid,
  Link,
  Typography,
  Box,
  TextField,
  InputLabel,
  Button,
  Container,
  Avatar,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useCommunityList from '../../../../hooks/useCommunityList';
import { ReactComponent as PersonIcon } from '../../../../assets/images/User.svg';
import useLogin from '../../../../../src/hooks/useAuth';
import Cookies from 'js-cookie';
import { useParams, useNavigate } from 'react-router-dom';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';
import { ContactSupportOutlined } from '@material-ui/icons';

const CommunityEditViewForCommunityReps = () => {
  const { editCommunityReps, getRoleData } = useLogin();
  const { getCommunityDetailById } = useCommunityList();

  const [userData, setUserData] = useState();
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [communityDetails, setCommunityDetails] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [userDesigText, setUserDesignationText] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [userDesc, setUserDescription] = useState(false);
  const [isUserDescriptionText, setUserDescriptionText] = useState('');
  const [isAccessible, setIsAccessible] = React.useState('');

  const params = useParams();

  const key = 'iFrasCwdJh';
  let bytes = null;

  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    window.location.hash = '#/app/home-page';
  }

  const isFirstRender = useRef(true);

  useEffect(() => {
    // Ensure that we have user data before making the API call
    if (isFirstRender.current && userCookie && userId) {
      const getUserData = async () => {
        const userDataResult = await getRoleData({ userId });
        setUserData(userDataResult.usersResult);
        console.log(userDataResult, 'GET');
        setIsLoading(false); // Mark loading as false when user data is available
      };
      getUserData();
      isFirstRender.current = false;
    }
  }, [userCookie, getRoleData, userId]);

  const [userId] = useState(loggedInUserData?.id);

  const maxCharacterCount = 700;

  const formik = useFormik({
    initialValues: {
      userId: communityDetails?.userrole[0]?.id,
      roleId: loggedInUserData.roleId,
      userDesignationText: userRole,
      userDescription: isUserDescriptionText,
    },

    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (communityDetails) {
          // console.log(communityDetails?.userrole[0]?.id, 363636);
          values['userId'] = communityDetails?.userrole[0]?.id;
          const response = await editCommunityReps(values, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log(response);
          formik.resetForm();
          navigate(`../../community-details/${params?.id}#Section2`);
        }
      } catch (error) {
        console.error(error.response.data);
      }
    },
  });

  const handleInputChange1 = (e) => {
    const { value } = e.target;
    setUserRole(value);
    setIsEdited(true);
  };

  const handleInputChange2 = (e) => {
    const { value } = e.target;
    setUserDescriptionText(value);
    setIsEdited(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      const communityData = communityDetailsResults.data;

      setCommunityDetails(communityData);
      setUserRole(communityDetailsResults?.data?.userrole[0]?.userDesignationText || '');
      setUserDescriptionText(communityDetailsResults?.data?.userrole[0]?.userDescription || '');

      if (communityData?.userrole[0]?.userDesignationText !== '') {
        setUserDesignationText(true);
      }
      if (communityData?.userrole[0]?.userDescription !== '') {
        setUserDescription(true);
      }

      formik.setValues({
        ...formik.values,
        userDesignationText: communityData?.userrole[0]?.userDesignationText || '',
        userDescription: communityData?.userrole[0]?.userDescription || '',
      });
    };

    fetchData();
  }, [params?.id]);

  const navigate = useNavigate();

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (formik.dirty) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${params?.id}#Section2`);
    }
  };

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
  };

  const handleEditAccount = () => {
    navigate('../../account');
  };

  useEffect(() => {
    loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2
      ? setIsAccessible(true)
      : setIsAccessible(false);
  }, [loggedInUserData]);

  return (
    <Container>
      <Box className="PageSpacing FixedMD CommunityRepresentativeProfile">
        {isAccessible ? (
          <>
            {showDeleteBox && (
              <>
                <ModalEditViewDelete
                  status={true}
                  navigateUrl={`../../community-details/${params?.id}#Section2`}
                  onClose={onTakeMeback}
                ></ModalEditViewDelete>
              </>
            )}
            <Grid container>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    Community Representative Profile
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            {/* my edit */}
            <Grid container className="FixedID">
              <Grid item xs={12}>
                <Box className="UserInfoName CommunityRepProfileUser">
                  <Avatar
                    className="UserAvatar Large"
                    alt={`${userData?.firstName}}`}
                    src={
                      window.location.hostname === 'localhost'
                        ? `//localhost:9001/${userData?.profilePic}`
                        : `/api/${userData?.profilePic}`
                    }
                  >
                    <PersonIcon />
                  </Avatar>
                  <Box className="UserInfoText">
                    <Typography variant="h4" className="UserNameInfo">
                      <Box style={{ paddingRight: '5px' }} component="span">
                        {userData?.firstName}
                      </Box>
                      <Box component="span">{userData?.lastName}</Box>
                    </Typography>
                    <Button
                      onClick={handleEditAccount}
                      variant="body2"
                      component="p"
                      className="CommonEdit LinkTypeButton Uppercase"
                    >
                      Edit Account
                    </Button>
                  </Box>
                </Box>
              </Grid>

              {/* my edit */}

              <Grid item xs={12} className="commonFieldBox">
                <Box display="flex" justifyContent="space-between" mt={4}>
                  <InputLabel className="InputLabelFld" htmlFor="jobTitle">
                    Job<span> (or Community Involvement)</span> Title
                  </InputLabel>
                </Box>
                <TextField
                  className="TextFieldTextarea"
                  fullWidth
                  label=""
                  variant="outlined"
                  name="userDesignationText"
                  value={userRole}
                  onChange={handleInputChange1}
                  onBlur={formik.handleBlur}
                  {...formik.getFieldProps('userDesignationText')}
                />
              </Grid>
              <Grid item xs={12} className="">
                <Box display="flex" justifyContent="space-between">
                  {/* <InputLabel className="InputLabelFld" htmlFor="jobTitle">
                    Why <span>LaGrange?</span> Add a brief statement on what makes{' '}
                    <span>LaGrange</span> special to you.
                  </InputLabel> */}
                  <InputLabel className="InputLabelFld" htmlFor="jobTitle">
                    Why <span>{communityDetails?.communityNameText}</span> Add a brief statement on
                    what makes <span>{communityDetails?.communityNameText}</span> special to you.
                  </InputLabel>
                </Box>
                <TextField
                  className="TextFieldTextarea"
                  fullWidth
                  label=""
                  variant="outlined"
                  name="userDescription"
                  multiline
                  minRows={8}
                  maxLength={700}
                  value={isUserDescriptionText}
                  onChange={handleInputChange2}
                  onBlur={formik.handleBlur}
                  {...formik.getFieldProps('userDescription')}
                  inputProps={{
                    maxLength: maxCharacterCount,
                  }}
                  // helperText={`${maxCharacterCount - isUserDescriptionText?.length}`}
                  helperText={`${maxCharacterCount - formik.values?.userDescription?.length}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="Wrapper ButtonWrapper">
                  <Button
                    disabled={
                      !formik.dirty ||
                      (!userRole.length === 0 && !isUserDescriptionText.length === 0 && !isEdited)
                    }
                    onClick={formik.handleSubmit}
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="EditViewButton"
                    fullWidth
                  >
                    Publish
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          'You do not have permissions to access this url.'
        )}

        {/* <Grid container>
          <Grid item xs={12}>
            <Link
              underline="none"
              onClick={(e) => {
                handleBackToParent(e);
              }}
            >
              <Typography className="BackButton" variant="body1">
                <ArrowBackIcon color="primary" className="BackButtonArrow" />
                Edit Community Profile
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <InputLabel htmlFor="jobTitle">
                Job<span> (or Community Involvement)</span> Title
              </InputLabel>
            </Box>
            <TextField
              className="TextFieldTextarea"
              fullWidth
              label=""
              variant="outlined"
              name="userDesignationText"
              value={userRole}
              onChange={handleInputChange1}
              onBlur={formik.handleBlur}
              {...formik.getFieldProps('userDesignationText')}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <InputLabel htmlFor="jobTitle">
                Why <span>[LaGrange]?</span> Add a brief statement on what makes{' '}
                <span>[LaGrange]</span> special to you.
              </InputLabel>
            </Box>
            <TextField
              className="TextFieldTextarea"
              fullWidth
              label=""
              variant="outlined"
              name="userDescription"
              multiline
              minRows={8}
              maxLength={700}
              value={isUserDescriptionText}
              onChange={handleInputChange2}
              onBlur={formik.handleBlur}
              {...formik.getFieldProps('userDescription')}
              inputProps={{
                maxLength: maxCharacterCount,
              }}
              // helperText={`${maxCharacterCount - isUserDescriptionText?.length}`}
              helperText={`${maxCharacterCount - formik.values?.userDescription?.length}`}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="Wrapper ButtonWrapper">
              <Button
                disabled={
                  !formik.dirty ||
                  (!userRole.length === 0 && !isUserDescriptionText.length === 0 && !isEdited)
                }
                onClick={formik.handleSubmit}
                color="primary"
                variant="contained"
              >
                Publish
              </Button>
            </div>
          </Grid>
        </Grid> */}
      </Box>
    </Container>
  );
};

export default CommunityEditViewForCommunityReps;
