/* eslint-disable */
import update from 'immutability-helper';

import { TASK_EVENT } from '../constants/actionTypes';

export const initialState = {
  tasksStatusBucket: [],
  pendingBucket: [],
  completionBucket: [],
  panelOpen: false,
  sourceToken: null,
};

const taskReducer = (state, { type, payload }) => {
  switch (type) {
    case TASK_EVENT.UPLOAD_TASK:
      payload.status = TASK_EVENT.STARTED;

      return {
        ...state,
        tasksStatusBucket: [...state.tasksStatusBucket, payload],
        pendingBucket: [...state.pendingBucket, payload.taskName + payload.index],
        completionBucket: [...state.completionBucket, payload.taskName + payload.index],
        panelOpen: true,
        sourceToken: payload.source,
      };

    case TASK_EVENT.UPLOAD_PROGRESS_TASK:
      return update(state, {
        tasksStatusBucket: {
          [payload.index]: {
            progressVal: { $set: payload.progressVal },
            status: { $set: TASK_EVENT.IN_PROGRESS },
          },
        },
      });

    case TASK_EVENT.UPLOAD_COMPLETE_TASK:
      return update(state, {
        tasksStatusBucket: {
          [payload.index]: {
            status: { $set: TASK_EVENT.SAVED },
          },
        },
        pendingBucket: { $splice: [[-1, 1]] },
        completionBucket: { $splice: [[-1, 1]] },
      });

    case TASK_EVENT.UPLOAD_FAILED_TASK:
      return update(state, {
        tasksStatusBucket: {
          [payload.index]: {
            status: { $set: TASK_EVENT.FAILED },
            progressVal: { $set: 100 },
          },
        },
        pendingBucket: { $splice: [[-1, 1]] },
      });

    case TASK_EVENT.TASK_COMPLETE_RESET:
      return initialState;

    case TASK_EVENT.DRAWER_CLOSE:
      return update(state, {
        panelOpen: {
          $set: false,
        },
      });

    default:
      return state;
  }
};

export default taskReducer;
