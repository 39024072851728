/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { entityEnum } from '../../../../constants/globalConstants';
import { Grid, Card, CardContent, CardMedia, Button, Typography, Box } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { FavoriteSharp } from '@material-ui/icons/';
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useHouseList from '../../../../hooks/useHouseList';
import useStyles from '../../Main.style';

const HouseCard = (props) => {
  const [isFav, setIsFav] = useState(false);
  const { addHouseAsFavourite, getStatusById } = useHouseList();

  // const [houseList, setHouseList] = useState([]);
  // const [userHouses, setUserHouses] = useState([]);
  // const [favHouses, setFavHouses] = React.useState([]);
  // const [tabValue, setTabValue] = React.useState(0);
  // const [searchedText, setSearchedText] = useState('');
  // const [selectedHouse, setSelectedHouse] = useState('');

  // let housesArr = [];
  // let userHousesArr = [];
  // let favHousesList = {};

  const classes = useStyles();
  const key = 'iFrasCwdJh';
  const userCookie = Cookies.get('user');

  let bytes = null;
  let loggedInUserData = null;

  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // const fillHouseList = async () => {
  //   if (housesArr.length === 0) {
  //     housesArr = await getAllHouses();
  //     setHouseList(housesArr?.usersResult);
  //   }
  //   return housesArr?.usersResult;
  // };

  // const fillUserHouses = async () => {
  //   let houseResults = [];
  //   if (userId !== null && userId !== undefined) {
  //     houseResults = await getHousesByUser({ userId, typeText: entityEnum[1] });
  //     setUserHouses(houseResults?.usersResult);
  //   }
  //   return houseResults;
  // };

  // const getFavHousesList = async () => {
  //   userHousesArr = await fillUserHouses();
  // };

  // const houseFilterFunction = (houseArray, _tabValue, searchedText) => {
  //   let filteredList = [];
  //   switch (_tabValue) {
  //     case 0: {
  //       filteredList = houseArray;
  //       break;
  //     }
  //     case 1: {
  //       const userHouseObj = userHouses.reduce(
  //         (acc, cur) => ({ ...acc, [cur?.houseMapId?.id]: cur?.readAt }),
  //         {},
  //       );
  //       houseArray
  //         .sort((a, b) => {
  //           return new Date(a.readAt) - new Date(b.readAt);
  //         })
  //         .map((clbs) => {
  //           if (userHouseObj[clbs?.id] !== undefined) {
  //             clbs.readAt = userHouseObj[clbs?.id];
  //           }
  //         });

  //       filteredList = houseArray;
  //       break;
  //     }
  //     case 2: {
  //       filteredList = houseArray.filter((clbs) => favHouses[clbs.id] == true);
  //       break;
  //     }
  //     case 3: {
  //       filteredList = houseArray.filter((clbs) => favHouses[clbs.id] == true);
  //       break;
  //     }
  //     default: {
  //       break;
  //     }
  //   }
  //   return filteredList.filter((clbs) => {
  //     return (
  //       clbs?.houseStyle?.toLowerCase().indexOf(searchedText.toLowerCase()) !== -1 ||
  //       clbs?.address?.toLowerCase().indexOf(searchedText.toLowerCase()) !== -1
  //     );
  //   });
  // };

  // const ReadMore = ({ children }) => {
  //   const text = children;
  //   const [isReadMore, setIsReadMore] = useState(true);
  //   const toggleReadMore = () => {
  //     setIsReadMore(!isReadMore);
  //   };
  //   return (
  //     <p className="text">
  //       {isReadMore ? text.slice(0, 140) : text}
  //       <span onClick={toggleReadMore} className="read-or-hide">
  //         {isReadMore ? '...see more' : ' show less'}
  //       </span>
  //     </p>
  //   );
  // };

  // const getFavouriteStatus = async () => {
  //   housesArr = await fillHouseList();
  //   userHousesArr = await fillUserHouses();
  //   const userHousesObject = userHousesArr.usersResult;
  //   // userCommunities?.length > 0 ? userCommunities : userCommunitiesArr.usersResult;
  //   const housesListObject = housesArr;
  //   // communityListByState?.length > 0 ? communityListByState : stateCommunitiesArr;
  //   if (userHousesObject) {
  //     let mergeArr = [];
  //     for (let i = 0; i < housesListObject.length; i++) {
  //       mergeArr.push({
  //         ...housesListObject[i],
  //         ...userHousesObject.find((itmInner) => itmInner.houseMapId.id === housesListObject[i].id),
  //       });
  //     }
  //     if (mergeArr.length > 0) {
  //       mergeArr?.forEach((elem) => {
  //         {
  //           favHousesList[
  //             `${elem?.houseMapId?.id === undefined ? elem?.id : elem?.houseMapId?.id}`
  //           ] = elem.isFavourite !== undefined ? Boolean(elem.isFavourite) : false;
  //         }
  //       });
  //     }

  //     setFavHouses(favHousesList);

  //     return favHousesList;
  //   }
  // };
  const handleFavToggle = async (houseId) => {
    // setSelectedHouse(houseId);
    console.log(props, 3000);
    if (userId !== null && userId !== undefined) {
      const favResult = await addHouseAsFavourite({
        houseId,
        userId,
        typeText: 'house',
      });

      if (favResult) {
        setIsFav(favResult?.responseData?.isFavourite);
        console.log(location.href.indexOf('community-details'), 30000);
        if (!location.href.indexOf('community-details')) {
          updateFavObject();
        }
        // const udpatedFavStatus = await getFavouriteStatus();
        // setFavHouses(udpatedFavStatus);
      }
    }
  };

  const setHouseStatus = async () => {
    if (userId !== null && userId !== undefined) {
      const favResult = await getStatusById({ houseId: props.hs.id, userId });
      setIsFav(favResult?.usersResult?.isFavourite);
    }
  };
  // const handleChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  // const handleFilterClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleSearchChange = (event) => {
  //   setSearchedText(event.currentTarget.value);
  //   houseFilterFunction(houseList, tabValue, searchedText);
  // };
  // const handleFilterClose = () => {
  //   setAnchorEl(null);
  // };
  useEffect(() => {
    if (typeof userId !== 'undefined' || userId !== null) {
      setHouseStatus();
    }
  }, []);

  return (
    <>
      {props.hs ? (
        <Grid key={props?.hs?.id} xs={12} sm={6} md={4} lg={4} className="CommonCardColumn">
          <Card className="CommonCard FullCommonCard">
            <CardMedia
              className="CommonCardBanner"
              image={
                location.hostname === 'localhost'
                  ? `//localhost:9001${
                      props?.hs?.houseToGallery
                        ? props?.hs?.houseToGallery[0]?.image
                        : '/assets/house/house1.jpg'
                    }`
                  : `/api${
                      props?.hs?.houseToGallery
                        ? props?.hs?.houseToGallery[0]?.image
                        : '/assets/house/house1.jpg'
                    }`
              }
              title={props?.hs?.mlsNo}
            ></CardMedia>
            <CardContent>
              <Grid container>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Box className="ToggleButtonBox">
                    <Box>
                      <ToggleButton
                        onClick={() => {
                          handleFavToggle(props?.hs?.id);
                        }}
                        value="check"
                        selected={isFav}
                        className="FavoriteToggleButton"
                      >
                        <FavoriteTwoToneIcon
                          style={{
                            color: isFav ? '#F6AF9F' : '#486877',
                          }}
                        />
                      </ToggleButton>
                    </Box>
                    {!props.homeInView && (
                      <Box ml={2}>
                        <Button className="LinkButton">
                          <img
                            communityId={props?.hs?.id}
                            className={classes.ShareCommunityIcon}
                            src={require('../../../../assets/images/Share.svg').default}
                            alt="Badge Icon"
                          />
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" component="div" className="StateNameHeading">
                    {props?.hs?.houseStyle}
                  </Typography>
                  <Typography className="CityNameHeading" variant="h2" component="div">
                    ${props?.hs?.currentListingPrice.toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                  <Typography variant="body2" component="div">
                    {props?.hs?.address}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row">
                <Grid item xs className="HouseInfoBox">
                  <Typography variant="body2" component="div" className="HouseInfo">
                    <b>{props?.hs?.bed}</b> beds
                  </Typography>
                </Grid>
                <Grid item xs className="HouseInfoBox">
                  <Typography variant="body2" component="div" className="HouseInfo">
                    <b>{Number(props?.hs?.fullBaths) + Number(props?.hs?.halfBaths) / 2}</b> baths
                  </Typography>
                </Grid>
                <Grid item xs className="HouseInfoBox">
                  <Typography variant="body2" component="div" className="HouseInfo">
                    <b>{props?.hs?.houseSize}</b> sqft
                  </Typography>
                </Grid>
                {!props.homeInView && (
                  <Grid item xs className="HouseInfoBox">
                    <Typography variant="body2" component="div" className="HouseInfo">
                      <b>{props?.hs?.lotSize}</b> acre lot
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        'Loading Component...'
      )}
    </>
  );
};
HouseCard.defaultProps = {
  hs: null,
  homeInView: false,
  updateFavObject: null,
};
export default HouseCard;
