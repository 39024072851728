/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FastField, Formik, Form, Field } from 'formik';
import { Typography, Button, Box, InputLabel, Grid, TextField } from '@material-ui/core';
import Videoposter from '../../../assets/images/Video-poster.png';
import video from './Trussville-video.mp4';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import useLogin from '../../../../src/hooks/useAuth';
import useCommunityList from '../../../hooks/useCommunityList';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="CloseButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const ModalRequestVideoConsultation = (props) => {
  const navigate = useNavigate();
  const { getRoleData } = useLogin();
  const { sendCustomVideoRequest } = useCommunityList();
  const [open, setOpen] = React.useState(props.status);
  const [userData, setUserData] = useState();

  console.log(open, 'OPEN');
  const handleClose = () => {
    setOpen(false);
    props.onClose(false);
  };

  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    // window.location.hash = '#/app/home-page';
  }
  const [userId] = useState(loggedInUserData?.id);

  // Get User Details
  const getUserData = async () => {
    const userDataResult = await getRoleData({ userId });
    setUserData(userDataResult.usersResult);
    console.log(userDataResult, 'GET');
    return userDataResult;
  };

  console.log(userId, 'UUI');

  const params = useParams();
  let communityId = params?.id;

  console.log(userData?.emailId, 'UD');

  useEffect((newValue) => {
    if (userCookie) {
      getUserData();
    }
  }, []);

  const sendRequest = async () => {
    const response = await sendCustomVideoRequest({
      communityRepEmail: userData?.emailId,
      repId: userId,
      communityId: communityId,
      userName: userData?.firstName,
    });
    if (response.status === true) {
      console.log('SUCCESS');
      handleClose();
      props.onRequestSuccess();
      props.fetchVideoConsultationData();
    }
  };

  return (
    <Formik>
      {/* Start Delete any changes Modal */}
      <Dialog
        className="DeleteDialog ModalRequestVideoConsultation"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogTitle
          className="DialogTitleLeft"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography variant="h3" component="div" className="DialogTitleHeading TextColor">
            Request a Video Consultation
          </Typography>
          {/* <CloseIcon onClick={handleClose} /> */}
        </MuiDialogTitle>
        <MuiDialogContent>
          <Typography gutterBottom variant="body2" component="div">
            Our experienced team at Move to Happy will work with you to produce a video that puts
            the best parts of your community in future residents’ and businesses’ hands. Reach out
            today to learn more!
          </Typography>
          <Box className="AboutVideoBox">
            <video width="100%" height="auto" controls="false" poster={Videoposter}>
              <source src={video} type="video/mp4"></source>
            </video>
          </Box>
          <Grid item xs={12} className="commonFieldBox">
            <InputLabel className="InputLabelFld" htmlFor="email">
              Send response to
            </InputLabel>
            <TextField
              // as={TextField}
              name="emailId"
              id="outlined-basic"
              variant="outlined"
              type="email"
              // error={!!showErrIfTouched('emailId', errors, touched)}
              // helperText={showErrIfTouched('emailId', errors, touched)}
              fullWidth
              value={userData?.emailId}
            />
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            className="NoBorderButton"
          >
            CANCEL
          </Button>
          <Button color="primary" variant="contained" onClick={sendRequest}>
            Send
          </Button>
        </MuiDialogActions>
      </Dialog>
      {/* End Delete any changes Modal */}
    </Formik>
  );
};
ModalRequestVideoConsultation.defaultProps = {};
export default ModalRequestVideoConsultation;
