/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik'; // Import useFormik
import { useParams, useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import {
  Grid,
  InputLabel,
  Typography,
  Box,
  TextField,
  Button,
  Link,
  Container,
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from '../../Main.style';
import useCommunityList from '../../../../hooks/useCommunityList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';

const CommunityEditViewEducation = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [userId] = useState(loggedInUserData?.id);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [schoolTypes, setSchoolTypes] = React.useState({
    publicType: false,
    privateType: false,
    faithBasedPrivate: false,
    specialNeedsPrivate: false,
    publicTechnicalJuniorCollege: false,
    privateTechnicalJuniorCollege: false,
    publicFourYearCollegeUniversity: false,
    privatecFourYearCollegeUniversity: false,
    homeschoolResourceGroups: false,
    charter: false,
  });
  const [communityDetails, setCommunityDetails] = React.useState(null);
  const [educationPreDesc, setEducationPreDesc] = React.useState('');
  const [educationPostDesc, setEducationPostDesc] = React.useState('');
  // to publish blank values while editing
  const [checkbox, setCheckBox] = React.useState(false);
  const [isEducationPreDesc, setIsEducationPreDesc] = React.useState(false);
  const [isEducationPostDesc, setIsEducationPostDesc] = React.useState(false);

  // to publish blank values while editing
  const [isEdited, setIsEdited] = useState(false);
  const maxCharacterCountPre = 700;
  const maxCharacterCountPost = 700;
  const { addCommunityCatEducation, getCommunityDetailById } = useCommunityList();
  const params = useParams();
  const navigate = useNavigate();
  const [isAccessible, setIsAccessible] = React.useState('');

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
  };

  const handlePreDescChange = (event) => {
    const { value } = event.target;
    setEducationPreDesc(value);
    setIsEdited(true);
  };
  const handlePostDescChange = (event) => {
    const { value } = event.target;
    setEducationPostDesc(value);
    setIsEdited(true);
  };

  const handleSchoolTypesChange = (event) => {
    const { name, checked } = event.target;
    setIsEdited(true);
    setSchoolTypes((prevOthrTrans) => ({
      ...prevOthrTrans,
      [name]: checked,
    }));
  };

  const {
    publicType,
    privateType,
    faithBasedPrivate,
    specialNeedsPrivate,
    publicTechnicalJuniorCollege,
    privateTechnicalJuniorCollege,
    publicFourYearCollegeUniversity,
    privatecFourYearCollegeUniversity,
    homeschoolResourceGroups,
    charter,
  } = schoolTypes;

  const formik = useFormik({
    initialValues: {
      communityId: params?.id,
      repId: loggedInUserData?.id,
      educationPreDesc: educationPreDesc,
      educationPostDesc: educationPostDesc,
      publicType: schoolTypes.publicType,
      privateType: schoolTypes.privateType,
      faithBasedPrivate: schoolTypes.faithBasedPrivate,
      specialNeedsPrivate: schoolTypes.specialNeedsPrivate,
      publicTechnicalJuniorCollege: schoolTypes.publicTechnicalJuniorCollege,
      privateTechnicalJuniorCollege: schoolTypes.privateTechnicalJuniorCollege,
      publicFourYearCollegeUniversity: schoolTypes.publicFourYearCollegeUniversity,
      privatecFourYearCollegeUniversity: schoolTypes.privatecFourYearCollegeUniversity,
      homeschoolResourceGroups: schoolTypes.homeschoolResourceGroups,
      charter: charter,
      isAdminEntry: loggedInUserData?.roleId === 2,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const trimmedEducationPreDesc = values?.educationPreDesc?.trim();
        values['educationPreDesc'] = trimmedEducationPreDesc;

        const trimmedEducationPostDesc = values?.educationPostDesc?.trim();
        values['educationPostDesc'] = trimmedEducationPostDesc;
        const response = await addCommunityCatEducation(values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response); // The response from the API
        formik.resetForm(); // Reset the form after successful submission
        navigate(`../../community-details/${params?.id}#Section10`);
        // Do something with the response, e.g., show a success message
      } catch (error) {
        console.error(error.response.data); // Log any error response from the API
        // Handle error, e.g., show an error message
      }
    },
  });

  const loadCommunityDetails = async () => {
    const result = await getCommunityDetailById({ communityId: params?.id });
    console.log(result.data, 2000);
    if (result) {
      setCommunityDetails(result.data);
      setEducationPostDesc(result?.data?.education?.[0]?.postDesc || '');
      setEducationPreDesc(result?.data?.education?.[0]?.preDesc || '');
      setSchoolTypes({
        publicType: result?.data?.education?.[0]?.publicType || false,
        privateType: result?.data?.education?.[0]?.privateType || false,
        faithBasedPrivate: result?.data?.education?.[0]?.faithBasedPrivate || false,
        specialNeedsPrivate: result?.data?.education?.[0]?.specialNeedsPrivate || false,
        publicTechnicalJuniorCollege:
          result?.data?.education?.[0]?.publicTechnicalJuniorCollege || false,
        privateTechnicalJuniorCollege:
          result?.data?.education?.[0]?.privateTechnicalJuniorCollege || false,
        publicFourYearCollegeUniversity:
          result?.data?.education?.[0]?.publicFourYearCollegeUniversity || false,
        privatecFourYearCollegeUniversity:
          result?.data?.education?.[0]?.privatecFourYearCollegeUniversity || false,
        homeschoolResourceGroups: result?.data?.education?.[0]?.homeschoolResourceGroups || false,
        charter: result?.data?.education?.[0]?.charter || false,
      });
    }
  };
  useEffect(() => {
    loadCommunityDetails();
  }, []);

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (isEdited) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${params?.id}#Section10`);
    }
  };
  useEffect(() => {
    loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2
      ? setIsAccessible(true)
      : setIsAccessible(false);
  }, [loggedInUserData]);

  function areObjectsEqual(obj1, obj2) {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }
    return true;
  }

  useEffect(() => {
    if (communityDetails) {
      let checkPreDesc = false;
      let checkPostDesc = false;
      let checkSchoolTypes = false;

      if (communityDetails?.education?.[0]?.['preDesc'] == educationPreDesc) {
        checkPreDesc = true;
      }
      if (communityDetails?.education?.[0]?.['postDesc'] == educationPostDesc) {
        checkPostDesc = true;
      }

      checkSchoolTypes = areObjectsEqual(communityDetails?.education?.[0], schoolTypes);

      if (checkSchoolTypes && checkPostDesc && checkPreDesc) {
        setIsEdited(false);
      }
      //console.log(checkSchoolTypes, 1002, checkPostDesc, 1003, checkPreDesc, 1004);
    }
  }, [educationPreDesc, educationPostDesc, schoolTypes]);
  return (
    <Container>
      <Box className="PageSpacing FixedMD">
        {isAccessible ? (
          <>
            {showDeleteBox && (
              <>
                <ModalEditViewDelete
                  status={true}
                  navigateUrl={`../../community-details/${params?.id}#Section10`}
                  onClose={onTakeMeback}
                ></ModalEditViewDelete>
              </>
            )}
            <Grid container>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" /> Education Insight
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container className="FixedID">
              <Grid item xs={12} className="CommonOption">
                <Box className="HeadingBoxCEV FlexCenterStart">
                  <Typography variant="body2" className="HeadingCEV">
                    School Types
                  </Typography>
                  <Typography variant="caption" className="HeadingCEVCaption">
                    Select all that apply
                  </Typography>
                </Box>
                <Typography variant="body2" className="SubtitleCEV">
                  Inputting this content helps users find your community when filtering.
                </Typography>
              </Grid>
              <Grid item xs={12} className="CommonOption">
                <Box className="CheckboxOption">
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.publicType}
                            onChange={handleSchoolTypesChange}
                            name="publicType"
                            // {...formik.getFieldProps('publicType')}
                          />
                        }
                        label="Public"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.privateType}
                            onChange={handleSchoolTypesChange}
                            name="privateType"
                            // {...formik.getFieldProps('privateType')}
                          />
                        }
                        label="Private"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.faithBasedPrivate}
                            onChange={handleSchoolTypesChange}
                            name="faithBasedPrivate"
                            // {...formik.getFieldProps('faithBasedPrivate')}
                          />
                        }
                        label="Faith based private"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.specialNeedsPrivate}
                            onChange={handleSchoolTypesChange}
                            name="specialNeedsPrivate"
                            // {...formik.getFieldProps('specialNeedsPrivate')}
                          />
                        }
                        label="Special needs private"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.publicTechnicalJuniorCollege}
                            onChange={handleSchoolTypesChange}
                            name="publicTechnicalJuniorCollege"
                            // {...formik.getFieldProps('publicTechnicalJuniorCollege')}
                          />
                        }
                        label="Public Technical / Junior college"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.privateTechnicalJuniorCollege}
                            onChange={handleSchoolTypesChange}
                            name="privateTechnicalJuniorCollege"
                            // {...formik.getFieldProps('privateTechnicalJuniorCollege')}
                          />
                        }
                        label="Private Technical / Junior college"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.publicFourYearCollegeUniversity}
                            onChange={handleSchoolTypesChange}
                            name="publicFourYearCollegeUniversity"
                            // {...formik.getFieldProps('publicFourYearCollegeUniversity')}
                          />
                        }
                        label="Public four year college / university"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.privatecFourYearCollegeUniversity}
                            onChange={handleSchoolTypesChange}
                            name="privatecFourYearCollegeUniversity"
                            // {...formik.getFieldProps('privatecFourYearCollegeUniversity')}
                          />
                        }
                        label="Private four year college / university"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.homeschoolResourceGroups}
                            onChange={handleSchoolTypesChange}
                            name="homeschoolResourceGroups"
                            // {...formik.getFieldProps('homeschoolResourceGroups')}
                          />
                        }
                        label="Homeschool Resource Group(s)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={schoolTypes.charter}
                            onChange={handleSchoolTypesChange}
                            name="charter"
                            // {...formik.getFieldProps('charter')}
                          />
                        }
                        label="Charter"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="Grid" mt={3}>
                  <Typography variant="body2" className="HeadingCEVCaption">
                    Pre-K through 12th Grade
                  </Typography>
                  <InputLabel className="InputLabelFld">
                    Describe your community’s Pre-K - 12th Grade schools. Are there any daycares,
                    private, special needs, or deaf and blind schools?
                  </InputLabel>
                </Box>
                <TextField
                  className="TextFieldTextarea"
                  fullWidth
                  multiline
                  minRows={8}
                  maxLength={700}
                  variant="outlined"
                  value={educationPreDesc}
                  onChange={handlePreDescChange}
                  // {...formik.getFieldProps('educationPreDesc')}
                  inputProps={{
                    maxLength: maxCharacterCountPre,
                  }}
                  helperText={`${maxCharacterCountPre - educationPreDesc.length}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="Grid" mt={3}>
                  <Typography variant="body2" className="HeadingCEVCaption">
                    Post-Secondary Education
                  </Typography>
                  <InputLabel className="InputLabelFld">
                    Describe your community’s post-secondary schools. Are there any trade schools,
                    community colleges, adult education, or universities?
                  </InputLabel>
                </Box>
                <TextField
                  className="TextFieldTextarea"
                  fullWidth
                  multiline
                  minRows={8}
                  maxLength={700}
                  label=""
                  variant="outlined"
                  value={educationPostDesc}
                  onChange={handlePostDescChange}
                  // {...formik.getFieldProps('educationPostDesc')}
                  inputProps={{
                    maxLength: maxCharacterCountPost,
                  }}
                  // helperText={`${maxCharacterCountPost - educationPostDesc.length}`}
                  helperText={`${maxCharacterCountPost - formik.values.educationPostDesc.length}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="Wrapper ButtonWrapper">
                  {console.log(formik.dirty, 9494)}
                  <Button
                    // disabled={
                    //    isEducationPreDesc && isEducationPostDesc
                    //     ? false
                    //     : educationPostDesc.length == 0 &&
                    //       educationPreDesc.length == ''

                    // }
                    // disabled={
                    //   !formik.dirty ||
                    //   (!isEducationPreDesc.length === 0 && !educationPostDesc.length === 0  && !isEdited)
                    // }

                    disabled={!formik.dirty && !isEdited}
                    onClick={formik.handleSubmit}
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="EditViewButton"
                    fullWidth
                  >
                    Publish
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          'You do not have permissions to access this url.'
        )}
      </Box>
    </Container>
  );
};
CommunityEditViewEducation.defaultProps = {};
export default CommunityEditViewEducation;
