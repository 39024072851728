/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  List,
  ListItem,
  Button,
  Typography,
  Box,
  Tab,
  Container,
  InputBase,
  InputAdornment,
  TextField,
  Popover,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../assets/images/Search-Icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { entityEnum } from '../../../constants/globalConstants';
import useCommunityList from '../../../hooks/useCommunityList';
import useAuth from '../../../hooks/useAuth';
import EmptyState from './EmptyState/EmptyState';
import '../Main.style.css';
// import useStyles from '../Main.style';
import CommunityCard from './CommunityCard/CommunityCard';
import CardLoader from '../../../components/CardLoader/CardLoader';
import Login from '../../../screens/Auth/Login/Login';
import DeclineInvitation from './DeclineInvitation';

const options = ['Filter 1', 'Filter 2', 'Filter 3', 'Filter 4'];
const ITEM_HEIGHT = 48;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const Invitation = (props) => {
  const [communityList, setCommunityList] = useState([]);
  const [userCommunities, setUserCommunities] = useState(null);
  const [favCommunities, setFavCommunities] = React.useState([]);
  const [recCommunities, setRecCommunities] = React.useState([]);
  const [searchedText, setSearchedText] = useState('');
  const [tabValue, setTabValue] = React.useState(props?.tabValue);
  const { invitationId } = useParams();
  let navigate = useNavigate();
  const { getAllCommunities, getCommunitiesByUser } = useCommunityList();
  const { getInviteDetails, getInviteDetailsById } = useAuth();
  let stateCommunitiesArr = [];
  let userCommunitiesArr = [];
  const favCommunitiesList = {};
  const recCommunitiesList = {};
  let filteredList = [];
  const [isLoading, setIsLoading] = useState(false);
  // const classes = useStyles();
  const key = 'iFrasCwdJh';

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [declineModal, setDeclineModal] = React.useState(false);
  const handleDeclineClose = () => {
    setDeclineModal(false);
  };

  const [changeTabSignUp, setChangeTabSignUp] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);

  const createFavListObject = () => {
    if (userId !== null && userId !== undefined) {
      const userCommunitiesObject = userCommunitiesArr.usersResult;
      const communityListByStateObject = stateCommunitiesArr.usersResult;

      if (userCommunitiesObject) {
        const mergeArr = [];
        if (communityListByStateObject !== undefined) {
          for (let i = 0; i < communityListByStateObject.length; i += 1) {
            mergeArr.push({
              ...communityListByStateObject[i],
              ...userCommunitiesObject.find(
                (itmInner) => itmInner.communityMapId.id === communityListByStateObject[i].id,
              ),
            });
          }
        }

        if (mergeArr.length > 0) {
          mergeArr.forEach((elem) => {
            recCommunitiesList[
              `${elem?.communityMapId?.id === undefined ? elem?.id : elem?.communityMapId?.id}`
            ] = elem.isRecommended !== undefined ? Boolean(elem.isRecommended) : false;
            favCommunitiesList[
              `${elem?.communityMapId?.id === undefined ? elem?.id : elem?.communityMapId?.id}`
            ] = elem.isFavourite !== undefined ? Boolean(elem.isFavourite) : false;
          });
        }
        setFavCommunities(favCommunitiesList);
        setRecCommunities(recCommunitiesList);
      }
    }
    return favCommunitiesList;
  };

  const communityFilterFunction = (communityArray, _tabValue, searchedTextParam) => {
    switch (_tabValue) {
      case 0: {
        filteredList = communityArray;
        break;
      }
      case 1: {
        if (userCommunities !== null && userCommunities.length > 0) {
          const userCommObj = userCommunities.reduce(
            (acc, cur) => ({ ...acc, [cur?.communityMapId?.id]: cur?.readAt }),
            {},
          );

          communityArray
            .sort((a, b) => {
              return (
                new Date(userCommObj[b.id] || '1900-01-01') -
                new Date(userCommObj[a.id] || '1900-01-01')
              );
            })
            .forEach((clbs) => {
              if (userCommObj[clbs?.id] !== undefined) {
                /* eslint-disable no-param-reassign */
                clbs.readAt = userCommObj[clbs?.id];
                /* eslint-enable no-param-reassign */
              }
            });

          filteredList = communityArray;
        }
        break;
      }
      case 2: {
        if (userCommunities !== null && userCommunities.length > 0) {
          filteredList = communityArray.filter((clbs) => favCommunities[clbs.id] === true);
        }

        break;
      }
      case 3: {
        if (userCommunities !== null && userCommunities.length > 0) {
          filteredList = communityArray.filter((clbs) => {
            return recCommunities[clbs.id] === true;
          });
        }
        break;
      }
      default: {
        break;
      }
    }

    return filteredList.filter((clbs) => {
      return (
        clbs?.communityNameText?.toLowerCase().indexOf(searchedTextParam.toLowerCase()) !== -1 ||
        clbs?.stateCommunities?.stateName
          ?.toLowerCase()
          .indexOf(searchedTextParam.toLowerCase()) !== -1
      );
    });
  };

  const fillCommunityList = async () => {
    if (stateCommunitiesArr.length === 0) {
      stateCommunitiesArr = await getAllCommunities();
      createFavListObject();
      setCommunityList(stateCommunitiesArr?.usersResult);
    }
    return stateCommunitiesArr?.usersResult;
  };
  const fillUserCommunities = async () => {
    if (userId !== null && userId !== undefined) {
      if (userCommunitiesArr.length === 0) {
        userCommunitiesArr = await getCommunitiesByUser({
          userId,
          typeText: entityEnum[0],
        });
        createFavListObject();
        setUserCommunities(userCommunitiesArr?.usersResult);
      }
    }
    return userCommunitiesArr?.usersResult;
  };
  const callFilterFromChild = async () => {
    await fillCommunityList();
    await fillUserCommunities();
    communityFilterFunction(communityList, tabValue, searchedText);
  };

  const handleChange = async (event, newValue) => {
    setIsLoading(true);
    setTabValue(newValue);
    setSearchedText('');
    setTimeout(async () => {
      setIsLoading(false);
      if (userId !== null && userId !== undefined) {
        // createFavListObject();
        await fillCommunityList();
        await fillUserCommunities();
        communityFilterFunction(communityList, tabValue, searchedText);
      }
    }, 200);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchChange = (event) => {
    setSearchedText(event.currentTarget.value);
    communityFilterFunction(communityList, tabValue, searchedText);
  };

  const clearSearch = () => {
    setSearchedText('');
    communityFilterFunction(communityList, tabValue, searchedText);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    fillCommunityList();
  }, []);

  useEffect(() => {
    fillUserCommunities();
  }, []);

  useEffect(() => {
    // createFavListObject();
  }, []);

  const [stateName, setStateName] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getInviteDetailsById({ invitationId });
        console.log(result, 'R');
        setStateName(result.data[0]?.crInvComMapId?.stateCommunities?.stateShortName);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect once when the component mounts.

  useEffect(() => {}, []);
  const [invitationDetails, setInvitationDetails] = useState(null);
  const _getInviteDetails = async () => {
    const invitationData = await getInviteDetails({ invitationId });
    setInvitationDetails(invitationData?.data?.id ? invitationData?.data : null);
    if (invitationData?.data?.invitationStatus === 'Declined') {
      navigate('/app/decline-Invitation-success');
    }
    if (['Expired', 'Accepted', 'Cancelled'].includes(invitationData?.data?.invitationStatus)) {
      navigate(
        `/app/invitation-link-expired/${invitationData?.data?.invitationStatus?.toLowerCase()}`,
      );
    }
  };
  useEffect(() => {
    if (invitationId) {
      _getInviteDetails();
    }
  }, [invitationId]);

  return (
    <Box className="FullWidthPattern">
      <Container>
        <Box className="PageSpacing FixedMD">
          {props?.showSearch && (
            <>
              {/* <img
              className="InvitationRightImage"
              src={require('../../../assets/images/InvitationRightImage.png').default}
              alt="background image"
            />
            <img
              className="InvitationTopImage"
              src={require('../../../assets/images/InvitationTopImage.png').default}
              alt="background image"
            />
            <img
              className="InvitationBottomImage"
              src={require('../../../assets/images/InvitationBottomImage.png').default}
              alt="background image"
            /> */}
              <Box className="InvitationWrapper">
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Box className="OnboardingBg">
                      <Box className="OnboardingImgContainer">
                        {/* <img
                      className=""
                      src={require('../../../assets/images/bg-blue1.png').default}
                      alt="background image"
                    /> */}
                        <img
                          className="HeartIcon"
                          src={require('../../../assets/images/InvitationIcon.svg').default}
                          alt="background image"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="InvitationTitleBox">
                    <Typography component="div" variant="h2" className="BlackColor InvitationTitle">
                      Welcome to Move to Happy!
                    </Typography>
                    <Typography
                      component="div"
                      variant="h2"
                      className="LightgreyColor InvitationSubTitle"
                    >
                      You have been selected to represent
                    </Typography>
                    <Typography
                      component="div"
                      variant="h2"
                      className="LightgreyColor InvitationName"
                    >
                      {invitationDetails?.crInvComMapId?.communityNameText || ''}, {stateName}.
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="InvitationContent">
                      <Typography variant="h4" className="InvitationHeading">
                        Community Representative Responsibilities
                      </Typography>
                      <List className="CommonListType">
                        <ListItem disableGutters>
                          <Typography variant="body1" component="div">
                            Create your Community Representative Profile
                          </Typography>
                        </ListItem>
                        <ListItem disableGutters>
                          <Typography variant="body1" component="div">
                            Provide an overview of your Community
                          </Typography>
                        </ListItem>
                        <ListItem disableGutters>
                          <Typography variant="body1" component="div">
                            Add and maintain insights for prospective residents and businesses about
                            your Community’s:
                          </Typography>

                          <List>
                            <ListItem disableGutters>
                              <Typography variant="body1" component="div">
                                Amenities
                              </Typography>
                            </ListItem>
                            <ListItem disableGutters>
                              <Typography variant="body1" component="div">
                                Population
                              </Typography>
                            </ListItem>
                            <ListItem disableGutters>
                              <Typography variant="body1" component="div">
                                Healthcare
                              </Typography>
                            </ListItem>
                            <ListItem disableGutters>
                              <Typography variant="body1" component="div">
                                Education
                              </Typography>
                            </ListItem>
                            <ListItem disableGutters>
                              <Typography variant="body1" component="div">
                                Climate
                              </Typography>
                            </ListItem>
                            <ListItem disableGutters>
                              <Typography variant="body1" component="div">
                                and more
                              </Typography>
                            </ListItem>
                          </List>
                        </ListItem>
                        <ListItem disableGutters>
                          <Typography variant="body1" component="div">
                            Ensure your Community’s profile maintains copyright compliance (all
                            text, images, descriptions, etc.)
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="Wrapper ButtonWrapper">
                      <Button
                        // disabled={isSubmitting || !dirty || !isValid}
                        // type="submit"
                        fullWidth
                        className="Button EditViewButton"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setOpen(true);
                          setChangeTabSignUp(1);
                        }}
                      >
                        Accept Invitation
                      </Button>
                      {/* {isSubmitting && <CircularProgress size={24} className="ButtonProgress" />} */}
                    </Box>
                    <Box component="div" display="flex" justifyContent="center" mt={2}>
                      <Link
                        // onClick={() => {
                        //   setDeclineModal(true);
                        // }}
                        to={`/app/decline-invitation/${invitationId}`}
                        underline="none"
                        className="DeleteAmenityButton LinkTypeButton RedLineButton"
                        color="secondary"
                        variant="text"
                      >
                        Decline Invitation
                      </Link>
                    </Box>
                    {/*  <Box mt={3} mb={3} justifyContent="center" display="flex">
                  <Link
                    className="LinkTypeButton RedLineButton"
                    to={`/app/decline-invitation/${invitationId}`}
                    // onClick={handleClose}
                  >
                    Decline Invitation
                  </Link>
                </Box> */}
                    {/* <Box mt={2}>
                  <Typography
                    variant="body2"
                    className="ForgotPassword DeclineLink"
                    onClick={() => {
                      setDeclineModal(true);
                    }}
                  > */}
                    {/* <Link
                    style={{ textDecoration: 'inherit' }}
                    className="ForgotPassword DeclineLink"
                    to={`/app/decline-invitation/${invitationId}`}
                  > */}
                    {/* Decline Invitation */}
                    {/*  </Link> */}
                    {/* </Typography>
                </Box> */}
                  </Grid>
                </Grid>
              </Box>
              <Dialog
                className="CustomDialog"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                disableEscapeKeyDown
                disableBackdropClick
              >
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={handleClose}
                  className="CustomDialogTitle"
                >
                  <IconButton className="CustomCloseIcon" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </DialogTitle>
                <DialogContent className="CustomDialogContent">
                  <Login changeTabSignUp={changeTabSignUp} handleClose={handleClose} />
                </DialogContent>
              </Dialog>
              <Dialog
                onClose={handleDeclineClose}
                aria-labelledby="customized-dialog-title"
                open={declineModal}
                className="CustomDialog"
                disableEscapeKeyDown
                disableBackdropClick
              >
                {/*  <DialogTitle
              id="customized-dialog-title"
              onClose={handleDeclineClose}
              className="CustomDialogTitle"
            >
              <IconButton className="CustomCloseIcon" onClick={handleDeclineClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </DialogTitle> */}
                <DialogContent className="CustomDialogContent">
                  {/* <DeclineInvitation
                    invitationId={invitationId}
                    handleDeclineClose={handleDeclineClose}
                  /> */}
                </DialogContent>
              </Dialog>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};
Invitation.defaultProps = {
  showSearch: true,
  tabValue: 0,
};
export default Invitation;
