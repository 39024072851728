/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Typography, Button } from '@material-ui/core';
// import useStyles from '../../Main/Main.style';
// import useCommunityList from '../../../../hooks/useCommunityList';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="CloseButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const ModalEditViewDelete = (props) => {
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(props.status);
  const onDeleteHandler = () => {
    // setOpen(true);
    navigate(props.navigateUrl);
  };
  console.log(open, 'OPEN');
  const handleClose = () => {
    setOpen(false);
    props.onClose(false);
  };

  useEffect(() => {}, []);
  return (
    <>
      {/* Start Delete any changes Modal */}
      <Dialog
        className="DeleteDialog"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogTitle
          className="DialogTitleLeft"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography variant="h4" component="div" className="DialogTitleHeading TextColor">
            Are you sure you want to delete any changes?
          </Typography>
          {/* <CloseIcon onClick={handleClose} /> */}
        </MuiDialogTitle>
        <MuiDialogContent>
          <Typography gutterBottom variant="body2" component="div">
            Not published content will be erased.You will not be able to recover it.
          </Typography>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            className="NoBorderButton"
          >
            No, Go Back
          </Button>
          <Button color="secondary" variant="contained" onClick={onDeleteHandler}>
            Yes, Delete
          </Button>
        </MuiDialogActions>
      </Dialog>
      {/* End Delete any changes Modal */}
    </>
  );
};
ModalEditViewDelete.defaultProps = {};
export default ModalEditViewDelete;
