/* eslint-disable*/
import React, { useState } from 'react';
import path from 'path';
import {
  withStyles,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Typography,
  IconButton,
  Grid,
  Box,
  Link,
  Divider,
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import { Share } from '@material-ui/icons/';
import { Alert } from '@material-ui/lab/';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { Link } from "react-router-dom";

import { ReactComponent as FbIcon } from '../../../assets/social-sharing-icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/images/TwitterxIcon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/social-sharing-icons/email.svg';
import { ReactComponent as CopyIcon } from '../../../assets/social-sharing-icons/copy.svg';
// import useStyles from './ShareCommunity.styles';
import './ShareCommunity.styles.css';
import { backendUrl } from '../../../constants/globalConstants';
import ShareCommunityThroughEmail from './ShareCommunityThroughEmail';

const ShareCommunity = ({ communityId, communityName }) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  // const classes = useStyles();
  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const url = `${window.location.origin}/#/app/community-details/${communityId}`;
  const shareUrl = `${backendUrl}share-community/${communityId}`;
  return (
    <>
      <Button className="LinkButton">
        <img
          communityId={communityId}
          className="ShareCommunityIcon"
          src={require('../../../assets/images/Share.svg').default}
          alt="Badge Icon"
          onClick={handleClickOpen}
        />
      </Button>

      <Dialog
        className=""
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Snackbar open={show} autoHideDuration={2000} onClose={() => setShow(false)}>
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity="success"
            icon={<CheckCircleIcon color="primary" fontSize="inherit" />}
            action={
              <Link
                color="primary"
                underline="none"
                aria-label="close"
                onClick={handleClose}
                className="AlertDismissButton"
              >
                Dismiss
              </Link>
            }
          >
            Copied link successfully.
          </Alert>
        </Snackbar>
        <Box className="ShareDialog" onClick={(e) => e.stopPropagation()}>
          <Grid
            className="ShareHeading"
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="ShareTitle" variant="h4" noWrap component="span">
              Share this Community
            </Typography>
            <Button onClick={handleClose} className="LinkTypeButton" color="primary">
              {/* <CloseIcon fontSize="small" /> */}
              Cancel
            </Button>
          </Grid>
          <DialogContent className="CustomDialogContent">
            <Box className="ShareContentBox">
              <Box className="ShareContent" textAlign="center">
                <a
                  target="_blank"
                  /* to={{
                    pathname: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      shareUrl,
                    )}`,
                  }} */

                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    shareUrl,
                  )}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box className="ShareIcon" textAlign="center">
                    <FbIcon />
                  </Box>
                </a>

                <Typography className="IconTitle" variant="caption" component="div">
                  Facebook
                </Typography>
              </Box>
              <Box className="ShareContent" textAlign="center">
                <a
                  target="_blank"
                  /* to={{
                    pathname: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      shareUrl,
                    )}`,
                  }} */

                  href={`https://twitter.com/intent/tweet/?text=${`Check out ${communityName}!`}&url=${encodeURIComponent(
                    shareUrl,
                  )}&via=MoveToHappy23`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box className="ShareIcon" textAlign="center">
                    <TwitterIcon />
                  </Box>
                </a>
                <Typography className="IconTitle" variant="caption" component="div">
                  Twitter
                </Typography>
              </Box>
              <Box className="ShareContent" textAlign="center">
                <ShareCommunityThroughEmail communityId={communityId} closeShareModal={setOpen} />
                <Typography className="IconTitle" variant="caption" component="div">
                  Email
                </Typography>
              </Box>
            </Box>
            <Divider className="ShareDivider" />
            <Box variant="h3">
              <Typography className="copyTitle">Or copy link</Typography>
              <Box className="copyLinkBox" mt={1}>
                <Box className="copyLinkText">
                  <Typography>{url}</Typography>
                  <CopyToClipboard text={url} onCopy={() => setShow(true)}>
                    <CopyIcon className="copyIcon" onClick={(e) => e.stopPropagation()} />
                  </CopyToClipboard>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default ShareCommunity;
