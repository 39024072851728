/* eslint-disable*/
import React from 'react';
import { Modal, Button, Box, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import StarIcon from '@material-ui/icons/Star';
import './CommunityBadge.style.css';
// import useStyles from '../CommunityBadge/CommunityBadge.styles';
const CommunityBadge = (props) => {
  // const classes = useStyles();
  return (
    <>
      <Box className="BadgeBox">
        {props?.badgeName === 'represented' ? (
          <>
            <Box className="represented CommonBadgeInner">
              <img
                className="BadgeIcon RepresentedBadgeIcon"
                src={require('../../../../assets/images/Badge-Icon.svg').default}
                alt="Badge Icon"
              />
              {/* <img
                className="BadgeIcon RepresentedBadgeIconWhite"
                src={require('../../../../assets/images/Badge-Icon-White.svg').default}
                alt="Badge Icon"
              /> */}
              <Typography variant="h6" component="div" className="BadgeHeading">
                Represented Community
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box className="recommended RecommendedBadgeInner">
              <StarIcon fontSize="small" className="RecommendedIcon" />
              <Typography variant="h6" component="div" className="BadgeHeading">
                MTH Recommended
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default CommunityBadge;
