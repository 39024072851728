/* eslint-disable react/prop-types */
/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, IconButton, Box, Typography, List, ListItem } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
// import { isFile } from '../../utils';
import { ReactComponent as VideoIcon } from '../../../assets/images/VideoIcon.svg';
import { ReactComponent as VideoSuccessIcon } from '../../../assets/images/VideoSuccessIcon.svg';
import { ReactComponent as VideoErrorIcon } from '../../../assets/images/VideoErrorIcon.svg';
import { ReactComponent as DeleteFilledIcon } from '../../../assets/images/DeleteFilled.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import fileType from 'file-type';

const maxFileSize = 2097152; // Maximum file size in bytes (e.g., 2MB)
const acceptedFileTypes = ['video/mp4', 'video/webm', 'video/quicktime']; // Accepted file types
const Dropzone = ({
  // field: { name, value },
  // form: { setFieldValue, touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  dropzonenote,
  thumb,
  thumbInner,
  img,
  id,
  onFileUpload,
  ...props
}) => {
  // const error = getIn(touched, name) && getIn(errors, name);
  const [state, setState] = useState({
    show: false,
    message: '',
  });
  const { show, message } = state;
  const handleClose = () => {
    setState({ ...state, show: false });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const [checkFile, setcheckFile] = useState(null);
  const [wrongFile, setWrongFile] = useState(false);
  console.log(selectedFile, 'SF');
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (file.size <= maxFileSize && acceptedFileTypes.includes(file.type)) {
          setSelectedFile(file);
          onFileUpload(true);
          onFileUpload(file);
        } else if (file.size > maxFileSize || !acceptedFileTypes.includes(file.type)) {
          setSelectedFile(null);
          setcheckFile(file);
          setWrongFile(true);
          onFileUpload(false);
          // The file size is greater than maxFileSize OR the file type is not in acceptedFileTypes
          // This is the condition for an incorrect file
        }
      }
    },

    ...props,
  });
  console.log('selectedFile.size: ', selectedFile?.size);
  console.log('maxFileSize: ', maxFileSize);
  console.log('selectedFile.type: ', selectedFile?.type);
  console.log('acceptedFileTypes: ', acceptedFileTypes);
  console.log(
    'acceptedFileTypes.indexOf(selectedFile.type): ',
    acceptedFileTypes.indexOf(selectedFile?.type),
  );
  const isDropContainerVisible = selectedFile === null && !wrongFile;
  let errorText = ''; // Variable to store the error message
  let fileSizeMB = 0;

  if (checkFile && wrongFile) {
    if (checkFile?.size > maxFileSize) {
      errorText = 'File too large. Failed';
    } else if (!acceptedFileTypes.includes(checkFile?.type)) {
      errorText = 'Invalid File Type. Failed';
    }
    fileSizeMB = (selectedFile?.size / (1024 * 1024)).toFixed(2); // Convert to MB and round to 2 decimal places
  }

  return (
    <FormControl className="DropWrapper">
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid container direction="row" justifyContent="center" alignItems="flex-start">
            {isDropContainerVisible && (
              <Grid item xs={12} className="DropContainer">
                <div className="dropzonestyle" {...getRootProps({})}>
                  <input {...getInputProps()} id="dropped" />
                  <div className="dropzonetitle">
                    <VideoIcon className="DropzoneIcon" />
                    <Box component="div">
                      <Typography variant="body1" className="Dropzonetext" component="span">
                        Drag and drop a video, or
                      </Typography>
                      <Typography
                        className="BrowseButton"
                        type="button"
                        variant="body1"
                        onClick={open}
                        component="span"
                      >
                        Browse
                      </Typography>
                    </Box>
                  </div>
                  <Typography variant="caption" className="DropzoneInfo">
                    Maximum file size of 2MB. MP4, WEBM, and .MOV accepted
                  </Typography>
                  <Box>
                    <List className="DropzoneNote">
                      <ListItem>
                        <Typography variant="caption">1080p or 720p resolution</Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="caption">16:9 or 3:2 aspect ratio</Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="caption">1 minute or less</Typography>
                      </ListItem>
                    </List>
                    <List className="DropzoneNote">
                      <ListItem>
                        <Typography variant="caption">Alcohol references are prohibited</Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="caption">
                          No media with copyright restrictions
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              {selectedFile && (
                <Box className="DropzoneSuccess">
                  <Box className="UploadMessageBox">
                    <VideoSuccessIcon className="VideoSuccess" />
                    <Box className="VideoSuccessInfo">
                      <Typography
                        variant="body2"
                        component="div"
                        className="VideoHeading BlackColor"
                      >
                        {selectedFile.name} {/* Display the file name */}
                      </Typography>
                      <Typography variant="caption" component="div">
                        {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB . Complete
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="DropzoneActionButton">
                    <IconButton
                      onClick={() => {
                        setSelectedFile(null);
                        onFileUpload(false); // Set the file as incorrect
                      }}
                    >
                      <DeleteFilledIcon />
                    </IconButton>
                    <IconButton>
                      <CheckCircleIcon className="SuccessColor" />
                    </IconButton>
                  </Box>
                </Box>
              )}

              {wrongFile && (
                <Box className="DropzoneSuccess">
                  <Box className="UploadMessageBox">
                    <VideoErrorIcon className="VideoSuccess" />
                    <Box className="VideoSuccessInfo">
                      <Typography
                        variant="body2"
                        component="div"
                        className="VideoHeading ErrorColor"
                      >
                        Oops! Video upload issue. Check format and size. If problems persist, please
                        contact our support team. We’ll get this sorted together! 
                      </Typography>
                      <Typography variant="caption" component="div" className="ErrorColor">
                        {errorText}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="DropzoneActionButton">
                    <IconButton onClick={() => setWrongFile(false)}>
                      <DeleteFilledIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormControl>
  );
};

Dropzone.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  dropzonenote: PropTypes.string,
};
Dropzone.defaultProps = {
  dropzonenote: '',
};
export default Dropzone;
