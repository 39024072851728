/* eslint-disable  */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useCommunityList from '../../../hooks/useCommunityList';
import useLogin from '../../../../src/hooks/useAuth';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import {
  Grid,
  List,
  ListItem,
  Button,
  Typography,
  InputLabel,
  Box,
  Tab,
  Container,
  InputBase,
  InputAdornment,
  TextField,
  Popover,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';

const AdminEditApproval = () => {
  const { approveEditVideoRequest } = useCommunityList();
  const { requestId } = useParams();
  const [approvalStatus, setApprovalStatus] = useState(null);

  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const { getRoleData } = useLogin();
  const [userData, setUserData] = useState();
  const [userId] = useState(loggedInUserData?.id);

  const isFirstRender = useRef(true);
  const apiCallDone = useRef(false); // Track if the API call is done

  useEffect(() => {
    // Ensure that we have user data before making the API call
    if (isFirstRender.current && userCookie && userId) {
      const getUserData = async () => {
        const userDataResult = await getRoleData({ userId });
        setUserData(userDataResult.usersResult);
        console.log(userDataResult, 'GET');
      };
      getUserData();
      isFirstRender.current = false;
    }
  }, [userCookie, getRoleData, userId]);

  useEffect(() => {
    // Ensure that we have both "action" and "communityRepEmail" before making the API call
    if (userData?.emailId && requestId && !apiCallDone.current) {
      // First, set the action to 'approve'
      approveEditVideoRequest({
        requestId: requestId,
        action: 'approve',
        communityRepEmail: userData.emailId,
        userName: userData.firstName,
      })
        .then((response) => {
          if (response.status === true) {
            setApprovalStatus('Approved');
          } else {
            setApprovalStatus('Not Approved');
          }
        })
        .catch((error) => {
          console.error('Approval failed:', error);
          setApprovalStatus('Approval Failed');
        });

      apiCallDone.current = true; // Mark the API call as done
    }
  }, [approveEditVideoRequest, requestId, userData?.emailId]);

  return (
    <Box className="FullWidthPattern">
      <Container>
        <Box className="PageSpacing FixedMD">
          <Box className="InvitationWrapper">
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h2" component="div">
                    {approvalStatus === 'Approved' ? <div>Approved</div> : <div></div>}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminEditApproval;
