/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ToggleButton from '@material-ui/lab/ToggleButton';
import PropTypes from 'prop-types';
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useCommunityList from '../../../../hooks/useCommunityList';
import CommunityBadge from '../CommunityBadge/CommunityBadge';
import HomeIcon from '../../../../assets/images/House-Icon.svg';
import { ReactComponent as PopulationIcon } from '../../../../assets/images/Population-Icon.svg';
import Login from '../../../Auth/Login/Login';
import CardLoader from '../../../../components/CardLoader/CardLoader';
import ShareCommunity from '../../ShareCommunity/ShareCommunity';
import { backendUrl } from '../../../../constants/globalConstants';

const CommunityCard = ({ cm, listView, tabValue, callFilterFromChild, updateFavObject }) => {
  const [isFav, setIsFav] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const { addCommunityAsFavourite, getStatusById } = useCommunityList();
  const [isLoading, setIsLoading] = useState(true);
  const key = 'iFrasCwdJh';
  const userCookie = Cookies.get('user');

  const [open, setOpen] = React.useState(false);
  const [changeTabSignUp, setChangeTabSignUp] = React.useState(0);
  let bytes = null;
  let loggedInUserData = null;
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);

  const navigate = useNavigate();
  // const { cm, listView, updateFavObject } = props;
  const cmObj = { cm }.cm;

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <div className="text">
        {isReadMore ? text.slice(0, 140) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? '...see more' : ' show less'}
        </span>
      </div>
    );
  };

  ReadMore.PropTypes = {
    children: PropTypes.string.isRequired,
  };

  const handleClickOpen = (tabs) => {
    setOpen(true);
    setChangeTabSignUp(tabs);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFavToggle = async (communityId) => {
    if (userId !== null && userId !== undefined) {
      const favResult = await addCommunityAsFavourite({
        communityId,
        userId,
        typeText: 'community',
      });

      if (favResult) {
        setIsFav(favResult?.responseData?.isFavourite);

        if (!location.href.indexOf('community-details')) {
          updateFavObject();
        }

        if (tabValue === 2) {
          await callFilterFromChild();
          setIsFav(true);
        }
      }
    } else {
      handleClickOpen(0);
    }
  };

  const setCommunityStatus = async () => {
    if (userId !== null && userId !== undefined) {
      const favResult = await getStatusById({ communityId: cmObj.id, userId });

      setIsFav(favResult?.usersResult?.isFavourite);
      setIsRecommended(favResult?.usersResult?.isRecommended);
    }
  };

  const handleCardClick = (communityId, favStatus) => {
    if (location.href.indexOf('community-details') < 0) {
      navigate(`../community-details/${communityId}`, {
        state: { isFavourite: favStatus },
      });
    }
  };

  useEffect(() => {
    if (typeof userId !== 'undefined' || userId !== null) {
      setCommunityStatus();
      // updateFavObject();
    }
  }, [tabValue, cmObj?.id]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, []);
  return (
    <>
      <Dialog
        className="CustomDialog"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="CustomDialogTitle"
        >
          <IconButton className="CustomCloseIcon" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="CustomDialogContent">
          <Login changeTabSignUp={changeTabSignUp} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
      {cmObj &&
        (isLoading ? (
          <CardLoader />
        ) : (
          <Grid key={cmObj.id} xs={12} sm={6} md={4} lg={4} className="CommonCardColumn">
            <Card className="CommonCard FullCommonCard">
              {cmObj?.isRepresented ? (
                <>
                  <CardMedia
                    className="CommonCardBanner"
                    image={backendUrl + cmObj?.images}
                    title={cmObj.communityNameText}
                    onClick={() => {
                      handleCardClick(cmObj?.id, isFav);
                    }}
                  >
                    {Boolean(isRecommended) && <CommunityBadge badgeName="reccomended" />}
                  </CardMedia>
                </>
              ) : (
                <>
                  <Box
                    className="CommonCardImgBox"
                    onClick={() => {
                      handleCardClick(cmObj?.id, isFav);
                    }}
                    style={{
                      backgroundImage: backendUrl + cmObj?.images,
                    }}
                  ></Box>
                </>
              )}
              <CardContent>
                <Grid container>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <Box className="ToggleButtonBox">
                      <Box>
                        <Choose>
                          <When condition={{ listView }}>
                            <ToggleButton
                              onClick={() => {
                                handleFavToggle(cmObj?.id);
                              }}
                              value="check"
                              selected={isFav}
                              className="FavoriteToggleButton"
                            >
                              <FavoriteTwoToneIcon
                                style={{
                                  color: isFav ? '#F6AF9F' : '#486877',
                                }}
                              />
                            </ToggleButton>
                          </When>
                          <When condition={!{ listView }}>
                            <ToggleButton
                              onClick={() => {
                                handleFavToggle(cmObj?.id);
                              }}
                              value="check"
                              selected={isFav}
                              className="FavoriteToggleButton"
                            >
                              <FavoriteTwoToneIcon
                                style={{
                                  color: isFav ? '#F6AF9F' : '#486877',
                                }}
                              />
                            </ToggleButton>
                          </When>
                        </Choose>
                      </Box>
                      <Box ml={2}>
                        <ShareCommunity
                          communityId={cmObj?.id}
                          communityName={cmObj?.communityNameText}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="div" className="StateNameHeading">
                      {cmObj?.stateCommunities?.stateName}
                    </Typography>
                    <Box className="CommunityCityBox">
                      <Typography className="CityNameHeading" variant="h2" component="div">
                        {cmObj?.cityCommunitiesInState?.cityName}
                      </Typography>
                      {cmObj?.isRepresented && <CommunityBadge badgeName="represented" />}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box className="CountBox">
                          <PopulationIcon className="CountIcon" />
                          <Box className="CountContent">
                            <Typography className="Count" variant="body1" component="div">
                              {cmObj.population.toLocaleString()}
                            </Typography>
                            <Typography
                              className="CountHeading CountHeadingPopulation"
                              variant="caption"
                              component="div"
                            >
                              Population
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box className="CountBox">
                          <img className="HomeIcon" alt="thumbnail" src={HomeIcon} />

                          <Box className="CountContent">
                            <Typography className="Count" variant="body1" component="div">
                              ${cmObj.medianHomePrice.toLocaleString()}
                            </Typography>
                            <Typography className="CountHeading" variant="caption" component="div">
                              Median Home Listing Price
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {cmObj?.isRepresented && { listView } && (
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="CityNameSubheading"
                      >
                        About {cmObj?.cityCommunitiesInState?.cityName}
                      </Typography>
                      <Typography variant="body2" component="div">
                        {cmObj?.aboutDesc?.length > 140 ? (
                          <ReadMore className="OrangeColor">{cmObj?.aboutDesc}</ReadMore>
                        ) : (
                          cmObj?.aboutDesc
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
    </>
  );
};
// CommunityCard.defaultProps = {
//   cm: null,
//   listView: null,
//   updateFavObject: null,
// };
CommunityCard.PropTypes = {
  cm: PropTypes.object.isRequired,
  listView: PropTypes.Boolean,
  tabValue: PropTypes.bool.isRequired,
  updateFavObject: PropTypes.func,
  callFilterFromChild: PropTypes.func,
};
export default CommunityCard;
