/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Typography, Box, Paper, Avatar } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import Carousel from "react-simply-carousel";
import Carousel from 'react-material-ui-carousel';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useCommunityList from '../../../../hooks/useCommunityList';
import Residents from '../../Residents/Residents';
import InsightsBy from '../../InsightsBy/InsightsBy';
import AboutCommunity from '../../AboutCommunity/AboutCommunity';
import CostOfLiving from '../../CostOfLiving/CostOfLiving';
import Amenities from '../../Amenities/Amenities';
import Jobs from '../../Jobs/Jobs';
import CommunityCard from '../../CommunityList/CommunityCard/CommunityCard';
import CommunityMap from './../CommunityMap/CommunityMap';
import HouseList from '../../HouseList/HouseList';
import ArrowLeft from '../../../../assets/images/Arrow-Left.png';
import ArrowRight from '../../../../assets/images/Arrow-Right.png';
import { ReactComponent as PersonIcon } from '../../../../assets/images/User.svg';

const CommunityDetailPublicView = (props) => {
  const key = 'iFrasCwdJh';
  const [communityDetails, setCommunityDetails] = useState(null);
  const { getCommunityDetailById } = useCommunityList();
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);
  const loc = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const fillCommunityDetails = async () => {
    let communityDetailsResults = [];
    if (params?.id) {
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults.data);
    }
    return communityDetailsResults?.data;
  };

  useEffect(() => {
    fillCommunityDetails();
  }, []);
  const [activeSlide, setActiveSlide] = useState(0);
  var items = [
    {
      Image: 'assets/amenities/Slide.jpg',
    },
    {
      Image: 'assets/amenities/Slide.jpg',
    },
  ];
  return (
    <>
      {communityDetails ? (
        <Box component="div" className="communityDetailsContainer">
          <Box
            component="div"
            className="communityDetailsBannerbg"
            style={{
              backgroundImage: `url(
                      ${require(`../../../../assets/images/map-full.jpg`).default}
                    )`,
            }}
          ></Box>
          {/* <Paper className="BackButton">
            <Box className="FlexCenter">
              <ArrowBackIcon color='primary' />
              <Link
                className="BackButtonLink"
                onClick={() => {
                  navigate('../../community-list');
                }}
              >
                {communityDetails?.communityNameText}
              </Link>
            </Box>
            <MoreHorizIcon className="GreenColor" />
          </Paper> */}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="communityDetailsContent"
          >
            {/* <Grid item xs={12} sm={2} className="communityDetailsSidebar">
              <Box className="SidebarContent">
                <ul className="nav navbar-nav">
                  <li>
                    <Link
                      activeClass="active"
                      className="Section1"
                      to="Section1"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-110}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      className="Section3"
                      to="Section3"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-110}
                    >
                      Residents
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      className="Section3"
                      to="Section3"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-110}
                    >
                      Amenities
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      className="Section4"
                      to="Section4"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-110}
                    >
                      Cost of Living
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      className="Section5"
                      to="Section5"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-110}
                    >
                      Map
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      className="Section6"
                      to="Section6"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-110}
                    >
                      Jobs
                    </Link>
                  </li>
                </ul>
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={10}>
              <Box component="div" className="ScrollerBoxCD">
                <Choose>
                  <When condition={!communityDetails?.isRepresented}>
                    <Box key={communityDetails?.id}>
                      <Element name="">
                        <Box
                          className="CardBannerSection"
                          style={{
                            backgroundImage:
                              window.location.hostname === 'localhost'
                                ? `url(//localhost:9001${communityDetails?.images})`
                                : `url(/api${communityDetails?.images})`,
                          }}
                        ></Box>
                        <CommunityCard
                          cm={communityDetails}
                          listView={false}
                          favStatus={loc?.state?.isFavourite}
                        />
                      </Element>
                      <Element name="">
                        <InsightsBy communityInfo={communityDetails} userInfo={loggedInUserData} />
                      </Element>
                      <Element name="Section1">
                        <AboutCommunity />
                      </Element>
                      <Element name="Section2">
                        <Residents communityDetails={communityDetails} />
                      </Element>
                      <Element name="Section3">
                        <Amenities />
                      </Element>
                      <Element name="Section4">
                        <CostOfLiving communityDetails={communityDetails} />
                      </Element>
                      <Element name="Section5">
                        <CommunityMap />
                      </Element>
                      <Element name="Section6">
                        <Jobs communityDetails={communityDetails} />
                      </Element>
                    </Box>
                  </When>
                  <When condition={communityDetails?.isRepresented}>
                    <Element name="">
                      <Box
                        className="CardBannerSection"
                        style={{
                          backgroundImage:
                            window.location.hostname === 'localhost'
                              ? `url(//localhost:9001${communityDetails?.images})`
                              : `url(/api${communityDetails?.images})`,
                        }}
                      ></Box>
                      <CommunityCard
                        cm={communityDetails}
                        listView={false}
                        favStatus={loc?.state?.isFavourite}
                      />
                    </Element>
                    <Element name="">
                      <InsightsBy communityInfo={communityDetails} />
                    </Element>
                    <Element name="Section1">
                      <AboutCommunity communityInfo={communityDetails} />
                    </Element>
                    <Element name="Section2">
                      <Residents communityInfo={communityDetails} />
                    </Element>
                    <Element name="Section3">
                      <Amenities communityInfo={communityDetails} />
                    </Element>
                    <Element name="Section4">
                      <CostOfLiving communityDetails={communityDetails} />
                    </Element>
                    <Element name="Section5">
                      <CommunityMap />
                    </Element>
                    <Element name="Section6">
                      <Jobs communityDetails={communityDetails} />
                    </Element>
                    {/* <Climate></Climate> */}
                  </When>
                </Choose>
              </Box>

              <Paper className="WhitePaper HomesBoxCD" component="div">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h4" className="BoxHeadingSecond">
                      Homes In {communityDetails.communityNameText}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box className="CommonDetailBoxGrid">
                    <Box className="communityDetailsCarousel">
                      <HouseList showSearch={false} communityId={communityDetails?.id} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} className="ViewButton">
                  <Link
                    onClick={() => {
                      navigate(`../../houselist/${communityDetails?.id}`);
                    }}
                    underline="none"
                    color="primary"
                    className="RightFloatBtn"
                  >
                    View all homes in {communityDetails.communityNameText}
                    <Box component="span" marginLeft={1}>
                      <ArrowForwardIcon />
                    </Box>
                  </Link>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ) : (
        'No content to display'
      )}
    </>
  );
};

CommunityDetailPublicView.defaultProps = {};
export default CommunityDetailPublicView;
