/* eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import {
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Avatar,
  Divider,
  FormControlLabel,
  Checkbox,
  IconButton,
  Snackbar,
  Link,
} from '@material-ui/core';
import ShowMoreText from 'react-show-more-text';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import { ReactComponent as DividerMTHButterfly } from '../../../assets/images/Divider-MTH-Butterfly.svg';
import { ReactComponent as VideoIcon } from '../../../assets/images/VideoIcon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/TrashIcon.svg';
import video from './Trussville-video.mp4';
import Videoposter from '../../../assets/images/Video-poster.png';
import ModalUploadVideo from './ModalUploadVideo';
import useCommunityList from '../../../hooks/useCommunityList';
import useLogin from '../../../hooks/useAuth';
import ModalRequestVideoConsultation from './ModalRequestVideoConsultation';
import { ReactComponent as CheckOutlineIcon } from '../../../assets/images/CheckOutline.svg';
import { ReactComponent as WarningOutlineIcon } from '../../../assets/images/WarningOutline.svg';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import ModalRemoveVideo from './ModalRemoveVideo';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import ModalEditVideo from './ModalEditVideo';
import { Alert } from '@material-ui/lab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { backendUrl } from '../../../constants/globalConstants';

const AboutCommunity = (props) => {
  const {
    getVideoRequestData,
    readVideoNotification,
    getCustomVideoRequestData,
    markConsultVideoRequestAsRead,
  } = useCommunityList();

  const { getUserDataToAdmin } = useLogin();

  const params = useParams();
  let communityId = params?.id;
  //console.log(communityId, 'COMMU_ID');

  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [userData, setUserData] = useState();

  console.log(loggedInUserData?.roleId, 'LOG');

  const [userId] = useState(loggedInUserData?.id);
  //console.log(userId, 911);

  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const [videoConsultData, setVideoConsultData] = useState(null);
  const [isVideoApproved, setVideoApproved] = useState(true);
  const [isVideoRejected, setVideoRejected] = useState(true);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [isEditSnackbarOpen, setEditSnackbarOpen] = useState(false);
  const [isDeleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [isVideoConsultOpen, setVideoConsultOpen] = useState(false);
  const [isConsultBoxOpen, setConsultBoxOpen] = useState(true);
  const [editVideo, setEditVideo] = useState(false);
  const [uploadVideoMode, setUploadVideoMode] = useState('upload'); // Initialize with 'upload'
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [isAccessible, setIsAccessible] = useState(false);
  const [repDetails, setRepDetails] = useState();

  const handleVideoApprovalClose = async () => {
    // Function to close the video approval box
    const response = await readVideoNotification({ videoId: videoData.requestId }); // Use communityId
    if (response.status === true) {
      //console.log(response);
    }
    setVideoApproved(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseEditSnackbar = () => {
    setEditSnackbarOpen(false);
  };

  const handleCloseDeleteSnackbar = () => {
    setDeleteSnackbarOpen(false);
  };

  const handlePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  //console.log(videoData, 'VDT');

  const fetchVideoData = async () => {
    try {
      const response = await getVideoRequestData({ communityId: params?.id }); // Use communityId

      if (response?.status === true) {
        // Video data is available, you can use response.data to access it
        const data = response?.data;

        if (data?.repId === communityDetails?.userrole[0]?.id || data?.isAdminEntry)
          setVideoData(data);
      } else {
        // Handle API request errors
        //console.error('Failed to fetch video data');
        setVideoData(null);
      }
    } catch (error) {
      // Handle other errors, e.g., network issues or unexpected exceptions
      console.error('Async function error:', error);
    }
  };

  const getRepDetails = async () => {
    try {
      const userDataResult = await getUserDataToAdmin({ userIdd: communityDetails?.updatedBy });
      setRepDetails(userDataResult.usersResult);
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  const handleLoadedMetadata = () => {
    // After 1 second (1000 milliseconds), pause the video and reset its position
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }, 1000);
  };

  const fetchVideoConsultationData = async () => {
    try {
      const response = await getCustomVideoRequestData({ communityId: params?.id }); // Use communityId

      if (response.status === true) {
        // Video data is available, you can use response.data to access it
        const data = response.data;
        setVideoConsultData(data);
      } else {
        // Handle API request errors
        //console.error('Failed to fetch video data');
        setVideoConsultData();
      }
    } catch (error) {
      // Handle other errors, e.g., network issues or unexpected exceptions
      console.error('Async function error:', error);
    }
  };

  useEffect(() => {
    // Call the function when the component mounts
    fetchVideoData();
    fetchVideoConsultationData();
    getRepDetails();
  }, [communityId]); // Add communityId as a dependency

  const openModal = () => {
    setIsModalOpen(true);
    setUploadVideoMode('upload');
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal1 = () => {
    setIsModalOpen1(true);
  };
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };

  const openEditVideoModal = (videoId, videoURL) => {
    setEditVideo({ status: true, videoId, video: videoURL });
    setUploadVideoMode('change');
  };

  const closeEditVideoModal = () => {
    setEditVideo(false);
  };

  const handleVideoModeChange = (mode) => {
    setUploadVideoMode(mode);
  };

  const handleVideoRejectionClose = async () => {
    const response = await readVideoNotification({ videoId: videoData.requestId }); // Use communityId
    if (response.status === true) {
      //console.log(response);
    }
    setVideoRejected(false);
  };

  const handleCloseConsultSnackbar = async () => {
    setVideoConsultOpen(false);
  };

  const handleVideoConsultClose = async () => {
    // Update state or perform any actions to close the box
    const response = await markConsultVideoRequestAsRead({ requestId: videoConsultData?.id }); // Use communityId
    if (response.status === true) {
      //console.log(response);
    }
    setConsultBoxOpen(false);
  };

  useEffect(() => {
    setIsAccessible(
      (props?.loggedInUserData?.id &&
        communityDetails?.userrole?.[0]?.id === props?.loggedInUserData?.id) ||
        props?.loggedInUserData?.roleId === 2,
    );
  }, [communityDetails]);

  return (
    <Paper className="WhitePaper" component="div">
      <Grid container>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          className="CustomSnackbar"
        >
          <Alert
            elevation={6}
            variant="filled"
            // onClose={closeSnackbar}
            severity="success"
            icon={<CheckCircleIcon color="primary" fontSize="inherit" />}
            action={
              <Link
                color="primary"
                underline="none"
                aria-label="close"
                onClick={handleCloseSnackbar}
                className="AlertDismissButton"
              >
                Dismiss
              </Link>
            }
          >
            {loggedInUserData?.roleId !== 2 ? (
              <>
                <Typography variant="body2" component="div" className="BlackColor SnackbarHeading">
                  Video Upload Request Submitted
                </Typography>
                <Typography variant="caption" component="div" className="SnackbarSubheading">
                  You will be notified by email when Move to Happy has reviewed your new video
                </Typography>
              </>
            ) : (
              // Your else content goes here
              <Typography variant="body2" component="div" className="BlackColor SnackbarHeading">
                Video added to community.
              </Typography>
            )}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isEditSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseEditSnackbar}
          className="CustomSnackbar"
        >
          <Alert
            elevation={6}
            variant="filled"
            // onClose={closeSnackbar}
            severity="success"
            icon={<CheckCircleIcon color="primary" fontSize="inherit" />}
            action={
              <Link
                color="primary"
                underline="none"
                aria-label="close"
                onClick={handleCloseEditSnackbar}
                className="AlertDismissButton"
              >
                Dismiss
              </Link>
            }
          >
            {loggedInUserData?.roleId !== 2 ? (
              <>
                <Typography variant="body2" component="div" className="BlackColor SnackbarHeading">
                  Video Change Request Submitted
                </Typography>
                <Typography variant="caption" component="div" className="SnackbarSubheading">
                  You will be notified by email when Move to Happy has reviewed your new video
                </Typography>
              </>
            ) : (
              // Your else content goes here
              <Typography variant="body2" component="div" className="BlackColor SnackbarHeading">
                Video added to community.
              </Typography>
            )}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isDeleteSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseDeleteSnackbar}
          // className="CustomSnackbar"
        >
          <Alert
            elevation={6}
            variant="filled"
            // onClose={closeSnackbar}
            severity="success"
            icon={<TrashIcon color="primary" fontSize="inherit" />}
            action={
              <Link
                color="primary"
                underline="none"
                aria-label="close"
                onClick={handleCloseDeleteSnackbar}
                className="AlertDismissButton"
              >
                Dismiss
              </Link>
            }
          >
            <Typography variant="body2" component="div" className="BlackColor SnackbarHeading">
              Video successfully removed
            </Typography>
          </Alert>
        </Snackbar>
        {/* //consult video */}
        <Snackbar
          open={isVideoConsultOpen}
          autoHideDuration={6000}
          onClose={handleCloseConsultSnackbar}
          className="CustomSnackbar"
        >
          <Alert
            elevation={6}
            variant="filled"
            // onClose={closeSnackbar}
            severity="success"
            icon={<CheckCircleIcon color="primary" fontSize="inherit" />}
            action={
              <Link
                color="primary"
                underline="none"
                aria-label="close"
                onClick={handleCloseConsultSnackbar}
                className="AlertDismissButton"
              >
                Dismiss
              </Link>
            }
          >
            <Typography variant="body2" component="div" className="BlackColor SnackbarHeading">
              Message Sent!
            </Typography>
            <Typography variant="caption" component="div" className="SnackbarSubheading">
              Our team will reach out to you soon.
            </Typography>
          </Alert>
        </Snackbar>

        {/* //consult voiodeo */}
        <Grid item xs={12} className="BoxHeadingContainer">
          <Typography color="primary" variant="h2" className="BoxHeading">
            About
            {/* <span>{communityDetails?.communityNameText}</span> */}
          </Typography>
          <If condition={props?.isAccessible && !props?.publicView && communityDetails?.aboutDesc}>
            <Button
              type="submit"
              className="EditCommonButton LinkTypeButton"
              startIcon={<EditPenIconGreen />}
              size="small"
              color="primary"
              href={'/#/app/community-details-edit-about/' + communityDetails?.id}
            >
              Edit
            </Button>
          </If>
        </Grid>

        <Grid item xs={12} className="CommonDetailBoxGrid ">
          <Box className="AboutChatBoxWrapper">
            {/* {communityDetails?.isRepresented && communityDetails?.aboutDesc && ( */}
            {communityDetails?.aboutDesc && (
              <Box className="ChatBox">
                <Box className="ChatContent">
                  <Typography variant="body2" className="ChatText" color="primary">
                    {communityDetails?.aboutDesc}
                  </Typography>
                </Box>
              </Box>
            )}
            {communityDetails?.aboutDesc && (
              <Box className="UserInfoName UserInfoIP">
                <Avatar
                  className="UserAvatar small"
                  alt={`${communityDetails?.userrole[0]?.firstName || repDetails?.firstName}}`}
                  src={
                    window.location.hostname === 'localhost'
                      ? `//localhost:9001/${
                          communityDetails?.userrole[0]?.profilePic || repDetails?.profilePic
                        }`
                      : `/api/${
                          communityDetails?.userrole[0]?.profilePic || repDetails?.profilePic
                        }`
                  }
                >
                  <PersonIcon />
                </Avatar>
                <Box className="UserInfoText">
                  <Typography variant="h6">
                    <Box component="span" className="FirstName">
                      {communityDetails?.userrole[0]?.firstName || repDetails?.firstName}
                    </Box>
                    <Box component="span" className="LastName">
                      {communityDetails?.userrole[0]?.lastName || repDetails?.lastName}
                    </Box>
                  </Typography>
                  <Typography variant="caption">
                    {communityDetails?.userrole[0]?.userDesignationText ||
                      repDetails?.userDesignationText}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {/* {props?.isCommunityRepresentative && !props?.publicView && (
            <Box className="ChatBox CommonDetailBoxGrid">
              <Box className="UserInfoName UserInfoIP">
                <Box className="UserInfoText">
                  <Typography variant="caption"></Typography>
                </Box>
              </Box>
            </Box>
          )} */}
        </Grid>
        {/* <Grid item xs={12}>
        <Divider className="DividerCD"></Divider>
      </Grid> */}

        <If condition={props?.isAccessible && !props?.publicView}>
          <Grid item xs={12}>
            <If
              condition={communityDetails?.aboutDesc === null || communityDetails?.aboutDesc == ''}
            >
              <Box>
                <Typography variant="body2" className="InsightLabel AboutInsightLabel ">
                  Show what your Community has to offer. People talk about the highlights of their
                  Community and what makes it a great place.
                </Typography>
              </Box>
            </If>
          </Grid>
          <Grid item xs={12}>
            <If condition={!communityDetails?.aboutDesc}>
              <Box className="Wrapper ButtonWrapper InsightButtonContainer">
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  href={'/#/app/community-details-edit-about/' + communityDetails?.id}
                >
                  {/* <img
                    src={require('../../../assets/images/Insight.svg').default}
                    alt="Insight Icon"
                  /> */}
                  <Box className="AddButton">Add Insight</Box>
                </Button>
              </Box>
            </If>
          </Grid>
        </If>

        <Grid item xs={12}>
          {/* {isVideoApproved && videoData && videoData.approvedStatus === 'Approved' && ( */}

          {/* <Box className="GreenDividerLogo">
            <DividerMTHButterfly className="DividerLogo" />
            <Divider className="GreenDivider" />
          </Box> */}
          {/* )} */}

          {isVideoApproved &&
          videoData &&
          videoData.approvedStatus === 'Approved' &&
          videoData.isRead === false &&
          props?.isAccessible &&
          !props?.publicView &&
          (videoData.editedStatus === 'Approved' || videoData.editedStatus === null) ? (
            <>
              {' '}
              <Box className="GreenDividerLogo">
                <DividerMTHButterfly className="DividerLogo" />
                <Divider className="GreenDivider" />
              </Box>
              <Box className="SuccessVideoApproved">
                <Box className="SuccessVideoInfo">
                  <CheckOutlineIcon />
                  <Box className="SuccessVideoText">
                    <Typography variant="body1" component="div" className="VideoApprovedHeading">
                      Success ! Your video is now live on Move to Happy.{' '}
                      <Box className="CommunityNameText">
                        Congrats on amplifying {communityDetails?.communityNameText} !
                      </Box>
                    </Typography>
                    {/* <Typography variant="body1" component="div" className="LightgreyColor">
                    Your video is now visible on your Community Page
                  </Typography> */}
                  </Box>
                </Box>
                <IconButton onClick={handleVideoApprovalClose}>
                  <ClearRoundedIcon />
                </IconButton>
              </Box>
            </>
          ) : null}
          {(isVideoRejected &&
            videoData &&
            videoData.approvedStatus === 'Rejected' &&
            videoData.isRead === false &&
            videoData.editedStatus === null &&
            props?.isAccessible &&
            !props?.publicView) ||
          (isVideoRejected &&
            videoData &&
            videoData.approvedStatus === 'Approved' &&
            videoData.editedStatus === 'Rejected' &&
            videoData.isRead === false &&
            props?.isAccessible &&
            !props?.publicView) ? (
            <Box className="SubmissionVideoRejected">
              <Box className="SubmissionVideoInfo">
                <WarningOutlineIcon />
                <Box className="SubmissionVideoText">
                  <Typography variant="body1" component="div" className="VideoApprovedHeading">
                    Oops! Looks like something wasn’t right
                  </Typography>
                  <Typography variant="body1" component="div" className="LightgreyColor">
                    Our team has denied your video request for the following reason:
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    className="LightgreyColor VideoApprovedReason"
                  >
                    {videoData.rejectionReason}
                  </Typography>
                </Box>
              </Box>
              <IconButton onClick={handleVideoRejectionClose}>
                <ClearRoundedIcon />
              </IconButton>
            </Box>
          ) : null}

          {videoData &&
            videoData.approvedStatus === 'Approved' &&
            videoData.editedStatus !== 'Pending' &&
            props?.isAccessible &&
            !props?.publicView && (
              <Box className="AboutVideoMainBoxEdit">
                <Button
                  onClick={() => openEditVideoModal(videoData.requestId, videoData.video)}
                  className="LinkTypeButton"
                  startIcon={<EditPenIconGreen />}
                  size="small"
                  color="primary"
                >
                  Edit
                </Button>
              </Box>
            )}
          {editVideo.status && (
            <ModalEditVideo
              status={editVideo.status}
              video={editVideo.video}
              videoId={editVideo.videoId}
              onClose={closeEditVideoModal}
              changeVideoMode={handleVideoModeChange}
              setUploadVideoMode={setUploadVideoMode}
              fetchVideoData={fetchVideoData}
              onEditUploadSuccess={() => setEditSnackbarOpen(true)}
              onDeleteUploadSuccess={() => setDeleteSnackbarOpen(true)}
            />
          )}
          {videoData &&
          videoData.editedStatus === 'Pending' &&
          props?.isAccessible &&
          !props?.publicView ? (
            <Box className="SuccessVideoApproved ReceivedMessage">
              <Box className="SuccessVideoInfo">
                <InfoOutlinedIcon className="GrassGreenColor" />
                <Box className="SuccessVideoText">
                  <Typography variant="body1" component="div" className="VideoApprovedHeading">
                    Request For Video Change Submitted - Hang in there while we review your video!
                  </Typography>
                  <Typography variant="body1" component="div" className="LightgreyColor">
                    You will be notified via email once our team has made a decision
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : null}
          {isConsultBoxOpen && videoConsultData && videoConsultData.isRead === false && (
            <Box className="SuccessVideoApproved ReceivedMessage">
              <Box className="SuccessVideoInfo">
                <InfoOutlinedIcon className="GrassGreenColor" />
                <Box className="SuccessVideoText">
                  <Typography variant="body1" component="div" className="VideoApprovedHeading">
                    We've Received Your Message
                  </Typography>
                  <Typography variant="body1" component="div" className="LightgreyColor">
                    Check your email for a response about a Move to Happy video consultation.
                  </Typography>
                </Box>
              </Box>
              <IconButton onClick={handleVideoConsultClose}>
                <ClearRoundedIcon />
              </IconButton>
            </Box>
          )}

          {videoData && videoData?.approvedStatus && videoData?.approvedStatus === 'Approved' ? (
            <Box className="AboutCommunityVideoContainer">
              <video
                width="100%"
                height="auto"
                muted
                controls
                playsInline
                onTouchEnd={handlePlay}
                autoPlay
                ref={videoRef}
                onLoadedMetadata={handleLoadedMetadata}
              >
                <source src={backendUrl + videoData.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* <video
                width="100%"
                height="auto"
                src={backendUrl + videoData.video}
                controls
                preload="auto"
                onTouchEnd={handlePlay}
                onEnded={() => setIsPlaying(false)}
                muted
                onError={(e) => console.error('Video Error:', e)}
              /> */}
              {/* {console.log(backendUrl + videoData.video, '12345')} */}
            </Box>
          ) : null}
          {videoData && videoData.approvedStatus && videoData.approvedStatus === 'Approved' ? (
            <div>
              <br></br>
              <If condition={props?.isAccessible && !props?.publicView}>
                <Box className="GreenDividerLogo">
                  <DividerMTHButterfly className="DividerLogo" />
                  <Divider className="GreenDivider" />
                </Box>
                <Paper className="UploadVideoPaper">
                  <FormControlLabel
                    className="UploadVideoCheckboxWrapper"
                    control={
                      <Checkbox
                        className="UploadVideoCheckbox"
                        readonly="readonly"
                        icon={<VisibilityOffOutlinedIcon />}
                        checkedIcon={<VisibilityOutlinedIcon />}
                        // checked={}
                        // onChange={handleChange}
                        name="Profile"
                        color="primary"
                      />
                    }
                    label="Only visible to you"
                  />
                  <VideoIcon />
                  <Typography variant="h4" className="UploadVideoHeading">
                    Need a video made for your Community? See how we can help!
                  </Typography>
                  <Typography variant="body2" className="UploadVideoSubheading">
                    Our team at Move to Happy has experience producing videos perfect for your
                    community page. Reach out today to learn more!
                  </Typography>
                  <Button
                    onClick={openModal1}
                    className="VideoTeamButton LinkTypeButton SignUpButton"
                  >
                    Contact our video team
                  </Button>
                  {isModalOpen1 && (
                    <ModalRequestVideoConsultation
                      status={isModalOpen1}
                      onClose={closeModal1}
                      onRequestSuccess={() => setVideoConsultOpen(true)}
                      fetchVideoConsultationData={fetchVideoConsultationData}
                    />
                  )}
                </Paper>
              </If>
            </div>
          ) : (
            <div>
              <If condition={props?.isAccessible && !props?.publicView}>
                <Box className="GreenDividerLogo">
                  <DividerMTHButterfly className="DividerLogo" />
                  <Divider className="GreenDivider" />
                </Box>
                <Paper className="UploadVideoPaper">
                  <FormControlLabel
                    className="UploadVideoCheckboxWrapper"
                    control={
                      <Checkbox
                        className="UploadVideoCheckbox"
                        readonly="readonly"
                        icon={<VisibilityOffOutlinedIcon />}
                        checkedIcon={<VisibilityOutlinedIcon />}
                        // checked={}
                        // onChange={handleChange}
                        name="Profile"
                        color="primary"
                      />
                    }
                    label="Only visible to you"
                  />
                  <VideoIcon />
                  <Typography variant="h4" className="UploadVideoHeading">
                    Boost your Community page with a video!
                  </Typography>
                  <Typography variant="body2" className="UploadVideoSubheading">
                    Show off the best sides of your Community by either uploading your own video or
                    working with Move to Happy to produce one for you.
                  </Typography>
                  {console.log(videoData, 'VD44')}
                  {videoData && videoData.approvedStatus === 'Pending' ? (
                    <Box className="SuccessVideoApproved ReceivedMessage">
                      <Box className="SuccessVideoInfo">
                        <InfoOutlinedIcon className="GrassGreenColor" />
                        <Box className="SuccessVideoText">
                          <Typography
                            variant="body1"
                            component="div"
                            className="VideoApprovedHeading"
                          >
                            Thanks for your video submission! Your contribution matters to us, we'll
                            review and update you soon.
                          </Typography>
                          {/* <Typography variant="body1" component="div" className="LightgreyColor">
                            You will be notified via email once our team has made a decision
                          </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className="UploadVideoButton"
                      onClick={openModal}
                    >
                      upload video
                    </Button>
                  )}

                  {/* {videoData && videoData.approvedStatus !== 'Pending' ( */}
                  {/* )} */}
                  {isModalOpen && (
                    <ModalUploadVideo
                      status={isModalOpen}
                      onCloseEditUpload={closeEditVideoModal}
                      onClose={closeModal}
                      onUploadSuccess={() => setSnackbarOpen(true)}
                      uploadVideoMode={'upload'}
                      fetchVideoData={fetchVideoData}
                    />
                  )}
                  <Button
                    onClick={openModal1}
                    className="VideoTeamButton LinkTypeButton SignUpButton"
                  >
                    Contact our video team
                  </Button>
                  {isModalOpen1 && (
                    <ModalRequestVideoConsultation
                      status={isModalOpen1}
                      onClose={closeModal1}
                      onRequestSuccess={() => setVideoConsultOpen(true)}
                      fetchVideoConsultationData={fetchVideoConsultationData}
                    />
                  )}
                </Paper>
              </If>
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AboutCommunity;
