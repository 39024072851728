/* eslint-disable  */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import styled from 'styled-components';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../../assets/images/Search-Icon.svg';
import useStyles from '../../../../screens/Main/Main.style';

const EmptyState = (props) => {
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {props.tabValue === 0 && (
          <Box className="EmptyNoteBox">
            {/* <Box className="EmptyNoteImg">
              <img
                className=""
                src={require('../../../../assets/images/Empty-Favorites-Bg.png').default}
                alt="Favorites"
              />
              <SearchIcon className="EmptyNoteIcon GreenColor SearchIcon" />
            </Box> */}
            <Box className="EmptySearchBox BgSecond">
              <Typography variant="h3" className="EmptySearchHeading" component="div">
                Oops, nothing to see here!
              </Typography>
              <Typography variant="body1" className="EmptySearchSubHeading" component="div">
                Bear with us while we grow. Try adjusting your search criteria or let us know what
                you're looking for.
              </Typography>
            </Box>
          </Box>
        )}
        {props.tabValue === 1 && (
          <Box className="EmptyNoteBox FavoritesEmptyNote">
            {/* <Box className="EmptyNoteImg">
              <img
                className=""
                src={require('../../../../assets/images/Empty-Favorites-Bg.png').default}
                alt="Favorites"
              />
              <SearchIcon className="EmptyNoteIcon GreenColor SearchIcon" />
            </Box> */}
            <Box className="EmptySearchBox BgSecond">
              <Typography variant="h3" className="EmptySearchHeading" component="div">
                Oops, nothing to see here!
              </Typography>
              <Typography variant="body1" className="EmptySearchSubHeading" component="div">
                Come back after exploring to see communities you have recently viewed
              </Typography>
            </Box>
          </Box>
        )}
        {props.tabValue === 2 && (
          <Box className="EmptyNoteBox">
            {/* <Box className="EmptyNoteImg">
              <img
                className=""
                src={require('../../../../assets/images/Empty-Favorites-Bg.png').default}
                alt="Favorites"
              />
              <FavoriteBorderIcon className="EmptyNoteIcon GreenColor" />
            </Box> */}
            <Box className="EmptySearchBox BgThird">
              <Typography variant="h3" className="EmptySearchHeading" component="div">
                Oops, no favorites yet!
              </Typography>
              <Typography variant="body1" className="EmptySearchSubHeading" component="div">
                Select the heart icon to build a list of your favorite communities here
              </Typography>
            </Box>
          </Box>
        )}
        {props.tabValue === 3 && (
          <Box className="EmptyNoteBox">
            {/* <Box className="EmptyNoteImg">
              <img
                className=""
                src={require('../../../../assets/images/Empty-Favorites-Bg.png').default}
                alt="Favorites"
              /> */}
            {/* <SearchIcon className='EmptyNoteIcon GreenColor' /> */}
            {/* </Box> */}
            <Box className="EmptySearchBox BgFourth">
              <Typography variant="h3" className="EmptySearchHeading" component="div">
                Oops, no recommendations yet!
              </Typography>
              <Typography variant="body1" className="EmptySearchSubHeading" component="div">
                &rsquo;Find your Happy&rsquo; using our interactive quiz to get recommendations
                today
              </Typography>
              {/* <Button variant="contained" color="primary">
                Find Your Happy
              </Button> */}
            </Box>
          </Box>
        )}
        {props.tabValue === 4 && (
          <Box className="EmptyNoteBox">
            {/* <Box className="EmptyNoteImg">
              <img
                className=""
                src={require('../../../../assets/images/Empty-Favorites-Bg.png').default}
                alt="Favorites"
              />
              <SearchIcon className="EmptyNoteIcon GreenColor" />
            </Box> */}
            <Box className="EmptySearchBox BgSecond">
              <Typography variant="h3" className="EmptySearchHeading" component="div">
                Oops, nothing to see here!
              </Typography>
              <Typography variant="body1" className="EmptySearchSubHeading" component="div">
                Bear with us while we grow. Try adjusting your search criteria or let us know what
                you're looking for.
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
EmptyState.defaultProps = {
  tabValue: 0,
};
export default EmptyState;
