/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Box,
  FormControlLabel,
  TextField,
  Checkbox,
  Paper,
  InputLabel,
} from '@material-ui/core';
import * as yup from 'yup';
import * as Yup from 'yup';
import { FastField, Formik, Form, Field } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import '../Main.style.css';
import { values } from 'lodash';

// import PropTypes from 'prop-types';
const JobListFilter = (props) => {
  const [filterJobTitle, setFilterJobTitle] = useState('');
  const [filterJobCat, setFilterJobCat] = useState(1);
  const [filterJobType, setFilterJobType] = useState('');
  const [filterJobPostedAt, setFilterJobPostedAt] = useState('');
  const [filterJobIsRemote, setFilterJobIsRemote] = useState(false);
  const formikRef = React.createRef();
  const [initialValues, setInitialValues] = useState({
    jobTitle: '',
    jobCat: optionsCategories[0],
    jobType: optionsTypes[0],
    jobPostedAt: optionsPostedAt[0],
    jobIsRemote: false,
  });

  // const validationSchema = Yup.object().shape({
  //   filterJobCat: yup.string().required('Job category name is required'),
  //   filterJobType: yup.string().required('Job type is required'),
  //   filterJobPostedAt: yup.string().required('Posted at time is required'),
  // });
  const resetJobFilters = async () => {
    formikRef.current.resetForm(initialValues);
    // setFieldValue('jobCat', optionsCategories[0]);
    // setInitialValues({
    //   jobTitle: '',
    //   jobCat: optionsCategories[0],
    //   jobType: optionsTypes[0],
    //   jobPostedAt: optionsPostedAt[0],
    //   jobIsRemote: false,
    // });
  };

  const handleSearch = async (values) => {
    try {
      // props.setLoading(true);
      console.log(props, 100);
      console.log(values, 'handleSearch');
      props.getSearchObject(values);
      props.updateJobList();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    console.log(props, 123123);
    if (props?.jobFilterReset) {
      resetJobFilters();
    }
  }, [props?.jobFilterReset, initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      innerRef={formikRef}
      onSubmit={handleSearch}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        setFieldValue,
        isValid,
        isSubmitting,
        dirty,
        touched,
        handleChange,
        values,
        errors,
      }) => (
        <Form className="" onSubmit={handleSubmit}>
          <Paper className="JobPostFilter">
            <Grid container>
              <Grid item xs={12} className="commonFieldBox">
                <InputLabel className="InputLabelFld" htmlFor="filter">
                  Filter
                </InputLabel>
                <FastField
                  as={TextField}
                  name="filter"
                  id="jobTitle"
                  variant="outlined"
                  // type="text"
                  // value={values?.jobTitle}
                  placeholder="Skill, company, tag ..."
                  // error={!!showErrIfTouched('firstName', errors, touched)}
                  // helperText={showErrIfTouched('firstName', errors, touched)}
                  fullWidth
                  onChange={(e) => {
                    setFilterJobTitle(e.target.value);
                    setFieldValue('jobTitle', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} className="commonFieldBox">
                <InputLabel className="InputLabelFld" htmlFor="category">
                  Category
                </InputLabel>
                <Box className="AutocompleteNoIcon">
                  <Autocomplete
                    className="CommonAutocomplete"
                    id="jobCat"
                    options={optionsCategories || []}
                    getOptionLabel={(option) => option.title || ''}
                    value={
                      values?.jobCat !== undefined &&
                      optionsCategories.find((v) => v == values?.jobCat)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="All categories"
                        label=""
                        variant="outlined"
                      />
                    )}
                    popupIcon={<KeyboardArrowDownIcon />}
                    onChange={(e, value) => {
                      console.log(value, 5000);
                      setFilterJobCat(value);
                      setFieldValue('jobCat', value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className="commonFieldBox">
                <InputLabel className="InputLabelFld">Job Types</InputLabel>
                <Box className="AutocompleteNoIcon">
                  <Autocomplete
                    className="CommonAutocomplete"
                    id="jobType"
                    options={optionsTypes || []}
                    getOptionLabel={(option) => option.title || ''}
                    value={
                      values?.jobType !== undefined &&
                      optionsTypes.find((v) => v == values?.jobType)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="All job types"
                        label=""
                        variant="outlined"
                      />
                    )}
                    popupIcon={<KeyboardArrowDownIcon />}
                    onChange={(e, value) => {
                      setFilterJobType(value);
                      setFieldValue('jobType', value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className="commonFieldBox">
                <InputLabel className="InputLabelFld">Posted At</InputLabel>
                <Box className="AutocompleteNoIcon">
                  <Autocomplete
                    className="CommonAutocomplete"
                    id="jobPostedAt"
                    options={optionsPostedAt || []}
                    getOptionLabel={(option) => option.title || ''}
                    value={
                      values?.jobPostedAt !== undefined &&
                      optionsPostedAt.find((v) => v == values?.jobPostedAt)
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Any time" label="" variant="outlined" />
                    )}
                    popupIcon={<KeyboardArrowDownIcon />}
                    onChange={(e, value) => {
                      setFilterJobPostedAt(value);
                      setFieldValue('jobPostedAt', value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className="commonFieldBox">
                <FormControlLabel
                  className="FilterCheckbox"
                  control={
                    <Checkbox
                      checked={filterJobIsRemote && filterJobIsRemote}
                      onChange={(e, value) => {
                        setFieldValue('jobIsRemote', value);
                        setFilterJobIsRemote(value);
                      }}
                      name="noUse"
                      color="primary"
                    />
                  }
                  label="Remote Jobs Only"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  // disabled={isSubmitting || !dirty || !isValid}
                  type="submit"
                  // className=""
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

JobListFilter.propTypes = {};
const optionsCategories = [
  { title: 'All Categories', id: 0 },
  { title: 'Software Development', id: 75022 },
  { title: 'Quality Assurance (QA)', id: 75023 },
  { title: 'Data Science', id: 75024 },
  { title: 'Design', id: 75025 },
  { title: 'Product Management', id: 75026 },
  { title: 'Marketing & Sales', id: 75027 },
  { title: 'Network Administration', id: 75028 },
  { title: 'Other', id: null },
];

const optionsTypes = [
  { title: 'All job types', id: 0 },
  { title: 'Full-time', id: 20325 },
  { title: 'Part-time', id: 20326 },
  { title: 'Contract', id: 20327 },
  { title: 'Internship', id: 20328 },
];

const optionsPostedAt = [
  { title: 'Any time', id: 0 },
  { title: 'Last 24 hours', id: 1 },
  { title: 'Last 3 days', id: 2 },
  { title: 'Last 7 days', id: 3 },
  { title: 'Last 14 days', id: 4 },
  { title: 'Last 30 days', id: 5 },
];
export default JobListFilter;
