/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable import/named */
/* eslint-disable  */
import Cookies from 'js-cookie';
import React, { lazy } from 'react';
//import crypto from "crypto";
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import authRoutes from './authRoutes';
// import settingsNav from './settingsNav';
import { ReactComponent as TestIcon } from '../assets/svgIcons/sidebarNav/testicon.svg';
import { ReactComponent as TestIconActive } from '../assets/svgIcons/sidebarNav/testicon-active.svg';
import { ReactComponent as PracticeIcon } from '../assets/svgIcons/sidebarNav/practices-whitesvg.svg';
import { ReactComponent as PracticeIconActive } from '../assets/svgIcons/sidebarNav/practices-orangesvg.svg';
import { ReactComponent as NeuroIcon } from '../assets/svgIcons/sidebarNav/neuro-whitesvg.svg';
import { ReactComponent as NeuroIconActive } from '../assets/svgIcons/sidebarNav/neuro-orangesvg.svg';
import { ReactComponent as ClinicalIcon } from '../assets/svgIcons/sidebarNav/clinical-whitesvg.svg';
import { ReactComponent as ClinicalIconActive } from '../assets/svgIcons/sidebarNav/clinical-orangesvg.svg';
import { ReactComponent as SurveyIcon } from '../assets/svgIcons/sidebarNav/survey-whitesvg.svg';
import { ReactComponent as SurveyIconActive } from '../assets/svgIcons/sidebarNav/survey-orangesvg.svg';
import { ReactComponent as PatientIcon } from '../assets/svgIcons/sidebarNav/patients-whitesvg.svg';
import { ReactComponent as PatientIconActive } from '../assets/svgIcons/sidebarNav/patients-orangesvg.svg';
import { ReactComponent as MessagesIcon } from '../assets/svgIcons/sidebarNav/messages-whitesvg.svg';
import { ReactComponent as MessagesIconActive } from '../assets/svgIcons/sidebarNav/messages-orangesvg.svg';
import { ReactComponent as SettingsIcon } from '../assets/svgIcons/sidebarNav/settings-whitesvg.svg';
import { ReactComponent as SettingsIconActive } from '../assets/svgIcons/sidebarNav/settings-orangesvg.svg';
import { ReactComponent as ResourceOrange } from '../assets/svgIcons/sidebarNav/ResourceOrange.svg';
import { ReactComponent as ResourceWhite } from '../assets/svgIcons/sidebarNav/ResourceWhite.svg';
import { ReactComponent as Resource } from '../assets/svgIcons/sidebarNav/survey-whitesvg.svg';
import { ReactComponent as ReferralIcon } from '../assets/svgIcons/sidebarNav/blueReferral.svg';
import { ReactComponent as ReferralActiveIcon } from '../assets/svgIcons/sidebarNav/orangeReferral.svg';
// import { ReactComponent as AppointmentIcon } from '../assets/svgIcons/sidebarNav/appointment.svg';
// import { ReactComponent as CalendartIcon } from '../assets/svgIcons/sidebarNav/calendar.svg';
// import { ReactComponent as ClassestIcon } from '../assets/svgIcons/sidebarNav/classes.svg';
// import { ReactComponent as ClientstIcon } from '../assets/svgIcons/sidebarNav/clients.svg';
// import { ReactComponent as ReportstIcon } from '../assets/svgIcons/sidebarNav/reports.svg';
// import { ReactComponent as SalesIcon } from '../assets/svgIcons/sidebarNav/sales.svg';
// import { ReactComponent as StaffIcon } from '../assets/svgIcons/sidebarNav/staff.svg';
// import { ReactComponent as StudiosIcon } from '../assets/svgIcons/sidebarNav/studios.svg';
// import { ReactComponent as SettingsIcon } from '../assets/svgIcons/sidebarNav/settings.svg';

// const TimeZoneProvider = lazy(() => import('../contexts/timeZoneContext'));
// // const CompaniesProvider = lazy(() => import('../contexts/companiesContext'));
// const CompanyProvider = lazy(() => import('../contexts/companyContext'));
// const ClassCategoriesProvider = lazy(() => import('../contexts/classCategoriesContext'));
// const RevenueCategoriesProvider = lazy(() => import('../contexts/revenueCategoriesContext'));
// const TaxDetailsProvider = lazy(() => import('../contexts/taxDetailsContext'));
// const StateProvider = lazy(() => import('../contexts/stateContext'));
// const CancelPolicyProvider = lazy(() => import('../contexts/cancelPolicyContex'));

import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PublicIcon from '@material-ui/icons/Public';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import CommunityList from '../screens/Main/CommunityList/CommunityList';
import CommunityDetails from '../screens/Main/CommunityDetails/CommunityDetails';
import CommunityDetailPublicView from '../screens/Main/CommunityDetails/CommunityDetailPublicView';
import CommunityEditViewAbout from '../screens/Main/CommunityDetails/CommunityEditViewAbout';
import CommunityUpdateCoverPhoto from '../screens/Main/CommunityCoverPhoto/CommunityUpdateCoverPhoto';
import CommunityEditViewResidents from '../screens/Main/CommunityDetails/CommunityEditViewResidents';
import CommunityEditViewClimate from '../screens/Main/CommunityDetails/CommunityEditViewClimate';
import CommunityEditViewGettingAround from '../screens/Main/CommunityDetails/CommunityEditViewGettingAround';
import CommunityEditViewEducation from '../screens/Main/CommunityDetails/CommunityEditViewEducation';
import CommunityAddHealthFacility from '../screens/Main/CommunityDetails/CommunityAddHealthFacility';

import CommunityEditviewAmenities from '../screens/Main/CommunityDetails/CommunityEditViewAmenities';
import CommunityEditViewAddNewAmenity from '../screens/Main/CommunityDetails/CommunityEditViewAddNewAmenity';
import CommunityEditViewForCommunityReps from '../screens/Main/CommunityDetails/CommunityEditViewForCommunityReps/CommunityEditViewForCommunityReps';

import CommunityEditViewJobs from '../screens/Main/CommunityDetails/CommunityEditViewJobs/CommunityEditViewJobs';
import ViewAllAmenities from '../screens/Main/CommunityDetails/ViewAllAmenities/ViewAllAmenities';
import CommunityHealthInsights from '../screens/Main/CommunityDetails/CommunityHealthInsights/CommunityHealthInsights';
import CommunityHealthFacility from '../screens/Main/CommunityDetails/CommunityHealthFacility/CommunityHealthFacility';

import HouseList from '../screens/Main/HouseList/HouseList';
import Invitation from '../screens/Main/Invitation/Invitation';
import InvitationLinkExpired from '../screens/Main/Invitation/InvitationLinkExpired';
import OnboardingConfirmation from '../screens/Main/Invitation/OnboardingConfirmation';
import LeaveCommunity from '../screens/Main/LeaveCommunity/LeaveCommunity';
import DeclineInvitationSuccess from '../screens/Main/Invitation/DeclineInvitationSuccess';
import JobList from '../screens/Main/JobList/JobList';
import CommunityEditViewCostOfLiving from '../screens/Main/CommunityDetails/CommunityEditViewCostOfLiving/CommunityEditViewCostOfLiving';
import AdminEditApproval from '../screens/Main/AboutCommunity/AdminEditApproval';
import AdminRejection from '../screens/Main/AboutCommunity/AdminRejection';
import AdminEditRejection from '../screens/Main/AboutCommunity/AdminEditRejection';

let userType = '';
let appNav = [];
// const AddPractices = lazy(() => import('../screens/Main/AddPractices'));
const EditUserAccount = lazy(() => import('../screens/Main/Account/EditUserAccount'));
const Account = lazy(() => import('../screens/Main/Account'));
const EditAccount = lazy(() => import('../screens/Main/EditAccount'));
const EditAccountAdmin = lazy(() => import('../screens/Main/EditAccount/EditAccountAdmin'));

const HomeIn = lazy(() => import('../screens/Main/HomeIn'));
const ForgotPassword = lazy(() => import('../screens/Auth/ForgotPassword'));
const CheckYourEmail = lazy(() => import('../screens/Auth/CheckYourEmail'));
const EditEmail = lazy(() => import('../screens/Main/EditEmail'));
const EditEmailByAdmin = lazy(() => import('../screens/Main/EditEmail/EditEmailByAdmin'));

const EditPassword = lazy(() => import('../screens/Main/EditPassword'));
const EditPasswordByAdmin = lazy(() => import('../screens/Main/EditPassword/EditPasswordByAdmin'));

const ResetPassword = lazy(() => import('../screens/Auth/ResetPassword'));
const DeleteAccount = lazy(() => import('../screens/Auth/DeleteAccount'));
const DeleteAccountSuccess = lazy(() => import('../screens/Auth/DeleteAccountSuccess'));
const HomePage = lazy(() => import('../screens/Main/HomePage/HomePage'));
const FindYourHappy = lazy(() => import('../screens/Main/FindYourHappy/FindYourHappy'));
const ChangeEmailSent = lazy(() => import('../screens/Auth/ChangeEmailSent/ChangeEmailSent'));
const ComingSoon = lazy(() => import('../screens/Main/ComingSoon/ComingSoon'));
const NoFound = lazy(() => import('../screens/NotFound/NoFound'));
const VerifyChangeEmail = lazy(() => import('../screens/Auth/VerifyChangeEmail/VerifyChangeEmail'));
const JobDetails = lazy(() => import('../screens/Main/JobDetails/JobDetails'));
const AdminApproval = lazy(() => import('../screens/Main/AboutCommunity/AdminApproval'));
const AdministrativeCenter = lazy(() =>
  import('../screens/Auth/AdministrativeCenter/AdministrativeCenter'),
);
const EditUserDetails = lazy(() => import('../screens/Auth/AdministrativeCenter/EditUserDetails'));
const ChangeAccountType = lazy(() =>
  import('../screens/Auth/AdministrativeCenter/ChangeAccountType'),
);
const CreateNewCommunity = lazy(() =>
  import('../screens/Auth/AdministrativeCenter/CreateNewCommunity'),
);
const AdminVideoReview = lazy(() =>
  import('../screens/Auth/AdministrativeCenter/AdminVideoReview'),
);
const AdminVideoDeny = lazy(() => import('../screens/Auth/AdministrativeCenter/AdminVideoDeny'));
const AdminCreateNewCommunity = lazy(() =>
  import('../screens/Auth/AdministrativeCenter/AdminCreateNewCommunity'),
);
const InviteCommunityRepresentative = lazy(() =>
  import('../screens/Auth/AdministrativeCenter/InviteCommunityRepresentative'),
);
const DeclineInvitation = lazy(() => import('../screens/Main/Invitation/DeclineInvitation'));
const DeclineInviteInfo = lazy(() =>
  import('../screens/Auth/AdministrativeCenter/DeclineInviteInfo'),
);

// const Settings = lazy(() => import('../screens/Main/Settings'));
const key = 'iFrasCwdJh';

const userCookie = Cookies.get('user');

if (userCookie) {
  //const userData = JSON.parse(atob(userCookie));
  //const userData = crypto.createDecipher('aes-256-ctr', key).update(userCookie, 'hex', 'utf-8');
  let bytes = CryptoJS.AES.decrypt(userCookie, key);
  const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  userType = (userData.userType && userData.userType.type) || null;
  // navigate('../../app/account');
  console.log(1111, 'A1');
} else {
  console.log(1111, 'B1');
  /* if (window.location.href.indexOf('verify') < 0) {
    console.log(1111, 'C1');
    window.location = '#/app/home-page';
  } else  */
  if (
    window.location.hash === '' ||
    window.location.hash === '/#' ||
    window.location.hash === '#/'
  ) {
    console.log(1111, 'C2');
    window.location.hash = '#/app/home-page';
  }
}
// else {
//   // if (
//   //   window.location.href.indexOf('set-password') < 0 &&
//   //   window.location.href.indexOf('forgot-password') < 0 &&
//   //   window.location.href.indexOf('reset-password') < 0 &&
//   //   window.location.href.indexOf('two-factor-authentication') < 0 &&
//   //   window.location.href.indexOf('practice-account/create-account') < 0 &&
//   //   window.location.href.indexOf('addusers') < 0 &&
//   //   window.location.href.indexOf('create-account') < 0 &&
//   //   window.location.href.indexOf('practice-account/payment') < 0
//   // )
//   // {
//     // window.location = '/#/login';
//     /*to pass no authorization urls*/
//     let noAuthUrls = ['dialogstable', 'CommunityList']; // add urls here to access without auth
//     let urlIndex = noAuthUrls.filter((responses) => window.location.href.indexOf(responses) > -1);
//     /*to pass no authorization urls*/

//     if (!urlIndex || !urlIndex.length) {
//       console.log('RRR');
//       window.location = '/#/app/home-page';
//     } else {
//       appNav = [
//         // {
//         //   name: 'Dialogs Table',
//         //   /* navImage: require('../assets/images/staff.png').default, */
//         //   image: require('../assets/images/staff.png').default,
//         //   navIcons: <TestIconActive />,
//         //   path: 'dialogstable',
//         //   element: null,
//         //   navIconsAlt: <TestIcon />,
//         // },
//       ];
//     // }
//   }
// }
appNav = [
  // {
  //   name: 'Landing',
  //   path: '',
  //   element: <WaterMark />,
  // },

  {
    navIcons: <AdministrativeCenter />,
    path: 'admin-center',
    element: <AdministrativeCenter />,
    navIconsAlt: <AdministrativeCenter />,
    num: -1,
  },
  {
    navIcons: <AdminVideoReview />,
    path: 'admin-video-review/:communityName/:communityVideoId',
    element: <AdminVideoReview />,
    navIconsAlt: <AdminVideoReview />,
    num: -1,
  },
  {
    navIcons: <AdminVideoDeny />,
    path: 'admin-video-deny/:communityName/:communityVideoId',
    element: <AdminVideoDeny />,
    navIconsAlt: <AdminVideoDeny />,
    num: -1,
  },

  {
    navIcons: <AdministrativeCenter />,
    path: 'admin-center/:tab1/:tab2',
    element: <AdministrativeCenter />,
    navIconsAlt: <AdministrativeCenter />,
    num: -1,
  },
  {
    navIcons: <EditUserDetails />,
    path: 'edit-user-details',
    element: <EditUserDetails />,
    navIconsAlt: <EditUserDetails />,
    num: -1,
  },
  {
    navIcons: <ChangeAccountType />,
    path: 'change-account-type/:userId',
    element: <ChangeAccountType />,
    navIconsAlt: <ChangeAccountType />,
    num: -1,
  },
  {
    navIcons: <CreateNewCommunity />,
    path: 'create-new-community',
    element: <CreateNewCommunity />,
    navIconsAlt: <CreateNewCommunity />,
    num: -1,
  },
  {
    navIcons: <AdminCreateNewCommunity />,
    path: 'admin-create-community/:communityId',
    element: <AdminCreateNewCommunity />,
    navIconsAlt: <AdminCreateNewCommunity />,
    num: -1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'invite-community-representative',
    element: <InviteCommunityRepresentative />,
    navIconsAlt: <InviteCommunityRepresentative />,
    num: -1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'invite-community-representative/:inviteCommunityId',
    element: <InviteCommunityRepresentative />,
    navIconsAlt: <InviteCommunityRepresentative />,
    num: -1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'home-in',
    element: <HomeIn />,
    navIconsAlt: <PersonIcon />,
    num: 2,
  },
  {
    navIcons: <PersonIcon />,
    path: 'home-page',
    element: <HomePage />,
    num: -1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'find-your-happy',
    element: <FindYourHappy />,
    num: -1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'community-list',
    name: 'CommunityList',
    element: <CommunityList />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'find-your-happy',
    name: 'FindYourHappy',
    element: <ComingSoon />,
    navIconsAlt: <PersonIcon />,
    num: 2,
  },
  {
    navIcons: <PersonIcon />,
    path: 'not-found',
    name: 'Not Found',
    element: <NoFound />,
    navIconsAlt: <PersonIcon />,
    num: 2,
  },
  {
    navIcons: <ForgotPassword />,
    path: 'forgot-password',
    element: <ForgotPassword />,
    navIconsAlt: <ForgotPassword />,
    num: -1,
  },
  {
    navIcons: <EditEmail />,
    path: 'edit-email',
    element: <EditEmail />,
    navIconsAlt: <EditEmail />,
    num: -1,
  },
  {
    navIcons: <EditEmailByAdmin />,
    path: 'edit-email-by-admin/:userId',
    element: <EditEmailByAdmin />,
    navIconsAlt: <EditEmailByAdmin />,
    num: -1,
  },
  {
    path: 'change-email-sent',
    element: <ChangeEmailSent />,
  },
  {
    path: 'verify-change-email-token/:token',
    element: <VerifyChangeEmail />,
  },
  {
    navIcons: <EditPassword />,
    path: 'edit-password',
    element: <EditPassword />,
    navIconsAlt: <EditPassword />,
    num: -1,
  },
  {
    navIcons: <EditPasswordByAdmin />,
    path: 'edit-password-by-admin/:userId',
    element: <EditPasswordByAdmin />,
    navIconsAlt: <EditPasswordByAdmin />,
    num: -1,
  },

  {
    navIcons: <ResetPassword />,
    path: 'reset-password/:token',
    element: <ResetPassword />,
    navIconsAlt: <ResetPassword />,
    num: -1,
  },
  {
    navIcons: <DeleteAccount />,
    path: 'delete-account/:id',
    element: <DeleteAccount />,
    navIconsAlt: <DeleteAccount />,
    num: -1,
  },
  {
    navIcons: <DeleteAccountSuccess />,
    path: 'delete-account-success',
    element: <DeleteAccountSuccess />,
    navIconsAlt: <DeleteAccountSuccess />,
    num: -1,
  },
  {
    name: 'CommunityDetails',
    path: 'community-details/:id',
    navIcons: <PersonIcon />,
    element: <CommunityDetails />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditviewResidents',
    path: 'community-details-edit-residents/:id',
    navIcons: <PersonIcon />,
    element: <CommunityEditViewResidents />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditviewClimate',
    path: 'community-details-edit-climate/:id',
    navIcons: <PersonIcon />,
    element: <CommunityEditViewClimate />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditviewClimate',
    path: 'community-details-edit-cost-of-living/:id',
    navIcons: <PersonIcon />,
    element: <CommunityEditViewCostOfLiving />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },

  {
    name: 'CommunityEditViewForCommunityReps',
    path: 'community-details-edit-community-reps/:id',
    navIcons: <PersonIcon />,
    element: <CommunityEditViewForCommunityReps />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityHealthInsights',
    path: 'community-details-health-insights/:id',
    navIcons: <PersonIcon />,
    element: <CommunityHealthInsights />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityHealthInsights',
    path: 'community-details-health-facilities/:id',
    navIcons: <PersonIcon />,
    element: <CommunityHealthFacility />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditviewGettingAround',
    path: 'community-details-edit-getting-around/:id',
    navIcons: <PersonIcon />,
    element: <CommunityEditViewGettingAround />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditviewEducation',
    path: 'community-details-edit-education/:id',
    navIcons: <PersonIcon />,
    element: <CommunityEditViewEducation />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityAddHealthFacility',
    path: 'community-add-health-facility/:id',
    navIcons: <PersonIcon />,
    element: <CommunityAddHealthFacility />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityAddHealthFacility',
    path: 'community-edit-health-facility/:id/:facilityId',
    navIcons: <PersonIcon />,
    element: <CommunityAddHealthFacility />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityDetailPublicView',
    path: 'community-details-publicview/:id',
    navIcons: <PersonIcon />,
    element: <CommunityDetailPublicView />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditViewAddNewAmenity',
    path: 'community-details-update-cover-photo/:id',
    navIcons: <PersonIcon />,
    element: <CommunityUpdateCoverPhoto />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditViewAbout',
    path: 'community-details-edit-about/:id',
    navIcons: <PersonIcon />,
    element: <CommunityEditViewAbout />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditViewAbout',
    path: 'video-upload-request/:requestId',
    navIcons: <PersonIcon />,
    element: <AdminApproval />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditViewAbout',
    path: 'video-reject-request/:requestId',
    navIcons: <PersonIcon />,
    element: <AdminRejection />,
    navIconsAlt: <PersonIcon />,
    num: 1,
    header: false,
    footer: false,
  },
  {
    name: 'CommunityEditViewAbout',
    path: 'approve-edit-video-request/:requestId',
    navIcons: <PersonIcon />,
    element: <AdminEditApproval />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditViewAbout',
    path: 'reject-edit-video-request/:requestId',
    navIcons: <PersonIcon />,
    element: <AdminEditRejection />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditViewJobs',
    path: 'community-details-edit-jobs/:id',
    navIcons: <PersonIcon />,
    element: <CommunityEditViewJobs />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'ViewAllAmenities',
    path: 'view-all-amenities/:id',
    navIcons: <PersonIcon />,
    element: <ViewAllAmenities />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'JobList',
    path: 'job-list/:id',
    navIcons: <PersonIcon />,
    element: <JobList />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'JobDetails',
    path: 'job-details/:communityId/:jobId/:employerId',
    navIcons: <PersonIcon />,
    element: <JobDetails />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditviewAmenities',
    path: 'community-details-edit-amenities/:id',
    navIcons: <PersonIcon />,
    element: <CommunityEditviewAmenities />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'CommunityEditViewAddNewAmenity',
    path: 'community-details-edit-add-new-amenity/:id/:amenityId',
    navIcons: <PersonIcon />,
    element: <CommunityEditViewAddNewAmenity />,
    navIconsAlt: <PersonIcon />,
    num: 1,
  },
  {
    name: 'HouseList',
    path: 'houselist',
    navIcons: <PersonIcon />,
    element: <HouseList />,
    navIconsAlt: <PersonIcon />,
    num: 0,
  },
  {
    name: 'HouseList',
    path: 'houselist/:communityId',
    navIcons: <PersonIcon />,
    element: <HouseList />,
    navIconsAlt: <PersonIcon />,
    num: 2,
  },
  {
    navIcons: <CheckYourEmail />,
    path: 'check-your-email',
    element: <CheckYourEmail />,
    navIconsAlt: <CheckYourEmail />,
    num: -1,
  },

  {
    path: 'invitation/:invitationId',
    name: 'invitation',
    navIcons: <PersonIcon />,
    element: <Invitation />,
    navIconsAlt: <PersonIcon />,
    num: -1,
    header: false,
    footer: false,
  },
  {
    path: 'invitation-link-expired',
    name: 'invitation-link-expired',
    navIcons: <PersonIcon />,
    element: <InvitationLinkExpired />,
    navIconsAlt: <PersonIcon />,
    num: -1,
  },
  {
    path: 'invitation-link-expired/:reason',
    name: 'invitation-link-expired',
    navIcons: <PersonIcon />,
    element: <InvitationLinkExpired />,
    navIconsAlt: <PersonIcon />,
    num: -1,
  },
  {
    path: 'onboarding-confirmation/:invitationId',
    name: 'Onboarding Confirmation',
    navIcons: <PersonIcon />,
    element: <OnboardingConfirmation />,
    navIconsAlt: <PersonIcon />,
    num: -1,
  },

  {
    navIcons: <PersonIcon />,
    path: 'decline-Invitation-success',
    element: <DeclineInvitationSuccess />,
    navIconsAlt: <PersonIcon />,
    num: -1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'decline-invitation/:id',
    element: <DeclineInvitation />,
    num: -1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'decline-invite-info/:id',
    element: <DeclineInviteInfo />,
    num: -1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'decline-invitation-success',
    element: <DeclineInvitationSuccess />,
    num: -1,
  },
  {
    navIcons: <PersonIcon />,
    path: 'leave-community/:id',
    element: <LeaveCommunity />,
    num: -1,
  },
  {
    name: 'Account',

    navIcons: <PersonIcon />,
    path: 'account',
    element: <Account />,
    navIconsAlt: <PersonIcon />,
    num: -1,
  },
  {
    name: 'EditUserAccount',
    navIcons: <PersonIcon />,
    path: 'edit-user-account/:userId',
    element: <EditUserAccount />,
    navIconsAlt: <PersonIcon />,
    num: -1,
  },
  {
    name: 'EditAccount',
    navIcons: <PersonIcon />,
    path: 'editaccount',
    element: <EditAccount />,
    navIconsAlt: <PersonIcon />,
    num: -1,
  },
  {
    name: 'EditAccountAdmin',
    navIcons: <PersonIcon />,
    path: 'edit-account-admin/:userId',
    element: <EditAccountAdmin />,
    navIconsAlt: <PersonIcon />,
    num: -1,
  },
];

export default appNav;
