/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CryptoJS from 'crypto-js';
import {
  Grid,
  Link,
  Typography,
  Box,
  Container,
  InputLabel,
  Button,
  Avatar,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useCommunityList from '../../../../hooks/useCommunityList';
import useLogin from '../../../../hooks/useAuth';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ModalFormik from '../CommunityHealthInsights/ModalFormik';
import useStyles from '../../Main.style';
import EditIcon from '@material-ui/icons/Edit';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const CommunityHealthFacility = () => {
  const {
    addCommunityHealthFacility,
    getCommunityHealthFacility,
    updateCommunityHeathFacility,
  } = useCommunityList();

  const { getRoleData } = useLogin();

  const [id, setId] = useState();

  const [userData, setUserData] = useState();
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [healthFacility, setHealthFacility] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);

  const params = useParams();
  let communityID = params?.id;
  console.log(communityID, 'COMMU_ID');

  const fetchData = async () => {
    try {
      const response = await getCommunityHealthFacility({
        communityId: params?.id,
        repId: loggedInUserData?.id,
      });
      // Assuming the response contains an array of health facilities
      setHealthFacility(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    // Call the getCommunityHealthFacility function and handle the returned data

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  console.log(healthFacility, 'HF');

  const classes = useStyles();

  const key = 'iFrasCwdJh';
  let bytes = null;

  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    window.location.hash = '#/app/home-page';
  }

  const [userId] = useState(loggedInUserData?.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('add');

  const openModal = () => {
    setIsModalOpen(true);
    setModalType('add');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.log(userId);
  console.log(loggedInUserData.roleId, 'LOG_DATA');

  const navigate = useNavigate();

  const openEditModal = (facility) => {
    setSelectedFacility(facility); // Step 2: Set selected facility for editing
    setIsModalOpen(true);
    setModalType('edit');
    // setId(selectedFacility.id);
  };
  console.log(selectedFacility, 'SEL');
  // Get User Details
  const getUserData = async () => {
    const userDataResult = await getRoleData({ userId });
    setUserData(userDataResult.usersResult);

    console.log(userDataResult, 'GET');
    return userDataResult;
  };

  const initialFormData = {
    userId: userId,
    roleId: loggedInUserData.roleId,
    repId: loggedInUserData?.id,
    userDescription: '',
  };

  console.log(loggedInUserData.roleId, 'REPID');

  useEffect(
    (newValue) => {
      if (userCookie) {
        getUserData();
      }
    },
    [userCookie],
  );

  const [formData, setFormData] = useState({
    userId: userId,
    roleId: loggedInUserData.roleId,
    repId: loggedInUserData?.id,
    userDescription: '',
  });

  const handleBackToParent = (e) => {
    e.preventDefault();

    navigate(`../../community-details-health-insights/${params?.id}`);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const digits = phoneNumber?.replace(/\D/g, '');
    return digits?.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
  };

  return (
    <>
      {loggedInUserData?.roleId == 1 ? (
        <Container>
          <Box className="PageSpacing">
            <Grid container>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    Healthcare Insight
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Box className="HeadingBoxCEV FlexCenterStart">
                  <Grid item xs={4}>
                    <Typography variant="h4" className="HeadingCEV">
                      Healthcare Facilities
                    </Typography>
                  </Grid>
                  <Grid item xs={8} className="ViewButton">
                    <Link
                      onClick={openModal}
                      underline="none"
                      color="primary"
                      className="RightFloatBtn"
                    >
                      <Box component="span" marginRight={1}>
                        <AddCircleOutlineIcon />
                      </Box>
                      Add Facility
                    </Link>
                  </Grid>
                </Box>
                <Typography variant="caption" className="SubtitleCEV">
                  Inputting this content helps users find your community when filtering.
                </Typography>
              </Grid>
            </Grid>
            <br />
            <div className="CommonCardColumn">
              {Object?.keys(healthFacility)
                .sort()
                .map((facilityType) => (
                  <div key={facilityType}>
                    <Typography variant="caption" style={{ color: 'black' }}>
                      {facilityType}
                    </Typography>
                    <br></br>
                    <br></br>
                    {healthFacility[facilityType].map((facility, index) => (
                      <Card key={index} className="HealthCard">
                        <CardContent>
                          <Grid container spacing={3} direction="row" alignItems="center">
                            <div
                              className="EditIconContainer"
                              onClick={() => openEditModal(facility)}
                            >
                              <EditIcon className="EditIcon" />
                              <Typography className="EditText">EDIT</Typography>
                            </div>
                            <Grid item xs={12}>
                              <Typography variant="body1" component="div">
                                {facility.facilityName}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                component="div"
                                className="FacilityCardText"
                              >
                                <LocationOnOutlinedIcon className="FacilityIcons" />
                                {facility.facilityAddress}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                component="div"
                                className="FacilityCardText"
                              >
                                <LocalPhoneOutlinedIcon className="FacilityIcons" />
                                {formatPhoneNumber(facility.facilityPhone)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                component="div"
                                className="FacilityCardText"
                              >
                                <LanguageOutlinedIcon className="FacilityIcons" />
                                {facility.facilityWebUrl}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                ))}
            </div>
            <ModalFormik
              isOpen={isModalOpen}
              onClose={closeModal}
              selectedFacility={selectedFacility}
              updateCommunityHeathFacility={updateCommunityHeathFacility}
              fetchData={fetchData}
              modalType={modalType}
            />
          </Box>
        </Container>
      ) : (
        'You do not have permissions to access this url.'
      )}
    </>
  );
};

export default CommunityHealthFacility;
