/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, Button, Avatar, Link, Divider } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useParams, useNavigate } from 'react-router-dom';
import JobList from '../JobList/JobList';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import { ReactComponent as JobComingSoonIcon } from '../../../assets/images/JobComingSoonIcon.svg';
import useLogin from '../../../hooks/useAuth';

const Jobs = (props) => {
  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const params = useParams();
  const navigate = useNavigate();
  const { getUserDataToAdmin } = useLogin();
  const [isCommunityRepresentative] = useState(props?.isCommunityRepresentative);
  const [isAccessible, setIsAccessible] = useState(false);
  const [repDetails, setRepDetails] = useState();
  const [totalJobs, setTotalJobs] = useState(0);

  // console.log(totalJobs, 555);

  const jobListData = communityDetails?.jobs || [];

  const getRepDetails = async () => {
    try {
      if (communityDetails?.jobs[0]?.isAdminEntry) {
        const userDataResult = await getUserDataToAdmin({
          userIdd: communityDetails?.jobs[0]?.repId,
        });
        setRepDetails(userDataResult.usersResult);
      }
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  const handleTotalJobs = (total) => {
    setTotalJobs(total);
  };

  useEffect(() => {
    setIsAccessible(
      (props?.loggedInUserData &&
        communityDetails?.userrole?.[0]?.id === props?.loggedInUserData?.id) ||
        props?.loggedInUserData?.roleId === 2,
    );
    getRepDetails();
  }, [communityDetails]);

  return (
    <Paper className="WhitePaper" component="div">
      <Grid container>
        <Grid item xs={12} className="BoxHeadingContainer">
          <Typography color="primary" variant="h2" className="BoxHeading">
            Jobs
          </Typography>
          {isAccessible && !props?.publicView && communityDetails?.jobs[0]?.jobsDesc && (
            <Button
              type="submit"
              className="EditCommonButton LinkTypeButton"
              startIcon={<EditPenIconGreen />}
              size="small"
              color="primary"
              href={'/#/app/community-details-edit-jobs/' + communityDetails?.id}
            >
              Edit
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className="CommonDetailBoxGrid">
          <Typography variant="h4" component="div" className="CDNumber">
            {communityDetails?.medianIncome && '$'}
            {communityDetails?.medianIncome?.toLocaleString() || 'Unavailable'}
          </Typography>
          <Typography variant="body2" component="div" className="TextPara">
            Median Household Income
          </Typography>
          <JobList
            communityDetailView={true}
            communityInfo={communityDetails}
            onTotalJobs={handleTotalJobs}
          ></JobList>
          {/* {jobListData.length > 0 ? (
            <JobList communityDetailView={true} communityInfo={communityDetails}></JobList>
          ) : (
            <Box className="JobEmptyState">
              <Box className="JobEmptyStateContent">
                <JobComingSoonIcon />
                <Typography variant="h3" component="div">
                  Coming Soon!
                </Typography>
                <Typography variant="body1" component="div" className="LightgreyColor">
                  Stay tuned for job openings in {communityDetails?.communityNameText}.{' '}
                </Typography>
              </Box>
              <Divider />
            </Box>
          )} */}
          {/* {props?.isCommunityRepresentative && !props?.publicView && ( */}
          {jobListData.length > 0 ? (
            <Typography variant="body2" component="p" className="JobTextPara">
              Move to Happy job data is sourced by{' '}
              <Link href="https://move-to-happy.myjboard.io/" target="_blank">
                jBoard
              </Link>
              . This may not be representative of all job postings in the area.
            </Typography>
          ) : (
            ''
          )}

          {/*  )} */}
          {totalJobs > 0 ? (
            <Link
              onClick={(e) => {
                e.preventDefault();
                navigate(`../../job-list/${params?.id || communityDetails?.id}`);
              }}
              underline="none"
              color="primary"
              className="RightFloatBtn ViewJobButton"
            >
              View jobs in {communityDetails?.communityNameText}
              <Box component="span" marginLeft={1}>
                <ArrowForwardIcon />
              </Box>
            </Link>
          ) : (
            ''
          )}
        </Box>
      </Grid>

      {/* {communityDetails?.isRepresented && ( */}
      <Grid item xs={12}>
        <Box className="ChatBox CommonDetailBoxGrid JobChatBox">
          {communityDetails?.jobs[0] && communityDetails?.jobs[0]?.jobsDesc && (
            <Box>
              <Box className="ChatContent">
                <Typography variant="body2" className="ChatText" color="primary">
                  {communityDetails?.jobs[0]?.jobsDesc}
                </Typography>
              </Box>

              <Box className="UserInfoName UserInfoIP">
                <Avatar
                  className="UserAvatar small"
                  alt={`${
                    communityDetails?.jobs?.[0]?.isAdminEntry
                      ? repDetails?.firstName
                      : communityDetails?.userrole[0]?.firstName
                  } `}
                  src={
                    window.location.hostname === 'localhost'
                      ? `//localhost:9001/${
                          communityDetails?.jobs?.[0]?.isAdminEntry
                            ? repDetails?.profilePic
                            : communityDetails?.userrole[0]?.profilePic
                        }`
                      : `/api/${
                          communityDetails?.jobs?.[0]?.isAdminEntry
                            ? repDetails?.profilePic
                            : communityDetails?.userrole[0]?.profilePic
                        }`
                  }
                >
                  <PersonIcon />
                </Avatar>
                <Box className="UserInfoText">
                  <Typography variant="h6">
                    <Box component="span" className="FirstName">
                      {communityDetails?.jobs?.[0]?.isAdminEntry
                        ? repDetails?.firstName
                        : communityDetails?.userrole[0]?.firstName}
                    </Box>
                    <Box component="span" className="LastName">
                      {communityDetails?.jobs?.[0]?.isAdminEntry
                        ? repDetails?.lastName
                        : communityDetails?.userrole[0]?.lastName}
                    </Box>
                  </Typography>
                  <Typography variant="caption">
                    {communityDetails?.jobs?.[0]?.isAdminEntry
                      ? repDetails?.userDesignationText
                      : communityDetails?.userrole[0]?.userDesignationText}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
      {/* )} */}
      {isAccessible && !props?.publicView && (
        <>
          <If
            condition={
              communityDetails?.jobs?.length == 0 || communityDetails?.jobs[0]?.jobsDesc == ''
            }
          >
            <Grid item xs={12}>
              <Divider className="DividerCD"></Divider>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <Typography variant="body2" className="InsightLabel">
                  Highlight the economic activity and job market in your Community.
                </Typography>
              </Box>
            </Grid>
          </If>
        </>
      )}
      <If
        condition={
          props?.isAccessible && !props?.publicView && !communityDetails?.jobs[0]?.jobsDesc
        }
      >
        <Grid item xs={12}>
          <Box className="Wrapper ButtonWrapper InsightButtonContainer">
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              href={'/#/app/community-details-edit-jobs/' + communityDetails?.id}
            >
              {/* <img
                  src={require('../../../assets/images/Insight.svg').default}
                  alt="Insight Icon"
                /> */}
              {communityDetails?.jobs[0]?.jobsDesc ? ' Edit' : ' Add'}
              <Box className="AddButton">Insight</Box>
            </Button>
          </Box>
        </Grid>
      </If>
    </Paper>
  );
};

export default Jobs;
