/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'; // Import useFormik
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import {
  Grid,
  Link,
  Typography,
  Box,
  TextField,
  InputLabel,
  Button,
  Container,
  Divider,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useCommunityList from '../../../../hooks/useCommunityList';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';

const CommunityEditViewResidents = (props) => {
  const theme = useTheme();
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [residentsDesc, setResidentsDesc] = React.useState('');
  const [isResidentsDesc, setIsResidentsDesc] = React.useState(false);
  const [communityDetails, setCommunityDetails] = React.useState(null);
  const maxCharacterCount = 700;
  const { addCommunityCatResidents, getCommunityDetailById } = useCommunityList();
  const params = useParams();
  const navigate = useNavigate();
  const [isAccessible, setIsAccessible] = useState(false);

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResidentsDesc(value);
  };

  const formik = useFormik({
    initialValues: {
      repId: loggedInUserData?.id,
      communityId: params?.id,
      residentsDesc: residentsDesc,
      isAdminEntry: loggedInUserData?.roleId === 2,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const residentsDesc = values.residentsDesc?.trim();
        values['residentsDesc'] = residentsDesc;
        const response = await addCommunityCatResidents(values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response); // The response from the API
        formik.resetForm(); // Reset the form after successful submission
        navigate(`../../community-details/${params?.id}#Section6`);
        // Do something with the response, e.g., show a success message
      } catch (error) {
        console.error(error.response.data); // Log any error response from the API
        // Handle error, e.g., show an error message
      }
    },
  });

  const fillCommunityDetails = async () => {
    let communityDetailsResults = [];
    if (params?.id) {
      console.log(userId);
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults.data);
      setResidentsDesc(communityDetailsResults?.data?.residents?.[0]?.residentsDesc || '');
      if (communityDetailsResults?.data?.residents?.[0]?.residentsDesc !== '') {
        setIsResidentsDesc(true);
      }
    }
    return communityDetailsResults?.data;
  };

  useEffect(() => {
    fillCommunityDetails();
  }, []);

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (formik.dirty) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${params?.id}#Section6`);
    }
  };

  useEffect(() => {
    loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2
      ? setIsAccessible(true)
      : setIsAccessible(false);
  }, [loggedInUserData]);
  return (
    <Container>
      <Box className="PageSpacing FixedMD CommunityEditViewResidents">
        {isAccessible ? (
          <>
            {showDeleteBox && (
              <ModalEditViewDelete
                status={true}
                navigateUrl={`../../community-details/${params?.id}#Section6`}
                onClose={onTakeMeback}
              ></ModalEditViewDelete>
            )}
            <Grid container direction="row" className="ResidentBoxCEV">
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    Resident Insights
                  </Typography>
                </Link>
              </Grid>
              <Grid container className="FixedID">
                <Grid item xs={6} className="AutoWidth">
                  <Box className="CommonDetailBoxGrid DataCDNumber">
                    <Typography variant="body1" component="div" className="CDNumber">
                      {communityDetails && communityDetails.population.toLocaleString()}
                    </Typography>
                    <Typography variant="body2" component="div">
                      Population Per Square Mile
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} className="AutoWidth">
                  <Box className="CommonDetailBoxGrid">
                    <Typography variant="body1" component="div" className="CDNumber">
                      {communityDetails && communityDetails.medianAge.toLocaleString()} years
                    </Typography>
                    <Typography variant="body2" component="div">
                      Median Age
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider className="DividerCD"></Divider>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <InputLabel className="InputLabelFld">
                      Add what people should know about the residents of your Community.
                    </InputLabel>
                  </Box>
                  <TextField
                    className="TextFieldTextarea"
                    fullWidth
                    multiline
                    minRows={8}
                    maxLength={700}
                    label=""
                    variant="outlined"
                    value={residentsDesc}
                    name="residentsDesc"
                    onChange={handleChange}
                    {...formik.getFieldProps('residentsDesc')}
                    inputProps={{
                      maxLength: maxCharacterCount,
                    }}
                    helperText={`${maxCharacterCount - formik.values.residentsDesc.length}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className="Wrapper ButtonWrapper">
                    <Button
                      disabled={
                        !formik.dirty ||
                        (formik.values.residentsDesc.length === 0 && !isResidentsDesc)
                      }
                      onClick={formik.handleSubmit}
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="EditViewButton"
                      fullWidth
                    >
                      Publish
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          'You do not have permissions to access this URL.'
        )}
      </Box>
    </Container>
  );
};

CommunityEditViewResidents.defaultProps = {};
export default CommunityEditViewResidents;
