/* eslint-disable*/
import React from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import { ReactComponent as PublicIcon } from '../../../assets/images/PublicIcon.svg';

const ViewPublic = ({ communityDetails, setPublicView }) => {
  return (
    <Box className="FlexCenterStart ViewPublicBox" justifyContent="space-between" mb={2}>
      <Typography className="FlexCenterStart" variant="body1" component="span">
        <PublicIcon style={{ marginRight: '10' }} /> Viewing as Public
      </Typography>
      <Link
        className="ExitButton"
        underline="none"
        onClick={() => {
          if (setPublicView) setPublicView(false);
        }}
      >
        Exit
      </Link>
    </Box>
  );
};

export default ViewPublic;
