/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import {
  IconButton,
  Link,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  Paper,
  Typography,
  Box,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const ITEM_HEIGHT = 48;

export default function ActionButton({ setPublicView, communityId, user }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="MoreHorizIcon"
      >
        <MoreHorizIcon color="primary" />
      </Box>

      <Menu
        className="ActionButtonPopup"
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        // PaperProps={{
        //   style: {
        //     maxHeight: ITEM_HEIGHT * 4.5,
        //     width: '20ch',
        //   },
        // }}
      >
        <>
          <MenuItem
            className="ActionButtonContainer"
            onClick={() => {
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
              if (setPublicView) setPublicView(true);
              handleClose();
            }}
          >
            <ListItemIcon className="ActionButtonIcon">
              <img
                src={require('../../../assets/images/PublicView.svg').default}
                alt="PublicView"
              />
            </ListItemIcon>
            <Typography variant="inherit">Public View</Typography>
          </MenuItem>
          <If condition={user?.roleId === 1}>
            <MenuItem
              className="ActionButtonContainer LeaveCommunity"
              onClick={() => {
                if (communityId) navigate(`/app/leave-community/${communityId}`);
                // handleClose();
              }}
            >
              <ListItemIcon className="ActionButtonIcon">
                <img
                  src={require('../../../assets/images/LeaveCommunity.svg').default}
                  alt="LeaveCommunity"
                />
              </ListItemIcon>
              <Typography variant="inherit">Leave Community</Typography>
            </MenuItem>
          </If>
        </>
      </Menu>
    </Box>
  );
}
