/* eslint-disable */
import React from 'react';
import './AuthLogo.styles.css';

const MainLogo = () => {
  return (
    <div className="LogoBox">
      <img
        className="Logo"
        src={require('../../assets/images/MTH-logo.svg').default}
        alt="Logo"
      />
    </div>
  );
};

export default MainLogo;
