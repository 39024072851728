/* eslint-disable  */

import { makeStyles } from '@material-ui/core/styles';
// import bgstyle03 from '../../assets/images/bgstyle03.jpg';

const useStyles = makeStyles((theme) => ({

  // hide: {
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'none',
  //   },
  // },
  // root: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   direction: 'column',
  //   justify: 'flex-start',
  // },



  // Overrides style - modal dialog



  //MTH





  // container: {
  //   width: '375px',
  //   height: 'auto',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //     minHeight: '100%',
  //     overflowY: 'auto',
  //     overflowX: 'hidden',
  //   },
  // },



}));
export default useStyles;
