/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik'; // Import useFormik
import CryptoJS from 'crypto-js';
import {
  Grid,
  Link,
  Typography,
  Box,
  TextField,
  InputLabel,
  Button,
  Container,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useCommunityList from '../../../../hooks/useCommunityList';
import useLogin from '../../../../hooks/useAuth';
import ModalFormik from './ModalFormik';
import Cookies from 'js-cookie';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';
import ModalInsightDelete from './ModalInsightDelete';
import { useNavigate, useParams } from 'react-router-dom';

const CommunityHealthInsights = (selectedFacility) => {
  const {
    addCommunityHealthInsights,
    getCommunityHealthFacility,
    getCommunityDetailById,
    getByFacilityId,
  } = useCommunityList();
  const { getRoleData } = useLogin();
  const params = useParams();
  const [userData, setUserData] = useState();
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [showInsightsDeleteBox, setInsightsDeleteBox] = useState(false);
  const key = 'iFrasCwdJh';
  let bytes = null;

  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    window.location.hash = '#/app/home-page';
  }

  const [userId] = useState(loggedInUserData?.id);
  const [healthFacility, setHealthFacility] = useState([]);
  const [communityDetails, setCommunityDetails] = useState(null);
  const [healthCare, setHealthCare] = useState('');
  const [isHealthCare, setIsHealthCare] = useState(false);
  const [compRepId, setCompRepId] = useState(null);

  const maxCharacterCount = 700;

  const navigate = useNavigate();

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
    setInsightsDeleteBox(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHealthCare(value);
    // setIsEdited(true);
    // setHasChanges(true); // Set hasChanges to true when input changes
  };



  const formik = useFormik({
    initialValues: {
      repId: loggedInUserData?.id,
      communityId: params?.id,
      healthCareDesc: healthCare,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const healthCareDesc = values.healthCareDesc?.trim();
      values['healthCareDesc'] = healthCareDesc;
      values['isAdminEntry'] = Boolean(loggedInUserData?.roleId === 2) 
      try {
        const response = await addCommunityHealthInsights(values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response); // The response from the API
        formik.resetForm(); // Reset the form after successful submission
        navigate(`../../community-details/${params?.id}#Section11`);
        // Do something with the response, e.g., show a success message
      } catch (error) {
        console.error(error.response.data); // Log any error response from the API
        // Handle error, e.g., show an error message
      }
    },
  });

  const fetchData = async () => {
    try {
      const response = await getCommunityHealthFacility({
        communityId: params?.id,
        repId: loggedInUserData?.id,
      });

      // Assuming the response contains an array of health facilities
      setHealthFacility(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fillCommunityDetails = async () => {
    let communityDetailsResults = [];
    if (params?.id) {
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        // userId,
      });
      setCommunityDetails(communityDetailsResults.data);
      const mostRecentHealthInsight = communityDetailsResults?.data?.healthInsights
        ?.filter((x) => loggedInUserData?.roleId === 2 || loggedInUserData?.roleId === 1)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];

      setHealthCare(mostRecentHealthInsight?.healthCareDesc || '');

      if (mostRecentHealthInsight?.healthCareDesc !== '') {
        setIsHealthCare(true);
      }
    }
    return communityDetailsResults?.data;
  };

  useEffect(() => {
    if (compRepId && compRepId[0] && compRepId[0].repId) {
      // fetchData();
    }
    fillCommunityDetails();
  }, [compRepId, params?.id, params?.facilityId]);

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (formik.dirty) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${params?.id}#Section11`);
    }
  };

  return (
    <Container>
      <Box className="PageSpacing FixedMD CommunityEditViewHealthInsight">
        {loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2 ? (
          <>
            {showDeleteBox && (
              <ModalEditViewDelete
                status={true}
                navigateUrl={`../../community-details/${params?.id}#Section11`}
                onClose={onTakeMeback}
              />
            )}
            {showInsightsDeleteBox && (
              <ModalInsightDelete
                status={true}
                navigateUrl={`../../community-details-health-facilities/${params?.id}`}
                onClose={onTakeMeback}
              />
            )}
            <Grid container>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    Healthcare Insight
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container className="FixedID">
              {/* Hi Rutvik --  Ubaid Commented below code */}
              {/* <Grid item xs={12}>
                <Box className="HeadingBoxCEV FlexCenterStart">
                  <Grid item xs={4}>
                    <Typography variant="h4" className="HeadingCEV">
                      Healthcare Facilities
                    </Typography>
                  </Grid>

                  <Grid item xs={8} className="ViewButton">
                    <Link
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                      underline="none"
                      color="primary"
                      className="RightFloatBtn"
                    >
                      <Box component="span" marginRight={1}>
                        <AddCircleOutlineIcon />
                      </Box>
                      Add Facility
                    </Link>
                  </Grid>
                </Box>
                <Typography variant="caption" className="SubtitleCEV">
                  Inputting this content helps users find your community when filtering.
                </Typography>
              </Grid> */}
              {/* <Grid item xs={12} className="ViewButton">
                {Object.values(healthFacility).some((facilities) => facilities.length > 0) ? (
                  <Link
                    onClick={() => {
                      if (formik.dirty) {
                        setInsightsDeleteBox(true);
                      } else {
                        navigate('../../community-details-health-facilities/' + params?.id);
                      }
                    }}
                    underline="none"
                    color="primary"
                    className="RightFloatBtn"
                  >
                    View Healthcare Facilities
                    <Box component="span" marginLeft={1}>
                      <ArrowForwardIcon />
                    </Box>
                  </Link>
                ) : (
                  ''
                )}
              </Grid> */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <InputLabel className="InputLabelFld">
                    Describe your community’s healthcare. Are there any specialties or awards
                    facilities are known for?
                  </InputLabel>
                </Box>
                <TextField
                  className="TextFieldTextarea"
                  fullWidth
                  multiline
                  minRows={8}
                  maxLength={maxCharacterCount}
                  value={healthCare}
                  onChange={handleChange}
                  name="healthCareDesc"
                  label=""
                  variant="outlined"
                  {...formik.getFieldProps('healthCareDesc')}
                  inputProps={{
                    maxLength: maxCharacterCount,
                  }}
                  helperText={`${maxCharacterCount - formik.values.healthCareDesc.length}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="Wrapper ButtonWrapper">
                  <Button
                    disabled={
                      !formik.dirty || (formik.values.healthCareDesc.length === 0 && !isHealthCare)
                    }
                    onClick={formik.handleSubmit}
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="EditViewButton"
                    fullWidth
                  >
                    Publish
                  </Button>
                </Box>
              </Grid>
              <ModalFormik
                isOpen={isModalOpen}
                onClose={() => {
                  setIsModalOpen(false);
                }}
                modalType={modalType}
                fetchData={fetchData}
              />
            </Grid>
          </>
        ) : (
          'You do not have permissions to access this url.'
        )}
      </Box>
    </Container>
  );
};

export default CommunityHealthInsights;
