/* eslint-disable */
import React from 'react';
import { TextField } from '@material-ui/core';
import _ from 'lodash';

const CustomTextInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  renderComponent,
  ...props
}) => {
  return (
    <div>
      <TextField
        error={_.get(touched, field.name) && _.get(errors, field.name) && true}
        helperText={_.get(touched, field.name) && _.get(errors, field.name)}
        {...field}
        {...props}
      >
        {renderComponent}
      </TextField>
    </div>
  );
};

CustomTextInput.propTypes = {};

export default CustomTextInput;
