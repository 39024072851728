/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react';
import {
  InputLabel,
  Button,
  Typography,
  Box,
  InputBase,
  InputAdornment,
  TextField,
  Popover,
  Paper,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import { Formik, Form, FastField, Field } from 'formik';
import { ReactComponent as SearchIcon } from '../../../../assets/images/Search-Icon.svg';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import {
  COST_OF_LIVING_TYPE,
  FACILITY_TYPES,
  OTHER_TRANSPORTATION_TYPES,
  PUBLIC_TRANSPORTATION_TYPES,
  SCHOOL_TYPES,
  entityEnum,
} from '../../../../constants/globalConstants';
import useCommunityList from '../../../../hooks/useCommunityList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import '../../Main.style.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

const CommunityListSearch = ({
  searchedText,
  clearSearch,
  handleFilterClose,
  handleSearchChange,
  open,
  anchorEl,
  handleFilterClick,
  intialWhereValue,
  communityList,
  tabValue,
  fillCommunityList,
  allCommunities,
}) => {
  const { getAllCommunities, getCommunitiesByUser } = useCommunityList();
  const [resultCount, setResultCount] = useState(0);
  const [seeResultText, setSeeResultText] = useState('See Results');
  const [total, setTotal] = useState(0);
  let stateCommunitiesArr = [];
  let userCommunitiesArr = [];
  const favCommunitiesList = {};
  const recCommunitiesList = {};
  let filteredList = [];
  const [isLoading, setIsLoading] = useState(false);
  const key = 'iFrasCwdJh';
  const formikRef = useRef();
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);

  const getTotal = async (values) => {
    const totalCount =
      values?.states?.length +
      ((values?.minPopulation > -1 && 1) || 0) +
      ((values?.maxPopulation > -1 && 1) || 0) +
      values?.amenitiesType?.length +
      values?.costOfLivingType?.length +
      values?.publicTransType?.length +
      ((values?.dedicatedWalkingPaths === true && 1) + 0) +
      ((values?.dedicatedBikingPaths === true && 1) + 0) +
      ((values?.localAirport === true && 1) + 0) +
      ((values?.regionalAirport === true && 1) + 0) +
      ((values?.publicType === true && 1) + 0) +
      ((values?.privateType === true && 1) + 0) +
      ((values?.faithBasedPrivate === true && 1) + 0) +
      ((values?.specialNeedsPrivate === true && 1) + 0) +
      ((values?.publicTechnicalJuniorCollege === true && 1) + 0) +
      ((values?.privateTechnicalJuniorCollege === true && 1) + 0) +
      ((values?.publicFourYearCollegeUniversity === true && 1) + 0) +
      ((values?.privatecFourYearCollegeUniversity === true && 1) + 0) +
      ((values?.homeschoolResourceGroups === true && 1) + 0) +
      ((values?.charter === true && 1) + 0) +
      values?.facilityType?.length;
    setTotal(totalCount);
    console.log('totalCount', totalCount);
    if (totalCount) {
      const data = await getAllCommunities({
        ...values,
        states: values?.states?.map((s) => s?.id),
        getResultCount: true,
      });
      console.log('getResultCount', data?.count);
      setResultCount(data?.count || 0);
    } else {
      setResultCount(0);
    }
  };
  // console.log(communityList, 9696);
  useEffect(() => {
    setSeeResultText(
      `See  ${resultCount > 0 ? `[${resultCount}]` : ``} result${resultCount > 1 ? `s` : ``}`,
    );
  }, [resultCount]);

  const loadAllStates = async () => {
    let allCommunityStatesArr = [];
    // const communityList = await getAllCommunities();

    allCommunities?.forEach((community) => {
      if (community.stateCommunities && community.stateCommunities.stateName) {
        allCommunityStatesArr.push({
          stateName: community.stateCommunities.stateName,
          id: community.stateCommunities.id,
        });
      }
    });
    // Remove duplicates based on stateName
    allCommunityStatesArr = allCommunityStatesArr.reduce((unique, item) => {
      return unique.some((obj) => obj.stateName === item.stateName) ? unique : [...unique, item];
    }, []);
    LocationOptionsBox = [];
    LocationOptionsBox = allCommunityStatesArr;
    console.log(communityList, 9797);
  };

  useEffect(() => {
    loadAllStates();
  }, [allCommunities]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [tabValue]);

  const getCount = (values) => {
    getTotal(values);
    return (
      <Box className="FilterCount" component="span">
        {total > 0 ? `(${total})` : ''}
      </Box>
    );
  };
  return (
    <>
      {' '}
      <Formik
        initialValues={intialWhereValue}
        // validationSchema={SetPasswordSchema}
        onSubmit={async (values) => {
          // setWhere(values);
          await fillCommunityList({ ...values, states: values?.states?.map((s) => s?.id) });
          handleFilterClose();
          // loadAllStates();
        }}
        innerRef={formikRef}
      >
        {({
          errors,
          touched,
          isSubmitting,
          isValid,
          dirty,
          values,
          setFieldTouched,
          setFieldValue,
          submitForm,
          resetForm,
        }) => (
          <>
            <Box className="SearchBoxContainer" mb={0}>
              <TextField
                className="SearchBox"
                value={searchedText}
                as={InputBase}
                onChange={handleSearchChange}
                variant="outlined"
                type="text"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className="GreenColor" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={clearSearch}>
                      {searchedText !== '' && <CloseIcon className="GreenColor" />}
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                className="SearchButton"
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleFilterClick}
                startIcon={<FilterListIcon color="primary" />}
                variant="outlined"
              >
                Filters {getCount(values)}
              </Button>
            </Box>
            <Popover
              id="long-menu"
              open={open}
              anchorEl={anchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              className="MobileFilter"
            >
              {console.log('78768768', { values })}
              <Paper className="CommonFilterBox AmenityFilters">
                <Box className="FilterHeader">
                  <Typography className="FilterBackButton" variant="h4" onClick={handleFilterClose}>
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    Community Filters
                  </Typography>
                </Box>
                <Box className="FilterBody">
                  <Box className="FilterTagBox">
                    <Typography variant="h3" className="LightgreyColor FilterTagHeading">
                      Location
                    </Typography>
                    <Box className="FilterTagContent FilterDropdownContent">
                      <InputLabel className="InputLabelFld" htmlFor="email">
                        State
                      </InputLabel>
                      <Box className="AutocompleteBox">
                        <Box className="CommonAutocomplete">
                          <Autocomplete
                            className="CommonAutocomplete"
                            multiple
                            name="states"
                            id="checkboxes-tags-demo"
                            options={LocationOptionsBox}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.stateName}
                            value={values.states}
                            /* getOptionSelected={(option, value) => {
                            return option?._id === value || option?._id === value?._id;
                          }} */
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 0 }}
                                  checked={selected}
                                />
                                {option.stateName}
                              </React.Fragment>
                            )}
                            onChange={(e, value) => setFieldValue('states', value)}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="" label="" variant="outlined" />
                            )}
                            popupIcon={<KeyboardArrowDownIcon />}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="FilterTagBox">
                    <Typography variant="h3" className="LightgreyColor FilterTagHeading">
                      Residents
                    </Typography>
                    <Box className="FilterTagContent FilterDropdownContent">
                      <InputLabel className="InputLabelFld" htmlFor="email">
                        Population
                      </InputLabel>
                      <Box className="AutocompleteBox">
                        <Box className="HalfwidthBox">
                          <Autocomplete
                            className="CommonAutocomplete"
                            id="minPopulation"
                            name="minPopulation"
                            options={PopulationOptionsBoxMin}
                            getOptionLabel={(option) => option?.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="No Min"
                                label=""
                                variant="outlined"
                              />
                            )}
                            popupIcon={<KeyboardArrowDownIcon />}
                            value={PopulationOptionsBoxMin?.find(
                              (p) => p?.value === values.minPopulation,
                            )}
                            onChange={(e, data) => setFieldValue('minPopulation', data?.value)}
                          />
                        </Box>
                        <Box className="DashIcon">
                          <Typography variant="body2">-</Typography>
                        </Box>
                        <Box className="HalfwidthBox">
                          <Autocomplete
                            className="CommonAutocomplete"
                            id="maxPopulation"
                            name="maxPopulation"
                            options={PopulationOptionsBoxMax?.map((p) =>
                              values?.minPopulation > p?.value && p?.value !== -1
                                ? { ...p, disabled: true }
                                : p,
                            )}
                            getOptionDisabled={(option) => option.disabled}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="No Max"
                                label=""
                                variant="outlined"
                              />
                            )}
                            popupIcon={<KeyboardArrowDownIcon />}
                            value={PopulationOptionsBoxMax?.find(
                              (p) => p?.value === values.maxPopulation,
                            )}
                            onChange={(e, data) => setFieldValue('maxPopulation', data?.value)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="FilterTagBox">
                    <Typography variant="h3" className="LightgreyColor FilterTagHeading">
                      Amenities
                    </Typography>
                    <Typography component="div" variant="caption" className="FilterTagSubHeading">
                      Only represented communities with this content will show in results.
                    </Typography>
                    {amenitiesOptions?.map((amenitiesCategory) => (
                      <Box key={amenitiesCategory?.id} className="FilterTagContent FilterTagInner">
                        <InputLabel className="InputLabelFld">{amenitiesCategory?.name}</InputLabel>
                        {amenitiesCategory?.types?.map((amenitiesType) => (
                          <Button
                            key={amenitiesCategory?.id + amenitiesType?.id}
                            className={`FilterTag ${
                              values?.amenitiesType?.includes(amenitiesType?.id) ? 'Selected' : ''
                            }`}
                            onClick={() => {
                              setFieldValue(
                                'amenitiesType',
                                values?.amenitiesType &&
                                  values?.amenitiesType?.includes(amenitiesType?.id)
                                  ? values?.amenitiesType.filter(
                                      (selected) => selected !== amenitiesType?.id,
                                    )
                                  : [...values?.amenitiesType, amenitiesType?.id],
                              );
                              /* setTimeout(() => {
                                setFieldTouched('amenitiesType');
                              }, 2000); */
                              // handleFilterClick1(facilityType);
                            }}
                          >
                            {amenitiesType?.name}
                          </Button>
                        ))}
                      </Box>
                    ))}
                  </Box>
                  <Box className="FilterTagBox">
                    <Typography variant="h3" className="LightgreyColor FilterTagHeading">
                      Cost of Living
                    </Typography>
                    <Typography component="div" variant="caption" className="FilterTagSubHeading">
                      Only represented communities with this content will show in results.
                    </Typography>
                    <Box className="FilterTagContent FilterTagInner">
                      <InputLabel className="InputLabelFld" htmlFor="email">
                        Cost of Living
                      </InputLabel>
                      <FormControl component="fieldset" className="">
                        <FormGroup>
                          {COST_OF_LIVING_TYPE?.map((costOfLivingType) => (
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  color="primary"
                                  name="costOfLivingType"
                                  value={costOfLivingType}
                                  checked={values?.costOfLivingType?.some(
                                    (c) => c === costOfLivingType,
                                  )}
                                />
                              }
                              label={costOfLivingType}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="FilterTagBox">
                    <Typography variant="h3" className="LightgreyColor FilterTagHeading">
                      Transportation
                    </Typography>
                    <Typography component="div" variant="caption" className="FilterTagSubHeading">
                      Only represented communities with this content will show in results.
                    </Typography>
                    <Box className="FilterTagContent FilterTagInner">
                      <InputLabel className="InputLabelFld" htmlFor="email">
                        Public Transportation
                      </InputLabel>
                      <FormControl component="fieldset" className="">
                        <FormGroup>
                          {PUBLIC_TRANSPORTATION_TYPES?.map((publicTransType) => (
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  color="primary"
                                  name="publicTransType"
                                  value={publicTransType?.key}
                                  checked={values?.publicTransType?.some(
                                    (c) => c === publicTransType?.key,
                                  )}
                                />
                              }
                              label={publicTransType?.label}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Box>
                    <Box className="FilterTagContent FilterTagInner">
                      <InputLabel className="InputLabelFld" htmlFor="email">
                        Other Transportation
                      </InputLabel>
                      <FormControl component="fieldset" className="">
                        <FormGroup>
                          {OTHER_TRANSPORTATION_TYPES?.map((otherTransType) => (
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  color="primary"
                                  name={otherTransType?.key}
                                  value={otherTransType?.key}
                                  checked={values[otherTransType?.key]}
                                />
                              }
                              label={otherTransType?.label}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="FilterTagBox">
                    <Typography variant="h3" className="LightgreyColor FilterTagHeading">
                      Education
                    </Typography>
                    <Typography component="div" variant="caption" className="FilterTagSubHeading">
                      Only represented communities with this content will show in results.
                    </Typography>
                    <Box className="FilterTagContent FilterTagInner">
                      <InputLabel className="InputLabelFld" htmlFor="email">
                        School Types
                      </InputLabel>
                      <FormControl component="fieldset" className="">
                        <FormGroup>
                          {SCHOOL_TYPES?.map((schoolType) => (
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  color="primary"
                                  name={schoolType?.key}
                                  value={schoolType?.key}
                                  checked={values[schoolType?.key]}
                                />
                              }
                              label={schoolType?.label}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="FilterTagBox">
                    <Typography variant="h3" className="LightgreyColor FilterTagHeading">
                      Healthcare
                    </Typography>
                    <Typography component="div" variant="caption" className="FilterTagSubHeading">
                      Only represented communities with this content will show in results.
                    </Typography>
                    <Box className="FilterTagContent FilterTagInner">
                      <InputLabel className="InputLabelFld" htmlFor="email">
                        Healthcare Facilities
                      </InputLabel>
                      {FACILITY_TYPES?.map((facilityType) => (
                        <Button
                          key={facilityType}
                          className={`FilterTag ${
                            values?.facilityType.includes(facilityType) ? 'Selected' : ''
                          }`}
                          onClick={() => {
                            setFieldValue(
                              'facilityType',
                              values?.facilityType.includes(facilityType)
                                ? values?.facilityType.filter(
                                    (selected) => selected !== facilityType,
                                  )
                                : [...values?.facilityType, facilityType],
                            );
                            setFieldTouched('facilityType');
                            setFieldTouched('facilityType');
                            setTimeout(() => {
                              setFieldTouched('facilityType');
                            }, 2000);
                            // handleFilterClick1(facilityType);
                          }}
                        >
                          {facilityType}
                        </Button>
                      ))}
                      {/* <Button className="FilterTag">Assisted Living Facility</Button>
                    <Button className="FilterTag">Behavioral Health Facility</Button>
                    <Button className="FilterTag">Home Healthcare</Button>
                    <Button className="FilterTag">Hospice Services</Button>
                    <Button className="FilterTag">Hospital - General Acute Care</Button>
                    <Button className="FilterTag">Hospital - Specialty Care</Button>
                    <Button className="FilterTag">Long Term Care Facility</Button>
                    <Button className="FilterTag">Outpatient Specialty Care Facility</Button> */}
                    </Box>
                  </Box>
                </Box>
                <Box className="FilterFooter">
                  <Divider />
                  <Box className="FilterAction">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        resetForm();
                        // loadAllStates();
                        // submitForm();
                      }}
                    >
                      Reset All
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        submitForm();
                        // loadAllStates();
                      }}
                      color="primary"
                      size="small"
                    >
                      {seeResultText}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Popover>
          </>
        )}
      </Formik>
    </>
  );
};

let LocationOptionsBox = [
  { stateName: 'Alabama', id: 1 },
  { stateName: 'Georgia', id: 0 },
];
const PopulationOptionsBoxMin = [
  { label: 'No Min', value: -1 },
  { label: '5,000', value: 5000 },
  { label: '50,000', value: 50000 },
  { label: '100,000', value: 100000 },
  { label: '250,000', value: 250000 },
  { label: '500,000', value: 500000 },
];
const PopulationOptionsBoxMax = [
  { label: 'No Max', value: -1 },
  { label: '5,000', value: 5000 },
  { label: '50,000', value: 50000 },
  { label: '100,000', value: 100000 },
  { label: '250,000', value: 250000 },
  { label: '500,000', value: 500000 },
];

const amenitiesOptions = [
  {
    id: '45234d87-4945-4521-92d7-77a188d02665',
    name: 'Arts, Culture, & Entertainment',
    types: [
      {
        id: '92a376f9-b563-4f2e-bbc1-9d33edbc36f0',
        name: 'Cultural Institutions',
        categoryId: '45234d87-4945-4521-92d7-77a188d02665',
      },
      {
        id: 'a0f1cc94-e9be-4085-b89d-554453b44e3f',
        name: 'Entertainment Centers',
        categoryId: '45234d87-4945-4521-92d7-77a188d02665',
      },
      {
        id: 'e8ba56b6-ca04-4bbe-9306-edd07454418a',
        name: 'Performing Arts',
        categoryId: '45234d87-4945-4521-92d7-77a188d02665',
      },
      {
        id: 'e9551237-868f-449e-81e0-4c782aa41836',
        name: 'Theme Parks',
        categoryId: '45234d87-4945-4521-92d7-77a188d02665',
      },
    ],
  },
  {
    id: '8de5fb8f-c56d-43a6-921b-b74d8a648931',
    name: 'Events & Festivals',
    types: [
      {
        id: '5b1ca08c-7c0b-4d47-b86b-71565e40d1df',
        name: 'Fairs',
        categoryId: '8de5fb8f-c56d-43a6-921b-b74d8a648931',
      },
      {
        id: '235c2f33-db6d-45f6-8960-aa7bebbc2e2d',
        name: 'Festivals',
        categoryId: '8de5fb8f-c56d-43a6-921b-b74d8a648931',
      },
      {
        id: '0a8b9e92-b3e2-4d2a-9733-05b7d445a8ff',
        name: 'Parades',
        categoryId: '8de5fb8f-c56d-43a6-921b-b74d8a648931',
      },
    ],
  },
  {
    id: 'ac2620bb-df6a-4104-8f26-8dd8849bc494',
    name: 'Outdoor Activities & Sports',
    types: [
      {
        id: '1e455045-89f4-4bdb-b82b-237533031b76',
        name: 'Biking & Walking',
        categoryId: 'ac2620bb-df6a-4104-8f26-8dd8849bc494',
      },
      {
        id: 'dd19dc2b-5730-4a6c-a77d-e58afda95d03',
        name: 'Fitness',
        categoryId: 'ac2620bb-df6a-4104-8f26-8dd8849bc494',
      },
      {
        id: '11b0991e-5df2-4b59-9f79-dd14a0ed55e6',
        name: 'Gardens',
        categoryId: 'ac2620bb-df6a-4104-8f26-8dd8849bc494',
      },
      {
        id: 'b3c16bb1-bad5-45e7-aede-9d5dc2bea14d',
        name: 'Golf',
        categoryId: 'ac2620bb-df6a-4104-8f26-8dd8849bc494',
      },
      {
        id: '6eabbd9c-a147-4cd6-88df-c6d67aad5680',
        name: 'Parks & Playgrounds',
        categoryId: 'ac2620bb-df6a-4104-8f26-8dd8849bc494',
      },
      {
        id: 'ba636859-e142-44b0-9939-bddeae7d4faa',
        name: 'Professional Sports',
        categoryId: 'ac2620bb-df6a-4104-8f26-8dd8849bc494',
      },
      {
        id: '8005b8ba-dd66-498f-b191-2b9f750cb329',
        name: 'Sport Complexes',
        categoryId: 'ac2620bb-df6a-4104-8f26-8dd8849bc494',
      },
      {
        id: '4da57475-b8fa-4621-b826-98c1d2b09c70',
        name: 'Winter Facilities',
        categoryId: 'ac2620bb-df6a-4104-8f26-8dd8849bc494',
      },
    ],
  },
  {
    id: 'f04da3f2-4801-4a51-9b05-64e5e8b7a22b',
    name: 'Religion & Spirituality',
    types: [
      {
        id: 'f63f58f6-23de-417f-8fe7-193520094341',
        name: 'Churches',
        categoryId: 'f04da3f2-4801-4a51-9b05-64e5e8b7a22b',
      },
      {
        id: '289b5ec0-d7d2-40e1-8201-28ee3f16de83',
        name: 'Mosques',
        categoryId: 'f04da3f2-4801-4a51-9b05-64e5e8b7a22b',
      },
      {
        id: 'f84ad034-159d-4c4c-ae64-3958cf6e7d6b',
        name: 'Temples',
        categoryId: 'f04da3f2-4801-4a51-9b05-64e5e8b7a22b',
      },
      {
        id: '741e130a-d314-481a-88dc-91e5b37bfd89',
        name: 'Synagogues',
        categoryId: 'f04da3f2-4801-4a51-9b05-64e5e8b7a22b',
      },
    ],
  },
  {
    id: '999792ab-fa00-4e73-8a02-b0edfba8c4ad',
    name: 'Shopping',
    types: [
      {
        id: '01002f51-5e5a-488c-88aa-f9cd9b1e3d59',
        name: 'Farmers Markets',
        categoryId: '999792ab-fa00-4e73-8a02-b0edfba8c4ad',
      },
      {
        id: 'a5a5eb90-5fac-4c24-9a4e-79d3a72c8cb7',
        name: 'Mall & Outlets',
        categoryId: '999792ab-fa00-4e73-8a02-b0edfba8c4ad',
      },
    ],
  },
  {
    id: '242ff743-ed72-4032-a153-2a09dbf1641a',
    name: 'Landscape',
    types: [
      {
        id: '2ee52e8d-8516-43e2-99ee-e2203cd496c3',
        name: 'Beaches',
        categoryId: '242ff743-ed72-4032-a153-2a09dbf1641a',
      },
      {
        id: 'b46c21e0-9b65-4f06-8fea-d365502a4163',
        name: 'Lakes',
        categoryId: '242ff743-ed72-4032-a153-2a09dbf1641a',
      },
      {
        id: '0f229f3f-666d-4f95-afec-9dd0572ce140',
        name: 'Mountains',
        categoryId: '242ff743-ed72-4032-a153-2a09dbf1641a',
      },
      {
        id: '9a5de16f-a397-4776-9691-f0b196586d65',
        name: 'Rivers',
        categoryId: '242ff743-ed72-4032-a153-2a09dbf1641a',
      },
    ],
  },
];
export default CommunityListSearch;
