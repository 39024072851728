/* eslint-disable */
import ajax from '../utils/ajaxHelper';
import { useCallback } from 'react';

const useHouseList = () => {
  const getAllHouses = useCallback(async (val) => {
    try {
      return await ajax.get(`house/get-all`);
    } catch (error) {
      return error;
    }
  });
  const getHousesByUser = useCallback(async (val) => {
    try {
      const value = { userId: val.userId, typeText: val.typeText };

      return await ajax.post(`house/get-by-user`, value);
    } catch (error) {
      return error;
    }
  });

  const getStatusById = useCallback(async (val) => {
    try {
      const value = { userId: val.userId, houseId: val.houseId };
      return await ajax.post(`house/get-status`, value);
    } catch (error) {
      return error;
    }
  });

  const addHouseAsFavourite = useCallback(async (val) => {
    try {
      const values = { userId: val.userId, houseId: val.houseId, typeText: val.typeText };
      return await ajax.post(`house/add-as-favourite`, values);
    } catch (error) {
      return error;
    }
  });

  return {
    getAllHouses,
    addHouseAsFavourite,
    getHousesByUser,
    getStatusById,
  };
};

export default useHouseList;
