/*  Author -  Rutvik Gandhi 
    Date - 24/2/2023
    Description - Login Page For User
*/
/* eslint-disable*/
import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Box,
  Tabs,
  Tab,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import IconButton from '@material-ui/core/IconButton';
import { FastField, Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import { object, string } from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import Cookies from 'js-cookie';
import '../Auth.style.css';
import useLogin from '../../../hooks/useAuth';
import AuthLogo from '../../../components/AuthLogo';
import SignUp from '../SignUp';
import CustomTextInput from '../../../components/Common/CustomTextInput';
import { showErrIfTouched } from '../../../utils';
import { KEY } from '../../../constants/globalConstants';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.isRequired,
  index: PropTypes.isRequired,
  value: PropTypes.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LoginSchema = object().shape({
  emailId: string().required('Required Field: Please enter an email'),
  password: string().required('Required Field: Please enter your password'),
});

const Login = ({ handleClose, changeTabSignUp }) => {
  // const classes = useStyles();
  const [value, setValue] = React.useState(changeTabSignUp);
  const [checkFlag, setCheckFlag] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { login } = useLogin();
  Cookies.remove('path');
  const [values1, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values1, showPassword: !values1.showPassword });
  };

  const recaptchaRef = React.useRef();

  return (
    <Formik
      initialValues={{ emailId: '', password: '', rememberme: '' }}
      validationSchema={LoginSchema}
      onSubmit={async (values, actions) => {
        const token = await recaptchaRef.current.executeAsync();

        if (token) {
          values1.recapToken = token;

          login(values, actions, recaptchaRef.current);
        }
      }}
    >
      {({ errors, isSubmitting, isValid, dirty, touched }) => {
        return (
          <Form autoComplete="off">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className="Container"
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <Grid container alignItems="center" direction="column">
                      <Grid item xs={12}>
                        <Box className="LoginBanner">
                          <img
                            className="LoginHeroImg"
                            src={require('../../../assets/images/Home-Banner.jpg').default}
                            alt="Hero Pic"
                          />
                          <AuthLogo />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="TabBox">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label=""
                      className="CommonTabNav"
                    >
                      <Tab label="Log In" {...a11yProps(0)} />
                      <Tab label="Sign Up" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0} className="CommonTabpanel">
                      <Box>
                        <Grid item xs={12} className="commonFieldBox">
                          <InputLabel className="InputLabelFld" htmlFor="email">
                            Email
                            <Box
                              mb={1}
                              component="span"
                              display="inline-block"
                              className="Asterisk"
                            >
                              *
                            </Box>
                          </InputLabel>
                          <FastField
                            as={TextField}
                            name="emailId"
                            id="outlined-basic"
                            variant="outlined"
                            type="email"
                            error={!!showErrIfTouched('emailId', errors, touched)}
                            helperText={showErrIfTouched('emailId', errors, touched)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel className="InputLabelFld" htmlFor="password">
                            Password
                            <Box
                              mb={1}
                              component="span"
                              display="inline-block"
                              className="Asterisk"
                            >
                              *
                            </Box>
                          </InputLabel>
                          <Field
                            component={CustomTextInput}
                            name="password"
                            type={values1.showPassword ? 'text' : 'password'}
                            id="outlined-basic-2"
                            variant="outlined"
                            error={!!showErrIfTouched('password', errors, touched)}
                            helperText={showErrIfTouched('password', errors, touched)}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleClickShowPassword()}
                                  >
                                    <Choose>
                                      <When condition={values1.showPassword}>
                                        <VisibilityOutlinedIcon />
                                      </When>
                                      <Otherwise>
                                        <VisibilityOffOutlinedIcon />
                                      </Otherwise>
                                    </Choose>
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="Wrapper ButtonWrapper">
                            <Button
                              disabled={isSubmitting || !dirty || !isValid}
                              type="submit"
                              className="Button loginButton"
                              variant="contained"
                              color="primary"
                            >
                              Log In
                            </Button>
                            {isSubmitting && (
                              <CircularProgress size={24} className="ButtonProgress" />
                            )}
                          </Box>
                          <Box mt={2}>
                            <Typography variant="body2">
                              <Link
                                style={{ textDecoration: 'inherit' }}
                                className="ForgotPassword"
                                to="../app/forgot-password"
                                onClick={handleClose}
                              >
                                Forgot Password
                              </Link>
                            </Typography>
                          </Box>
                          <Box mt={2} textAlign="center">
                            <Typography variant="body2">
                              Don’t have an account?
                              <Button
                                style={{ textDecoration: 'inherit' }}
                                className="SignUpButton LinkTypeButton"
                                onClick={() => {
                                  setValue(1);
                                }}
                              >
                                Sign Up
                              </Button>
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1} className="CommonTabpanel">
                      <Box>
                        <SignUp value={value} setValue={setValue} handleClose={handleClose} />
                      </Box>
                    </TabPanel>
                  </Grid>
                </Grid>
              </Grid>
              <ReCAPTCHA ref={recaptchaRef} sitekey={KEY.SITE} size="invisible" />
            </Grid>
            {/* <If condition={checkFlag === true}>
            <Box className={classes.forgotPass}>

            <ForgotPassword  setValue={setValue} />
            </Box>
            </If> */}
          </Form>
        );
      }}
    </Formik>
  );
};

Login.propTypes = {
  changeTabSignUp: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default Login;
