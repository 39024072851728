/* eslint-disable react/no-array-index-key */
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const TableSkeleton = () => {
  return (
    <Box width="100%">
      <Table>
        <TableBody>
          {/* Repeat this TableRow for as many rows as you want */}
          {[...Array(6)].map((_, index) => (
            <TableRow key={`table-row-${index}`}>
              {/* Repeat this TableCell for 5 columns */}
              <TableCell>
                <Skeleton height={18} />
                <Skeleton animation="wave" height={12} />
              </TableCell>
              <TableCell>
                <Skeleton height={18} />
                <Skeleton animation="wave" height={12} />
              </TableCell>
              <TableCell>
                <Skeleton height={18} />
              </TableCell>
              <TableCell>
                <Skeleton height={18} />
              </TableCell>
              <TableCell>
                <Skeleton height={18} />
              </TableCell>
              <TableCell>
                <Skeleton height={18} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TableSkeleton;
