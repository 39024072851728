/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Grid, Link, Typography, Box, Button, Container, IconButton } from '@material-ui/core';
import { ReactComponent as TrashIcon } from '../../../assets/images/TrashWhite.svg';
import { Formik, Form, Field, useFormik, FastField } from 'formik';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useCommunityList from '../../../hooks/useCommunityList';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import ModalEditViewDelete from '../ModalEditViewDelete/ModalEditViewDelete';
import { backendUrl } from '../../../constants/globalConstants';
import * as Yup from 'yup';

const CommunityUpdateCoverPhoto = (props) => {
  const theme = useTheme();
  /*  */
  const [snackbar, setSnackbar] = useState(false);

  const [communityDetails, setCommunityDetails] = React.useState(null);
  const closeSnackbar = () => {
    setSnackbar(false);
  };
  const { updateCoverPhoto, deleteCoverPhoto, getCommunityDetailById } = useCommunityList();

  /*  */
  const fileInput = React.useRef();
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);
  const [isCoverPhotoDeleted, setCoverPhotoDeleted] = useState(false);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const maxImages = 6;
  const maxFileSize = 5 * 1024 * 1024;
  const imgDimensions = { width: 1200, height: 720 };
  const [uploadError, setUploadError] = useState(null);
  const [isImagesDirty, setIsImagesDirty] = useState(false);
  const formikRef = React.createRef();
  let imageDim = {};
  const [isCommunityRepresentative] = useState(params?.id === loggedInUserData?.communityId);
  const [previmages, setPrevimages] = useState(images);
  const [isAccessible, setIsAccessible] = useState(false);

  const handleBackToParent = (e, formikObj) => {
    e.preventDefault();
    console.log(
      'formikObj.dirty ::' +
        formikObj.dirty +
        ', formikObj.isValid::' +
        formikObj.isValid +
        ', formikObj.isSubmitting::' +
        formikObj.isSubmitting +
        'isImagesDirty ::' +
        isImagesDirty,
    );
    // if (!(!formikObj.dirty || !formikObj.isValid || formikObj.isSubmitting) || isImagesDirty) {
    if (
      !(!formikObj.dirty || formikObj.isSubmitting) ||
      isImagesDirty ||
      images.length !== previmages.length ||
      isCoverPhotoDeleted
    ) {
      setShowDeleteBox(true);
    } else {
      navigate(`../../community-details/${params?.id}`);
    }
  };
  const onDeleteBack = (event) => {
    navigate(`../../community-details/${params?.id}`);
  };
  const onTakeMeback = (event) => {
    //console.log('here');
    setShowDeleteBox(false);
  };

  const isSupportedFileType = (file) => {
    const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    return acceptedTypes.includes(file.type);
  };

  const getUniqueIdentifier = (file) => {
    console.log('getUniqueIdentifier');
    return `${file.size}`;
  };

  const loadImageAndCheckDimensions = (src, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (Number(width) < Number(maxWidth) /* || Number(height) !== Number(maxHeight) */) {
          resolve('Incorrect Photo dimensions');
        } else {
          resolve('Photo dimensions are within the allowed limits.');
        }
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = src;
    });
  };

  const handleImageUpload = async (event, values, errors, form, field) => {
    //console.log(event, 1111);
    if (event) {
      const selectedFiles = event.target.files;
      let checkImageDims = '';
      let newImages = [];

      for (const file of selectedFiles) {
        if (!isSupportedFileType(file)) {
          setUploadError('Incorrect file type');
          return;
        }

        if (file.size > maxFileSize) {
          setUploadError('File size too large, max file size is 5mb');
          return;
        }
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          try {
            const imageSrc = await new Promise((resolve, reject) => {
              reader.onload = () => {
                resolve(reader.result);
              };
              reader.onerror = (error) => {
                reject(error);
              };
            });

            checkImageDims = await loadImageAndCheckDimensions(
              imageSrc,
              imgDimensions.width,
              imgDimensions.height,
            );
          } catch (error) {
            console.error('Error loading image:', error);
          }
        }
        // imgDimensions
        newImages = Array.from(selectedFiles).filter(
          (fileInner) =>
            isSupportedFileType(fileInner) &&
            fileInner.size <= maxFileSize &&
            !images.some((image) => {
              return getUniqueIdentifier(image) === getUniqueIdentifier(file);
            }),
        );
        if (newImages.length === 0) {
          setUploadError('Duplicate image is not allowed');
          return;
        }
        newImages = Array.from(selectedFiles);
        if (newImages.length + images.length > maxImages) {
          alert(`You can only select up to ${maxImages} images.`);
          return;
        }
        if (checkImageDims === 'Incorrect Photo dimensions') {
          setUploadError(
            'Photo dimensions not supported. Please upload images with a width of 1200 pixels.', //  and a height of 720 pixels
          );
          return;
        }
        console.log(newImages, 5555);
      }
      /* eslint-disable */
      setUploadError(null);
      console.log(newImages, 5656);
      const imageList = [...images, ...newImages];
      setImages(imageList);
      console.log(imageList, 1111);
      // setPrevimages(imageList);
    }
  };

  const handleImageDelete = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    //console.log(newImages, 8686);
    setImages(newImages);
    // setPrevimages(newImages);
  };

  useEffect(() => {
    handleImageUpload();
  }, [uploadError]);

  useEffect(() => {
    console.log(images.length, 'images');
    console.log(previmages.length, 'previmages');
    if (
      previmages.length > 0 &&
      images.length !== previmages.length
      // ||
      // (images.length > 0 && images.length !== previmages.length)
    ) {
      setIsImagesDirty(true);
      return;
    }

    for (let i = 0; i < images.length; i++) {
      if (
        previmages.length > 0 &&
        images[i]?.name !== previmages[i].name &&
        images[i]?.size !== previmages[i].size
      ) {
        //console.log(images[i], '!==', previmages[i]);
        setIsImagesDirty(true);
        return;
      }
    }
    setIsImagesDirty(false);
  }, [images]);

  const fillCommunityDetails = async () => {
    if (params?.id) {
      let communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults.data);
      // if (communityDetails?.images) setImages([communityDetails?.images]);
    }
  };

  useEffect(() => {
    fillCommunityDetails();
  }, []);

  useEffect(() => {
    setIsAccessible(
      communityDetails?.userrole?.[0]?.id === loggedInUserData?.id ||
        loggedInUserData?.roleId === 2,
    );
  }, [communityDetails]);
  {
    console.log(isAccessible, 9999);
  }
  return (
    <Container>
      <Grid container className="PageSpacing FixedMD">
        <Grid item xs={12}>
          {isAccessible ? (
            <>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e, formikRef.current);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    {params?.amenityId !== 'default' ? 'Edit ' : 'Add New '}
                    Community Photo
                  </Typography>
                </Link>
              </Grid>
              <Grid container className="FixedID">
                <Grid item xs={12}>
                  <Formik
                    enableReinitialize
                    innerRef={formikRef}
                    initialValues={{ coverPhoto: undefined }}
                    validationSchema={Yup.object().shape({
                      coverPhoto: Yup.mixed()
                        .test('fileSize', 'File size too large, max file size is 5mb', (fileVal) =>
                          fileVal ? fileVal.size <= 5000000 : true,
                        )
                        .test('fileType', 'Incorrect file type', (fileVal) =>
                          fileVal
                            ? ['image/png', 'image/jpg', 'image/jpeg'].includes(fileVal.type)
                            : true,
                        ),
                    })}
                    validateOnChange
                    onSubmit={async (values, { resetForm }) => {
                      try {
                        let result = { status: false };
                        if (isCoverPhotoDeleted) {
                          result = await deleteCoverPhoto({
                            communityId: params?.id,
                          });
                        }
                        if (images?.length) {
                          const formData = new FormData();
                          formData.append('communityId', params?.id);
                          formData.append('coverPhoto', images[0]);

                          result = await updateCoverPhoto(formData);
                        }

                        setTimeout(() => {
                          if (result?.status) {
                            resetForm();

                            setImages([]);
                            navigate(`../../community-details/${params?.id}`);
                          }
                        }, 1000);
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                  >
                    {({
                      handleSubmit,
                      setFieldValue,
                      isValid,
                      isSubmitting,
                      dirty,
                      touched,
                      handleChange,
                      values,
                      errors,
                    }) => (
                      <Form className="" onSubmit={handleSubmit} encType="multipart/form-data">
                        <Grid container className="AddCoverForm">
                          <Grid item xs={12} className="">
                            <Field name="imageList">
                              {({ field, form }) => (
                                <Box className="CoverImagesContainer">
                                  <Box className="">
                                    {/* <Box className="AmenityImagesContent"> */}
                                    <If
                                      condition={communityDetails?.images && !isCoverPhotoDeleted}
                                    >
                                      <Box key={0} elevation={3} className="CoverImageBox">
                                        <img
                                          src={backendUrl + communityDetails?.images}
                                          alt={`Image-0`}
                                          className="CoverImg"
                                        />
                                        <IconButton
                                          onClick={() => {
                                            setCoverPhotoDeleted(true);
                                          }}
                                          className="CoverImageDeleteBox"
                                        >
                                          <TrashIcon />
                                        </IconButton>
                                      </Box>
                                    </If>
                                    {!(communityDetails?.images && !isCoverPhotoDeleted) &&
                                      images.map((image, index) => (
                                        <Box key={index} elevation={3} className="CoverImageBox">
                                          <img
                                            src={URL.createObjectURL(image)}
                                            alt={`Image-${index + 1}`}
                                            className="CoverImg"
                                          />
                                          <IconButton
                                            onClick={() => handleImageDelete(index)}
                                            className="CoverImageDeleteBox"
                                          >
                                            <TrashIcon />
                                          </IconButton>
                                        </Box>
                                      ))}
                                    {console.log(images, 5555)}
                                    {!(communityDetails?.images && !isCoverPhotoDeleted) &&
                                      images.length === 0 && (
                                        <label
                                          htmlFor="image-input"
                                          className="CoverImagesUploadBox"
                                        >
                                          <input
                                            id="image-input"
                                            ref={fileInput}
                                            type="file"
                                            accept=".png,.jpg,.jpeg"
                                            style={{ display: 'none' }}
                                            onChange={async (event) => {
                                              setFieldValue(
                                                'coverPhoto',
                                                event.currentTarget.files[0],
                                              );
                                              handleImageUpload(event, values, errors, form, field);
                                              /* const validForm = await validateForm({
                                              coverPhoto: event.currentTarget.files[0],
                                            }); */
                                              // if (validForm?.coverPhoto) setSnackbar(true);
                                              // submitForm();
                                            }}
                                          />

                                          <Box elevation={3} className="CoverAddImagesBox">
                                            <PhotoCameraOutlinedIcon
                                              color="primary"
                                              fontSize="large"
                                            />
                                            <Typography
                                              variant="body2"
                                              color="primary"
                                              component="div"
                                              className="CoverAddImagesLabel"
                                            >
                                              Add Photo
                                            </Typography>
                                          </Box>
                                        </label>
                                      )}
                                  </Box>
                                  {uploadError && (
                                    <Box mt={2}>
                                      <Typography
                                        variant="body2"
                                        component="div"
                                        className="CustomError"
                                      >
                                        {uploadError}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Box className="Wrapper ButtonWrapper">
                              <Button
                                disabled={!isCoverPhotoDeleted && images.length == 0}
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="EditViewButton"
                                fullWidth
                              >
                                Publish
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
              {/* {console.log(showDeleteBox, 5689)} */}
              {showDeleteBox && (
                <>
                  <ModalEditViewDelete
                    status={true}
                    navigateUrl={`../../community-details/${params?.id}`}
                    onDelete={onDeleteBack}
                    onClose={onTakeMeback}
                  ></ModalEditViewDelete>
                </>
              )}
            </>
          ) : (
            'You do not have permissions to access this url.'
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
CommunityUpdateCoverPhoto.defaultProps = {};
export default CommunityUpdateCoverPhoto;
