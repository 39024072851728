/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Menu,
  MenuItem,
  Button,
  Typography,
  Box,
  Tab,
  Tabs,
  InputBase,
  InputAdornment,
  TextField,
  Popover,
  FormControlLabel,
  Checkbox,
  Paper,
  InputLabel,
  Container,
  Hidden,
  Link,
  Avatar,
  Divider,
} from '@material-ui/core';

import Pagination from '@material-ui/lab/Pagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useCommunityList from '../../../hooks/useCommunityList';
import useJobList from '../../../hooks/useJobList';
import '../Main.style.css';
import JobListFilter from './JobListFilter';
import { FastField, Formik, Form, Field } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { ReactComponent as SearchIcon } from '../../../assets/images/Search-Icon.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/Clock-Icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import { entityEnum } from '../../../constants/globalConstants';
import '../Main.style.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import moment from 'moment';
import Loader from '../../../components/Loader/Loader';
import { ReactComponent as JobComingSoonIcon } from '../../../assets/images/JobComingSoonIcon.svg';
import CardLoader from '../../../components/CardLoader/CardLoader';
import TableSkeleton from '../../../components/TableSkeleton/TableSkeleton';
import JobLoader from '../../../components/JobLoader/JobLoader';

const JobList = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobPage, setJobPage] = useState(1);
  const [jobFilterReset, setJobFilterReset] = useState(false);
  const searchObject = {};
  const handleChangeJobPage = (event, newJobPage) => {
    setJobPage(newJobPage);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const key = 'iFrasCwdJh';
  const [communityDetails, setCommunityDetails] = useState(null);
  const [jobList, setJobList] = useState(null);
  const { getCommunityDetailById } = useCommunityList();
  const {
    getJobListByCommunity,
    getRecentJobListByCommunity,
    getDifferenceInHrs,
    getJboardImportIds,
  } = useJobList();
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobNoLimit, setJobNoLimit] = useState(3);
  const open = Boolean(anchorEl);
  let startJobIndex = (jobPage - 1) * 5;
  let endJobIndex =
    jobList && startJobIndex + 5 > jobList.length ? jobList.length : startJobIndex + 5;
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);

  const params = useParams();
  const navigate = useNavigate();

  const resetJobList = async () => {
    setJobList(null);
  };

  const fillJobList = async (values) => {
    let communityDetailsResults = {};
    if (params?.id) {
      if (!props?.communityInfo) {
        communityDetailsResults = await getCommunityDetailById({
          communityId: params?.id,
          userId,
        });
      } else {
        communityDetailsResults['data'] = props?.communityInfo;
      }

      let jobList = [];
      if (props?.communityDetailView) {
        jobList = await getRecentJobListByCommunity({
          // communityName: communityDetailsResults?.data?.communityNameText?.toLowerCase(),
          communityId: params?.id,
        });
        console.log(jobList, 'JJLL');
      } else {
        jobList = await getJobListByCommunity({
          // communityName: communityDetailsResults?.data?.communityNameText?.toLowerCase(),
          // searchObject: values || null,
          communityId: params?.id,
        });
      }
      console.log(jobList, 'JJLL11');
      let tempJobList = [];
      tempJobList = jobList?.data?.filter((job) =>
        job?.location
          .toLowerCase()
          .includes(communityDetailsResults?.data?.communityNameText?.toLowerCase()),
      );
      jobList = tempJobList;
      console.log(jobList, 'JJKKK');
      if (values) {
        const { jobTitle, jobCat, jobType, jobIsRemote, jobPostedAt } = values || null;
        let filteredJobs = [];

        filteredJobs = jobList;
        if (
          jobTitle == '' &&
          jobCat?.id == 0 &&
          jobType?.id == 0 &&
          !jobIsRemote &&
          jobPostedAt?.id == 0
        ) {
          filteredJobs = jobList;
        } else {
          const currentUTCDateString = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
          let fromTime = '';

          switch (jobPostedAt?.id) {
            case 1: {
              fromTime = moment().clone().subtract(24, 'hours');
              break;
            }
            case 2: {
              fromTime = moment().clone().subtract(3, 'days');
              break;
            }

            case 3: {
              fromTime = moment().clone().subtract(7, 'days');
              break;
            }
            case 4: {
              fromTime = moment().clone().subtract(14, 'days');
              break;
            }
            case 5: {
              fromTime = moment().clone().subtract(30, 'days');
              break;
            }
            default: {
              break;
            }
          }
          // skills, company name etc.
          filteredJobs = jobList?.filter(
            (job) =>
              job?.title?.toLowerCase().includes(jobTitle?.toLowerCase()) ||
              job?.description?.toLowerCase().includes(jobTitle?.toLowerCase()) ||
              job?.employer?.name?.toLowerCase().includes(jobTitle?.toLowerCase()),
          );

          // skills, company name etc.
          // console.log(filteredJobs, 2000, jobCat?.id);
          filteredJobs =
            jobCat?.id > 0
              ? filteredJobs.filter((job) => job?.category_id === jobCat?.id)
              : filteredJobs;
          // console.log(filteredJobs, 3000);

          filteredJobs =
            jobType?.id > 0
              ? filteredJobs.filter((job) => job?.job_type_id === jobType?.id)
              : filteredJobs;
          // console.log(filteredJobs, 4000);

          filteredJobs =
            jobPostedAt?.id > 0
              ? filteredJobs.filter((job, i) => {
                  const postedAtTime = moment(job?.posted_at);
                  const currentTime = moment();

                  const hoursDifferencePostedAt = currentTime.diff(postedAtTime, 'hours');
                  if (i == 5) {
                    // console.log(currentTime.diff(fromTime, 'hours'), 600, i);
                    // console.log(hoursDifferencePostedAt, 700);
                    currentTime.diff(fromTime, 'hours');
                  }

                  if (hoursDifferencePostedAt <= currentTime.diff(fromTime, 'hours')) {
                    return job;
                  }
                })
              : filteredJobs;

          // setIsLoading(false);
          filteredJobs = filteredJobs.filter((job) => job?.remote === jobIsRemote);
        }
        console.log(filteredJobs, 8000);
        setJobList(filteredJobs);
      } else {
        if (jobList) {
          let defaultFilterJobs = [];
          jobList.map((job) => {
            if (
              !job?.remote &&
              job?.location
                .toLowerCase()
                .includes(communityDetailsResults.data.communityNameText.toLowerCase())
            ) {
              defaultFilterJobs.push(job);
            }
          });
          setJobList(defaultFilterJobs);

          // setJobList(jobList);
        }
      }

      setCommunityDetails(communityDetailsResults.data);
    }

    return communityDetailsResults?.data;
  };

  const fetchImports = async (req, res) => {
    const response = await getJboardImportIds({ communityId: params?.id });
    // console.log(response.data.data, 'ROW');
  };
  // fetchImports();

  const receiveSearchObject = async (values) => {
    setJobPage(1);
    await fillJobList(values);
  };
  function resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  }

  const handleBackToParent = async () => {
    console.log(jobList, 7575);
    if (jobList.length > 0) {
      navigate(
        `../../community-details/${params?.id || communityDetails?.id}${
          communityDetails?.isRepresented ? '#Section5' : '#Section6'
        }`,
      );
    } else {
      setIsLoading(false);
      setJobFilterReset(true);
      const result = await fillJobList();
      if (result) {
        setIsLoading(true);
      }
    }
  };
  const updateJobList = async () => {
    setJobList(null);
  };

  useEffect(() => {
    fillJobList();
  }, []);

  useEffect(() => {
    if (!props?.communityDetailView) {
      jobList && setJobNoLimit(jobList.length);
    } else if (props?.communityDetailView) {
      setJobNoLimit(3);
    }
    if (!jobList) {
      setIsLoading(false);
    }
    if (jobList) {
      setIsLoading(true);
    }

    console.log(jobList, 9696);
  }, [jobList]);

  useEffect(() => {
    if (props?.onTotalJobs) {
      props?.onTotalJobs(jobList?.length);
    }
  }, [jobList, props?.onTotalJobs]);

  return (
    <Formik>
      <Container className="JobListContainer">
        <Box className="PageSpacing">
          {/* <iframe
          width="100%"
          height="360"
          frameBorder="0"
          scrolling="false"
          onload="resizeIframe(this)"
          src="https://move-to-happy.myjboard.io/widget/jobs?filter%5Bjob_importer_id%5D=8219&filter%5Bfinal_status%5D=activew%5hide_page_title%5D=1&w%5Bjobs_limit%5D=3"
          // src="https://move-to-happy.myjboard.io/widget/jobs?q=Job%20list&location=Lagrange%2C%20Georgia%2C%20United%20States&location_id=1428947&w%5Bhide_page_title%5D=1&w%5Bjobs_limit%5D=20"
        ></iframe> */}

          {!props?.communityDetailView && (
            <Grid container>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    {communityDetails?.communityNameText?.toUpperCase()}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={12}>
              <If condition={jobList !== null && jobList.length > 0}>
                {' '}
                <Typography variant="h3" component="div" className="jobListMainHeading">
                  All Jobs in{' '}
                  <span>
                    {communityDetails?.communityNameText},{' '}
                    {communityDetails?.stateCommunities?.stateShortName}
                  </span>
                </Typography>
              </If>
            </Grid>
            <Grid container spacing={3} className="FilterContainer">
              {!props?.communityDetailView && (
                <Hidden only={['md', 'lg', 'xl']}>
                  <Grid item xs={12}>
                    <Box className="FilterMobileBox">
                      <Button
                        className="SearchButton"
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleFilterClick}
                        startIcon={<FilterListIcon color="primary" />}
                        variant="outlined"
                      >
                        Filters{' '}
                      </Button>
                      <Popover
                        id="long-menu"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleFilterClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        className="TransparentPopover FilterPopover"
                      >
                        <JobListFilter
                          jobFilterReset={jobFilterReset}
                          getSearchObject={receiveSearchObject}
                          updateJobList={updateJobList}
                        />
                      </Popover>
                    </Box>
                  </Grid>
                </Hidden>
              )}

              <Grid item xs={12} sm={12} md={8} lg={9} className="JobListColumn">
                {/* {console.log(jobList)} */}
                <If condition={!isLoading}>
                  <JobLoader />
                </If>
                {jobList
                  ?.sort((a, b) => {
                    const timestampA = moment.utc(a.posted_at);
                    const timestampB = moment.utc(b.posted_at);

                    if (timestampA.isBefore(timestampB)) {
                      return 1;
                    } else if (timestampA.isAfter(timestampB)) {
                      return -1;
                    } else {
                      return 0;
                    }
                  })
                  ?.slice(startJobIndex, !props?.communityDetailView ? endJobIndex : jobNoLimit)
                  ?.map((job, index) => {
                    return (
                      <Link
                        underline="none"
                        onClick={(e) => {
                          navigate(
                            `../../job-details/${params?.id || communityDetails?.id}/${job?.id}/${
                              job?.employer_id
                            }`,
                          );
                        }}
                      >
                        <Paper className="JobPostBox" key={index}>
                          <Box className="JobPostLogo">
                            <Avatar
                              className="JobPostLogo"
                              alt={job?.title}
                              src={job?.employer?.logo}
                            >
                              <span>
                                {job?.employer?.name?.split(' ')[1] !== undefined
                                  ? job?.employer?.name?.split(' ')[0].split('')[0] +
                                    job?.employer?.name?.split(' ')[1].split('')[0]
                                  : job?.employer?.name?.split(' ')[0].split('')[0]}
                              </span>
                            </Avatar>
                            {/* <img src={job?.employer?.logo} alt="" /> */}
                          </Box>
                          <Box className="JobPostContent">
                            <Link
                              onClick={(e) => {
                                navigate(
                                  `../../job-details/${params?.id || communityDetails?.id}/${
                                    job?.id
                                  }/${job?.employer_id}`,
                                );
                              }}
                              // href={job?.job_details_url || ''}
                              // target="_blank"
                            >
                              <Typography
                                variant="body2"
                                component="div"
                                className="JobPostHeading"
                              >
                                {job?.title || ''}
                              </Typography>
                            </Link>
                            <Typography
                              variant="caption"
                              component="div"
                              className="JobPostAddress"
                            >
                              <span>{job?.employer?.name || ''}</span>  • 
                              <span>{job?.location || ''}</span>
                            </Typography>
                          </Box>
                          <Box className="JobPostIcon">
                            {/* <FavoriteBorderIcon color="primary" /> */}
                            <Typography
                              variant="h6"
                              component="div"
                              className="JobPostTiming FlexCenterStart"
                              color="primary"
                            >
                              <ClockIcon color="primary" />
                              {getDifferenceInHrs(job?.posted_at) || ''} ago
                            </Typography>
                          </Box>
                        </Paper>
                      </Link>
                    );
                  })}

                <If condition={jobList !== null && jobList.length == 0}>
                  <Typography variant="h3" component="div" className="jobListMainHeading">
                    All Jobs in{' '}
                    <span>
                      {communityDetails?.communityNameText},{' '}
                      {communityDetails?.stateCommunities?.stateShortName}
                    </span>
                  </Typography>
                  <Box className="JobEmptyState">
                    <Box className="JobEmptyStateContent">
                      <JobComingSoonIcon />
                      <Typography variant="h3" component="div">
                        Coming Soon!
                      </Typography>
                      <Typography variant="body1" component="div" className="LightgreyColor">
                        Stay tuned for job openings in {communityDetails?.communityNameText}.{' '}
                      </Typography>
                    </Box>
                    <Divider />
                  </Box>
                </If>
                {!props?.communityDetailView && jobList && jobList.length > 0 && (
                  <Box className="PaginationWrapper BasicPagination">
                    {console.log(endJobIndex, 8787)}
                    <Typography variant="body2" component="span" className="PaginationNumber">
                      <Box component="span">{startJobIndex + 1}</Box>-
                      <Box component="span">{endJobIndex}</Box> of{' '}
                      <Box component="span">{jobList.length}</Box> results
                    </Typography>
                    <Pagination
                      count={Math.ceil(Number(jobList.length / 5))}
                      page={jobPage}
                      onChange={handleChangeJobPage}
                      color="primary"
                    />
                  </Box>
                )}
              </Grid>
              {!props?.communityDetailView && (
                <Hidden only={['xs', 'sm']}>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <JobListFilter
                      jobFilterReset={jobFilterReset}
                      getSearchObject={receiveSearchObject}
                      updateJobList={updateJobList}
                    />
                  </Grid>
                </Hidden>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Formik>
  );
};

export default JobList;
