/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Videoposter from '../../../assets/images/Video-poster.png';
import ModalUploadVideo from './ModalUploadVideo';
import video from './Trussville-video.mp4';

import { Typography, Button, Box } from '@material-ui/core';
// import useStyles from '../../Main/Main.style';
// import useCommunityList from '../../../../hooks/useCommunityList';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import useCommunityList from '../../../hooks/useCommunityList';
import ModalRemoveVideo from './ModalRemoveVideo';
import { backendUrl } from '../../../constants/globalConstants';

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="CloseButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const ModalEditVideo = (props) => {
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const { videoId, video, fetchVideoData, onEditUploadSuccess, onDeleteUploadSuccess } = props; // Access the videoId from the props
  console.log(onEditUploadSuccess, 'SHOW');

  // Log the videoId to the console
  console.log('videoId:', video);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(props.status);

  const [openRemoveVideoModal, setOpenRemoveVideoModal] = useState(false);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [isEditVideoModalOpen, setEditVideoModalOpen] = useState(false);

  console.log(open, 'OPEN1');
  const handleCloseEdit = () => {
    setEditVideoModalOpen(false);
    props.onClose(false);
  };

  console.log(openUploadModal, 'ttr');

  const openEditVideoModal = () => {
    // Code to open ModalEditVideo

    // Open ModalUploadVideo in edit mode
    props.setUploadVideoMode('change');
    setEditVideoModalOpen(true);
  };

  const closeModal = () => {
    setEditVideoModalOpen(false);
  };

  const closeEditVideoModal = () => {
    setEditVideoModalOpen(false);
  };

  // Function to open the Remove Video modal
  const openRemoveVideoModalHandler = () => {
    setOpenRemoveVideoModal(true);
  };

  // Function to close the Remove Video modal
  const closeRemoveVideoModalHandler = () => {
    setOpenRemoveVideoModal(false);
  };

  useEffect(() => {}, []);
  return (
    <>
      {/* Start Delete any changes Modal */}
      <Dialog
        className="DeleteDialog"
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogTitle
          className="DialogTitleLeft"
          id="customized-dialog-title"
          onClose={handleCloseEdit}
        >
          <Typography variant="h3" component="div" className="DialogTitleHeading TextColor">
            Edit Community Video
          </Typography>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className="AboutVideoBox AboutVideoMainBox">
            <video width="100%" height="100%" src={backendUrl + video} controls />
          </Box>
        </MuiDialogContent>
        <MuiDialogActions className='DialogActionsWrap'>
          <Button color="secondary" variant="contained" onClick={openRemoveVideoModalHandler}>
            Remove Video
          </Button>
          {openRemoveVideoModal && (
            <ModalRemoveVideo
              status={openRemoveVideoModal}
              onClose={closeRemoveVideoModalHandler}
              onDelete={openRemoveVideoModalHandler} // Pass the function that deletes the video
              videoId={videoId}
              handleCloseEdit={handleCloseEdit}
              fetchVideoData={fetchVideoData}
              onDeleteUploadSuccess={onDeleteUploadSuccess}
            />
          )}
          <Button
            onClick={handleCloseEdit}
            variant="outlined"
            color="primary"
            className="NoBorderButton"
          >
            CANCEL
          </Button>
          <Button color="primary" variant="contained" onClick={openEditVideoModal}>
            Change Video
          </Button>
          {isEditVideoModalOpen && (
            <ModalUploadVideo
              onCloseEditUpload={closeEditVideoModal}
              onClose={closeModal}
              status={isEditVideoModalOpen}
              handleCloseEdit={handleCloseEdit}
              videoId={videoId}
              uploadVideoMode={'change'}
              fetchVideoData={fetchVideoData}
              onEditUploadSuccess={onEditUploadSuccess}
            />
          )}
        </MuiDialogActions>
      </Dialog>
      {/* End Delete any changes Modal */}
    </>
  );
};
ModalEditVideo.defaultProps = {};
export default ModalEditVideo;
