/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FastField, Formik, Form, Field } from 'formik';
import { Typography, Button, Box, InputLabel, CircularProgress } from '@material-ui/core';
import Videoposter from '../../../assets/images/Video-poster.png';
import video from './Trussville-video.mp4';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { DragIndicatorSharp } from '@material-ui/icons';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useCommunityList from '../../../hooks/useCommunityList';
import DragDropZone from './DragDropZone';
import useLogin from '../../../hooks/useAuth';
import Loader from '../../../components/Loader/Loader';

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="CloseButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const ModalUploadVideo = (props) => {
  const navigate = useNavigate();
  const { uploadVideoRequest, editVideoRequest, getCommunityDetailById } = useCommunityList();

  const [open, setOpen] = React.useState(props.status);
  const [isFileCorrect, setIsFileCorrect] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [communityDetails, setCommunityDetails] = useState(null);
  const [isCommunityRepresentative, setCommunityRepresentative] = useState(
    params?.id === loggedInUserData?.communityId,
  );

  const { videoId, fetchVideoData, handleCloseEdit, onEditUploadSuccess } = props; // Access the videoId from the props
  console.log(onEditUploadSuccess, 'SHOW');
  console.log(videoId, 'VIDD');

  console.log(isFileCorrect, 'FILECHECK');
  const handleFileUpload = (file) => {
    // Set the isFileCorrect state to true, indicating that the file is correct
    setIsFileCorrect(true);
    setSelectedFile(file);
  };

  const fileInputRef = useRef(null);

  const fillCommunityDetails = async () => {
    let communityDetailsResults = [];
    if (params?.id) {
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults.data);
    }
    setCommunityRepresentative(
      params?.id === loggedInUserData?.communityId && communityDetailsResults?.data?.isRepresented,
    );
    // return communityDetailsResults?.data;
  };

  useEffect(() => {
    fillCommunityDetails();
  }, []);

  const params = useParams();
  let communityId = params?.id;
  console.log(communityId, 'COMMU_ID');

  const key = 'iFrasCwdJh';
  let bytes = null;

  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    // window.location.hash = '#/app/home-page';
  }

  const { getRoleData } = useLogin();

  console.log(loggedInUserData?.roleId);

  const [repId] = useState(loggedInUserData?.id);

  const [userData, setUserData] = useState();

  const [userId] = useState(loggedInUserData?.id);

  const [loading, setLoading] = useState(false);

  const getUserData = async () => {
    const userDataResult = await getRoleData({ userId });
    setUserData(userDataResult.usersResult);
    console.log(userDataResult, 'GET');
    return userDataResult;
  };

  console.log(userData, 'UDM');

  useEffect((newValue) => {
    if (userCookie) {
      getUserData();
    }
  }, []);

  console.log(repId, 'repp');

  console.log(props.uploadVideoMode, 8871);

  console.log(fetchVideoData, 'FDFD');

  const onSubmit = async () => {
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      const fileSize = selectedFile.size;
      if (props.uploadVideoMode === 'upload') {
        formData.append('video', selectedFile);
        formData.append('repId', repId);
        formData.append('isAdminEntry', Boolean(loggedInUserData?.roleId === 2));
        formData.append('communityId', communityId);
        formData.append('communityRepEmail', userData?.emailId);
        formData.append('communityName', communityDetails?.communityNameText);
        formData.append('userName', userData?.firstName);
        formData.append('fileSize', fileSize);
      } else if (props.uploadVideoMode === 'change') {
        formData.append('editedVideo', selectedFile);
        formData.append('videoId', props.videoId);
        formData.append('communityRepEmail', userData?.emailId);
        formData.append('userName', userData?.firstName);
      }

      try {
        if (props.uploadVideoMode === 'upload') {
          const response = await uploadVideoRequest(formData);
          if (response.status === true) {
            handleClose();
            props.onUploadSuccess();
            fetchVideoData();
          } else {
            console.error('Video upload failed:', response.data);
          }
        } else if (props.uploadVideoMode === 'change') {
          const response = await editVideoRequest(formData); // Call the edit video request API
          if (response.status === true) {
            onEditUploadSuccess();
            handleCloseEdit();
            handleClose();
            fetchVideoData();
            window.location.reload();
            // You can add specific handling for editing video success here
          } else {
            console.error('Video edit failed:', response.data);
          }
        }
      } catch (error) {
        console.error('Video upload/edit error:', error);
      }
    } else {
      console.error('No file selected');
    }
  };

  console.log(open, 'OPEN');

  const handleClose = () => {
    setOpen(false);
    props.onCloseEditUpload(false);
    props.onClose(false);
  };

  useEffect(() => {}, []);
  return (
    <Formik>
      {/* Start Delete any changes Modal */}
      <Dialog
        className="DeleteDialog ModalUploadVideo"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogTitle
          className="DialogTitleLeft"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography variant="h3" component="div" className="DialogTitleHeading TextColor">
            {props.uploadVideoMode === 'change'
              ? 'Request Community Video Change'
              : 'Upload a Community Video'}
          </Typography>
          {/* <CloseIcon onClick={handleClose} /> */}
        </MuiDialogTitle>
        <MuiDialogContent>
          <InputLabel className="InputLabelFld">How it works</InputLabel>
          <Typography gutterBottom variant="body2" component="div">
            After submitting your video, our team will review it to ensure the content follows the
            Move to Happy guidelines. You will be notified of the decision via email as well as on
            your Community page.
          </Typography>
          <Box>
            <DragDropZone onFileUpload={handleFileUpload} />
          </Box>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            className="NoBorderButton"
          >
            CANCEL
          </Button>
          <Button
            disabled={!selectedFile || loading}
            color="primary"
            variant="contained"
            onClick={onSubmit}
            className="NowrapButton"
          >
            {loggedInUserData?.roleId === 1 ? (
              loading ? (
                <Loader size={24} color="inherit" />
              ) : (
                'Submit for Review'
              )
            ) : (
              'Add to Community'
            )}
          </Button>
        </MuiDialogActions>
      </Dialog>
      {/* End Delete any changes Modal */}
    </Formik>
  );
};
ModalUploadVideo.defaultProps = {};
export default ModalUploadVideo;
